import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import { withTranslation } from 'react-i18next';

class Table2 extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.editRow = {
           driverName:null
        };
    
        this.state = {
          error: null,
	        drivers: null,
          isLoading: true
        };
    }

    componentDidUpdate() {
        console.log ("Report5Table1:componentDidUpdate");
    }
    

    componentDidMount() {
        console.log ("Report5Table1:componentDidMount");
        this.getDrivers();
    }

    getDrivers = (props) =>{  
      fetch(process.env.REACT_APP_HOST_URL + 'commonPeopleDriversDS.php', {
        method: 'POST',
        body: JSON.stringify(
          {userId: this.props.userDetails.userId, companyId: this.props.userDetails.companyId})
        })
      .then(response => response.json())
      .then(data => this.setState({drivers: data}))
      .catch(error => this.setState({drivers:null}));  
    }


    afterSaveCell = (cellOld, cellNew, row, column) =>
    {
        console.log('Editing: ' + column.dataField + ":" + cellOld +":" + cellNew);
        console.log (row);

        this.editRow = row;

        if (column.dataField==='driverName')
        {
   	  this.editRow.driverName=cellNew;
        }

        this.update();	
    }


    update() {
      fetch(process.env.REACT_APP_HOST_URL+'report5UpdateDriverDS.php',
          {
              method: 'POST',
              body: JSON.stringify(
              {
                  userId      : this.props.userDetails.userId,
                  companyId   : this.props.userDetails.companyId,
                  month       : this.props.monthYear.month,
                  year        : this.props.monthYear.year,
                  eventId     : this.editRow.eventId,
                  driverName  : this.editRow.driverName
              })
          })
          .then(response => response.json())
          .then(data => this.props.handleDriverUpdate())
          .catch(error => null);
    }

      
    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'eventId',
            text: 'Event Id',
            editable: false,
            hidden: true          
        }, {
  	        dataField: 'eventStartDateTimeLocal',
            text: t('Enter')+'('+this.props.userDetails.timeZone+')',
            editable: false,
            sort: true
        }, {
            dataField: 'eventDateTimeLocal',
            text: t('Exit')+'('+this.props.userDetails.timeZone+')',
            editable: false,
            sort: true
        }, {
            dataField: 'driverName',
            text: t('Driver'),
            sort: true,
            editable: true,
            editor: {
                type: Type.SELECT,
                options: this.state.drivers
           }
        }, {
            dataField: 'zoneDescription',
            text: t('Zone'),
            editable: false,
            sort: true          
        }, {
            dataField: 'duration',
            text: t('Duration'),
            editable: false,
            sort: true,
   	    sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/:/g, '');
              var y = b.replace(/:/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }, {
            dataField: 'vehicleDescription',
            text: t('Vehicle'),
            sort: true          
        }];

        const rowEvents = {
           onClick: (e, row, rowIndex) => {
             console.log(`Report5Table1:Clicked on row with index: ${row.eventStartDateTimeLocal} `);
           },
           onMouseEnter: (e, row, rowIndex) => {
             console.log(`Report5Table1:Enter on row with index: ${row.no} ${rowIndex}`);
           },
           onMouseLeave: (e, row, rowIndex) => {
             console.log(`Report5Table1:Leave on row with index: ${row.no} ${rowIndex}`);
           }
        };
        
          const MyExportCSV = (props) => {
            const handleClick = () => {
              props.onExport();
            };
            return (
              <div>
                <button className="btn btn-success" onClick={ handleClick }>{t('Export_CSV')}</button>
              </div>
            );
          };

        return  (
        <div>
            <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>
            {this.props.driverName} {t('Details')}</h3>
            <ToolkitProvider 
                keyField='eventId'
                data={ this.props.data } 
                columns={ columns }                 
                exportCSV
            >
            {
              props => (
                <div>        
                  <BootstrapTable { ...props.baseProps }
                    bootstrap4
                    classes="table-responsive-s"
                    exportCSV
                    keyField='eventId'                
                    striped={true} 
                    hover={true} 
                    condensed={true}          
                    pagination={ paginationFactory({sizePerPage:15})} 
                    rowEvents={ rowEvents } 
		                cellEdit={ cellEditFactory({mode: 'click' , blurToSave:true, afterSaveCell: this.afterSaveCell })}
                    headerClasses="thead-dark" />

                    <MyExportCSV { ...props.csvProps }>Export</MyExportCSV>
                </div>
              )
            }</ToolkitProvider>
        </div>)
    }
}

export default withTranslation()(Table2);
