import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import ZoneMapInfoWindow from './ZoneMapInfoWindow';

/* global google */

function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    },
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.ROAD 
  };
}


class ZoneMap extends Component {
  static defaultProps = {
    center: {
      lat: 47.526352,
      lng: -92.701149 
    },
    zoom: 11,
  };


  constructor(props)
  {
    super(props);
      const heatMapData = {
      positions: [
        {lat: 0, lng: 0},
      ],
      options: {
        radius: 1,
        opacity: 0.6
      }
    };


    this.state = {
       zoneInfos: null,
       selectedPolygon: null,
       zoom : 11,
       center: {
         lat: 47.526352,
         lng: -92.701149
       },
       isLoading: true,
       heatMapData:heatMapData
    };


    this.polygons = [];
    this.map=null;
    this.maps=null;
    this.clickLatLng=null;

        // set default drawing styles
    this.styles = {
      polygon: {
      fillColor: '#00ff80',
      fillOpacity: 0.3,
      strokeColor: '#008840',
      strokeWeight: 1,
      clickable: true,
      editable: true,
      zIndex: 1
    }};
  }

  componentWillReceiveProps(nextProps){
    console.log("ZoneMap:componentWillReceiveProps");
  }
  
  componentDidMount() {
    console.log("ZoneMap:componentDidMount");
  }


  handleApiLoaded =(google) => {
    console.log ("handleApiLoaded:",google);

    this.map = google.map;
    this.maps=google.maps;

    this.getZoneInfos(true);

    var drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null, //Default the hand 
      drawingControlOptions: {
        drawingModes: [ google.maps.drawing.OverlayType.POLYGON ]
      },
      drawingControl: true,
      polygonOptions: {
        draggable: true,
        editable: true,
        handleZoneAdd: this.handleZoneAdd
      }
    });
    drawingManager.setMap(google.map);

    google.maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {
      drawingManager.setDrawingMode(null); //Exit drawing mode

      if (event.type === 'polygon') {
        var path = event.overlay.getPath();

        //Add Polygon with default description,type and speedLimit
        event.overlay.handleZoneAdd (path,"", "", "", 0);

        event.overlay.setMap(null);
      }
    }); 
  }


  getZoneInfos = (reCenter) =>{
      console.log("ZoneMap:getZoneInfos", this.props.userDetails.userId, " ", this.props.userDetails.companyId);
      this.setState({isLoading: true });
      fetch(process.env.REACT_APP_HOST_URL+'zoneMapZoneInfoDS.php',
      {
          method: 'POST',
          body: JSON.stringify(
          {
             userId    : this.props.userDetails.userId,
             companyId : this.props.userDetails.companyId
          })
      })
      .then(response => response.json())
      .then(data => {this.setState({ zoneInfos:data });
                     this.zonesDraw(data, reCenter);})
      .catch(error => this.setState({ error, isLoading: true }));
  }

  zonesDraw =(zoneInfos, reCenter) => {
    console.log("ZoneMap:zonesDraw", zoneInfos, reCenter);

    var bounds = new google.maps.LatLngBounds();

    //Remove all current Polygons on map
    for (var n=0; n<this.polygons.length; n++)
    {
      this.polygons[n].setMap(null);
    }
    this.polygons=[];

    var i = 0;
    while (i < zoneInfos.length) {
      var polygon = new google.maps.Polygon(
        { strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: '#FF0000',
          fillOpacity: 0.35, 
          userDetails:this.props.userDetails, zoneInfo:zoneInfos[i], handleSelect:this.handleSelect});

      for (var p=0;p<zoneInfos[i].points.length; p++){
        bounds.extend(zoneInfos[i].points[p]);
      }

      polygon.setPath(zoneInfos[i].points);

      google.maps.event.addListener(polygon.getPath(), 'insert_at', function(index, obj) {
        console.log ("insert-at",index,obj);
      });
      google.maps.event.addListener(polygon.getPath(), 'set_at', function(index, obj) {
        console.log ("set-at",index,obj);
      });
      google.maps.event.addListener(polygon.getPath(), 'remove_at', function(index, obj) {
        console.log ("remove-at",index,obj);
      });

      polygon.addListener('click', function(e) {
        console.log ("click", e, this.userDetails, this.zoneInfo);
        this.handleSelect(this.zoneInfo.id, this, e.latLng);
      });

      // and display it on the map
      polygon.setMap(this.map);

      this.polygons.push(polygon);

      i++;
    }

    if(reCenter===true) this.map.fitBounds(bounds);
  };

  handleSelect = (id, polygon, clickLatLng) => {
    console.log('ZoneMap:handleSelect',clickLatLng);
    this.clickLatLng=clickLatLng;
    if (this.state.selectedPolygon==null) {
      polygon.setEditable(true);
      this.setState({selectedPolygon:polygon});
    }
  }

  handleZoneDelete = () => {
    console.log('ZoneMap:handleZoneDelete');
    this.handleZoneUpdate (0, 
                          this.state.selectedPolygon.zoneInfo.description, 
                          this.state.selectedPolygon.zoneInfo.speedLimit, 
                          this.state.selectedPolygon.zoneInfo.exportId, 
                          this.state.selectedPolygon.zoneInfo.type); //Mark as inactive
  }


  handleZoneAdd = (path, description, speedLimit, exportId, type) => {
    console.log('ZoneMap:handleZoneAdd', description, speedLimit, type);

    if (description==undefined) description="";
    if (speedLimit==undefined)  speedLimit="";
    if (exportId==undefined)    exportId="";
    if (type==undefined)        type=0;

    var points = [];

    for (var i=0; i < path.getLength(); i++) {
      points.push({
        latitude: path.getAt(i).lat(),
        longitude: path.getAt(i).lng()
      });
    }

    fetch(process.env.REACT_APP_HOST_URL+'zoneMapAddDS.php',
    {
       method: 'POST',
       body: JSON.stringify(
       {
          userId      : this.props.userDetails.userId,
          companyId   : this.props.userDetails.companyId,
          description : description,
          type        : type,
          speedLimit  : speedLimit,
          exportId    : exportId  ,
          points      : points
       })
    })
    .then(response => response.json())
    .then(data => {this.setState({zoneInfos:data});
                   this.zonesDraw(data, false); })
    .catch(error => this.setState({ error, isLoading: true }));
  }


  handleZoneUpdate = (state, description, speedLimit, exportId, type) => {
    if (description==undefined) description=this.state.selectedPolygon.zoneInfo.description;
    if (speedLimit==undefined) speedLimit=this.state.selectedPolygon.zoneInfo.speedLimit;
    if (exportId==undefined) exportId=this.state.selectedPolygon.zoneInfo.exportId;
    if (type==undefined) type=this.state.selectedPolygon.zoneInfo.type;

    console.log('ZoneMap:handleZoneUpdate', state, description, speedLimit, type);

    var path = this.state.selectedPolygon.getPath();

    console.log ( path.getLength());

    var points = [];

    for (var i=0; i < path.getLength(); i++) {
      points.push({
        latitude: path.getAt(i).lat(),
        longitude: path.getAt(i).lng()
      });
    }

    //Save updated zone
    fetch(process.env.REACT_APP_HOST_URL+'zoneMapUpdateDS.php',
    {
       method: 'POST',
       body: JSON.stringify(
       {
          userId      : this.props.userDetails.userId,
          companyId   : this.props.userDetails.companyId,
          zoneId      : this.state.selectedPolygon.zoneInfo.id,
          description : description,
          type        : type,
          state       : state,
          speedLimit  : speedLimit,
          exportId    : exportId,
          points      : points
       })
    })
    .then(response => response.json())
    .then(data => {this.setState({zoneInfos:data}); 
                   this.zonesDraw(data, false); })
    .catch(error => this.setState({ error, isLoading: true }));
  }

  handleRemoveInfoWindow = () => {
    console.log('ZoneMap:handleRemoveInfoWindow');
    this.state.selectedPolygon.setEditable(false);
    this.setState({selectedPolygon:null});
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
           yesIWantToUseGoogleMapApiInternals
           onGoogleApiLoaded={this.handleApiLoaded}
           bootstrapURLKeys={{ key: 'AIzaSyDlrKQh55n9-jY2KHHrUIx2t3dQOG30P98',  libraries: 'drawing,visualization' }}
           defaultCenter={this.state.center}
           center={this.state.center}
           zoom={this.state.zoom}
           defaultZoom={this.state.zoom}
           options={createMapOptions} 
	         onClick={this.onClickCallback}
           onChildClick={this.onChildClickCallback} >
        </GoogleMapReact>

         {(this.state.selectedPolygon != null) && [
          <ZoneMapInfoWindow
             userDetails = {this.props.userDetails}
             handleZoneUpdate = { this.handleZoneUpdate }
             handleRemoveInfoWindow = { this.handleRemoveInfoWindow }
             zoneInfo = {this.state.selectedPolygon.zoneInfo}
             position = {this.clickLatLng}
             editable = {true}
          />  ]}

      </div>
    );
  }
}

export default ZoneMap;
