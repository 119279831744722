import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';

class ReportUnkownHoleTable extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.editRow = null;
        this.editPatternNo = null;

        this.state = {
        };
    }

    componentDidMount() {       
      console.log ("ReportUnkownHoleTable:componentDidMount:",this.props.unkownHoles);
    }


    afterSaveCell = (cellOld, cellNew, row, column) =>    
    {
        console.log('ReportUnkownHoleTable:Editing: ' + column.dataField + ":" + cellOld +":" + cellNew);
        console.log (row);

        this.editRow = {...row}; //force copy else we have pointer to row
       
        if (column.dataField==='patternNo') {
          this.editRow.patternNo=cellNew;

          let temp=this.props.patternNoList.find(x => x.value == cellNew); //Just in cae its  is is still empty...
          if (temp) this.editRow.siteId=temp.siteId; else this.editRow.siteId=0; //Server side will not update
        } else if (column.dataField==='holeNo') {
          this.editRow.holeNo=cellNew;
        }

        this.props.updateHole(this.editRow);
    }

    render() 
    { 
        const { t } = this.props;

        var editAuth = false;
        if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_PATTERN) > 0) editAuth=true;
   
        const columns = [{
            dataField: 'timeStampLocal',
            text: 'Server' + t('DateTime')+'('+this.props.userDetails.timeZone+')',
            editable: false,
            sort: true   ,
         }, {
            dataField: 'eventDateTimeLocal',
            text: 'GPS ' + t('DateTime')+'('+this.props.userDetails.timeZone+')',
            editable: false,
            sort: true   ,
         }, {
            dataField: 'vehicleDescription',
            text: t('Vehicle'),
            editable: false,
            sort: true,
         }, {
            dataField: 'siteId',
            text: 'siteId',
            editable: false,
            hidden: true,
        }, {
            dataField: 'patternNo',
            text: t('Pattern_No'),
            editable: editAuth,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; },
            editor: {
              type: Type.SELECT,
              options: this.props.patternNoList
            },
        }, {
            dataField: 'holeNo',
            text: t('Hole_No'),
            editable: editAuth,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; } ,
        }, {
            dataField: 'weight',
            text: t('Weight')+ '('+this.props.userDetails.massMUnit+')',
            editable: false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.holeId} ${row.eventStartDateTimeLocal}`);
            },
              onMouseEnter: (e, row, rowIndex) => {
            }
          };

        return  (            
            <div>
               <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Unkown_Holes')}</h3>
                 <BootstrapTable 
                     bootstrap4
                     classes="table-responsive-sm"
                     keyField='holeId'    
                     striped={true} 
                     hover={true} 
                     condensed={true} 
                     data={ this.props.unkownHoles } 
                     columns={ columns } 
                     rowEvents={ rowEvents } 
                     headerClasses="thead-dark"
	                   pagination={ paginationFactory({sizePerPage:15})}
                     cellEdit={ cellEditFactory({mode: 'click', blurToSave:true, afterSaveCell: this.afterSaveCell, onStartEdit: (row) => { this.editHoleId=row.holeId }})}
                 />
            </div>
        )
    }
}

export default withTranslation()(ReportUnkownHoleTable);
