import Chart from "react-google-charts";
import React from "react";
import { withTranslation } from 'react-i18next';

class Chart1 extends React.Component 
{   
        prevRow=-1; 
    constructor(props) 
    {
        super(props);
    
        this.state = {
          chartData: null,
          error: null,
          isLoading: true
        };
    }

    componentDidUpdate() {
      }

    componentWillReceiveProps(nextProps){        
      if ((this.props.patternId!=nextProps.patternId) || (this.props.siteId!=nextProps.siteId)) this.getData(nextProps);
   }  

    getData = (props) =>{
        this.setState({isLoading: true });
        fetch(process.env.REACT_APP_HOST_URL+'report4Chart1DS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                  companyId     : props.userDetails.companyId,
                  userId        : props.userDetails.userId,
                  siteId        : props.siteId,
                  startDate     : props.startDate,
                  endDate       : props.endDate,
                  patternId     : props.patternId,
                  vehicleIdList : props.vehicleIdList
                })
            })
          .then(response => response.json())
          .then(data => this.setState({ data, isLoading: false }))
          .catch(error => this.setState({ error, isLoading: true }));
    }
    
    
    componentDidMount() {
        this.getData(this.props)
    }  

    
    render() 
    { 
        const { t } = this.props;

        if (this.state.isLoading)
        {
            return (<p>{t('Loading')}</p>);
        }

        var divStyle = {
            clear:'both',
           
        };

        return  (
        <div style={divStyle}>
            <Chart 
                chartType="Timeline"
                loader={<div>{t('Loading')}</div>}
                data={this.state.data.chartData}
                chartEvents={[
                {
                    eventName: "ready",
                    callback: ({ chartWrapper, google }) => {
                      const chart = chartWrapper.getChart();
                      google.visualization.events.addListener(chart, "onmouseover", e => {
                        const { row, column } = e;
                        if (this.prevRow!=row) { this.prevRow=row;  console.warn("MOUSE OVER ", { row, column} ,this.state.data.holeData[row].holeId ); this.props.onUpdateHoleSelect(this.state.data.holeData[row].holeId);}
                      });
                      google.visualization.events.addListener(chart, "onmouseout", e => {
                        const { row, column } = e;
                        if (this.prevRow!=-1) { this.prevRow=-1;  console.warn("MOUSE OUT ", { row, column }); this.props.onUpdateHoleSelect(0);}
                      });
                    }
                  }
                ]}
                options={{
                    showRowNumber: true,
                    title: t('TimeLine'),
                    height: 300,
                    timeline: { groupByRowLabel: true },
                    timeline: { colorByRowLabel: true },
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        </div>
        )
    }
}

export default withTranslation()(Chart1);
