import React, { Component } from "react";
import SetupProductTable from './SetupProductTable.1';
import AuthoriseObj from './Authorise.js'
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

 
class SetupProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {         
      newProductDescription: '',
      errorProductDescription: false,
      products: null
    };
    
    this.handleChangeProductDescription = this.handleChangeProductDescription.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  componentDidMount() {
    console.log ("SetupProduct:componentDidMount");
    this.getProducts();
  }


  handleChangeProductDescription(event) {
    this.setState({newProductDescription: event.target.value, errorProductDescription: false});
  }


  handleSubmit(event) {
    console.log("SetupProduct:handleSubmit:",this.state.newProductDescription);
    event.preventDefault();

    if(this.state.newProductDescription){
      fetch(process.env.REACT_APP_HOST_URL+'setupProductAddDS.php',
      {
         method: 'POST',
         body: JSON.stringify(
         {
           userId      : this.props.userDetails.userId,
           companyId   : this.props.userDetails.companyId,
           description : this.state.newProductDescription,
           stateDescription : 'Active'
         })
      })
      .then(response => response.json())
      .then(data => {this.setState({ products:data, newProductDescription: ''});})
      .catch(error => this.setState({ error, isLoading: true }));

    } else {
      this.setState({errorProductDescription: true}); 
    }
  }


  getProducts = () =>{
    fetch(process.env.REACT_APP_HOST_URL+'setupProductsGetDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId
      })
    })
    .then(response => response.json())
    .then(data => this.setState({ products:data, isLoadingPatterns: false }))
    .catch(error => this.setState({ error, isLoadingPatterns: true }));
  }


  render() {
    const { t } = this.props;

    return (
      <div>
        {(this.state.products != null) && [
          <SetupProductTable            
            userDetails = {this.props.userDetails} 
            companyId   = {this.props.userDetails.companyId}
            getProducts = {this.getProducts}
            products    = {this.state.products} />
        ]}

        {((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_PRODUCT) > 0) && [
          <div>
            <h4>{t('Add_New_Product')} </h4>
            <Form key={3} onSubmit={this.handleSubmit}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 200 }}>
                  <TextField
                    required
                    type          = "text"
                    id            = "productDescription"
                    label         ={t('Product_Description')}
                    error         = {this.state.errorProductDescription}
                    onChange      = {this.handleChangeProductDescription}
                    value         = {this.state.newProductDescription}
                    maxLength     = {64}
                  />
                </FormControl>
                <Button key={4} variant="contained" size="large" type="submit"> {t('Add')}</Button>
              </div>
            </Form>
          </div>
        ]}
      </div>
    );
  }
}

export default withTranslation()(SetupProduct);

