import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';

class AdminUsersTableSummary extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.editRow = null;

        this.state = {
        };
    }

    componentDidMount() {       
      console.log ("AdminUsersTableSummary:componentDidMount()");
    }


    updateUser = (row) => {       
        fetch(process.env.REACT_APP_HOST_URL+'adminUpdateUserInfoDS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                    userId        : this.props.userDetails.userId,
                    companyId     : this.props.userDetails.companyId,
                    userIdEdit    : row.id,
                    companyIdEdit : this.editRow.companyId,
                    permissions   : this.editRow.permissions,
                    unitId        : this.editRow.unitId,
                    timeZone      : this.editRow.timeZone,
                })
            })
          .then(response => response.json())
          .then(data => this.props.getUserInfos())
          .catch(error => this.setState({ error }));   
    }

    afterSaveCell = (cellOld, cellNew, row, column) =>    
    {
        console.log('"AdminUserTableSummary:Editing: ' + column.dataField + ":" + cellOld +":" + cellNew);
        console.log (row);

        this.editRow = {...row}; //force copy else we have pointer to row
       
        if (column.dataField==='permisions') {
          this.editRow.permissions=cellNew;
        } else if (column.dataField==='companyId') {
          this.editRow.companyId=cellNew;
        } else if (column.dataField==='unitId') {
          this.editRow.unitId=cellNew;
        } else if (column.dataField==='timeZone') {
          this.editRow.timeZone=cellNew;
        }

        this.updateUser(row);
    }

    render() 
    { 
        const { t } = this.props;

        var editAuth = false;
        if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_ADMIN_USERS) > 0) editAuth=true;
   
        const columns = [{
            dataField: 'id',
            text: 'User Id',
            hidden: true
        }, {
            dataField: 'userName',
            text: t('User_Name'),
            editable: false,
            sort: true,
            filter: textFilter(),
         }, {
            dataField: 'createdTimeStamp',
            text: t('Date'),
            editable: false,
            sort: true,
            hidden: false
        }, {
            dataField: 'companyId',
            text: t('Company'),
            editable: editAuth,
            sort: true,
            formatter: (cell, row) => {
              return this.props.companies.find(x => x.value === cell)?this.props.companies.find(x => x.value === cell).label:'Unkown';
            },
            editor: {
              type: Type.SELECT,
              options: this.props.companies
            },
        }, {
            dataField: 'email',
            text: t('Email'),
            editable: false,
            sort: true,
        }, {
            dataField: 'timeZone',
            text: t('Timezone'),
            editable: editAuth,
             headerStyle: (colum, colIndex) => {
              return { width: '100px', textAlign: 'center' };
            },
            sort: true
        }, {
            dataField: 'language',
            text: t('Language'),
            editable: false,
             headerStyle: (colum, colIndex) => {
              return { width: '100px', textAlign: 'center' };
            },
            sort: true
        }, {
            dataField: 'phoneNo',
            text: t('Phone_No'),
            editable: false,
            headerStyle: (colum, colIndex) => {
              return { width: '150px', textAlign: 'center' };
            },
            sort: true
        }, {
            dataField: 'permissions',
            text: t('Permissions'),
            editable: editAuth,
             headerStyle: (colum, colIndex) => {
              return { width: '150px', textAlign: 'center' };
            },
            sort: true
        }, {
            dataField: 'unitId',
            text: t('Units'),
            editable: editAuth,
             headerStyle: (colum, colIndex) => {
              return { width: '100px', textAlign: 'center' };
            },
            formatter: (cell, row) => {
              return this.props.units.find(x => x.value === cell).label;
            },
            editor: {
              type: Type.SELECT,
              options: this.props.units
            },
            sorte: true
        }, {
            dataField: 'lastLoginTimeStamp',
            text: t('Last_Login'),
            editable: false,
            sort: true
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.id} ${row.description}`);
            }
          };

        return  (            
            <div>
               <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Admin_Users')}</h3>
                 <BootstrapTable 
                     bootstrap4
                     classes="table-responsive"
                     keyField='id'    
                     striped={true} 
                     hover={true} 
                     condensed={true} 
                     data={ this.props.userInfos } 
                     columns={ columns } 
                     rowEvents={ rowEvents } 
                     headerClasses="thead-dark"
	                   pagination={ paginationFactory({sizePerPage:15})}
                     filter={ filterFactory()}
                     cellEdit={ cellEditFactory({mode: 'click', blurToSave:true, afterSaveCell: this.afterSaveCell, onStartEdit: (row) => { this.editUserId=row.id }})}
                 />
            </div>
        )
    }
}

export default withTranslation()(AdminUsersTableSummary);
