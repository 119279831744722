import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import AuthoriseObj from './Authorise.js'
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { DatePicker } from '@y0c/react-datepicker';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import moment from "moment"
import dayjs from "dayjs"


class MapSurveyInfoWindowEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles          : [],
      selectedDate      : dayjs(),
      selectedVehicle   : null,
      loadedWeight      : null,
      errorLoadedWeight    : false,
      errorSelectedVehicle : false,
    };
  }


  componentDidMount() {
    console.log("MapSurveyInfoWindowEdit:componentDidMount");
    this.getVehicles();
  }


  getVehicles = () =>{
    fetch(process.env.REACT_APP_HOST_URL+'reportVehiclesDS.php',
    {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            options   : 0,
        })
    })
    .then(response => response.json())
    .then(data => this.setState({ vehicles:data }))
    .catch(error => this.setState({ vehicles:[] }));
  }

  onDateChange = (date) => {
    console.log("MapSurveyInfoWindowEdit:onDateChange:");
    this.setState({selectedDate:date});
  }


  handleSelectVehicle = (selectedVehicle) => {
    this.setState({ selectedVehicle });
    console.log("MapSurveyInfoWindowEdit:handleChangeVehicle:", selectedVehicle);
    this.setState({errorSelectedVehicle: false});
  }


  handleChangeLoadedWeight = (event) => {
    var loadedWeight = event.target.value;
    console.log("MapSurveyInfoWindowEdit:handleChangeVehicle:", loadedWeight);
    if (event.target.validity.valid) {
      this.setState({loadedWeight:loadedWeight});
      this.setState({errorLoadedWeight: false});
    }
  }


  handleSave = (event) => {
    console.log("MapSurveyInfoWindowEdit:handleSave:",this.state.selectedDate, this.state.selectedVehicle, this.state.loadedWeight);

    if((this.state.selectedDate !== null) && (this.state.selectedVehicle !== null) && (this.state.loadedWeight!==null)) //We have some edits
    {
      let dateMySql = moment(this.state.selectedDate).format('YYYY-MM-DD');
      console.log("MapSurveyInfoWindowEdit:dateMySql:",dateMySql);

      this.props.handleSave(dateMySql, this.state.selectedVehicle, this.state.loadedWeight);
    }
    else
    {
      if (this.state.loadedWeight===null) this.setState({errorLoadedWeight: true}); else this.setState({errorLoadedWeight: false});
      if (this.state.selectedVehicle===null) this.setState({errorSelectedVehicle: true}); else this.setState({errorSelectedVehicle: false});
    }
   // event.stopPropagation(); //Otherwise HeatMap toggle
  }


  handleCancel = (event) => {
    console.log("MapSurveyInfoWindowEdit:handleCancel");
    this.props.handleCancel();
    event.stopPropagation(); //Otherwise HeatMap toggle
  }


  render() {
    const { t } = this.props;
    const holeInfo = this.props.holeInfo;

    let initialDate = dayjs();

    const style = {
    control: base => ({
      ...base,
       backgroundColor: (this.state.errorSelectedVehicle===false) ? 'white':'lightpink' 
    })
  };

    return (
      <div>
        <div>
         <h4 key={11} style={{ captionSide: 'top', textAlign: 'center', color: 'black' }}>{t('Add_Load')}</h4>
          <form>
             <div style={{ float:'left', maxWidth: '500px', margin: 'auto' }}>
              <DatePicker 
                onChange={this.onDateChange}
                initialDate ={initialDate}
                />
            </div>

            <div style={{ float:'left', width: '200px'}}>
              <Select
                 styles={style}
                value={this.state.selectedVehicle}
                onChange={this.handleSelectVehicle}
                options={this.state.vehicles}
                isMulti ={false}
                closeMenuOnSelect={true}
                placeholder ={t('Select_Vehicles')}
              />
            </div >

            <div>
              <input 
                id='LoadedWeight'
                style={{float:'left', textAlign:'right', fontSize:15, padding: '10px', border:'1px solid darkGrey',backgroundColor: (this.state.errorLoadedWeight===false) ? 'white':'lightpink'} }
                type="text" onChange={this.handleChangeLoadedWeight}
                value={this.state.loadedWeight}
                placeholder={t('Weight') + '('+this.props.userDetails.massMUnit+')'}
                maxLength = {4}
                pattern="^[0-9]{1,32}$"/>
            </div>

          </form>
        </div>
        <div style ={{ clear:'both'}}>
          <Button size="sm" onClick={this.handleSave}>{t('OK')}</Button>
          {" "}
          <Button size="sm" onClick={this.handleCancel}>{t('Cancel')}</Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MapSurveyInfoWindowEdit);

