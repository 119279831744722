import React, { Component } from "react";
import { RangeDatePicker } from '@y0c/react-datepicker';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import moment from "moment"
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import dayjs from "dayjs"

import TableHoleInfo from './CommonTableHoleInfo';
import TableDailySummary from './Report2TableDailySummary';
import Map    from './CommonMap';
import Chart1 from './Report4Chart.1';
import { withTranslation, useTranslation } from 'react-i18next';
import i18n from '../translations/i18n';


class Report2 extends Component {

    constructor(props) {
        super(props);

        let now       = dayjs();
        let endDate   = now;
        let startDate = now.subtract('1', 'day');
 
        this.state = {
            startDate  : startDate.format('YYYY-MM-DD'),
            endDate    : endDate.format('YYYY-MM-DD'), 
            selectedVehicles: [],
            selectedSite: null,
            isLoadingSites:true,
            isLoadingVehicles:true,
            selectedHoleId : 0,
            holeInfos : null,
            loadInfos : null,
            filteredHoleInfos : null,
            dailySummaries : null,
            drivers: null,
            blasters: null,
      	    productTypes: null,
            error:null,
            sites : [],
            vehicles : [],
            AdvancedChecked : false,
            holeInfoVersion : 0
        };

        this.handleChangeSites = this.handleChangeSites.bind(this);
        this.handleChangeVehicles = this.handleChangeVehicles.bind(this);
    }


    componentDidMount() {       
      this.getSites();
    }


    getSites = () =>{
        //this.setState({isLoading: true });
        fetch(process.env.REACT_APP_HOST_URL+'reportSitesDS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                    userId    : this.props.userDetails.userId,
                    companyId : this.props.userDetails.companyId
                })
            })
          .then(response => response.json())
          .then(data => {
            this.setState({ sites:data, isLoadingSites: false })
            if (data.length > 0){
              this.setState({ selectedSite:data[0] });
              this.getVehicles(data[0]);
            }
          })
          .catch(error => this.setState({ error, isLoadingSites: true }));
      }


      getVehicles = (selectedSite) =>{
        //this.setState({isLoading: true });              
        fetch(process.env.REACT_APP_HOST_URL+'reportVehiclesDS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                    userId    : this.props.userDetails.userId,
                    companyId : this.props.userDetails.companyId,
                    siteId    : selectedSite.value 
                })
            })
          .then(response => response.json())
          .then(data => {
            this.setState({ vehicles:data, isLoadingVehicles: false});
            if (data.length > 0 ){
              this.setState({});
            }
          })
          .catch(error => this.setState({ error, isLoadingVehicles: true }));
      }


      onChange = (startDate,endDate) => {       
        console.log(moment(startDate).format('YYYY-MM-DD') + '->'+moment(endDate).format('YYYY-MM-DD'));
        var startDateMySql = moment(startDate).format('YYYY-MM-DD');
        var endDateMySql = moment(endDate).format('YYYY-MM-DD');
        this.setState({startDate:startDateMySql, endDate:endDateMySql, holeInfos:null, dailySummaries:null });
      }


      handleChangeSites = (selectedSite) => {     
        this.setState({ selectedSite, holeInfos:null, dailySummaries:null, selectedVehicles:[] });
        console.log(`Site selected:`, selectedSite );
        this.getVehicles(selectedSite);
      }


      handleChangeVehicles = (selectedVehicles) => {
        this.setState({ selectedVehicles, holeInfos:null, dailySummaries:null });
        console.log(`Vehicles selected:`, selectedVehicles);        
        //this.handleClick();
      }

      
      handleUpdateHoleSelect = (selectedHoleNo) => {
        console.log('Report2:handleUpdateHoleSelect', selectedHoleNo);
        this.setState({selectedHoleNo : selectedHoleNo});
      }

      handleUpdateFilteredHolesInfos = (filteredHoleInfos) => {
        console.log('Report2:handleUpdateFilteredHoles');

        const newHoleInfos = [...this.state.holeInfos];
        for (let i=0; i< this.state.holeInfos.length; i++){
          let index = filteredHoleInfos.findIndex(holeInfo => holeInfo.holeId === this.state.holeInfos[i].holeId);
          if (index === -1) {
            newHoleInfos[i].filtered=1;
          } else {
            newHoleInfos[i].filtered=0;
          }
        }
        console.log ('Report2:handleUpdateFilteredHoles', newHoleInfos);
        this.setState({filteredHoleInfos : newHoleInfos});
      }

      refreshData = (options) => {
        console.log ("Report2:refreshData", options);
          //this.getSurveyData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);

        this.getLoadData ();
        //this.getHoleData ();
  
      }


      handleClick = () => {
        this.setState({ holeInfos:null, dailySummaries:null });
        fetch(process.env.REACT_APP_HOST_URL+'commonTableHoleInfoDS4.php',
        {
          method: 'POST',
          body: JSON.stringify(
          {
            userId        : this.props.userDetails.userId,
            companyId     : this.props.userDetails.companyId,
            siteId        : this.state.selectedSite.value,
            patternId     : 'ALL',
            startDate     : this.state.startDate,
            endDate       : this.state.endDate,
            vehicleIdList : this.state.selectedVehicles.map(a => a.value)
          })
        })
        .then(response => response.json())
        .then(data => {
          var holeInfoVersion = this.state.holeInfoVersion+1;
          this.setState({ holeInfoVersion:holeInfoVersion, holeInfos:data.holeInfos});
        })
        .catch(error => this.setState({ holeInfos:null }));

        this.getLoadData();


        fetch(process.env.REACT_APP_HOST_URL+'report2DailySummariesDS.php',
        {
          method: 'POST',
          body: JSON.stringify(
          {
            userId        : this.props.userDetails.userId,
            companyId     : this.props.userDetails.companyId,
            siteId        : this.state.selectedSite.value,
            startDate     : this.state.startDate,
            endDate       : this.state.endDate,
            vehicleIdList : this.state.selectedVehicles.map(a => a.value)
          })
        })
        .then(response => response.json())
        .then(data => this.setState({ dailySummaries:data }))
        .catch(error => this.setState({ dailySummaries:null }));


        fetch(process.env.REACT_APP_HOST_URL+'commonPeopleDriversDS.php',
        {
          method: 'POST',
          body: JSON.stringify(
          {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId
          })
        })
        .then(response => response.json())
        .then(data => this.setState({ drivers:data }))
        .catch(error => this.setState({ error, isLoading:true }));

        fetch(process.env.REACT_APP_HOST_URL+'commonPeopleBlastersDS.php',
        {
            method: 'POST',
            body: JSON.stringify(
            {
                userId    : this.props.userDetails.userId,
                companyId : this.props.userDetails.companyId
            })
        })
        .then(response => response.json())
        .then(data => this.setState({ blasters:data }))
        .catch(error => this.setState({ error, isLoading:true }));

	      fetch(process.env.REACT_APP_HOST_URL+'commonProductTypesDS.php',
        {
            method: 'POST',
            body: JSON.stringify(
            {
                userId    : this.props.userDetails.userId,
                companyId : this.props.userDetails.companyId
            })
        })
        .then(response => response.json())
        .then(data => this.setState({ productTypes:data }))
        .catch(error => this.setState({ error, isLoading:true }));
      }

    handleUpdateHoleInfos = (data) => {
      data.holeInfos.map(function(holeInfo){
        if (holeInfo.state == 0) holeInfo.counterStateDescription='OK';
        else {
          if((holeInfo.state & 0x01) > 0)  holeInfo.counterStateDescription+=i18n.t('Wet')+' ';
          if((holeInfo.state & 0x02) > 0)  holeInfo.counterStateDescription+=i18n.t('Collapsed')+' ';
          if((holeInfo.state & 0x04) > 0)  holeInfo.counterStateDescription+=i18n.t('Decked')+' ';
          if((holeInfo.state & 0x08) > 0)  holeInfo.counterStateDescription+=i18n.t('CleanOut')+' ';
          if((holeInfo.state & 0x10) > 0)  holeInfo.counterStateDescription+=i18n.t('ReDrill')+' ';
          if((holeInfo.state & 0x20) > 0)  holeInfo.counterStateDescription+=i18n.t('OverBurden')+' ';
          if((holeInfo.state & 0x40) > 0)  holeInfo.counterStateDescription+=i18n.t('Cracked')+' ';
          if((holeInfo.state & 0x100) > 0) holeInfo.counterStateDescription+=i18n.t('Tacked')+' ';
          if((holeInfo.state & 0x200) > 0) holeInfo.counterStateDescription+=i18n.t('TopLoaded')+' ';
          if((holeInfo.state & 0x400) > 0) holeInfo.counterStateDescription+=i18n.t('OverLoaded');
          if((holeInfo.state & 0x800) > 0) holeInfo.counterStateDescription+=i18n.t('UnderLoaded');
          if((holeInfo.state & 0x1000) > 0) holeInfo.counterStateDescription+=i18n.t('Start');
          if((holeInfo.state & 0x2000) > 0) holeInfo.counterStateDescription+=i18n.t('Hot');
        }
      })

      this.setState({ holeInfos:data.holeInfos});
      this.getLoadData();
    }


    handleUpdateHolePosition = (n,latitude,longitude) => {
      console.log('Report2:handleUpdateHolePosition:', n, latitude, longitude );
      var temp = this.state.holeInfos;

      temp[n].latitude=latitude;
      temp[n].longitude=longitude;

      this.setState({holeInfos:temp});
    }


    handleUpdateHoleSelect = (selectedHoleId) => {
      console.log('Report2:handleUpdateHoleSelect', selectedHoleId);
      this.setState({selectedHoleId : selectedHoleId});
    }


    handleRowClick = (date, patternId, vehicleId) => {
      console.log ('Report2:handleRowClick:', date, patternId, vehicleId);
    }

    handleCsvClick = () => {
      console.log("Report2:handleCsvClick");
      fetch(process.env.REACT_APP_HOST_URL+'getCsvPatternDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId        : this.props.userDetails.userId,
           companyId     : this.props.userDetails.companyId,
           siteId        : this.state.selectedSite.value,
           patternId     : "ALL",
           startDate     : this.state.startDate,
           endDate       : this.state.endDate,
           vehicleIdList : this.state.selectedVehicles.map(a => a.value)
        })
      })
      .then(response => response.text())
      .then( data =>{
          var fileDownload = require('js-file-download');
          fileDownload(data, this.state.selectedPatternId+'.csv');
       })
      .catch(error => this.setState({ error, isLoading:true }));
    }

    getLoadData = () => {
      console.log("Report2:getLoadData" );
      fetch(process.env.REACT_APP_HOST_URL+'commonGetLoadsTempDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            siteId    : this.state.selectedSite.value,
            startDate : this.state.startDate,
            endDate   : this.state.endDate,
            vehicles  : this.state.selectedVehicles.map(a => a.value)
        })
      })
      .then(response => response.json())
      .then(data => {
          if(data.length > 0) {
            this.setState({loadInfos:data, layerLoadedVisible:this.layerSurveyVisible?false:true});
          } else {
            this.setState({loadInfos:data});
          }
      })
      .catch(error => this.setState({ loadInfos:null }));
    }

    handleUpdateLoadPosition = (index, newCoords) => {

      if(index >= 0) {
        var tempLoadInfos = [...this.state.loadInfos]; //State must be imutable

        tempLoadInfos[index].latitude=newCoords.lat;
        tempLoadInfos[index].longitude=newCoords.lng;

        if(tempLoadInfos[index].noLoads > 1){
          tempLoadInfos[index].noLoads=1;
          tempLoadInfos[index].holeNo='';
        }

        this.setState({loadInfos:tempLoadInfos});
      }

    }


    render() {       
        const { t } = this.props;

        const { selectedVehicles,selectedSite} = this.state;      
        let now = dayjs();
        let initialDate = now.subtract('2', 'day')
        let initialStartDate = now.subtract('1', 'day');
        let initialEndDate =now;

        var weekdays = [t('Sunday'), t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday')];
        var weekdaysShort = [t('Sun'), t('Mon'), t('Tue'), t('Wed'), t('Thur'), t('Fri'), t('Sat')];

        const locale = {
          name: 'custom',
          weekdays:weekdays, 
          weekdaysShort:weekdaysShort, 
          months: '1,2,3,4,5,6,7,8,9,10,11,12'.split(','),
        };

    return (
        <div>
          <h3 style={{textAlign: 'center', color: 'black'}}>{t('Date_Range_Report')}</h3>
          <div >            
            <div style={{ float:'left', maxWidth: '500px', margin: 'auto' }}> 
              <RangeDatePicker onChange={this.onChange}
                locale={locale}
                showMonthCnt={2}
                initialDate ={initialDate}
                initialStartDate ={initialStartDate}
                initialEndDate= {initialEndDate}
                startPlaceholder={t('Start_Date')}
                endPlaceholder={t('End_Date')}/>  
            </div>
            <div style={{ clear :'both', width: '400px' }}> 
              <Select
                value={selectedSite}
                onChange={this.handleChangeSites}
                options={this.state.sites}
                isMulti ={false}
                placeholder ={t('Select_Site')}
              />
            </div>  
            <div style={{ float:'left', width: '400px' }}> 
              <Select
                value={selectedVehicles}
                onChange={this.handleChangeVehicles}
                options={this.state.vehicles}
                isMulti ={true}
                closeMenuOnSelect = {true}
                closeMenuOnScroll = {true}
                placeholder ={t('Select_Vehicles')}
              />
            </div >
          </div>

          {((this.state.selectedVehicles!==null) && (this.state.selectedVehicles.length >0) && (this.state.startDate!==null)  && (this.state.endDate!==null)) && [
              <div key={1} style={{ clear :'both' , paddingBottom: '20px'}}>
                  <Button onClick={this.handleClick} variant="primary">{t('Search')}</Button>
              </div>
          ]}          

          {(this.state.dailySummaries!==null) && [
            (this.state.dailySummaries.length>0)?
            <div key={12} style={{paddingTop: '20px'}}>
              <TableDailySummary
                key={7}
                onRowClick={this.handleRowClick}
                userDetails={this.props.userDetails}
                dailySummaries={this.state.dailySummaries}/>
            </div> :
            <div>
              {t('No_Data_Found')}
            </div>
          ]}

          {((this.state.holeInfos!==null) && (this.state.holeInfos.length) && 
            (this.state.loadInfos!==null) && (this.state.loadInfos.length) &&
            (this.state.blasters!==null) && (this.state.drivers!==null)) && [
            <div key={2} style={{clear :'both'}}>
              <TableHoleInfo              
                  onUpdateHoleInfos={this.handleUpdateHoleInfos}
                  onUpdateHoleSelect={this.handleUpdateHoleSelect}
                  onUpdateFilteredHoleInfos={this.handleUpdateFilteredHolesInfos}
                  userDetails={this.props.userDetails} 
                  siteId={this.state.selectedSite.value}
		              date={null}
		              vehicleId={null}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  holeInfos={this.state.holeInfos}
                  vehicleIdList={this.state.selectedVehicles.map(a => a.value)}
                  drivers={this.state.drivers}
		              productTypes={this.state.productTypes}
                  blasters={this.state.blasters}   />

              <Button onClick={this.handleCsvClick} variant="primary">{t('Export_CSV')}</Button> {' '}

              <Map    
		              onUpdateHoleInfos={this.handleUpdateHoleInfos}
                  handleUpdateLoadPosition={this.handleUpdateLoadPosition}
                  refreshData={this.refreshData}
		              date={null}
		              vehicleId={null}
                  userDetails={this.props.userDetails} 
                  siteId={this.state.selectedSite.value}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate} 
                  holeInfoVersion={this.state.holeInfoVersion}
                  holeInfos={this.state.filteredHoleInfos==null?this.state.holeInfos:this.state.filteredHoleInfos}
                  loadInfos={this.state.loadInfos}
		              selectedHoleId={this.state.selectedHoleId}
                  layerLoadedVisible={true}
                  layerDesignVisible={false}
                  layerHeatMapVisible={false}
                  layerSurveyVisible={false}
                  layerSurveyOptions={false}
                  layerSurveyShowGpsPos={false}
                  layerAccessoriesVisible={false}
                  layerAccessoriesOptions={false}
                  layerHoleStateVisible={false}
                  layerHoleStateOptions={false}
                  layerDesignVisible={false}
                  layerHeatMapVisible={false}
                  layerOverFillVisible={false}
                  layerOverFillOptions={false}
                  layerLoadProgressVisible={false}
                  layerProductVisible={false}
                  layerVehicleVisible={false}
                  layerHeatMapVisible={false}
                  vehicleIdList={this.state.selectedVehicles.map(a => a.value)}
                  />
                <Chart1
                  key={17}
                  onUpdateHoleSelect={this.handleUpdateHoleSelect}
                  userDetails={this.props.userDetails}
                  siteId={this.state.selectedSite.value}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate} 
                  patternId={'ALL'}
                  vehicleIdList={this.state.vehicleIdList}
                />
            </div>
          ]}
        
        </div>     
    );
  }
}

export default withTranslation()(Report2);
