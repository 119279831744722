import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';

class MyTable2 extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.myRef = React.createRef();
        this.messagesEnd = React.createRef();
        this.mustScroll=false;
   
        this.state = {
          data: null,
          error: null,
          isLoading: true
        };
    }

    componentWillReceiveProps(nextProps){      
      console.log ("Report1Table2:componentWillReceiveProps");
      if(nextProps.yearMonth!==this.props.yearMonth) 
      {             
        this.getData(nextProps);
      }
    }

//shouldComponentUpdate(nextProps,nextState)
//{
      //if((nextProps.yearMonth!=this.props.yearMonth) || 
         //(nextState.isLoading!=this.state.isLoading)) 
      //{ 
        //return true;            
      //}

//return (false);}


    componentDidUpdate() {
      }
  

  getData = (props) =>{
    //this.setState({isLoading: true });
    fetch(process.env.REACT_APP_HOST_URL+'report1Table2DS.php',
        {
            method: 'POST',                
            body: JSON.stringify(
            {
                userId    : props.userDetails.userId,
                companyId : props.userDetails.companyId,
                siteId    : props.siteId,
                month     : props.month,
                year      : props.year
            })
        })
      .then(response => response.json())
      .then(data => {this.mustScroll=true;this.setState({ data, isLoading: false })})
      .catch(error => this.setState({ error, isLoading: true }));
  }

    componentDidMount() {
        this.getData(this.props);
    }
     
    
    render() 
    { 
        const { t } = this.props;

        if (this.state.isLoading)
        {
            return (<p>{t('Loading')}</p>);
        }
   
        const columns = [{
            dataField: 'month',
            text: t('Month'),
            hidden: true                
        }, {
            dataField: 'year',
            text: t('Year'),
            hidden: true
        }, {
            dataField: 'yearMonth',
            text: t('Date'),
            hidden: true
        }, {
            dataField: 'devId',
            text: 'DeviceId',           
            hidden: true
        }, {
            dataField: 'vehicleId',
            text: 'VehicleId',            
            hidden: true  
        }, {
            dataField: 'vehicleDescription',
            text: t('Vehicle'),
            sort: true 
        }, {
            dataField: 'noHoles',
            text: t('No_Holes'),
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
        }, {
            dataField: 'duration',
            text: t('Duration'),
            sort: true,
             sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/:/g, '');
              var y = b.replace(/:/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }, {
            dataField: 'volume',
            text: t('Weight')+'('+this.props.userDetails.massMUnit+')',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }, {
            dataField: 'lastSeenDateTimeLocal',
            text: t('Date')+'('+this.props.userDetails.timeZone+')',
            sort: true
        }];
 
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`Report1Table2:clicked on row with index: ${row.vehicleDescription} ${row.vehicleId}`);
              this.props.onSelectVehicle(row.vehicleId, row.vehicleDescription);  
            },
            onMouseEnter: (e, row, rowIndex) => {
              //console.log(`enter on row with index: ${row.year} ${row.month}`);
            }
          };

        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
     
 const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn:true,
            style: { backgroundColor: '#c8e6c9'}
          };

        return  (
        <div>
          <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{monthNames[this.props.month-1]} {this.props.year}</h3>
          <BootstrapTable
            bootstrap4
            classes="table-responsive-sm"
            id = "section1" 
            keyField='devId' 
            selectRow={ selectRow } 
            striped={true} 
            hover={true} 
            condensed={true} 
            data={ this.state.data } 
            columns={ columns } 
            pagination={ paginationFactory()} 
            rowEvents={ rowEvents }
            headerClasses="thead-dark" />
        </div>)
    }
}

export default withTranslation()(MyTable2);
