import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import {Element, scroller} from 'react-scroll';
import Select from 'react-select';

import Map from './CommonMap';
import TableHoleInfo from './CommonTableHoleInfo';
import Chart1 from './Report1Chart.1';
import Chart2 from './Report1Chart.2';
import Chart3 from './Report1Chart.3';
import Table1 from './Report1Table.1';
import Table2 from './Report1Table.2';
import Table3 from './Report1Table.3';
import { withTranslation } from 'react-i18next';

class Report1 extends Component {

  years = [{value: '2019', label: '2019'}, {value: '2020', label: '2020'}, {value: '2021', label: '2021'}, {value: '2022', label: '2022'}, 
           {value: '2023', label: '2023'}, {value: '2024', label: '2024'}, ]

  constructor(props) {
    super(props);
    this.state = {
      drillLevel: 0,
      isLoadingSites:true,
      selectedSiteId: null,
      selectedSiteName: null,
      selectedYearId: '2024', //Default must be changed in JSX for the year selector
      selectedYearName: '2024',
      month: null,
      date: null,
      year: null,
      vehicleId: null,
      vehicleDescription: null,
      yearMonth: null,
      startDate: null,
      endDate: null,
      vehicleList: null,
      holeInfos: null,
      holeInfoVersion: 0,
      selectedHoleId: 0,
      showHoleId: 0,
      drivers: null,
      productTypes: null,
      blasters: null,
      // sites      : null,
      //years: [{value: '2019', label: '2019'}, {value: '2020', label: '2020'}, {value: '2021', label: '2021'}]
    };	  
  }


  componentDidMount() {
    this.getSites();
  }


  getSites =
      () => {
	this.setState ({isLoadingSites:true});      
        fetch(process.env.REACT_APP_HOST_URL + 'reportSitesDS.php', {
          method: 'POST',
          body: JSON.stringify(
              {userId: this.props.userDetails.userId, companyId: this.props.userDetails.companyId})
        })
            .then(response => response.json())
            .then(data => this.setState({sites: data, isLoadingSites: false}))
            .catch(error => this.setState({error, isLoadingSites: true}));
      }

  handleChangeSites =
      (selectedSite) => {
        var selectedSiteId = selectedSite.value;
        var selectedSiteName = selectedSite.label;
        this.setState({selectedSiteId, selectedSiteName, drillLevel: 0});
      }

  handleChangeYear =
      (selectedYear) => {
        var selectedYearId = selectedYear.value;
        var selectedYearName = selectedYear.label;
        this.setState({selectedYearId, selectedYearName, drillLevel: 0});
      }

  handleClick =
      () => {
        console.log('this is:', this);
        this.setState({drillLevel: 1});
      }

  handleYearMonthSelection =
      (year, month, yearMonth) => {
        scroller
            .scrollTo('scrolltable2', {
              duration: 800,
              offset: 50,
              delay: 500,
              smooth: 'easeInOutQuart'
            })

                this.setState({
                  year: year,
                  month: month,
                  yearMonth: yearMonth,
                  drillLevel: 2
                });
      }

  handleVehicleSelection =
      (vehicleId, vehicleDescription) => {
        scroller
            .scrollTo('scrolltable3', {
              duration: 800,
              offset: 50,
              delay: 500,
              smooth: 'easeInOutQuart'
            })

                this.setState({
                  vehicleId: vehicleId,
                  vehicleDescription: vehicleDescription,
                  drillLevel: 3
                });
      }


  handleChartDateSelection =
      (date) => {
        console.log(date);
        this.setState(
            {month: null, vehicleId: null, vehicleDescription: 'All'});
        this.handleDateSelection(date);
      }


  handleDateSelection =
      (date) => {
        scroller.scrollTo(
            'scrolltable4',
            {duration: 800, delay: 500, smooth: 'easeInOutQuart'})

        fetch(process.env.REACT_APP_HOST_URL + 'commonTableHoleInfoDS4.php', {
          method: 'POST',
          body: JSON.stringify({
            userId: this.props.userDetails.userId,
            companyId: this.props.userDetails.companyId,
            siteId: this.state.selectedSiteId,
            patternId: 'ALL',
            date: date,
            vehicleId: this.state.vehicleId
          })
        })
        .then(response => response.json())
        .then(data => {
          var holeInfoVersion = this.state.holeInfoVersion + 1;
          this.setState({
            holeInfos: data.holeInfos,
            holeInfoVersion:holeInfoVersion,
            date:date,
            drillLevel: 4,
            isLoading: false
          });

          //this.setState({date: date, drillLevel: 4});
        })
        .catch(error => this.setState({error, isLoading: true}));

        fetch(process.env.REACT_APP_HOST_URL + 'commonPeopleDriversDS.php', {
          method: 'POST',
          body: JSON.stringify(
              {userId: this.props.userDetails.userId, companyId: this.props.userDetails.companyId})
        })
            .then(response => response.json())
            .then(data => this.setState({drivers: data}))
            .catch(error => this.setState({error, isLoading: true}));

        fetch(process.env.REACT_APP_HOST_URL + 'commonPeopleBlastersDS.php', {
          method: 'POST',
          body: JSON.stringify(
              {userId: this.props.userDetails.userId, companyId: this.props.userDetails.companyId})
        })
            .then(response => response.json())
            .then(data => this.setState({blasters: data}))
            .catch(error => this.setState({error, isLoading: true}));

        fetch(process.env.REACT_APP_HOST_URL + 'commonProductTypesDS.php', {
          method: 'POST',
          body: JSON.stringify(
              {userId: this.props.userDetails.userId, companyId: this.props.userDetails.companyId})
        })
            .then(response => response.json())
            .then(data => this.setState({productTypes: data}))
            .catch(error => this.setState({error, isLoading: true}));
      }

  handleUpdateHoleInfos =
      (data) => {
        this.setState({
          holeInfos: data.holeInfos
        });
      }
      
  handleUpdateHolePosition = (n,latitude,longitude) => {
      console.log('handleUpdateHolePosition:', n, latitude, longitude );
      var temp = this.state.holeInfos;

      temp[n].latitude=latitude;
      temp[n].longitude=longitude;

      this.setState({holeInfos:temp});
    }
    


  handleUpdateHoleSelect =
      (selectedHoleId) => {
        console.log('handleUpdateHoleSelect', selectedHoleId);
        this.setState({selectedHoleId: selectedHoleId});
      }


  render() {
    const { t } = this.props;

    const {selectedSites, selectedYear} = this.state;

    if (this.state.isLoadingSites)
    {
      return (<p>{t('Loading')}</p>);
      	    
    }

    return (
      <div>   
        <h3 style={{
      textAlign: 'center', color: 'black'}}>{t('Year_Report')}</h3>
        <div style={{ float:'left', width: '400px', margin: 'auto', paddingBottom: '10px'}}> 
          <Select
              value={selectedSites}
              onChange={this.handleChangeSites}
              options={this.state.sites}
              isMulti ={false}
              placeholder ={t('Select_Site')}
          />
          <Select
      	      defaultValue={this.years[this.years.length-1]}
              value={selectedYear}
              onChange={this.handleChangeYear}
              options={this.years}
              isMulti ={false}
              placeholder ={t('Select_Year')}
          />
          {((this.state.selectedYearId !=null) && (this.state.selectedSiteId!=null)) && [
              <div key={1} style={{ clear :'both' , paddingBottom: '20px'}}>
                  <Button key={1} onClick={this.handleClick} variant="primary">{t('Search')}</Button>
              </div>
          ]} 
        </div>  

        {(this.state.drillLevel >0) && [
           <div key={2} style={{clear: 'both'}}>
            <Chart1
              onSelectDate = {this.handleChartDateSelection} 
              userDetails  = {this.props.userDetails} 
              siteId       = {this.state.selectedSiteId} 
              year         = {this.state.selectedYearName } />
            <Chart2 
              userDetails  = {this.props.userDetails} 
              siteId       = {this.state.selectedSiteId}
              year         = {this.state.selectedYearName}/ >
            < Table1
              onSelectYearMonth = {this.handleYearMonthSelection} 
              userDetails       = {this.props.userDetails} 
              siteId            = {this.state.selectedSiteId}
              siteName          = {this.state.selectedSiteName}
              year              = {this.state.selectedYearName}/>
          </div>]}

 <Element name='scrolltable2' className='element'/>

        {((this.state.drillLevel >1) && (this.state.month!=null)) && [
          <Table2
           key={3}
           onSelectVehicle = {this.handleVehicleSelection} 
           userDetails     = {this.props.userDetails} 
           siteId          = {this.state.selectedSiteId} 
           year            = {this.state.year}
           month           = {this.state.month} 
           yearMonth       = { this.state.yearMonth } />]}

 <Element name="scrolltable3" className="element"/ >

    {((this.state.drillLevel >2) && (this.state.month!=null) && (this.state.vehicleId!=null)) && [
          <Table3
           key={4}
           onSelectDate = {this.handleDateSelection}
           userDetails  = {this.props.userDetails} 
           siteId       = {this.state.selectedSiteId} 
           year         = {this.state.year} 
           month        = {this.state.month} 
           vehicleDescription = {this.state.vehicleDescription} 
           vehicleId    = { this.state.vehicleId } />]}              

 <Element name="scrolltable4" className="element"/ >

      {(this.state.drillLevel >3) && [
          <TableHoleInfo
           key={5}
           onUpdateHoleInfos = {this.handleUpdateHoleInfos} 
           onUpdateHoleSelect = {this.handleUpdateHoleSelect} 
           userDetails  = {this.props.userDetails} 
           siteId = {this.state.selectedSiteId} 
           date = {this.state.date} 
           startDate = {this.state.date} 
           endDate = {this.state.date} 
           vehicleId = {this.state.vehicleId}
           vehicleDescription = {this.state.vehicleDescription}
           holeInfos = {this.state.holeInfos} 
           drivers = {this.state.drivers} 
           productTypes = {this.state.productTypes} 
           blasters ={ this.state.blasters } />,
          <Map    
            key={6}
            userDetails  = {this.props.userDetails} 
            onUpdateHoleInfos={this.handleUpdateHoleInfos}
            onUpdateHolePosition={this.handleUpdateHolePosition}
            siteId={this.state.selectedSiteId}
            date={this.state.date} 
            startDate={this.state.date} 
            endDate={this.state.date} 
            vehicleId={this.state.vehicleId} 
            holeInfos={this.state.holeInfos}
            holeInfoVersion={this.state.holeInfoVersion}
            selectedHoleId={this.state.selectedHoleId}		
            layerLoadedVisible={true}
            layerDesignVisible={false}
            layerSurveyVisible={false}
            layerSurveyOptions={false}
            layerSurveyShowGpsPos={false}
            layerAccessoriesVisible={false}
            layerAccessoriesOptions={false}
            layerHoleStateVisible={false}
            layerHoleStateOptions={false}
            layerDesignVisible={false}
            layerHeatMapVisible={false}
            layerOverFillVisible={true}
            layerOverFillOptions={true}
            layerLoadProgressVisible={false}
            layerProductVisible={false}
            productInfos={[]}
            layerVehicleVisible={false}
            layerHeatMapVisible={false}
            vehicleDescription={this.state.vehicleDescription}/ >,
          <Chart3
            key={7}
            onUpdateHoleSelect={this.handleUpdateHoleSelect}
            userDetails  = {this.props.userDetails} 
            siteId={this.state.selectedSiteId}
            date={this.state.date} 
            startDate={this.state.date} 
            endDate={this.state.date} 
            vehicleId={
            this.state.vehicleId}/>]}      
      </div>
    );
      }
    }

export default withTranslation()(Report1);
