import React, { Component } from "react";

import { withTranslation } from 'react-i18next';
 
class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {  
    };
  }

  render() {
    const { t } = this.props;

    return (
      <div>         
       <span style={{textAlign:'left', paddingLeft:'10px'}}>{t('Loaded')}</span>
       <span style={{textAlign:'left', fontSize:'30px', fontWeight:'bold',paddingLeft:'10px'}}>{this.props.patternSummary.weight}</span>
       <span style={{textAlign:'left', fontSize:'30px', fontWeight:'bold',paddingLeft:'10px'}}>{this.props.userDetails.massMUnit}</span>

       {(this.props.patternSummary.noBoosters) && [
         <>
           <span style={{textAlign:'left', paddingLeft:'50px'}}>{t('Boosters')}</span>
           <span style={{textAlign:'left', fontSize:'30px', fontWeight:'bold',paddingLeft:'10px'}}>{this.props.patternSummary.noBoosters}</span>
         </>
       ]}

       {(this.props.patternSummary.noDetonators) && [
         <>
           <span style={{textAlign:'left', paddingLeft:'50px'}}>{t('Detonators')}</span>
           <span style={{textAlign:'left', fontSize:'30px', fontWeight:'bold',paddingLeft:'10px'}}>{this.props.patternSummary.noDetonators}</span>
         </>
       ]}

       {(this.props.patternSummary.noOther) && [
         <>
           <span style={{textAlign:'left', paddingLeft:'50px'}}>{t('Other')}</span>
           <span style={{textAlign:'left', fontSize:'30px', fontWeight:'bold',paddingLeft:'10px'}}>{this.props.patternSummary.noOther}</span>
         </>
       ]}

       <span style={{textAlign:'left', paddingLeft:'50px'}}>{t('No_Vehicles')}</span>
       <span style={{textAlign:'left', fontSize:'30px', fontWeight:'bold',paddingLeft:'10px'}}>{this.props.patternSummary.noVehicles}</span>

       <span style={{textAlign:'left', paddingLeft:'50px'}}>{t('No_Days')}</span>
       <span style={{textAlign:'left', fontSize:'30px', fontWeight:'bold',paddingLeft:'10px'}}>{this.props.patternSummary.noDays}</span>

      </div>
    );
  }
}

export default withTranslation()(Summary);
