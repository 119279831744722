import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import AuthoriseObj from './Authorise.js'
import SurveyTable from './SurveyTable';
import MapSurveyInfoWindowEdit from './MapSurveyInfoWindowEdit';
import LoadTable from './LoadTable';
import DeckTable from './DeckTable';
import LoadAdd from './LoadAdd';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import i18n from '../translations/i18n';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class MapSurveyInfoWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys     : [],
      loads       : [],
      decks       : [],
      holeSummary : null
    };
  }

  componentDidMount() {
   this.getHoleSummary();
   this.getLoads();
   this.getSurveys();
  }

  refreshLoadData = () => {
    console.log("MapSurveyInfoWindow:refreshLoadData");
    this.getLoads();
    this.getSurveys();
    this.props.refreshData();
    //event.stopPropagation(); //Otherwise HeatMap toggle
  }

  refreshDesignData = () => {
    console.log("MapSurveyInfoWindow:refreshDesignData");
    this.getLoads();
    this.getHoleSummary();
    this.getSurveys();
    this.props.refreshData();
  }


  refreshSurveyData = () => {
    console.log("MapSurveyInfoWindow:refreshSurveyData");
    //this.getLoads();
    this.getSurveys();
    this.props.refreshData();
    //event.stopPropagation(); //Otherwise HeatMap toggle
  }

  handleCancel = (event) => {
    //event.stopPropagation(); //Otherwise HeatMap toggle
    console.log("MapSurveyInfoWindow:handleCancel:");
    this.props.handleMarkerClear();
  }

  handleClick = (event) => {
    event.stopPropagation(); //Otherwise HeatMap toggle
    console.log("MapSurveyInfoWindow:handleClick:");
  }

  getHoleSummary = () => {
    console.log("MapSurveyInfoWindow:getHoleSummary",this.props.hole.siteId, this.props.hole.patternNo, this.props.hole.holeNo);
    fetch(process.env.REACT_APP_HOST_URL+'commonGetHoleSummaryDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
         userId    : this.props.userDetails.userId,
         companyId : this.props.userDetails.companyId,
         siteId    : this.props.hole.siteId,
         patternNo : this.props.hole.patternNo,
         holeNo    : this.props.hole.holeNo,
      })
    })
    .then(response => response.json())
    .then(data =>this.setState({holeSummary:data}) )
    .catch(error => this.setState({holeSummary:[]}));
  }

  getLoads = () => {
    console.log("MapSurveyInfoWindow:getLoads",this.props.hole.siteId, this.props.hole.patternNo, this.props.hole.holeNo, this.props.hole.holeId);
    fetch(process.env.REACT_APP_HOST_URL+'commonGetLoadsDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
         userId    : this.props.userDetails.userId,
         companyId : this.props.userDetails.companyId,
         siteId    : this.props.hole.siteId,
         patternNo : this.props.hole.patternNo,
         holeNo    : this.props.hole.holeNo,
         holeId    : this.props.hole.holeId,
      })
    })
    .then(response => response.json())
    .then(data =>this.setState({loads:data}) )
    .catch(error => this.setState({loads:[]}));
  }

  getDecks = () => {
    console.log("MapSurveyInfoWindow:getDecks",this.props.hole.siteId, this.props.hole.patternNo, this.props.hole.holeNo);
    fetch(process.env.REACT_APP_HOST_URL+'commonGetDecksDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
         userId    : this.props.userDetails.userId,
         companyId : this.props.userDetails.companyId,
         siteId    : this.props.hole.siteId,
         patternNo : this.props.hole.patternNo,
         holeNo    : this.props.hole.holeNo,
      })
    })
    .then(response => response.json())
    .then(data =>this.setState({decks:data}) )
    .catch(error => this.setState({decks:[]}));
  }

  getSurveys() {
    console.log("MapSurveyInfoWindow:getSurveys",this.props.hole.siteId, this.props.hole.patternNo, this.props.hole.holeNo);
    fetch(process.env.REACT_APP_HOST_URL+'commonGetSurveysDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId        : this.props.userDetails.userId,
        companyId     : this.props.userDetails.companyId,
        holeId        : this.props.hole.holeId,
        siteId        : this.props.hole.siteId,
        patternNo     : this.props.hole.patternNo,
        holeNo        : this.props.hole.holeNo
      })
    })
    .then(response => response.json())
    .then(data => {
      data.map(function(survey){
        survey.surveyStateDescription = '';
        if(survey.surveyState==1) survey.surveyStateDescription=i18n.t('Survey_Depth_OK');
        if(survey.surveyState==2) survey.surveyStateDescription=i18n.t('Survey_Depth_Over');
        if(survey.surveyState==3) survey.surveyStateDescription=i18n.t('Survey_Depth_Under');
        if(survey.surveyState==4) survey.surveyStateDescription=i18n.t('Survey_Loaded');
        if(survey.surveyState==5) survey.surveyStateDescription=i18n.t('Survey_Stemming_OK');
        if(survey.surveyState==6) survey.surveyStateDescription=i18n.t('Survey_Stemming_Over');
        if(survey.surveyState==7) survey.surveyStateDescription=i18n.t('Survey_Stemming_Under');

        survey.phase = '';
        if(survey.options==0) survey.phase=i18n.t('Survey_Loading');
        if(survey.options==1) survey.phase=i18n.t('Survey_PostDrill');
        if(survey.options==2) survey.phase=i18n.t('Survey_PreLoading');
        if(survey.options==3) survey.phase=i18n.t('Survey_PostLoading');
        if(survey.options==4) survey.phase=i18n.t('Survey_PreStemming');

        survey.holeStateDescription = '';
        if (survey.holeState == 0) survey.holeStateDescription='OK';
        else {
          if((survey.holeState & 0x01) > 0)  survey.holeStateDescription+=i18n.t('Wet')+' ';
          if((survey.holeState & 0x02) > 0)  survey.holeStateDescription+=i18n.t('Collapsed')+' ';
          if((survey.holeState & 0x04) > 0)  survey.holeStateDescription+=i18n.t('Decked')+' ';
          if((survey.holeState & 0x08) > 0)  survey.holeStateDescription+=i18n.t('CleanOut')+' ';
          if((survey.holeState & 0x10) > 0)  survey.holeStateDescription+=i18n.t('ReDrill')+' ';
          if((survey.holeState & 0x20) > 0)  survey.holeStateDescription+=i18n.t('OverBurden')+' ';
          if((survey.holeState & 0x40) > 0)  survey.holeStateDescription+=i18n.t('Cracked')+' ';
          if((survey.holeState & 0x100) > 0) survey.holeStateDescription+=i18n.t('Tacked')+' ';
          if((survey.holeState & 0x200) > 0) survey.holeStateDescription+=i18n.t('TopLoaded')+' ';
          if((survey.holeState & 0x400) > 0) survey.holeStateDescription+=i18n.t('OverLoaded');
          if((survey.holeState & 0x800) > 0) survey.holeStateDescription+=i18n.t('UnderLoaded');
          if((survey.holeState & 0x1000) > 0) survey.holeStateDescription+=i18n.t('Start');
          if((survey.holeState & 0x2000) > 0) survey.holeStateDescription+=i18n.t('Hot');
        }
      })

      this.setState({surveys:data});
    })
    .catch(error => this.setState({ surveys: [] }));
  }


  render() {
    const { t } = this.props;

    const holeInfo = this.props.holeInfo;

    let offset = 0;
    const infoWindowStyle = {
      position: 'relative',
      bottom: 200+(this.state.surveys.length?70:0)+(this.state.loads.length?70:0)+(this.state.surveys.length + this.state.loads.length)*30 ,
      left: '-100px',
      width: ((this.state.loads.length>0)||(this.state.surveys.length>0) || 
              (this.props.markerClickInfo !== null) && (this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_PATTERN>0) && (this.props.loadAddEnabled))?1150:400,
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 5,
      fontSize: 14,
      zIndex: 100,
      opacity: 0.90
    };

    return (
      <div key='MapSurveyInfoWindow' onClick={this.handleClick} style={infoWindowStyle}>
        <Container>
          <Row style={{ fontSize: 20, color: 'White', fontWeight:'bold' }}>
            <Col style={{ fontSize: 20, backgroundColor: 'Black'}}>{this.props.hole.patternNo}</Col>
            <Col style={{ fontSize: 20, backgroundColor: 'Black'}}>{this.props.hole.holeNo}</Col>
            <Col></Col>
          </Row>
        </Container>
        
        <hr style={{margin:2}}></hr>

        {(this.state.holeSummary !== null) && [
        <Container>
          <Row style={{ fontSize: 14, color: 'grey' }}>
            <Col>{t('Weight')}({this.props.userDetails.massMUnit})</Col>
            <Col>{this.state.holeSummary.volume}/{this.state.holeSummary.designWeight}/{this.state.holeSummary.adjDesignWeight}</Col>
            <Col></Col>
          </Row>
           <Row style={{ fontSize: 14, color: 'grey' }}>
            <Col>{t('Duration')}</Col>
            <Col>{this.state.holeSummary.duration}</Col>
            <Col></Col>
          </Row>
          <Row style={{ fontSize: 14, color: 'grey' }}>
            <Col>{t('Diameter')}({this.props.userDetails.lengthSUnit})</Col>
            <Col>{this.state.holeSummary.designDiameter}</Col>
            <Col></Col>
          </Row>
          <Row style={{ fontSize: 14, color: 'grey' }}>
            <Col>{t('Depth_Tol')}</Col>
            <Col>{this.state.holeSummary.depthTol}</Col>
            <Col></Col>
          </Row>
          <Row style={{ fontSize: 14, color: 'grey' }}>
            <Col>{t('Stemming_Tol')}</Col>
            <Col>{this.state.holeSummary.stemmingTol}</Col>
            <Col></Col>
          </Row>
        </Container>
       ]}
        
       {(this.state.surveys.length > 0) && [
         <div>
           <h6 style={{textAlign: 'center', color: 'black'}}>Surveys</h6>
           <SurveyTable
             key={899}
             userDetails = {this.props.userDetails}
             refreshData = {this.refreshSurveyData}
             surveys     = {this.state.surveys}
           /> 
         </div>
       ]}

       {(this.state.loads.length > 0) && [
          <div>
            <h6 key={9} style={{textAlign: 'center', color: 'black'}}>Loads</h6>
            <LoadTable
              userDetails       = {this.props.userDetails}
              refreshData       = {this.refreshLoadData}
              loads             = {this.state.loads}
              siteId            = {this.props.hole.siteId}
              handleMarkerClear = {this.props.handleMarkerClear}
            />
          </div>
        ]}

        {(this.state.decks.length > 0) && [
          <div>
            <h6 key={9} style={{textAlign: 'center', color: 'black'}}>Loads</h6>
            <DeckTable
              userDetails = {this.props.userDetails}
              refreshData = {this.refreshLoadData}
              decks       = {this.state.decks}
            />
          </div>
        ]}

       {(this.props.markerClickInfo !== null) &&[
         <div
           style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
           }}
         >

           {((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_PATTERN>0) && (this.props.loadAddEnabled))? 
             <div>
               <LoadAdd
                 userDetails  = {this.props.userDetails}
                 siteId       = {this.props.hole.siteId}
                 patternNo    = {this.props.hole.patternNo}
                 holeNo       = {this.props.hole.holeNo}
                 latitude     = {this.props.hole.latitude?this.props.hole.latitude:this.props.hole.bestLat}
                 longitude    = {this.props.hole.longitude?this.props.hole.longitude:this.props.hole.bestLng}
                 handleAdd    = {this.refreshLoadData}
                 handleCancel = {null}
                 hole         = {this.props.hole}
               /> 
             </div>:
             null
           }

           <Button variant="contained" size="small" onClick={this.handleCancel}>{t('Cancel')}</Button>
         </div>
       ]}

      </div>
    );
  }
}

export default withTranslation()(MapSurveyInfoWindow);
