import Chart from "react-google-charts";
import React from "react";
import { withTranslation } from 'react-i18next';

class Chart5 extends React.Component 
{   
    constructor(props) 
    {
        super(props);
    
        this.state = {
          data  : null,
          title : 'Hole Loading: Design' ,
        };

        this.adjDesign = false;
    }

    componentDidMount() {
      this.getData();
    }


    componentDidUpdate(prevProps, prevState){
      if (prevProps.patternId != this.props.patternId) {
        this.getData();
      }
    }


    getData = (props) =>{
        console.log ('DashboardChart5:getData');
        fetch(process.env.REACT_APP_HOST_URL+'dashBoardChart5V2DS.php',
            {
                method: 'POST',
                body: JSON.stringify(
                {
                  companyId : this.props.userDetails.companyId,
                  userId    : this.props.userDetails.userId,
                  siteId    : this.props.siteId,
                  patternId : this.props.patternId,
                  adjDesign : this.adjDesign
                })
            })
          .then(response => response.json())
          .then(data => {
            this.setState({ data });
            //console.log('DashboardChart5:getData:',data);
            })
          .catch(error => this.setState({ data:null }));
    }


    render() 
    { 
        const { t } = this.props;

        return  (
          <div>
          {this.state.data && [
            <Chart
                key ='DashboardChart5'
                height={'250px'}
                chartType="PieChart"
                loader={<div>{t('Loading')}</div>}
                data={this.state.data}
                chartEvents={[
                {
                  eventName: "select",
                    callback: ({ chartWrapper, google }) => {
                      //var selection = chartWrapper.getChart().getSelection();
                      //var row = selection[0].row;
                      //console.log("Report5Chart1:onClick row=", selection[0]);
                      if(this.adjDesign) {
                        this.adjDesign=false; 
                        this.setState({title:'Hole Loading: Design'});
                      } else  {
                        this.adjDesign=true;
                        this.setState({title:'Hole Loading: Survey'});
                      }

                      this.getData();
                      //var driverId=this.state.data.rows[row].c[2];
                      //var driverName=this.state.data.rows[row].c[3];
                      //console.log("Report5Chart1:onClick driverId=", driverId, driverName);
                      //this.props.handleDriverSelect(driverId, driverName);
                    }
                }
                ]}
                options={{
                  title: this.state.title,
                  animation: {
                    duration: 1000,
                    easing: 'out',
                    startup: true
                  },

                  pieHole: 0.4,
            	    legend: { position: "right"},
                  chartArea: { width: '500'}
                }}
                rootProps={{ 'data-testid': '1' }}
          />
        ]}
        </div>
      )
    }
}

export default withTranslation()(Chart5);
