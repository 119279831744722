import Chart from "react-google-charts";
import React from "react";
import { withTranslation } from 'react-i18next';

class Chart2 extends React.Component 
{   
    constructor(props) 
    {
        super(props);
    
        this.state = {
          data  : null,
          title : 'Hole States: All' ,
        };

        this.excludeOk = false;
    }

    componentDidMount() {
      this.getData();
    }

    componentDidUpdate(prevProps, prevState){
      if (prevProps.patternId != this.props.patternId) {
        this.getData()
      }
    }

    getData = () =>{
        console.log ('DashboardChart2:getData:excludeOk=', this.excludeOk);
        fetch(process.env.REACT_APP_HOST_URL+'dashBoardChart2DS.php',
            {
                method: 'POST',
                body: JSON.stringify(
                {
                  companyId     : this.props.userDetails.companyId,
                  userId        : this.props.userDetails.userId,
                  siteId        : this.props.siteId,
                  patternId     : this.props.patternId,
                  excludeOk     : this.excludeOk,
                })
            })
          .then(response => response.json())
          .then(data => {
            this.setState({ data });
            //console.log('DashboardChart2:getData:',data);
            })
          .catch(error => this.setState({ data:null}));
    }


    render() 
    { 
        const { t } = this.props;

        return  (
          <div>
          {this.state.data && [
            <Chart
                key ='DashboardChart2'
                height={'250px'}
                chartType="PieChart"
                loader={<div>{t('Loading')}</div>}
                data={this.state.data}
                chartEvents={[
                {
                  eventName: "select",
                    callback: ({ chartWrapper, google }) => {
                      //var selection = chartWrapper.getChart().getSelection();
                      //if(selection){
                      //  var row = selection[0].row;
                      //  console.log("DashBoardChart2:onClick row=", selection[0]);
                     // }

                      if(this.excludeOk) {
                        this.excludeOk=false; 
                        this.setState({title:'Hole States: All'});
                      } else {
                        this.excludeOk=true;
                        this.setState({title:'Hole States: Excl OK'});
                      }

                      this.getData();
                      
                      //var driverId=this.state.data.rows[row].c[2];
                      //var driverName=this.state.data.rows[row].c[3];
                      //console.log("Report5Chart1:onClick driverId=", driverId, driverName);
                      //this.props.handleDriverSelect(driverId, driverName);
                    }
                }
                ]}
                options={{
                    title: this.state.title, 
                    animation: {
                      duration: 1000,
                      easing: 'out',
                      startup: true
                    },
                    pieHole: 0.4,
	            	    legend: { position: "right"},
                    chartArea: { width: '500'}
                }}
                rootProps={{ 'data-testid': '1' }}
          />
        ]}
        </div>
      )
    }
}

export default withTranslation()(Chart2);
