import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';

class Report7Table1 extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {       
    }
    
    componentWillReceiveProps(nextProps){
    }

    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'id',
            text: 'Id',
            hidden:true
          }, {     
            dataField: 'serviceDate',
            text: t('Date'),
            hidden:false,
            sort:true
          }, {     
            dataField: 'totalHours',
            text: t('Pump_Hours'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'hours',
            text: t('Hours'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'technician',
            text: t('Technician'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'notes',
            text: t('Notes'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'userName',
            text: t('Approved'),
            editable: false,
            hidden:false,
            sort: true                
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.id}`);
            },
        };
                      
        const selectRow = {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn:true,
          style: { backgroundColor: '#c8e6c9'}
        };

        return  (            
            <div>
                {(this.props.data!=null) && [
                <BootstrapTable 
                    bootstrap4
                    classes="table-responsive-sm"
                    keyField='id'    
		                selectRow={ selectRow }
                    striped={true} 
                    hover={true} 
                    condensed={true} 
                    data={ this.props.data } 
                    columns={ columns } 
                    rowEvents={ rowEvents } 
   		              pagination={ paginationFactory()}
                    headerClasses="thead-dark"            
                />]}
             </div>
        )
    }
}

export default withTranslation()(Report7Table1);
