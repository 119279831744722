import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';

class Table0 extends React.Component 
{   
    constructor(props) 
    {
      super(props);
    
      this.state = {
        zoneInfos: null
      };
    }

    componentDidUpdate() {
      console.log ("Report5Table0:componentDidUpdate");
    }

    componentDidMount() {
      console.log ("Report5Table0:componentDidMount");
      this.getData(this.props);
    }

    componentWillReceiveProps(nextProps){
      console.log(`Report5Table.0 : componentWillReceiveProps `, nextProps.monthYear, " " , this.props.monthYear);
      if(nextProps.monthYear!==this.props.monthYear)
      {
        this.getData(nextProps);
      }
    }


    getData = (props) =>{  
      this.setState({isLoading: true });
      fetch(process.env.REACT_APP_HOST_URL+'report5Table0DS.php',
      {
        method: 'POST',                
        body: JSON.stringify(
        {
          userId    : props.userDetails.userId,
          companyId : props.userDetails.companyId,    
    	    month     : props.monthYear.month,
          year      : props.monthYear.year
        })
      })
      .then(response => response.json())
      .then(data =>{this.setState({ zoneInfos:data });})
      .catch(error => this.setState({ zoneInfos: null }));
    }
      
    render() 
    { 
      const { t } = this.props;

      if (this.state.zoneInfos == null)
      {
         return (<p>{t('Loading')}</p>);
      }

      const columns = [{
        dataField: 'id',
        text: 'id',
        hidden: true          
      }, {
        dataField: 'zoneDescription',
        text: t('Zone'),
        sort: true          
	    }, {
        dataField: 'noDays',
        text: t('No_Days'),
        sort: true
      }, {
        dataField: 'duration',
        text: t('Duration'),
        sort: true,
   	    sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/:/g, '');
              var y = b.replace(/:/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
       }];

       const rowEvents = {
         onClick: (e, row, rowIndex) => {
           console.log(`Report5Table0:Clicked on row with index: ${row.id} `);
           this.props.handleZoneSelect(row.id, row.zoneDescription);
         },
         onMouseEnter: (e, row, rowIndex) => {
           console.log(`Report5Table0:Enter on row with index: ${row.no} ${rowIndex}`);
         },
           onMouseLeave: (e, row, rowIndex) => {
           console.log(`Report5Table0:Leave on row with index: ${row.no} ${rowIndex}`);
         }
       };
       
       const MyExportCSV = (props) => {
         const handleClick = () => {
           props.onExport();
         };

         return (
           <div>
             <button className="btn btn-success" onClick={ handleClick }>{t('Export_CSV')}</button>
           </div>
         );
       };

       return  (
         <div>
            <ToolkitProvider 
                keyField='eventId'
                data={ this.state.zoneInfos } 
                columns={ columns }                 
                exportCSV
            >
            {
              props => (
                <div>        
                  <BootstrapTable { ...props.baseProps }
                  bootstrap4
                  classes="table-responsive-sm"
                  exportCSV
                  keyField='id'                
                  striped={true} 
                  hover={true} 
                  condensed={true}          
                  pagination={ paginationFactory({sizePerPage:10})} 
                  rowEvents={ rowEvents } 
                  headerClasses="thead-dark" />
                  <MyExportCSV { ...props.csvProps }>Export</MyExportCSV>
                </div>
              )
            }</ToolkitProvider>
        </div>)
    }
}

export default withTranslation()(Table0);
