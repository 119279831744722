import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import InfoWindowMulti from './MapInfoWindowMulti';
import LoadMarker from './LoadMarker';
import LoadAddMarker from './LoadAddMarker';
import OverFillMarker from './MapOverFillMarker';
import LoadProgressMarker from './MapLoadProgressMarker';
import VehicleMarker from './MapVehicleMarker';
import DesignMarker from './MapDesignMarker';
import SurveyMarker from './MapSurveyMarker';
import GpsPosMarker from './MapGpsPosMarker';
import AccessoryMarker from './MapAccessoryMarker';
import HoleStateMarker from './MapHoleStateMarker';
import ProductMarker from './MapProductMarker';
import MapSurveyLegend from './MapSurveyLegend';
import MapProductLegend from './MapProductLegend';
import MapAccessoryLegend from './MapAccessoryLegend';
import MapAccessoryDateLegend from './MapAccessoryDateLegend';
import MapLoadProgressLegend from './MapLoadProgressLegend';
import MapVehicleLegend from './MapVehicleLegend';
import LoadAdd from './LoadAdd';
import DesignAdd from './DesignAdd';
import Button from 'react-bootstrap/Button';
import * as geometry from 'spherical-geometry-js'
import AuthoriseObj from './Authorise.js';
import { withTranslation, useTranslation } from 'react-i18next';
import i18n from '../translations/i18n';

/* global google */
  
function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    },
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.ROADMAP,
    //maxZoom:100, minZoom:8
  };
}

//Heat Map helper functions...
//Mercator --BEGIN--
var TILE_SIZE = 256;
function bound(value, opt_min, opt_max) {
    if (opt_min !== null) value = Math.max(value, opt_min);
    if (opt_max !== null) value = Math.min(value, opt_max);
    return value;
}

function degreesToRadians(deg) {
    return deg * (Math.PI / 180);
}

function radiansToDegrees(rad) {
    return rad / (Math.PI / 180);
}

function calcDistance(lat1, lon1, lat2, lon2, unit) {
  var  theta, dist;
  if ((lat1 == lat2) && (lon1 == lon2)){
    return 0;
  } else  {
    theta = lon1 - lon2;
    dist  = Math.sin(degreesToRadians(lat1)) * Math.sin(degreesToRadians(lat2)) + 
            Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) * Math.cos(degreesToRadians(theta));
    dist  = Math.acos(dist);
    dist  = radiansToDegrees(dist);
    dist  = dist * 60 * 1.1515;

    switch (unit) {
    case 'M':
      break;
    case 'K':
      dist = dist * 1.609344;
      break;
    case 'N':
      dist = dist * 0.8684;
      break;
    }
    return (dist);
  }
}


function MercatorProjection() {
    this.pixelOrigin_ = new google.maps.Point(TILE_SIZE / 2,
    TILE_SIZE / 2);
    this.pixelsPerLonDegree_ = TILE_SIZE / 360;
    this.pixelsPerLonRadian_ = TILE_SIZE / (2 * Math.PI);
}

MercatorProjection.prototype.fromLatLngToPoint = function (latLng, opt_point) {
    var me = this;
    var point = opt_point || new google.maps.Point(0, 0);
    var origin = me.pixelOrigin_;

    point.x = origin.x + latLng.lng() * me.pixelsPerLonDegree_;

    // NOTE(appleton): Truncating to 0.9999 effectively limits latitude to
    // 89.189.  This is about a third of a tile past the edge of the world
    // tile.
    var siny = bound(Math.sin(degreesToRadians(latLng.lat())), - 0.9999,
    0.9999);
    point.y = origin.y + 0.5 * Math.log((1 + siny) / (1 - siny)) * -me.pixelsPerLonRadian_;
    return point;
};

MercatorProjection.prototype.fromPointToLatLng = function (point) {
    var me = this;
    var origin = me.pixelOrigin_;
    var lng = (point.x - origin.x) / me.pixelsPerLonDegree_;
    var latRadians = (point.y - origin.y) / -me.pixelsPerLonRadian_;
    var lat = radiansToDegrees(2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);
    return new google.maps.LatLng(lat, lng);
};

function getNewRadius(map,maps) {
  var desiredRadiusPerPointInMeters = 12;
  var numTiles = 1 << map.getZoom();
  var center = map.getCenter();
  var moved = geometry.computeOffset(center, 10000, 90); /*1000 meters to the right*/
  var projection = new MercatorProjection();
  var initCoord = projection.fromLatLngToPoint(center);
  var endCoord = projection.fromLatLngToPoint(moved);
  var initPoint = new google.maps.Point(
    initCoord.x * numTiles,
    initCoord.y * numTiles);
  var endPoint = new google.maps.Point(
    endCoord.x * numTiles,
    endCoord.y * numTiles);
  var pixelsPerMeter = (Math.abs(initPoint.x-endPoint.x))/10000.0;
  var totalPixelSize = Math.floor(desiredRadiusPerPointInMeters*pixelsPerMeter);

  if(map.getZoom() === 17) totalPixelSize = 14;
  if(map.getZoom() === 18) totalPixelSize = 28;
  if(map.getZoom() === 19) totalPixelSize = 62;
  if(map.getZoom() === 20) totalPixelSize = 128;

  return totalPixelSize;
}

class CommonMap extends Component {
  static defaultProps = {
    center: {
      lat: 0,
      lng: 0
    },
    zoom: 11,
    userId    : 1,
    companyId : 2,
    siteId    : 1,
    date      : '2019-05-14',
    vehicleId : '12'
  };

  intervalID;

  constructor(props)
  {
    super(props);

    this.state = {
       error: null,
       center: {
         lat: 0,
         lng: 0
       },
       zoom: 11,
       positionMultiSelect:null,
       designMarkerClickInfo:null,
       loadMarkerClickInfo:null,
       surveyMarkerClickInfo:null,
       accessoryMarkerClickInfo:null,
       holeStateMarkerClickInfo:null,
       productMarkerClickInfo:null,
       loadMarkersMultiSelect:[],
       draggable: true,  // By default map is draggable
       designMarkersVisible: false,
       isLoading: true,
       loadMovedIndex: -1,
       shiftKeyDown: false,
       controlKeyDown: false,
       childMouseDown: false,
       loadProximityIndex: -1,
       designProximityIndex: -1,
       loadAddWindow: false,
       designAddWindow: false,
    };

    this.polygons = [];
    this.maps=null;
    this.map=null;
    this.heatMap=null;
    this.rectangle=null;
    this.rectStartLatLng=null;
    this.mapRef = React.createRef();
    this.holeNoUpdated = null;
    this.editLatitude = null;
    this.editLongitude = null;

  }

  //shouldComponentUpdate(prevProps, prevState) {
    //console.log ("CommonMap:shouldComponentUpdate",prevState.loadMarkersMultiSelect);
    //console.log ("CommonMap:shouldComponentUpdate",this.state.loadMarkersMultiSelect);
    //if(prevState.loadMarkersMultiSelect.length != this.state.loadMarkersMultiSelect.length ) {
      //console.log ("CommonMap:true");
      //return true;
    //} else {
      //console.log ("CommonMap:false");
      //return false;
    //}
  //}

  centerMap = (markers) => {
    console.log ("CommonMap:centerMap",markers);

    if (markers==null) return;
    if (markers.length===0) return;
    if (this.map==null) return;
    if (this.maps==null) return;

    var bounds = new google.maps.LatLngBounds();

    for (var i=0;i<markers.length; i++){
      var myLatLng = new google.maps.LatLng(markers[i].latitude, markers[i].longitude);
      bounds.extend(myLatLng);
    }

    this.map.fitBounds(bounds)
  };


  updateHeatMapData = (holeInfos) => {
    if ((this.map) && (this.maps) && (this.heatMap))
    {
      console.log ("commonMap:updateHeatMapData");
      var radius = getNewRadius(this.map,this.maps);

      var i=0;
      var positions = [];
      var options = {
        radius: radius,
        opacity: 0.6
      }

      for (i=0; i< holeInfos.length; i++ )
      {
        var volume = holeInfos[i].volume?holeInfos[i].volume.replace(',',''):0; //Our volume is comma delimeted for thousands, strip this out
        var point = {location: new this.maps.LatLng(holeInfos[i].latitude, holeInfos[i].longitude), weight:volume/100};
        positions.push(point);
      }

      this.heatMap.setData(positions);
      this.heatMap.setOption=(options);
    }
  };

  componentDidUpdate(prevProps){
    console.log("CommonMap:componentDidUpdate", this.props.patternId, prevProps.patternId, prevProps.layerHeatMapVisible,this.props.layerHeatMapVisible);
    //if (nextProps.holeInfos.length!=this.props.holeInfos.length) this.centerMap(nextProps.holeInfos);
    //if (nextProps.patternId!=this.props.patternId) this.centerMap(nextProps.holeInfos);
    //if (nextProps.date!=this.props.date) this.centerMap(nextProps.holeInfos);
    if (prevProps.holeInfoVersion!==this.props.holeInfoVersion) this.centerMap(this.props.holeInfos);
    if (prevProps.layerHeatMapVisible!==this.props.layerHeatMapVisible) this.showHeatMap(this.props.layerHeatMapVisible);

    if (prevProps.patternId!= this.props.patternId){
      console.log('componentWillReceiveProps',this.props.surveyInfos);
      this.centerMap(this.props.holeInfos.length>(this.props.surveyInfos?this.props.surveyInfos.length:0)?this.props.holeInfos:this.props.surveyInfos);
    }

    if(!this.props.layerVehicleVisible) this.arrowsClear()

    //this.updateHeatMapData(this.props.holeInfos);
  }

  componentDidMount() {
    this.centerMap(this.props.holeInfos.length>(this.props.surveyInfos?this.props.surveyInfos.length:0)?this.props.holeInfos:this.props.surveyInfos);

    this.intervalID = setInterval(this.refreshData.bind(this), 15000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  refreshData = () => {
    console.log ('CommonMap:refreshData:', this.props.layerSurveyVisible);
    if ((this.props.layerSurveyVisible) || (this.props.layerHoleStateVisible)){
      this.props.refreshData(1+2+4+8);
    } 
    
    if (this.props.layerAccessoriesVisible){
      this.props.refreshData(16+2+4+8);
    } 

    if (this.props.layerDesignVisible){
      this.props.refreshData(64);
    } 
    
    if (this.props.layerLoadedVisible){
      if(this.state.loadMovedIndex===-1){ //Do not do this when moving a marker, we will jump back to original position
        this.props.refreshData(32+2+4+8);
      }
    }
  }


  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = (key,holeInfo) => {
  };

  onChildMouseDown = (key, marker) =>{
    console.log("CommonMap:onChildMouseDown", key, marker);
    if ((this.props.userDetails.flags &  AuthoriseObj.AUTH_MOVE_HOLES) > 0) this.setState({draggable: false});
    this.setState({childMouseDown:true});
  }


 	onChildMouseUp = (key, marker) => {
    console.log("CommonMap:onChildMouseUp", key, marker,this.state.shiftKeyDown, this.state.loadMovedIndex, marker);
    this.setState({childMouseDown:false, draggable: true }); // loadMarkerClickInfo:null, designMarkerClickInfo:null});

 		if ((this.props.userDetails.flags & AuthoriseObj.AUTH_MOVE_HOLES) == 0) return;

    if (this.state.loadMovedIndex === -1) return;

    if (marker.type!=='LoadMarker') return;

    console.log("CommonMap:onChildMouseUp:Updating Load Marker", key, marker);

    //Close to design marker always prefered
    var holeNo = null;
    if (this.state.loadProximityIndex >= 0) holeNo = this.props.loadInfos[this.state.loadProximityIndex].holeNo;
    if (this.state.designProximityIndex >= 0) holeNo = this.props.designInfos[this.state.designProximityIndex].holeNo;

    fetch(process.env.REACT_APP_HOST_URL+'commonUpdateLoadMapPosDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
          userId        : this.props.userDetails.userId,
          companyId     : this.props.userDetails.companyId,
          siteId        : this.props.siteId,
          patternNo     : this.props.patternId,
          startDate     : this.props.startDate,
          endDate       : this.props.endDate,
          vehicleId     : this.props.vehicleId,
          vehicleIdList : this.props.vehicleIdList,
          holeId        : marker.loadInfo.holeId,
          holeNo        : holeNo,
          latitude      : marker.loadInfo.latitude,
          longitude     : marker.loadInfo.longitude
      })
    })
    .then(response => response.json())
    .then( data =>{
      console.log ('xxx0');
       this.setState({loadMovedIndex: -1,loadProximityIndex:-1, designProximityIndex:-1, loadMarkerClickInfo:null, designMarkerClickInfo:null});
       this.refreshData();
      console.log ('xxx1');
     }
    )
    .catch(error => {
      console.log ('xxx2');
      this.setState({ error, isLoading: true, loadMovedIndex: -1,
                      loadProximityIndex:-1, designProximityIndex:-1, loadMarkerClickInfo:null, designMarkerClickInfo:null});
      this.refreshData();

    })
 	}

  checkProximity = (holes, newCoords, radiusCm) => {
      var index = -1;

      for (var i = holes.length-1; i>=0; i--) { //Search backwards as that get first on on stack if staxked
        var distanceCm = calcDistance (holes[i].latitude, holes[i].longitude, newCoords.lat, newCoords.lng, 'K') *100 *1000;
        //console.log("CommonMap:checkProximity:cm",i, distanceCm);
        if ((distanceCm > 0) && (distanceCm < radiusCm)){
          index = i
          break;
        }
      }
      return index;
  }


 	onChildMouseMove = (key, marker, newCoords) =>{
    if (((this.props.userDetails.flags & AuthoriseObj.AUTH_MOVE_HOLES) > 0) && 
        (this.state.shiftKeyDown) &&
        (this.state.loadMarkersMultiSelect.length<=1) &&
        (typeof( marker.handleUpdatePosition) === typeof(Function)) ){ //Not defined for  all markers
      marker.handleUpdatePosition(marker.index, newCoords); 

      var radiusCm = 100;
      var loadIndex = this.checkProximity(this.props.loadInfos, newCoords, radiusCm);

      var designIndex = -1;
      if(this.props.designInfos){
        designIndex = this.checkProximity(this.props.designInfos, newCoords, radiusCm);
      }

      console.log('CommonMap:onChildMouseMove',loadIndex,designIndex);
      this.setState({loadMovedIndex:marker.index, loadProximityIndex:loadIndex, designProximityIndex:designIndex});
 	  }
  }

  onChildMouseEnter = (key, marker) =>{
    console.log("CommonMap:onChildMouseEnter", key, marker);
  }

  onChildMouseLeave = (key, marker) =>{
    console.log("CommonMap:onChildMouseLeave", key, marker);
  }

  onZoomAnimationStart = () =>{
    console.log("CommonMap:onZoomAnimationStart");
     this.heatMap.setMap(null); //Remove the heatmap, will but it back at correct radius/zoom when done
  }
  
  onZoomAnimationEnd = () =>{
    var radius = getNewRadius(this.map,this.maps);

    this.heatMap.setOptions({radius:radius});
    this.heatMap.setMap(this.props.layerHeatMapVisible ? this.map : null);
  }

  onRightClickCallback = (event) => {
    if ((this.props.layerLoadedVisible) && (this.props.patternId)) {
      this.editLatitude  = event.latLng.lat();
      this.editLongitude = event.latLng.lng();

      console.log("CommonMap:Loaded:onRightClickCallback",event);

      this.setState ({loadAddWindow:true});
    } else if ((this.props.layerDesignVisible) && (this.props.patternId)) {
      this.editLatitude  = event.latLng.lat();
      this.editLongitude = event.latLng.lng();

      this.setState ({designAddWindow:true});
    }

  }

  onMapClickCallback = (event) => {
    console.log("CommonMap:onMapClickCallback",event);

    //this.setState({surveyMarkerClickInfo:null, loadMarkerClickInfo:null, designMarkerClickInfo:null});

    if (this.state.shiftKeyDown){
    }

    if (this.rectangle!=null){
      var arr = []; 

      for (var i=0; i< this.props.holeInfos.length; i++ )
      {
        var point = new this.maps.LatLng(this.props.holeInfos[i].latitude, this.props.holeInfos[i].longitude);
        var insideRectangle = false;

        if (this.rectangle && this.rectangle.getBounds && point){
          insideRectangle = this.rectangle.getBounds().contains(point);
          if (insideRectangle){
            arr.push(this.props.holeInfos[i].holeId); 
          }
        }
      }

      this.setState ({loadMarkersMultiSelect:arr});

      this.rectangle.setMap(null);
      this.map.setOptions({draggable:true});
      this.rectangle=null;
    }
  };


  handleLoadAdd = () => {
    console.log('CommonMap:handleLoadAdd');
    this.setState({loadAddWindow : false});
    this.refreshData();
  }


  handleLoadAddCancel = () => {
    console.log('CommonMap:handleLoadAddCancel');
    this.setState({loadAddWindow : false});
  }


  handleDesignAdd = () => {
    console.log('CommonMap:handleDesignAdd');
    this.setState({designAddWindow : false});
    this.refreshData();
  }

  handleDesignAddCancel = () => {
    console.log('CommonMap:handleDesignAddCancel');
    this.setState({designAddWindow : false});
  }



  handleMarkersClear = () => {
    console.log('CommonMap:handleMarkersClear');
    this.setState({loadMarkersMultiSelect:[]});
  }


  handleLoadMarkerClear = (holeId) => {
    console.log('CommonMap:handleLoadMarkerClear', holeId);
    this.setState({ loadMarkerClickInfo:null});
  }


  handleLoadMarkerClick = (loadInfo) => {
   console.log("CommonMap:handleLoadMarkerClick", loadInfo, this.state.loadMarkerClickInfo, this.state.loadMarkersMultiSelect.length);
    if (!this.state.controlKeyDown && !this.state.shiftKeyDown && this.state.loadMarkerClickInfo===null) {
      console.log("CommonMap:handleLoadMarkerClick:Select", loadInfo);
      this.setState({loadMarkerClickInfo:loadInfo});

      this.map.panTo({
        lat: parseFloat(loadInfo.latitude),
        lng: parseFloat(loadInfo.longitude)
      })

      this.map.panBy(500,-100);
    } else if (this.state.controlKeyDown){
      this.handleMarkerCntrClick(loadInfo.holeId); //Might as well allow click select/deseelct of holes when multi selected
    }

  };


  handleDesignMarkerClear = () => {
    console.log('CommonMap:handleDesignMarkerClear');
    this.setState({designMarkerClickInfo : null});
  }

  handleDesignMarkerClick = (designInfo) => {
    console.log("CommonMap:handleDesignMarkerClick",designInfo);
    this.setState({designMarkerClickInfo : designInfo});
  };

  handleSurveyMarkerClear = () => {
    console.log('CommonMap:handleSurveyMarkerClear');
    this.setState({surveyMarkerClickInfo : null});
  }

  handleAccessoryMarkerClear = () => {
    console.log('CommonMap:handleAccessoryMarkerClear');
    this.setState({accessoryMarkerClickInfo : null});
  }

  handleProductMarkerClear = () => {
    console.log('CommonMap:handleProductMarkerClear');
    this.setState({productMarkerClickInfo : null});
  }

  handleSurveyMarkerClick = (surveyInfo) => {
    console.log("CommonMap:handleSurveyMarkerClick", surveyInfo);
    if (this.state.surveyMarkerClickInfo===null) {
      this.setState({surveyMarkerClickInfo : surveyInfo});

      this.map.panTo({
        lat: parseFloat(surveyInfo.latitude),
        lng: parseFloat(surveyInfo.longitude)
      })

      this.map.panBy(500,-100);
    }
  };


  handleAccessoryMarkerClick = (accessoryInfo) => {
    console.log("CommonMap:handleAccessoryMarkerClick", accessoryInfo);
    if (this.state.accessoryMarkerClickInfo===null) {
      this.setState({accessoryMarkerClickInfo : accessoryInfo});

      this.map.panTo({
        lat: parseFloat(accessoryInfo.latitude),
        lng: parseFloat(accessoryInfo.longitude)
      })

      this.map.panBy(500,-100);
    }
  };

  handleProductMarkerClick = (productInfo) => {
    console.log("CommonMap:handleProductMarkerClick", productInfo);
    if (this.state.productMarkerClickInfo===null) {
      this.setState({productMarkerClickInfo : productInfo});

      this.map.panTo({
        lat: parseFloat(productInfo.latitude),
        lng: parseFloat(productInfo.longitude)
      })

      this.map.panBy(500,-100);
    }
  };


  handleHoleStateMarkerClear = () => {
    console.log('CommonMap:handleHoleStateMarkerClear');
    this.setState({holeStateMarkerClickInfo : null});
  }


  handleHoleStateMarkerClick = (surveyInfo) => {
    console.log("CommonMap:handleHoleStateMarkerClick", surveyInfo);
    if (this.state.holeStateMarkerClickInfo===null) {
      this.setState({holeStateMarkerClickInfo : surveyInfo});

      this.map.panTo({
        lat: parseFloat(surveyInfo.latitude),
        lng: parseFloat(surveyInfo.longitude)
      })

      this.map.panBy(500,-100);
    }
  };


  handleMarkerShiftClick = (holeId) => {
    console.log('CommonMap:handleMarkerShiftClick', holeId);

    this.handleMarkersClear();
  }


  handleMarkerCntrClick = (holeId) => {

    this.handleMarkerClear(0);

    var arr = [];
    var inCurrentList = false;

    for (var i=0;i<this.state.loadMarkersMultiSelect.length;i++){
      if ( this.state.loadMarkersMultiSelect[i] !== holeId) {
        arr.push(this.state.loadMarkersMultiSelect[i]);
      }
      else {
        inCurrentList = true;
      }
    }

    if (inCurrentList == false) {
      arr.push(holeId);
    }

    console.log('CommonMap:handleMarkerCntrClick', holeId, arr);
    this.setState ({loadMarkersMultiSelect:arr});
  }

  handleUpdateHole = (holeId, linkedId, editPatternNo, editHoleNo, importPos) => {
    console.log('handleUpdateHole', holeId, editPatternNo, editHoleNo, this.props, importPos);

    fetch(process.env.REACT_APP_HOST_URL+'commonUpdateHoleMapDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
          userId        : this.props.userDetails.userId,
          companyId     : this.props.userDetails.companyId,
          siteId        : this.props.siteId,
          date          : this.props.date,
          startDate     : this.props.startDate,
          endDate       : this.props.endDate,
          patternId     : this.props.patternId,
          vehicleId     : this.props.vehicleId,
          vehicleIdList : this.props.vehicleIdList,
          holeId        : holeId,
          linkedId      : linkedId,
          patternNo     : editPatternNo,
          holeNo        : editHoleNo,
          importPos     : importPos
      })
    })
    .then(response => response.json())
    .then(data => this.props.onUpdateHoleInfos(data))
    .catch(error => this.setState({ error, isLoading: true }));
  }


  handleUpdateHoles = (patternNo, density) => {
    console.log('CommonMap:handleUpdateHoles',this.state.loadMarkersMultiSelect, patternNo, density);

    fetch(process.env.REACT_APP_HOST_URL+'commonUpdateHolesMapDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
          userId        : this.props.userDetails.userId,
          companyId     : this.props.userDetails.companyId,
          siteId        : this.props.siteId,
          date          : this.props.date,
          startDate     : this.props.startDate,
          endDate       : this.props.endDate,
          patternId     : this.props.patternId,
          vehicleId     : this.props.vehicleId,
          vehicleIdList : this.props.vehicleIdList,
          holeIds       : this.state.loadMarkersMultiSelect,
          patternNo     : patternNo,
          density       : density
      })
    })
    .then(response => response.json())
    .then(data => this.props.onUpdateHoleInfos(data))
    .catch(error => this.setState({ error, isLoading: true }));
  }


  showHeatMap(visible) {
    console.log ("CommonMap:showHeatMap",this.visible);

    if (this.map !== undefined) {
      this.heatMap.setMap(visible ? this.map : null)
    }
  }


  showDesignMarkers(visible) {
    console.log ("CommonMap:showDesignMarkers",visible);
    this.setState({designMarkersVisible:visible});
  }


  handleMapMouseDown = (event) => {
    console.log ("CommonMap:handleMapMouseDown",this.state.loadMarkersMultiSelect.length, this.state.controlKeyDown, this.state.shiftKeyDown, event);

    if (this.state.childMouseDown==true) return;
    if (this.state.loadMarkerClickInfo!==null) return;

    if((this.state.controlKeyDown==true) && (this.rectangle==null))
    {
      this.rectStartLatLng = event.latLng;
      this.map.setOptions({draggable:false});

      var bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()),
        new google.maps.LatLng(event.latLng.lat(), event.latLng.lng())
      );

      var rectangle = new google.maps.Rectangle({
        bounds: bounds,
        editable: false
      });

      this.maps.event.addListener(rectangle, 'click', this.onMapClickCallback);
      this.maps.event.addListener(rectangle, 'mousedown', this.handleMapMouseDown);
      this.maps.event.addListener(rectangle, 'mouseup', this.handleMapMouseUp); 
      this.maps.event.addListener(rectangle, 'mousemove', this.handleMapMouseMove); 

      rectangle.setMap(this.map);
      this.rectangle=rectangle;
    }
  };

  handleMapMouseUp = (event) => {
    console.log ("CommonMap:handleMapMouseUp", event,this.rectangle, this.props.holeInfos);
    if (this.rectangle!=null){
      var arr = [];

      for (var i=0; i< this.props.holeInfos.length; i++ )
      {
        if (this.props.holeInfos[i].holeId !==null){
          var point = new this.maps.LatLng(this.props.holeInfos[i].latitude, this.props.holeInfos[i].longitude);
          var insideRectangle = false;

          if (this.rectangle && this.rectangle.getBounds && point){
            insideRectangle = this.rectangle.getBounds().contains(point);
            if (insideRectangle){
              console.log("CommonMap:handleMapMouseUp InsideRectangle",this.props.holeInfos[i].holeId);
              arr.push(this.props.holeInfos[i].holeId);
            }
          }
        }
      }

      this.setState ({loadMarkersMultiSelect:arr});

      console.log("CommonMap:handleMapMouseUp loadMarkersMultiSelect=",arr);

      this.rectangle.setMap(null);
      this.map.setOptions({draggable:true});
      this.rectangle=null;
    }
  };


  handleMapMouseMove = (event) => {
    //console.log(this.mapRef);
    //this.mapRef.current.focus();
    if (this.rectangle!=null) 
    {
      var bounds;

      var bounds = new google.maps.LatLngBounds();
      bounds.extend(event.latLng);
      bounds.extend(this.rectStartLatLng);

      this.rectangle.setBounds(bounds);
    }
  };


  handleApiLoaded = (google) => {
    console.log ("CommonMap:handleApiLoaded");

    this.maps = google.maps;
    this.map  = google.map;

    this.heatMap = new this.maps.visualization.HeatmapLayer();
    this.heatMap.setMap(this.map);
    this.updateHeatMapData(this.props.holeInfos);

    //this.centerMap(this.props.holeInfos.length>this.props.surveyInfos.length?this.props.holeInfos:this.props.surveyInfos);
    this.centerMap(this.props.holeInfos.length>(this.props.surveyInfos?this.props.surveyInfos.length:0)?this.props.holeInfos:this.props.surveyInfos);

    this.maps.event.addListener(this.map, 'click', this.onMapClickCallback);
    this.maps.event.addListener(this.map, 'rightclick', this.onRightClickCallback);
    this.maps.event.addListener(this.map, 'mousedown', this.handleMapMouseDown);
    this.maps.event.addListener(this.map, 'mouseup', this.handleMapMouseUp); 
    this.maps.event.addListener(this.map, 'mousemove', this.handleMapMouseMove); 

    //if((this.props.layerVehicleVisible) && (this.props.loadInfos.length>0)) {
      //this.arrowsDraw(6);
    //else {
     // this.arrowsClear();
    //}
  };

  handleKeyDown = (event) => { //These events repeat while key down, we only want the first one
    if (this.state.controlKeyDown != event.ctrlKey)
    {
      this.setState({controlKeyDown:event.ctrlKey});
    }
    else if (this.state.shiftKeyDown != event.shiftKey)
    {
      this.setState({shiftKeyDown:event.shiftKey}); 
    }
  }


  handleKeyUp = (event) => {
    this.setState({shiftKeyDown:event.shiftKey, controlKeyDown:event.ctrlKey});
  }

  handleVehicleLegendSelect = (selectedVehicleId) => {
    console.log("CommonMap:handleVehicleLegendSelect:",selectedVehicleId);
    this.arrowsDraw(selectedVehicleId);
  }

  handleAccessoryLegendSelect = (selectedAccessoryId) => {
    console.log("CommonMap:handleAccessoryLegendSelect:",selectedAccessoryId);
    this.props.handleAccessorySelect(selectedAccessoryId);
  }

  handleProductLegendSelect = (selectedProductId) => {
    console.log("CommonMap:handleProductLegendSelect:",selectedProductId);
    this.props.handleProductSelect(selectedProductId);
  }

  arrowsClear =() => {
  //  console.log("CommonMap:arrowsClear");

     for (var n=0; n<this.polygons.length; n++){
      this.polygons[n].setMap(null);
    }

    this.polygons=[];
  }


  arrowsDraw =(vehicleId) => {
    console.log("CommonMap:arrowsDraw");

    this.arrowsClear();

    this.polygons=[];

    var lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    };

    if (this.props.loadInfos.length > 1){
    //  var currentTruck = this.props.loadInfos[0].vehicleId;
      var startDate = new Date(this.props.loadInfos[0].eventDateTime);
      var startVehicleId = new Date(this.props.loadInfos[0].vehicleId);

      var i = 1;
      while (i < this.props.loadInfos.length) {
         
        //Only add arrow if winmtin 10 min from prev
        var endDate = new Date(this.props.loadInfos[i].eventDateTime);
        var deltaSec = (endDate.getTime() - startDate.getTime()) / 1000;

        if((deltaSec < 600 ) && (this.props.loadInfos[i].vehicleId==vehicleId) && (startVehicleId==vehicleId) && (!this.props.loadInfos[i].manual)){
          var line = new google.maps.Polyline(
            { strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              path: [
                { lat: parseFloat(this.props.loadInfos[i-1].latitude), lng: parseFloat(this.props.loadInfos[i-1].longitude) },
                { lat: parseFloat(this.props.loadInfos[i].latitude), lng: parseFloat(this.props.loadInfos[i].longitude) },
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: "95%",
                },
              ]
            }
          );

          line.setMap(this.map);

          this.polygons.push(line);
        }

        startDate = endDate; 
        startVehicleId = this.props.loadInfos[i].vehicleId; 
  
        i++;
      }
    }

  };

  render() {
   const { t } = this.props;

   //if (this.props.patternVehicles){
     //let n=this.props.patternVehicles.indexOf(this.props.holeInfo.devId);

     //let rgb = [];
     //if (n==0) rgb=[0x00,0x1f,0x3f];
     //if (n==1) rgb=[0x00,0x74,0xd9];
     //if (n==2) rgb=[0x7f,0xdb,0xff];
     //if (n==3) rgb=[0x39,0xcc,0xcc];
     //if (n==4) rgb=[0x3d,0x99,0x70];
     //if (n==5) rgb=[0x2e,0xcc,0x40];
     //if (n==6) rgb=[0x01,0xff,0x70];
     //if (n==7) rgb=[0xff,0xdc,0x00];
     //if (n==8) rgb=[0xff,0x85,0x1b];
     //if (n==9) rgb=[0xff,0x41,0x36];
     //if (n==10) rgb=[0x85,0x14,0x4b];
     //if (n==11) rgb=[0xf0,0x12,0xbe];
     //if (n==12) rgb=[0xb1,0x0d,0xc9];
     //if (n==13) rgb=[0x11,0x11,0x11];
     //if (n==14) rgb=[0xaa,0xaa,0xaa];
     //if (n>14)  rgb=[0xdd,0xdd,0xdd];
    //} 

      let circleClasses = "inline-block p-7 rounded-full w-10 mx-auto";
    return (
      // Important! Always set the container height explicitly
      <div key='CommonMap' style={{ height: '100vh', width: '100%' }} onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp} ref={this.mapRef}>

       {this.props.layerSurveyVisible===true && [
         <MapSurveyLegend key='MapSurveyLegend'/>
       ]}

       {(this.props.layerAccessoriesVisible===true && (this.props.layerAccessoriesOptions==='1' || this.props.layerAccessoriesOptions==='2'))&& [
         <MapAccessoryLegend
           key          = 'MapAccessoryLegend'
           userDetails  = {this.props.userDetails}
           siteId       = {this.props.siteId}
           patternNo    = {this.props.patternId}
           selectedId   = {this.props.selectedAccessoryId}
           handleSelect = {this.handleAccessoryLegendSelect}
           options      = {this.props.layerAccessoriesOptions}
        />
       ]}

       {(this.props.layerAccessoriesVisible===true && this.props.layerAccessoriesOptions==='2')&& [
         <MapAccessoryDateLegend
           key         = 'MapAccessoryDateLegend'
           userDetails = {this.props.userDetails}
           siteId      = {this.props.siteId}
           patternNo   = {this.props.patternId}
           dates       = {this.props.accessoryPatternDates}
        />
       ]}

       {this.props.layerLoadProgressVisible===true && [
         <MapLoadProgressLegend
           key               = 'MapLoadProgressLegend'
           userDetails       = {this.props.userDetails}
           siteId            = {this.props.siteId}
           patternNo         = {this.props.patternId}
           patternDates      = {this.props.patternDates}
         />
       ]}

       {this.props.layerVehicleVisible===true && [
         <MapVehicleLegend
           key               = 'MapVehicleLegend'
           userDetails       = {this.props.userDetails}
           siteId            = {this.props.siteId}
           patternNo         = {this.props.patternId}
           patternVehicles   = {this.props.patternVehicles}
           handleSelect      = {this.handleVehicleLegendSelect}
         />
       ]}

       {this.props.layerProductVisible===true && [
         <MapProductLegend
           key          = 'MapProductLegend'
           userDetails  = {this.props.userDetails}
           siteId       = {this.props.siteId}
           patternNo    = {this.props.patternId}
           selectedId   = {this.props.selectedProductId}
           handleSelect = {this.handleProductLegendSelect}
           options      = {this.props.layerProductOptions}
         />
       ]}

        <GoogleMapReact
           key                  = 'GoogleMapReact'
           yesIWantToUseGoogleMapApiInternals
           onGoogleApiLoaded    = {this.handleApiLoaded}
           bootstrapURLKeys     = {{ key: 'AIzaSyDlrKQh55n9-jY2KHHrUIx2t3dQOG30P98',  libraries: 'drawing,visualization' }}
           defaultCenter        = {this.state.center}
           draggable            = {this.state.draggable}
           center               = {this.state.center}
           zoom                 = {this.state.zoom}
           defaultZoom          = {this.state.zoom}
           options              = {createMapOptions} 
           onZoomAnimationStart = {this.onZoomAnimationStart}
           onZoomAnimationEnd   = {this.onZoomAnimationEnd}
           onChildMouseLeave    = {this.onChildMouseLeave}
           onChildMouseEnter    = {this.onChildMouseEnter}
           onChildMouseDown     = {this.onChildMouseDown}
		       onChildMouseUp       = {this.onChildMouseUp}
		       onChildMouseMove     = {this.onChildMouseMove}>

           {(this.props.layerOverFillVisible===true) && [
             this.props.holeInfos.map(data =>
              (<OverFillMarker
                  userDetails  = {this.props.userDetails}
                  key          = {data.no}
                  type         = {"OverFillMarker"}
                  lat          = {data.bestLat}
                  lng          = {data.bestLng}
                  holeInfo     = {data}
                  layerOptions = {this.props.layerOverFillOptions}
              />))
           ]}

            {(this.props.layerAccessoriesVisible===true) && [
             this.props.accessoryInfos.map(data =>
              (<AccessoryMarker
                  userDetails           = {this.props.userDetails}
                  key                   = {data.id}
                  type                  = {"AccessoryMarker"}
                  lat                   = {data.bestLat}
                  lng                   = {data.bestLng}
                  refreshData           = {this.refreshData}
                  holeInfo              = {data}
                  markerClickInfo       = {this.state.accessoryMarkerClickInfo}
                  accessoryIds          = {this.props.accessoryIds}
                  accessoryPatternDates = {this.props.accessoryPatternDates}
                  layerOptions          = {this.props.layerAccessoriesOptions}
              />))
           ]}

           {(this.props.layerVehicleVisible===true) && [
             this.props.holeInfos.map(data =>
              (<VehicleMarker
                  userDetails     = {this.props.userDetails}
                  key             = {data.no}
                  type            = {"VehicleMarker"}
                  lat             = {data.bestLat}
                  lng             = {data.bestLng}
                  refreshData     = {this.refreshData}
                  holeInfo        = {data}
                  markerClickInfo = {this.state.surveyMarkerClickInfo}
                  patternVehicles = {this.props.patternVehicles}
              />))
           ]}

           {(this.props.layerLoadProgressVisible===true) && [
             this.props.holeInfos.map(data =>
              (<LoadProgressMarker
                  userDetails     = {this.props.userDetails}
                  key             = {data.no}
                  type            = {"LoadProgressMarker"}
                  lat             = {data.bestLat}
                  lng             = {data.bestLng}
                  holeInfo        = {data}
                  refreshData     = {this.refreshData}
                  markerClickInfo = {this.state.surveyMarkerClickInfo}
                  patternDates    = {this.props.patternDates}
              />))
           ]}

           {(this.props.layerProductVisible===true) && (this.props.productInfos) &&[
            this.props.productInfos?.map(data =>
              (<ProductMarker
                  userDetails     = {this.props.userDetails}
                  key             = {data.id}
                  type            = {"ProductMarker"}
                  lat             = {data.bestLat}
                  lng             = {data.bestLng}
                  refreshData     = {this.refreshData}
                  holeInfo        = {data}
                  markerClickInfo = {this.state.productMarkerClickInfo}
                  productIds      = {this.props.productIds}
              />))
           ]}

           {(this.props.layerLoadedVisible===true) && [
             this.props.loadInfos.map((data,index) =>
              (data.holeId?<LoadMarker
                  userDetails          = {this.props.userDetails}
                  key                  = {data.holeId}
                  type                 = {"LoadMarker"}
                  lat                  = {data.latitude}
                  lng                  = {data.longitude}
                  holeNo               = {data.holeNo}
                  showInfoWindow       = {(this.state.loadMarkerClickInfo!==null && 
                                          ((this.state.loadMarkerClickInfo.holeNo && this.state.loadMarkerClickInfo.holeNo===data.holeNo ) || //Match on holeN
                                           (!this.state.loadMarkerClickInfo.holeNo && this.state.loadMarkerClickInfo.holeId===data.holeId )) && //Or holeId if no HolNo
                                           this.state.loadMarkersMultiSelect.length===0 && !this.state.loadAddWindow)?true:false} //Are we the Clicked one in the list AND we are not CNTRL Clicking
                  removeInfoWindow     = {(this.state.controlKeyDown || this.state.shiftKeyDown)?true:false}
                  index                = {index}
                  proximity            = {(this.state.loadProximityIndex==index)?true:false} //Design Marker prefered
                  moving               = {(this.state.loadMovedIndex==index)?true:false} 
                  state                = {data.state}
                  selected             = {((this.props.selectedHoleId===data.holeId)||this.state.loadMarkersMultiSelect.includes(data.holeId))?true:false}
                  markerClickInfo      = {this.state.loadMarkerClickInfo}
                  designClickInfo      = {this.state.designMarkerClickInfo}
                  handleMarkerClear    = {this.handleLoadMarkerClear}
                  handleMarkerClick    = {this.handleLoadMarkerClick}
                  refreshData          = {this.refreshData}
                  handleUpdatePosition = {this.props.handleUpdateLoadPosition} //Need it for marker move callback
                  loadAddWindow        = {this.state.loadAddWindow}
                  loadInfo             = {data}
              />:null))
           ]}


            {(this.props.layerDesignVisible==true) && [
             this.props.designInfos.map((data,index) =>
              (<DesignMarker
                  userDetails       = {this.props.userDetails}
                  key               = {data.holeId}
                  type              = {"DesignMarker"}
                  lat               = {data.latitude}
                  lng               = {data.longitude}
                  refreshData       = {this.refreshData}
                  index             = {index}
                  proximity         = {(this.state.designProximityIndex==index)?true:false}
                  showInfoWindow    = {((this.state.designMarkerClickInfo!==null) && 
                                        (this.state.designMarkerClickInfo.holeId===data.holeId) &&
                                        (!this.state.designAddWindow))?true:false}
                 // anchor            = {new google.maps.Point(5, 58)}
                  labelAnchor
                  selected          = {((this.props.selectedHoleId===data.holeId)||
                                       ((this.state.designMarkerClickInfo!==null) && (this.state.designMarkerClickInfo.holeId===data.holeId)))?true:false}
                  markerClickInfo   = {this.state.designMarkerClickInfo}
                  loadClickInfo     = {this.state.loadMarkerClickInfo}
                  handleMarkerClear = {this.handleDesignMarkerClear}
                  handleMarkerClick = {this.handleDesignMarkerClick}
                  designAddWindow   = {this.state.designAddWindow}
                  designInfo        = {data}
                  loadAddEnabled    = {true}
              />))
           ]}


           {(this.props.layerSurveyVisible===true && this.props.surveyInfos!=null) && [
             this.props.surveyInfos.map(data =>
              (<SurveyMarker
                  userDetails        = {this.props.userDetails}
                  key                = {data.holeId}
                  type               = {"SurveyMarker"}
                  lat                = {data.latitude}
                  lng                = {data.longitude}
                  refreshData        = {this.refreshData}
                  showInfoWindow     = {((this.state.surveyMarkerClickInfo!==null) && (this.state.surveyMarkerClickInfo.holeId===data.holeId))?true:false}
                  //anchor= {new google.maps.Point(5, 58)}
                  labelAnchor
                  selected           = {((this.props.selectedHoleId===data.loadedHoleId) || 
                                        ((this.state.surveyMarkerClickInfo!==null) && (this.state.surveyMarkerClickInfo.holeId===data.holeId)))?true:false}
                  markerClickInfo    = {this.state.surveyMarkerClickInfo}
                  handleMarkerClear  = {this.handleSurveyMarkerClear}
                  handleMarkerClick  = {this.handleSurveyMarkerClick}
                  surveyInfo         = {data}
                  layerOptions       = {this.props.layerSurveyOptions}
              />))
           ]}

           {(this.props.layerSurveyVisible==true && this.props.surveyInfos!=null && this.props.layerSurveyShowGpsPos) &&[
             this.props.surveyInfos.map(data =>
              (<GpsPosMarker
                  userDetails  = {this.props.userDetails}
                  key          = {data.holeId+'gps'}
                  type         = {"GpsPosMarker"}
                  lat          = {data.diffGpsLat}
                  lng          = {data.diffGpsLng}
                  gpsOptions   = {data.diffGpsOptions}
                  labelAnchor
                  surveyInfo   = {data}
              />))
           ]}

           {(this.props.layerHoleStateVisible==true && this.props.surveyInfos!=null) && [
             this.props.surveyInfos.map(data =>
              (<HoleStateMarker
                  userDetails        = {this.props.userDetails}
                  key                = {data.holeId}
                  type               = {"HoleStateMarker"}
                  lat                = {data.latitude}
                  lng                = {data.longitude}
                  refreshData        = {this.refreshData}
                  showInfoWindow     = {((this.state.holeStateMarkerClickInfo!==null) && (this.state.holeStateMarkerClickInfo.holeId===data.holeId))?false:false}
                  //anchor= {new google.maps.Point(5, 58)}
                  labelAnchor
                  selected           = {((this.props.selectedHoleId===data.loadedHoleId) ||
                                        ((this.state.holeStateMarkerClickInfo!==null) && (this.state.holeStateMarkerClickInfo.holeId===data.holeId)))?true:false}
                  markerClickInfo    = {this.state.holeStateMarkerClickInfo}
                  handleMarkerClear  = {this.handleHoleStateMarkerClear}
                  handleMarkerClick  = {this.handleHoleStateMarkerClick}
                  surveyInfo         = {data}
                  layerOptions       = {this.props.layerHoleStateOptions}
              />))
           ]}

           {(this.state.loadMarkersMultiSelect.length > 0) && [
             <InfoWindowMulti
               key='InfoWindowMulti'
               handleMarkersClear = { this.handleMarkersClear } 
               handleUpdateHoles  = { this.handleUpdateHoles }
               noSelected         = { this.state.loadMarkersMultiSelect.length}
             />  ]}

           {(this.state.loadAddWindow) && [
               <LoadAddMarker
                 userDetails          = {this.props.userDetails}
                 key                  = {12345}
                 type                 = {"LoadMarker"}
                 lat                  = {this.editLatitude}
                 lng                  = {this.editLongitude}
               />
           ]}

           {(this.state.designAddWindow) && [
             <LoadAddMarker
               userDetails          = {this.props.userDetails}
               key                  = {12345}
               type                 = {"DesignMarker"}
               lat                  = {this.editLatitude}
               lng                  = {this.editLongitude}
            />
           ]}

           {((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_PATTERN>0) && (this.state.loadAddWindow)) && [
             <div>
               <LoadAdd
                 userDetails  = {this.props.userDetails}
                 siteId       = {this.props.siteId}
                 patternNo    = {this.props.patternId}
                 holeNo       = {null}
                 latitude     = {this.editLatitude}
                 longitude    = {this.editLongitude}
                 handleAdd    = {this.handleLoadAdd}
                 handleCancel = {this.handleLoadAddCancel}
               />
             </div>
           ]}

           {((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_PATTERN>0) && (this.state.designAddWindow))?
             <div>
               <DesignAdd
                 userDetails  = {this.props.userDetails}
                 siteId       = {this.props.siteId}
                 patternNo    = {this.props.patternId}
                 holeNo       = {null}
                 latitude     = {this.editLatitude}
                 longitude    = {this.editLongitude}
                 handleAdd    = {this.handleDesignAdd}
                 handleCancel = {this.handleDesignAddCancel}
               />
             </div>:null
           }

        </GoogleMapReact>
      </div>
    );
  }
}

export default withTranslation()(CommonMap);
