import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import AuthoriseObj from './Authorise.js';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import FormControl from '@mui/material/FormControl/FormControl';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';


class ZoneMapInfoWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description      : null,
      speedLimit       : null,
      exportId         : null,
      checkedTiz       : null,
      checkedLoadZone  : null,
      errorDescription : false,
      errorSpeedLimit  : false,
      errorExportId    : false,
      saveDisabled     : true,
    };

    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeSpeedLimit  = this.handleChangeSpeedLimit.bind(this);
    this.handleChangeExportId    = this.handleChangeExportId.bind(this);
    this.handleChangeTiz         = this.handleChangeTiz.bind(this);
    this.handleChangeLoadZone    = this.handleChangeLoadZone.bind(this);
    this.handleSave              = this.handleSave.bind(this);
    this.handleDelete            = this.handleDelete.bind(this);
    this.handleCancel            = this.handleCancel.bind(this);
  }

  componentDidMount() {
    console.log ("ZoneMapInfoWindow:componentDidMount:",this.props.zoneInfo );
    if((this.props.zoneInfo.type&1) > 0) this.setState({checkedTiz:true}); else this.setState({checkedTiz:false});
    if((this.props.zoneInfo.type&2) > 0) this.setState({checkedLoadZone:true});else this.setState({checkedLoadZone:false});
    this.setState ({description: this.props.zoneInfo.description, 
                    speedLimit:this.props.zoneInfo.speedLimit,
                    exportId:this.props.zoneInfo.exportId});
  }


  handleChangeDescription(event) {
    this.setState({description: event.target.value, errorDescription:false, saveDisabled:false});
  }


  handleChangeSpeedLimit(event) {
    this.setState({speedLimit: event.target.value, errorSpeedLimit:false, saveDisabled:false});
  }

  handleChangeExportId(event) {
    this.setState({exportId: event.target.value, errorEportId:false, saveDisabled:false});
  }

  handleChangeTiz(event) {
    console.log ("ZoneMapInfoWindow:handleChangeTiz",event.target.checked);
    this.setState({checkedTiz: event.target.checked, saveDisabled:false});
  }


  handleChangeLoadZone(event) {
    console.log ("ZoneMapInfoWindow:handleChangeLoadZone",event.target.checked);
    this.setState({checkedLoadZone: event.target.checked, saveDisabled:false});
  }


  handleSave(event) {
    console.log("ZoneMapInfoWindow:handleSave:", this.state.description, this.state.speedLimit, this.state.exportId, this.state.checkedTiz, this.state.checkedLoadZone);

    if((this.state.description) && (this.state.speedLimit)) {
      let editState = 1;

      let editType = 0;
      if (this.state.checkedTiz) editType += 1; 
      if (this.state.checkedLoadZone) editType += 2; 

      this.props.handleZoneUpdate(editState,  this.state.description, this.state.speedLimit, this.state.exportId, editType);

      this.props.handleRemoveInfoWindow();
    } else {
      if (!this.state.description) this.setState({errorDescription: true}); else this.setState({errorDescription: false});
      if (!this.state.speedLimit)  this.setState({errorSpeedLimit: true}); else this.setState({errorSpeedLimit: false});
    }
  }

  handleCancel(event) {
    console.log("ZoneMapInfoWindow:handleCancel:",this.props.userDetails);
    this.props.handleRemoveInfoWindow();
  }

  handleDelete(event) {
    console.log("ZoneMapInfoWindow:handleDelete:");
    this.props.handleRemoveInfoWindow();
    var editState = 0;
    this.props.handleZoneUpdate(editState);
  }

  render() {
    const { t } = this.props;

    const infoWindowStyle = {
      position: 'relative', //this.props.position,
      bottom: 300,
      left: 10,
      width: 800,
      backgroundColor: 'white',
      padding: 5,
      fontSize: 14,
      zIndex: 100,
      opacity: 0.90
    };

    return (
      <div  style={infoWindowStyle}>
        <div style={{ fontSize: 20 }}>
          {(this.props.editable==true) && [
            <div>{t('Zone_Edit')}
            <hr/></div>]}
          <form>
            <div
              style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
            >

            <FormControl variant="outlined" sx={{ m: 1, minWidth: 150 }}>
              <TextField
                required
                type          = "text"
                id            = "description"
                label         ={t('Description')}
                error         = {this.state.errorDescription}
                onChange      = {this.handleChangeDescription}
                value         = {this.state.description}
                defaultValue  = {this.props.zoneInfo.description}
                maxLength     = {64}
              />
            </FormControl>

            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_REPORT_SPEEDING)>0)?
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 150 }}>
                  <TextField
                    required
                    type          = "number"
                    id            = "speedLimit"
                    label         ={t('Speed_Limit') + '('+this.props.userDetails.speedUnit+')'}
                    error         = {this.state.errorSpeedLimit}
                    onChange      = {this.handleChangeSpeedLimit}
                    value         = {this.state.speedLimit}
                    defaultValue  = {this.props.zoneInfo.speedLimit}
                    maxLength     = {3}
                  />
                </FormControl>
              :null }

              <FormControl variant="outlined" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                type          = "text"
                id            = "exportId"
                label         ={t('ExportId')}
                error         = {this.state.errorExportId}
                onChange      = {this.handleChangeExportId}
                value         = {this.state.exportId}
                defaultValue  = {this.props.zoneInfo.exportId}
                maxLength     = {45}
              />
            </FormControl>


              <div><input type="checkbox" id="loadZone" name="loadZone" value="true" checked={this.state.checkedLoadZone} onChange={this.handleChangeLoadZone}/>
              <label style={{clear:'both', fontSize:15, fontWeight:'normal', paddingLeft:10, border:'none'}} htmlFor="loadZone"> {t('Loading_Zone')}</label></div>

            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_REPORT_TIZ)>0)?<div style={{paddingLeft:20}}>
              <input type="checkbox" id="tiz" name="tiz" value="true" checked={this.state.checkedTiz} onChange={this.handleChangeTiz}/>
              <label style={{clear:'both', fontSize:15, fontWeight:'normal', paddingLeft:10, border:'none'}} htmlFor="tiz"> {t('TIZ')}</label></div>:null}
              </div>
          </form>
        </div>

        <div style ={{ clear:'both'}}>
         {((this.props.editable==true) && ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_ZONES)>0)) && [
           <Button sx={{ m: 1}} variant="contained" size="large" onClick={this.handleSave} disabled={this.state.saveDisabled}>{t('Save')}</Button>]}

         {((this.props.editable==true) && ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_ZONES)>0)) && [
           <Button sx={{ m: 1}} variant="contained" size="large" onClick={this.handleDelete}>{t('Delete')}</Button>]}

         <Button sx={{ m: 1}} variant="contained" size="large" onClick={this.handleCancel}>{t('Cancel')}</Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ZoneMapInfoWindow);
