import React, { Component } from "react";
import { RangeDatePicker } from '@y0c/react-datepicker';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import moment from "moment"
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import dayjs from "dayjs"
import SummaryTable from './Report.PatternLoadSummary.Table';
import { withTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class ReportPatternLoadSummary extends Component {

    constructor(props) {
        super(props);

        let now       = dayjs();
        let endDate   = now;
        let startDate = now.subtract('1', 'month');
 
        this.state = {
            startDate  : startDate.format('YYYY-MM-DD'),
            endDate    : endDate.format('YYYY-MM-DD'), 
            selectedSite: null,
            summaryData : null,
            sites : [],
        };
    }

    componentDidMount() {       
      this.getSites();
    }

    getSites = () =>{
    //this.setState({isLoading: true });
      fetch(process.env.REACT_APP_HOST_URL+'reportSitesDS.php',
      {
        method: 'POST',                
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId
        })
      })
      .then(response => response.json())
      .then(data => {
        this.setState({ sites:data  })
        if (data.length > 0){
          this.setState({ selectedSite:data[0] });
        }
      })
      .catch(error => this.setState({ sites: null }));
    }

    handleChangeDate = (startDate,endDate) => {       
      var startDateMySql = moment(startDate).format('YYYY-MM-DD');
      var endDateMySql = moment(endDate).format('YYYY-MM-DD');
      console.log('PatternLoadSummary:handleChangeDate', startDateMySql, endDateMySql );
      this.setState({startDate:startDateMySql, endDate:endDateMySql, summaryData:null });
    }


    handleChangeSites = (selectedSite) => {     
      this.setState({ selectedSite, summaryData:null });
      console.log('PatternLoadSummary:handleChangeSites', selectedSite );
    }


    handleRowClick = (date, patternId, vehicleId) => {
      console.log ('PatternLoadSummary:handleRowClick:', date, patternId, vehicleId);
    }

    handleSearch = () => {
      this.setState({ summaryData:null });
      fetch(process.env.REACT_APP_HOST_URL+'reportPatternLoadSummaryData.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId        : this.props.userDetails.userId,
          companyId     : this.props.userDetails.companyId,
          siteId        : this.state.selectedSite.value,
          startDate     : this.state.startDate,
          endDate       : this.state.endDate,
        })
      })
      .then(response => response.json())
      .then(data => {
        this.setState({summaryData:data});
      })
      .catch(error => this.setState({ summaryData:null }));
    }

    handleCsv = () => {
      console.log("PatternLoadSummary:handleCsv", this.state.selectedSite.value,this.state.startDate, this.state.endDate);
      fetch(process.env.REACT_APP_HOST_URL+'reportPatternLoadSummaryCsv.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId        : this.props.userDetails.userId,
          companyId     : this.props.userDetails.companyId,
          siteId        : this.state.selectedSite.value,
          startDate     : this.state.startDate,
          endDate       : this.state.endDate,
        })
      })
      .then(response => response.text())
      .then( data =>{
          var fileDownload = require('js-file-download');
          fileDownload(data, 'iBlastX_load_Summary.'+this.state.selectedSite.label+'.'+this.state.startDate+'.csv');
       })
      .catch(error => this.setState({ error, isLoading:true }));
    }


    handlePdf = () => {
      console.log("PatternLoadSummary:handlePdf");
      fetch(process.env.REACT_APP_HOST_URL+'reportPatternLoadSummaryPdf.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId        : this.props.userDetails.userId,
          companyId     : this.props.userDetails.companyId,
          siteId        : this.state.selectedSite.value,
          startDate     : this.state.startDate,
          endDate       : this.state.endDate,
        })
      })
      .then(response => {
        response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'iBlastX_load_Summary.'+this.state.selectedSite.label+'.'+this.state.startDate+'.pdf';
          alink.click();
        })
      })
    }


    render() {       
      const { t } = this.props;

      const { selectedVehicles,selectedSite} = this.state;      
      let now = dayjs();
      let initialStartDate = now.subtract('1', 'month');
      let initialEndDate =now;

      var weekdays = [t('Sunday'), t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday')];
      var weekdaysShort = [t('Sun'), t('Mon'), t('Tue'), t('Wed'), t('Thur'), t('Fri'), t('Sat')];

      const locale = {
        name: 'custom',
        weekdays:weekdays, 
        weekdaysShort:weekdaysShort, 
        months: '1,2,3,4,5,6,7,8,9,10,11,12'.split(','),
      };

      return (
        <div>
          <h3 style={{textAlign: 'center', color: 'black'}}>{t('Pattern_Load_Summary_Report')}</h3>
          <div >            
            <div style={{ float:'left', maxWidth: '500px', margin: 'auto' }}> 
              <RangeDatePicker onChange={this.handleChangeDate}
                locale={locale}
                showMonthCnt={2}
                initialStartDate ={initialStartDate}
                initialEndDate= {initialEndDate}
                startPlaceholder={t('Start_Date')}
                endPlaceholder={t('End_Date')}/>  
            </div>
            <div style={{ clear :'both', width: '400px' }}> 
              <Select
                value={selectedSite}
                onChange={this.handleChangeSites}
                options={this.state.sites}
                isMulti ={false}
                placeholder ={t('Select_Site')}
              />
            </div>  
          </div>

          {((this.state.startDate!==null)  && (this.state.endDate!==null) && (this.state.selectedSite!==null)) && [
              <div key={1} style={{ clear :'both' , paddingBottom: '10px'}}>
                  <Button onClick={this.handleSearch} variant="primary">{t('Search')}</Button>
              </div>
          ]}          

          {(this.state.summaryData!==null) && [
            (this.state.summaryData.summaries.length>0)?
            <div key={12}>
             <h3 key={1023} style={{borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black'}}>
               {t('Summary')+' : ' + this.state.summaryData.siteDescription + ' ' + 
               this.state.summaryData.startDate + String.fromCharCode(8594) + this.state.summaryData.endDate}</h3>
              <Container style={{paddingTop: '10px', paddingBottom: '15px', fontSize: '16px', color: 'grey'}}>
                <Row>
                  <Col>{t('Site')}</Col>
                  <Col>{this.state.summaryData.siteDescription}</Col>
                </Row>
                <Row>
                  <Col>{t('Start_Date')}</Col>
                  <Col>{this.state.summaryData.startDate}</Col>
                </Row>
                <Row>
                  <Col>{t('End_Date')}</Col>
                  <Col>{this.state.summaryData.endDate}</Col>
                </Row>
                <Row>
                  <Col>{t('noDays')}</Col>
                  <Col>{this.state.summaryData.noDays}</Col>
                </Row>
                <Row>
                  <Col>{t('No_Vehicles')}</Col>
                  <Col>{this.state.summaryData.noVehicles}</Col>
                </Row>
                <Row>
                  <Col>{t('Loaded')}({this.props.userDetails.massMUnit})</Col>
                  <Col>{this.state.summaryData.loadedWeight}</Col>
                </Row>
                <Row>
                  <Col>{t('Holes_Loaded')}</Col>
                  <Col>{this.state.summaryData.noLoadedHoles}</Col>
                </Row>
                <Row>
                  <Col>{t('OverLoaded')}</Col>
                  <Col>{this.state.summaryData.noHolesOverFilled}</Col>
                </Row>
                <Row>
                  <Col>{t('UnderLoaded')}</Col>
                  <Col>{this.state.summaryData.noHolesUnderFilled}</Col>
                </Row>
                <Row>
                  <Col>{t('Weight_Truck_Day')}({this.props.userDetails.massMUnit})</Col>
                  <Col>{this.state.summaryData.weightPerTruckDay}</Col>
                </Row>
                <Row>
                  <Col>{t('Holes_Truck_Day')}</Col>
                  <Col>{this.state.summaryData.noHolesPerTruckDay}</Col>
                </Row>
              </Container>

              <SummaryTable
                key={7}
                onRowClick={this.handleRowClick}
                userDetails={this.props.userDetails}
                summaryData={this.state.summaryData}/>

              <div key={1} style={{ clear :'both' , paddingBottom: '20px' }}>
                <Button onClick={this.handleCsv} variant="primary">{t('Export_CSV')}</Button>
                 &nbsp;
                 <Button onClick={this.handlePdf} variant="primary">{t('Export_PDF')}</Button>
              </div>

            </div> :
            <div>
              {t('No_Data_Found')}
            </div>
          ]}

        </div>     
    );
  }
}

export default withTranslation()(ReportPatternLoadSummary);
