import React, { Component } from "react";
import SetupTabletTable1 from './SetupTabletTable.1';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';
 
class SetupTablets extends Component {
  constructor(props) {
    super(props);

    this.state = {         
      companies:null,
      vehicles:null,
      tablets:null,
    };
  }

  componentDidMount() {
    this.getCompanies();
    this.getVehicles();
    this.getTablets();
  }


  getCompanies(){
    console.log ("SetupTablets:getCompanies");
    fetch(process.env.REACT_APP_HOST_URL+'adminGetCompaniesDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId,
      })
    })
    .then(response => response.json())
    .then(data => this.setState({companies:data}))
    .catch(error => this.setState({companies:null}));
  };


  getTablets=()=> {
    console.log("SetupTablets:getTablets");

    fetch(process.env.REACT_APP_HOST_URL+'setupTabletsGetDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : ((this.props.userDetails.flags & AuthoriseObj.AUTH_ROOT) > 0)?null:this.props.userDetails.companyId,
      })
    })
    .then(response => response.json())
    .then(data => this.setState({ tablets:data }))
    .catch(error => this.setState({ tablets:null }));
  }


  getVehicles=()=> {
    console.log("SetupTablets:getVehicles");

    fetch(process.env.REACT_APP_HOST_URL+'reportVehiclesDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId,
        options   : 0
      })
    })
    .then(response => response.json())
    .then(data => this.setState({ vehicles:data }))
    .catch(error => this.setState({ vehicles:null }));
  }


  handleUpdate = (uuid, description, companyId, vehicleId) =>{
    console.log("SetupTablets:handleUpdate", uuid, description, companyId, vehicleId);
    fetch(process.env.REACT_APP_HOST_URL+'setupTabletUpdateDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId          : this.props.userDetails.userId,
        companyId       : this.props.userDetails.companyId,
        uuid            : uuid,
        description     : description,
        tabletCompanyId : companyId,
        tabletVehicleId : vehicleId
      })
    })
    .then(response => {this.getTablets();})
    .catch(error => this.setState({error}));
  }

  handleDelete = (uuid) =>{
    console.log("SetupTablets:handleDelete",uuid);
    fetch(process.env.REACT_APP_HOST_URL+'setupTabletDeleteDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId,
        uuid      : uuid
      })
    })
    .then(response => {this.getTablets();})
    .catch(error => this.setState({error}));
  }

  render() {
    const { t } = this.props;

    return (
        <div>
          {((this.state.tablets!==null) && (this.state.companies!==null)) && [
            <SetupTabletTable1 
              userDetails  = {this.props.userDetails} 
              tablets      = {this.state.tablets}
              companies    = {this.state.companies}
              vehicles     = {this.state.vehicles}
              handleDelete = {this.handleDelete}
              handleUpdate = {this.handleUpdate}/>
          ]}
        </div>

    );
  }
}

export default withTranslation()(SetupTablets);
