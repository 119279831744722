import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import AuthoriseObj from './Authorise.js'
import Button from 'react-bootstrap/Button';
import LoadTable from './LoadTable';
import DeckTable from './DeckTable';
import SurveyTable from './SurveyTable';
import i18n from '../translations/i18n';
import { withTranslation } from 'react-i18next';

class MapInfoWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patternNo : null,
      holeNo    : null,
      surveys   : [],
      loads     : [],
      decks     : [],
    };

    this.handleSave            = this.handleSave.bind(this);
    this.handleCancel          = this.handleCancel.bind(this);
    this.handleClick           = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.props.holeInfo.noDecks > 1) this.getDecks();
    this.getLoads();
    this.getSurveys();
  }

  handleClick(event) {
    event.stopPropagation(); //Otherwise HeatMap toggle
    console.log("MapInfoWindow:handleClick:",this.props.holeInfo);
    this.props.handleClick(event);
  }

  handleSave(event) {
    console.log("MapInfoWindow:handleSave:",this.state,this.props.holeInfo.holeId);
    this.props.handleMarkerClear();

    if((this.state.patternNo != null) || (this.state.holeNo != null)) //We have some edits
    {
      var editPatternNo = this.props.holeInfo.patternNo;
      if(this.state.patternNo != null ) editPatternNo = this.state.patternNo;

      var editHoleNo = this.props.holeInfo.holeNo;
      if(this.state.holeNo != null ) editHoleNo = this.state.holeNo;

      this.props.handleUpdateHole(this.props.holeInfo.holeId, this.props.holeInfo.linkedId, editPatternNo, editHoleNo, this.props.holeInfo.importPos);
    }
    //event.stopPropagation(); //Otherwise HeatMap toggle
  }

  handleCancel(event) {
    //event.stopPropagation(); //Otherwise HeatMap toggle
    console.log("MapInfoWindow:handleCancel:",this.state,this.props.holeInfo.holeId);
    this.props.handleMarkerClear();
  }

  handleUpdateLoad = () => {
    console.log("MapInfoWindow:handleUpdateLoad:",this.state,this.props.holeInfo.holeId);
    this.getLoads();
    this.getDecks();
    this.props.refreshData(14);
  }

  getLoads = () => {
    console.log("MapInfoWindow:getLoads");
    fetch(process.env.REACT_APP_HOST_URL+'commonGetLoadsDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
         userId    : this.props.userDetails.userId,
         companyId : this.props.userDetails.companyId,
         siteId    : this.props.holeInfo.siteId,
         patternNo : this.props.holeInfo.patternNo,
         holeNo    : this.props.holeInfo.holeNo,
         holeId    : this.props.holeInfo.holeId,
      })
    })
    .then(response => response.json())
    .then(data =>this.setState({loads:data}) )
    .catch(error => this.setState({loads:[]}));
  }


  getDecks = () => {
    console.log("MapInfoWindow:getDecks");
    fetch(process.env.REACT_APP_HOST_URL+'commonGetDecksDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
         userId    : this.props.userDetails.userId,
         companyId : this.props.userDetails.companyId,
         holeId    : this.props.holeInfo.holeId
      })
    })
    .then(response => response.json())
    .then(data =>this.setState({decks:data}) )
    .catch(error => this.setState({decks:[]}));
  }


  getSurveys() {
    fetch(process.env.REACT_APP_HOST_URL+'commonGetSurveysDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId        : this.props.userDetails.userId,
        companyId     : this.props.userDetails.companyId,
        holeId        : this.props.holeInfo.holeId,
        siteId        : this.props.holeInfo.siteId,
        patternNo     : this.props.holeInfo.patternNo,
        holeNo        : this.props.holeInfo.holeNo
      })
    })
    .then(response => response.json())
    .then(data => {
      data.map(function(survey){
        survey.surveyStateDescription = '';
        if(survey.surveyState==1) survey.surveyStateDescription=i18n.t('Survey_Depth_OK');
        if(survey.surveyState==2) survey.surveyStateDescription=i18n.t('Survey_Depth_Over');
        if(survey.surveyState==3) survey.surveyStateDescription=i18n.t('Survey_Depth_Under');
        if(survey.surveyState==4) survey.surveyStateDescription=i18n.t('Survey_Loaded');
        if(survey.surveyState==5) survey.surveyStateDescription=i18n.t('Survey_Stemming_OK');
        if(survey.surveyState==6) survey.surveyStateDescription=i18n.t('Survey_Stemming_Over');
        if(survey.surveyState==7) survey.surveyStateDescription=i18n.t('Survey_Stemming_Under');

        survey.phase = '';
        if(survey.options==0) survey.phase=i18n.t('Survey_Loading');
        if(survey.options==1) survey.phase=i18n.t('Survey_PostDrill');
        if(survey.options==2) survey.phase=i18n.t('Survey_PreLoading');
        if(survey.options==3) survey.phase=i18n.t('Survey_PostLoading');
        if(survey.options==4) survey.phase=i18n.t('Survey_PreStemming');

        survey.holeStateDescription = '';
        if (survey.holeState == 0) survey.holeStateDescription='OK';
        else {
          if((survey.holeState & 0x01) > 0)  survey.holeStateDescription+=i18n.t('Wet')+' ';
          if((survey.holeState & 0x02) > 0)  survey.holeStateDescription+=i18n.t('Collapsed')+' ';
          if((survey.holeState & 0x04) > 0)  survey.holeStateDescription+=i18n.t('Decked')+' ';
          if((survey.holeState & 0x08) > 0)  survey.holeStateDescription+=i18n.t('CleanOut')+' ';
          if((survey.holeState & 0x10) > 0)  survey.holeStateDescription+=i18n.t('ReDrill')+' ';
          if((survey.holeState & 0x20) > 0)  survey.holeStateDescription+=i18n.t('OverBurden')+' ';
          if((survey.holeState & 0x40) > 0)  survey.holeStateDescription+=i18n.t('Cracked')+' ';
          if((survey.holeState & 0x100) > 0) survey.holeStateDescription+=i18n.t('Tacked')+' ';
          if((survey.holeState & 0x200) > 0) survey.holeStateDescription+=i18n.t('TopLoaded')+' ';
          if((survey.holeState & 0x400) > 0) survey.holeStateDescription+=i18n.t('OverLoaded');
          if((survey.holeState & 0x800) > 0) survey.holeStateDescription+=i18n.t('UnderLoaded');
          if((survey.holeState & 0x1000) > 0) survey.holeStateDescription+=i18n.t('Start');
          if((survey.holeState & 0x2000) > 0) survey.holeStateDescription+=i18n.t('Hot');
        }
      })

      this.setState({surveys:data});
    })
    .catch(error => this.setState({ surveys: [] }));
  }

  render() {
    const { t } = this.props;
    const holeInfo = this.props.holeInfo;

    const infoWindowStyle = {
      position: 'relative',
      bottom: 300+(this.state.surveys.length?40:0) + (this.state.loads.length?40:0) + (this.state.decks.length?40:0)+
              (this.state.surveys.length + this.state.loads.length + this.state.decks.length)*40 ,
      left: '-100px',
      width: ((this.state.loads.length===0) && (this.state.decks.length===0) && (this.state.surveys.length===0))? 250:1100,
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 5,
      fontSize: 14,
      zIndex: 100,
      opacity: 0.90
    };

     const textStyle = {
      color: this.props.holeNo ? 'white':'orange',
      fontSize: this.props.$hover ? 14:10,
      fontWeight: this.props.$hover ? 'bold':'normal',
    };

    var designWeight = holeInfo.idealWeight;
    if (holeInfo.designWeight !=0) designWeight=holeInfo.designWeight;
    if(designWeight==0) designWeight='-';

    var adjDesignWeight = holeInfo.adjDesignWeight;
    if(adjDesignWeight==0) adjDesignWeight='-';

    return (
      <div  onClick={this.handleClick} style={infoWindowStyle}>
        <div style={{ fontSize: 20,textAlign:'left', fontSize:20, fontWeight:'bold', border:'none' }}>
          <span> {this.props.holeInfo.patternNo?holeInfo.patternNo:'NA'} </span>
        </div>
        <hr style={{margin:2}}></hr>

        {((this.props.holeInfo.noLoads==1) && (this.props.holeInfo.noDecks==1))&& [
          <div>
            <span style={{textAlign:'left'}}>{t('DateTime')}</span><span style={{float:'right'}}>{holeInfo.eventStartDateTimeLocal}</span>
          </div>
        ]}

        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Weight')}({this.props.userDetails.massMUnit})</span>
          <span style={{float:'right'}}>{holeInfo.volume}/{designWeight}/{adjDesignWeight}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Depth')}({this.props.userDetails.lengthMUnit})</span><span style={{float:'right'}}>{holeInfo.designDepth}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Diameter')}({this.props.userDetails.lengthSUnit})</span><span style={{float:'right'}}>{holeInfo.diameter}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Duration')}</span><span style={{float:'right'}}>{holeInfo.duration}</span>
        </div>

        {((this.props.holeInfo.noLoads==1) && (this.props.holeInfo.noDecks==1))&& [
          <div>
            <div style={{ fontSize: 14, color: 'grey' }}>
              <span style={{textAlign:'left'}}>{t('Driver')}</span><span style={{float:'right'}}>{holeInfo.driverName}</span>
            </div>
            <div style={{ fontSize: 14, color: 'grey' }}>
              <span style={{textAlign:'left'}}>{t('Vehicle')}</span><span style={{float:'right'}}>{holeInfo.vehicleDescription}</span>
            </div>
          </div>
        ]}

        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('State')}</span><span style={{float:'right'}}>{holeInfo.counterStateDescription}</span>
        </div>

        {(this.state.surveys.length > 0) && [
          <div>
            <h6 key={9} style={{textAlign: 'center', color: 'black'}}>Surveys</h6>
            <SurveyTable
              key={898}
              userDetails = {this.props.userDetails}
              surveys     = {this.state.surveys}
            />
          </div>
        ]}
  
        {(this.state.loads.length > 0) && [
          <div>
            <h6 key={9} style={{textAlign: 'center', color: 'black'}}>Loads</h6>
            <LoadTable
              userDetails = {this.props.userDetails}
              updateLoad  = {this.handleUpdateLoad}
              loads       = {this.state.loads}
            />
          </div>
        ]}

        {(this.state.decks.length > 0) && [
          <div>
            <h6 key={9} style={{textAlign: 'center', color: 'black'}}>Decks</h6>
            <DeckTable
              userDetails = {this.props.userDetails}
              decks       = {this.state.decks}
            />
          </div>
        ]}

       {(this.props.markerClickId!=null && this.props.markerClickId!=0) &&
         <Button type="button" size="sm" onClick={this.handleCancel}>{t('Cancel')}</Button>}
      </div>
    );
  }
}

export default withTranslation()(MapInfoWindow);
