import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';

class AdminCompaniesTableSummary extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.editRow = null;

        this.state = {
        };
    }

    componentDidMount() {       
      console.log ("AdminCompaniesTableSummary:componentDidMount()");
    }


    updateCompany = (row) => {       
        fetch(process.env.REACT_APP_HOST_URL+'adminUpdateCompanyInfoDS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                    userId        : this.props.userDetails.userId,
                    companyId     : this.props.userDetails.companyId,
                    companyIdEdit : row.id,
                    description   : this.editRow.description,
                    logoId        : this.editRow.logoId
                })
            })
          .then(response => response.json())
          .then(data => this.props.getCompanyInfos())
          .catch(error => this.setState({ error }));   
    }

    afterSaveCell = (cellOld, cellNew, row, column) =>    
    {
        console.log('"AdminCompaniesTableSummary:Editing: ' + column.dataField + ":" + cellOld +":" + cellNew);
        console.log (row);

        this.editRow = {...row}; //force copy else we have pointer to row
       
        if (column.dataField==='description') {
          this.editRow.description=cellNew;
        } else if (column.dataField==='logoId') {
          this.editRow.logoId=cellNew;
        }

        this.updateCompany(row);
    }

    render() 
    { 
        const { t } = this.props;

        var editAuth = false;
        if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_ADMIN_COMPANIES) > 0) editAuth=true;
   
        const columns = [{
            dataField: 'id',
            text: 'Company Id',
            hidden: true
        }, {
            dataField: 'description',
            text: t('Description'),
            editable: editAuth,
            sort: true                
        }, {
            dataField: 'noUsers',
            text: t('no_users'),
            editable: false,
            sort: true                
        }, {
            dataField: 'logoId',
            text: t('Logo'),
            editable: editAuth,
            sort: true,
            formatter: (cell, row) => {
              return this.props.logos.find(x => x.value === cell).label;
            },
            editor: {
              type: Type.SELECT,
              options: this.props.logos
            },
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.id} ${row.description}`);
            }
          };

        return  (            
            <div>
               <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Admin_Companies')}</h3>
                 <BootstrapTable 
                     bootstrap4
                     classes="table-responsive-sm"
                     keyField='id'    
                     striped={true} 
                     hover={true} 
                     condensed={true} 
                     data={ this.props.companyInfos } 
                     columns={ columns } 
                     rowEvents={ rowEvents } 
                     headerClasses="thead-dark"
	                   pagination={ paginationFactory({sizePerPage:15})}
                     cellEdit={ cellEditFactory({mode: 'click', blurToSave:true, afterSaveCell: this.afterSaveCell, onStartEdit: (row) => { this.editCompanyId=row.id }})}
                 />
            </div>
        )
    }
}

export default withTranslation()(AdminCompaniesTableSummary);
