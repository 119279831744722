import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';

class SetupProductTable1 extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.editRow = null;

        this.state = {
          productStates : null,
        };
    }

    componentDidMount() {       
      console.log ("SetupProductTable1:componentDidMount()");
      this.getProductStates();
    }

    getProductStates()
    {
      console.log ("SetupProductTable1:getProductStates");
      fetch(process.env.REACT_APP_HOST_URL+'setupProductStates.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId
        })
      })
      .then(response => response.json())
      .then(data => this.setState({ productStates:data }))
      .catch(error => this.setState({ error }));
    };


    updateProduct = () => {       
        fetch(process.env.REACT_APP_HOST_URL+'setupProductUpdateDS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                    userId           : this.props.userDetails.userId,
                    companyId        : this.props.userDetails.companyId,
                    productId        : this.editRow.id,
                    description      : this.editRow.description,
                    stateDescription : this.editRow.stateDescription,
                    gassed           : this.editRow.gassed,
                    explosive        : this.editRow.explosive,
                    densityStart     : this.editRow.densityStart,
                    densityFinal     : this.editRow.densityFinal,
                    manual           : this.editRow.manual,
                    unitWeight       : this.editRow.unitWeight,
                    unitHeight       : this.editRow.unitHeight,
                    exportId         : this.editRow.exportId,
                })
            })
          .then(response => {
            console.log ("SetupProductsTable1:updateProduct",response.status);
            if(response.status===200) this.props.getProducts();
          })
          .catch(error => this.setState({ error }));   
    }

    afterSaveCell = (cellOld, cellNew, row, column) =>    
    {
        console.log('SetupProductsTable1:Editing: ' + column.dataField + ":" + cellOld +":" + cellNew);
        console.log (row);

        this.editRow = {...row}; //force copy else we have pointer to row
       
        if (column.dataField==='description') {
          this.editRow.description=cellNew;
        } else if (column.dataField==='stateDescription') {
          this.editRow.stateDescription=cellNew;
        } else if (column.dataField==='explosive') {
          this.editRow.explosive=cellNew;
        } else if (column.dataField==='gassed') {
          this.editRow.gassed=cellNew;
        } else if (column.dataField==='densityStart') {
          this.editRow.densityStart=cellNew;
        } else if (column.dataField==='densityFinal') {
          this.editRow.densityFinal=cellNew;
        } else if (column.dataField==='manual') {
          this.editRow.manual=cellNew;
        } else if (column.dataField==='unitWeight') {
          this.editRow.unitWeight=cellNew;
        } else if (column.dataField==='unitHeight') {
          this.editRow.unitHeight=cellNew;
        } else if (column.dataField==='exportId') {
          this.editRow.exportId=cellNew;
        }

        this.updateProduct();
    }

    render() 
    { 
        const { t } = this.props;

        var editAuth = false;
        if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_PRODUCT) > 0) editAuth=true;
   
        const columns = [{
            dataField: 'description',
            text: t('Description'),
            editable: editAuth,
            sort: true                
        }, {
            dataField: 'explosive',
            text: t('Explosive'),
            editable: editAuth,
            blurToSave: true,
            sort: true,
            editor: {
              type: Type.SELECT,
              options: [{
                value: t('Yes'),
                label: t('Yes')
              }, {
                value: t('No'),
                label: t('No')
              }]
            }
        }, {
            dataField: 'gassed',
            text: t('Gassed'),
            editable: editAuth,
            blurToSave: true,
            sort: true,
            editor: {
              type: Type.SELECT,
              options: [{
                value: t('Yes'),
                label: t('Yes')
              }, {
                value: t('No'),
                label: t('No')
              }]
            }
        }, {
           dataField: 'densityStart',
           text: t('DensityStart'),
           editable: editAuth,
           sort: true
        }, {
           dataField: 'densityFinal',
           text: t('DensityFinal'),
           editable: editAuth,
           hidden: false,
           sort: true
        }, {
            dataField: 'manual',
            text: t('Manual'),
            editable: editAuth,
            blurToSave: true,
            sort: true,
            editor: {
              type: Type.SELECT,
              options: [{
                value: t('Yes'),
                label: t('Yes')
              }, {
                value: t('No'),
                label: t('No')
              }]
            }
        }, {
           dataField: 'unitWeight',
           text: t('UnitWeight') + '('+this.props.userDetails.massMUnit+')',
           editable: editAuth,
           hidden: false,
           sort: true
        }, {
           dataField: 'unitHeight',
           text: t('UnitHeight') + '('+this.props.userDetails.lengthMUnit+')',
           editable: editAuth,
           hidden: false,
           sort: true
        }, {
           dataField: 'exportId',
           text: t('ExportId'),
           editable: editAuth,
           hidden: false,
           sort: true
        }, {
            dataField: 'stateDescription',
            text: t('State'),
            editable: editAuth,
            sort: true,
            editor: {
                type: Type.SELECT,
                options: this.state.productStates
              }
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.productId} ${row.description}`);
            },
              onMouseEnter: (e, row, rowIndex) => {
            }
          };

        return  (            
            <div>
               <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Setup_Products')}</h3>
                 <BootstrapTable 
                     bootstrap4
                     classes="table-responsive"
                     keyField='id'    
                     striped={true} 
                     hover={true} 
                     condensed={true} 
                     data={ this.props.products } 
                     columns={ columns } 
                     rowEvents={ rowEvents } 
                     headerClasses="thead-dark"
	                   pagination={ paginationFactory({sizePerPage:15})}
                     cellEdit={ cellEditFactory({mode: 'click', blurToSave:true, afterSaveCell: this.afterSaveCell, onStartEdit: (row) => { this.editProductId=row.id }})}
                 />
            </div>
        )
    }
}

export default withTranslation()(SetupProductTable1);
