import React, { Component } from "react";

import Chart0 from './DashBoardChart.0';
import Chart1 from './DashBoardChart.1';
import Chart2 from './DashBoardChart.2';
import Chart3 from './DashBoardChart.3';
import Chart4 from './DashBoardChart.4';
import Chart5 from './DashBoardChart.5';
import Chart6 from './DashBoardChart.6';
import Chart7 from './DashBoardChart.7';
import Chart8 from './DashBoardChart.8';
import Summary from './DashBoardSummary';

import { withTranslation } from 'react-i18next';
 
class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {  
    };
  }

  render() {
    const { t } = this.props;

    return (
      <div>         
        <Summary
          userDetails = {this.props.userDetails}
          patternSummary = {this.props.patternSummary}
        />
      <div>         
        <div key={'dashboardChart0'} style={{ float:'left', width: "300px", padding: '10px'}}>
          <Chart0
            userDetails = {this.props.userDetails}
            siteId      = {this.props.siteId} 
            patternId   = {this.props.patternId}
          />
        </div>

        <div key={'dashboardChart2'} style={{ float:'left', width: "300px", padding: '10px'}}>
          <Chart2
            userDetails = {this.props.userDetails}
            siteId      = {this.props.siteId}
            patternId   = {this.props.patternId}
          />
        </div>

         <div key={'dashboardChart5'} style={{ float:'left', width: "300px", padding: '10px'}}>
          <Chart5
            userDetails = {this.props.userDetails}
            siteId      = {this.props.siteId}
            patternId   = {this.props.patternId}
          />
        </div>

         <div key={'dashboardChart8'} style={{ float:'left', width: "500px", padding: '10px'}}>
          <Chart8
            userDetails = {this.props.userDetails}
            siteId      = {this.props.siteId}
            patternId   = {this.props.patternId}
          />
        </div>
        </div>

        <div style={{clear:'both'}}>
        <div key={'dashboardChart6'} style={{ float:'left', width: "300px", padding: '10px'}}>
          <Chart6
            userDetails = {this.props.userDetails}
            siteId      = {this.props.siteId}
            patternId   = {this.props.patternId}
          />
        </div>

        <div key={'dashboardChart3'} style={{ float:'left', width: "300px", padding: '10px'}}>
          <Chart3
            userDetails = {this.props.userDetails}
            siteId      = {this.props.siteId}
            patternId   = {this.props.patternId}
          />
        </div>

        <div key={'dashboardChart4'} style={{ float:'left', width: "300px", padding: '10px'}}>
          <Chart4
            userDetails = {this.props.userDetails}
            siteId      = {this.props.siteId}
            patternId   = {this.props.patternId}
          />
        </div>

         <div key={'dashboardChart7'} style={{ float:'left', width: "500px", padding: '10px'}}>
          <Chart7
            userDetails = {this.props.userDetails}
            siteId      = {this.props.siteId}
            patternId   = {this.props.patternId}
          />
        </div>

        </div>

        <div style={{clear:'both'}}/>
      </div>
    );
  }
}

export default withTranslation()(DashBoard);
