import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';

class SetupPeopleTable1 extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.editRow=null;

        this.state = {
          peopleTypes:  null,
          peopleStates: null,
          isLoading:    true          
        };
    }

    componentDidMount() {       
        this.updateTable1();
    }

    updateTable1()
    {
         fetch(process.env.REACT_APP_HOST_URL+'commonPeopleTypesDS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                    userId    : this.props.userDetails.userId,
                    companyId : this.props.userDetails.companyId
                })
            })
          .then(response => response.json())
          .then(data => this.setState({ peopleTypes:data }))
          .catch(error => this.setState({ error, isLoading: true })); 

          fetch(process.env.REACT_APP_HOST_URL+'commonPeopleStatesDS.php',
            {
                method: 'POST',
                body: JSON.stringify(
                {
                    userId    : this.props.userDetails.userId,
                    companyId : this.props.userDetails.companyId
                })
            })
          .then(response => response.json())
          .then(data => this.setState({ peopleStates:data }))
          .catch(error => this.setState({ error, isLoading: true }));
    };


    updatePerson = () => {       
      fetch(process.env.REACT_APP_HOST_URL+'setupPeopleUpdateDS.php',
      {
        method: 'POST',                
        body: JSON.stringify(
        {
          userId           : this.props.userDetails.userId,
          companyId        : this.props.userDetails.companyId,
          peopleId         : this.editRow.id,
          name             : this.editRow.name,
          exportId         : this.editRow.exportId,
          stateDescription : this.editRow.stateDescription,
          typeDescription  : this.editRow.typeDescription
        })
      })
      .then(response => {
        console.log ("SetupPeopleTable1:updatePeople",response.status);
        if(response.status===200) this.props.getPeople();
      })
      .catch(error => this.setState({ error }));   
    }

    afterSaveCell = (cellOld, cellNew, row, column) =>    
    {
        console.log('Editing: ' + column.dataField + ":" + cellOld +":" + cellNew);
        console.log (row);

        this.editRow = {...row}; //force copy else we have pointer to row
       
        if (column.dataField==='name') {
          this.editRow.name=cellNew;
        } else if (column.dataField==='stateDescription') {
          this.editRow.stateDescription=cellNew;
        } else if (column.dataField==='typeDescription') {
          this.editRow.typeDescription=cellNew;
        } else if (column.dataField==='exportId') {
          this.editRow.exportId=cellNew;
        }

        this.updatePerson();
    }

    render() 
    { 
        const { t } = this.props;

        if (this.props.people===null){
            return (<p>{t('Loading')}</p>);
        }

        var editAuth = false;
        if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_PEOPLE) > 0) editAuth=true;
   
        const columns = [{
            dataField: 'name',
            text: t('Name'),
            editable: editAuth,
            sort: true                
        }, {
            dataField: 'typeDescription',
            text: t('Type'),
            editable: editAuth,
            sort: true,
            editor: {
                type: Type.SELECT,
                options: this.state.peopleTypes
              }
        }, {
            dataField: 'exportId',
            text: t('ExportId'),
            editable: editAuth,
            hidden: false,
            sort: true
        }, {
            dataField: 'stateDescription',
            text: t('State'),
            editable: editAuth,
            sort: true,
            editor: {
                type: Type.SELECT,
                options: this.state.peopleStates
              }        
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.peopleId} ${row.name}`);
            },
              onMouseEnter: (e, row, rowIndex) => {
            }
          };

        return  (            
          <div>
             <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Setup_People')}</h3>

             <BootstrapTable 
                bootstrap4
                classes="table-responsive"
                keyField='peopleId'    
                striped={true} 
                hover={true} 
                condensed={true} 
                data={ this.props.people } 
                columns={ columns } 
                rowEvents={ rowEvents } 
                headerClasses="thead-dark"
	              pagination={ paginationFactory({sizePerPage:20})}
                cellEdit={ cellEditFactory({mode: 'click', blurToSave:true, afterSaveCell: this.afterSaveCell, })}
            />
         </div>
       )
    }
}

export default withTranslation()(SetupPeopleTable1);
