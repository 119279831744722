import React from "react";
import AuthoriseObj from './Authorise.js'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';

class HoleStateEdit extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.state = {
          checkedWet:false,
          checkedDecked:false,
          checkedReDrill:false,
          checkedCracked:false,
          checkedTopLoaded:false,
          checkedCollapsed:false,
          checkedCleanOut:false,
          checkedOverBurden:false,
          checkedTacked:false,
          checkedOverLoaded:false,
          checkedUnderLoaded:false,
        };

    }

    componentDidMount() {       
      console.log('HoleStateEdit:componentDidMount ' , this.props.editRow.id, this.props.editRow.holeState);
      if(this.props.editRow.holeState &0x01) this.setState({checkedWet:true});
      if(this.props.editRow.holeState &0x02) this.setState({checkedCollapsed:true});
      if(this.props.editRow.holeState &0x04) this.setState({checkedDecked:true});
      if(this.props.editRow.holeState &0x08) this.setState({checkedCleanOut:true});
      if(this.props.editRow.holeState &0x10) this.setState({checkedReDrill:true});
      if(this.props.editRow.holeState &0x20) this.setState({checkedOverBurden:true});
      if(this.props.editRow.holeState &0x40) this.setState({checkedCracked:true});
      if(this.props.editRow.holeState &0x100) this.setState({checkedTacked:true});
      if(this.props.editRow.holeState &0x200) this.setState({checkedTopLoaded:true});
      if(this.props.editRow.holeState &0x400) this.setState({checkedOverLoaded:true});
      if(this.props.editRow.holeState &0x800) this.setState({checkedUnderLoaded:true});
    }


    handleSave = (event) => {
      let holeState=0;

      if (this.state.checkedWet)        holeState |= 0x01;
      if (this.state.checkedCollapsed)  holeState |= 0x02;
      if (this.state.checkedDecked)     holeState |= 0x04;
      if (this.state.checkedCleanOut)   holeState |= 0x08;
      if (this.state.checkedReDrill)    holeState |= 0x10;
      if (this.state.checkedOverBurden) holeState |= 0x20;
      if (this.state.checkedCracked)    holeState |= 0x40;
      if (this.state.checkedTacked)     holeState |= 0x100;
      if (this.state.checkedTopLoaded)  holeState |= 0x200;

      if (this.props.editRow.holeState & 0x400) holeState |= 0x400; //Overload is not editable, just cary over
      if (this.props.editRow.holeState & 0x800) holeState |= 0x800; //underload is not editable, just cary over

      console.log("HoleStateEdit:handleSave:",this.props.editRow.id,holeState);
      this.props.handleSave(this.props.editRow.id, holeState);
    }

    handleCancel = (event) => {
      console.log("HoleStateEdit:handleCancel");
      this.props.handleCancel();
    }

    handleChange = (e) => {
      console.log("HoleStateEdit:handleChange:" , e.target);
      if(e.target.id=='Wet')        this.setState({checkedWet:e.target.checked});
      if(e.target.id=='Decked')     this.setState({checkedDecked:e.target.checked});
      if(e.target.id=='ReDrill')    this.setState({checkedReDrill:e.target.checked});
      if(e.target.id=='Cracked')    this.setState({checkedCracked:e.target.checked});
      if(e.target.id=='TopLoaded')  this.setState({checkedTopLoaded:e.target.checked});
      if(e.target.id=='Collapsed')  this.setState({checkedCollapsed:e.target.checked});
      if(e.target.id=='CleanOut')   this.setState({checkedCleanOut:e.target.checked});
      if(e.target.id=='OverBurden') this.setState({checkedOverBurden:e.target.checked});
      if(e.target.id=='Tacked')     this.setState({checkedTacked:e.target.checked});
    }


    render() 
    { 
        const { t } = this.props;

        return  (            
          <div>
            <div key={1} className="mb-3">
              <Form>
                <Form.Check
                  type='checkBox'
                  id='Wet'
                  label={t('Wet')}
                  checked={this.state.checkedWet}
                  onChange={this.handleChange}
                />
                <Form.Check
                  type='checkbox'
                  label={t('Decked')}
                  id='Decked'
                  checked={this.state.checkedDecked}
                  onChange={this.handleChange}
                />
                <Form.Check
                  type='checkbox'
                  label={t('ReDrill')}
                  id='ReDrill'
                  checked={this.state.checkedReDrill}
                  onChange={this.handleChange}
                />
                <Form.Check
                  type='checkbox'
                  label={t('Cracked')}
                  id='Cracked'
                  checked={this.state.checkedCracked}
                  onChange={this.handleChange}
                />
                <Form.Check
                  type='checkbox'
                  label={t('TopLoaded')}
                  id='TopLoaded'
                  checked={this.state.checkedTopLoaded}
                  onChange={this.handleChange}
                />
                <Form.Check
                  type='checkbox'
                  label={t('Collapsed')}
                  id='Collapsed'
                  checked={this.state.checkedCollapsed}
                  onChange={this.handleChange}
                />
                <Form.Check
                  type='checkbox'
                  label={t('CleanOut')}
                  id='CleanOut'
                  checked={this.state.checkedCleanOut}
                  onChange={this.handleChange}
                />
                <Form.Check
                  type='checkbox'
                  label={t('OverBurden')}
                  id='OverBurden'
                  checked={this.state.checkedOverBurden}
                  onChange={this.handleChange}
                />
                <Form.Check
                  type='checkbox'
                  label={t('Tacked')}
                  id='Tacked'
                  checked={this.state.checkedTacked}
                  onChange={this.handleChange}
                />
                <Form.Check
                  disabled
                  type='checkbox'
                  label={t('OverLoaded')}
                  id='OverLoaded'
                  checked={this.state.checkedOverLoaded}
                />
                <Form.Check
                  disabled
                  type='checkbox'
                  label={t('UnderLoaded')}
                  id='UnderLoaded'
                  checked={this.state.checkedUnderLoaded}
                />



                <Button key={4} size="sm" variant="primary" type="submit" onClick={this.handleSave}>{t('OK')} </Button>
                {' '}
                <Button key={5} size="sm" variant="primary" type="submit" onClick={this.handleCancel}>{t('Cancel')} </Button>

              </Form>
            </div>
          </div>)
    }
}

export default withTranslation()(HoleStateEdit);
