import Chart from "react-google-charts";
import React from "react";
import { withTranslation } from 'react-i18next';

class Chart1 extends React.Component 
{   
    constructor(props) 
    {
        super(props);
    
        this.state = {
        };
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps){        
    }

    render() 
    { 
        const { t } = this.props;

        return  (
            <Chart
                height={'400px'}
                chartType="PieChart"
                loader={<div>{t('Loading')}</div>}
                data={this.props.data}
                chartEvents={[
                {
                  eventName: "select",
                    callback: ({ chartWrapper, google }) => {
                      var selection = chartWrapper.getChart().getSelection();
                      var row = selection[0].row;
                      console.log("Report5Chart1:onClick row=", selection[0]);
                      //var driverId=this.state.data.rows[row].c[2];
                      //var driverName=this.state.data.rows[row].c[3];
                      //console.log("Report5Chart1:onClick driverId=", driverId, driverName);
                      //this.props.handleDriverSelect(driverId, driverName);
                    }
                }
                ]}
                options={{
	            	    //legend: { position: "top", maxLines: 3 },
                    chartArea: { width: '500'}
                }}
                rootProps={{ 'data-testid': '1' }}
          />
        )
    }
}

export default withTranslation()(Chart1);
