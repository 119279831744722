import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';

class SetupAccessoriesTable1 extends React.Component 
{   
  constructor(props) 
  {
    super(props);

    this.editRow = null;

    this.state = {
    };
  }

  componentDidMount() {
  }


  handleClickDelete = (boosterId) =>{
    console.log("SetupAccessoriesTable1:handleClickDelete", boosterId);
    this.props.handleDelete(boosterId);
  }


  updateRow() {       
    console.log("SetupAccessoriesTable1:updateRow",this.editRow);
    this.props.handleUpdate(this.editRow);
  }

  afterSaveCell = (cellOld, cellNew, row, column) =>    
  {
    console.log('SetupAccessoriesTable1:afterSaveCell: ' + column.dataField + ":" + cellOld + "->" + cellNew);

    if (cellOld!==cellNew){
      this.editRow = {...row};
       
      if (column.dataField==='description') {
        this.editRow.description=cellNew;
      } else if (column.dataField==='barcode') {
        this.editRow.barcode=cellNew;
      } else if (column.dataField==='exportId') {
        this.editRow.exportId=cellNew;
      } else if (column.dataField==='type') {
        this.editRow.type=cellNew;
      } else if (column.dataField==='state') {
        this.editRow.state=cellNew;
      }

      this.updateRow();
    }
  }


  render() 
  { 
    const { t } = this.props;

    var editAuth = false;
    if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_VEHICLES) > 0) editAuth=true;
 
    const columns = [{
        dataField: 'description',
        text: t('Description'),
        editable: editAuth,
        sort: true
     }, {
        dataField: 'type',
        text: t('Type'),
        editable: editAuth,
        sort: true,
        editor: {
          type: Type.SELECT,
          options: [{
            value: 'Booster',
            label: t('Booster')
         }, {
            value: 'Detonator',
            label: t('Detonator')
         }, {
            value: 'Other',
            label: t('Other')
          }]
        }
     }, {
        dataField: 'barcode',
        text: t('Barcode'),
        editable: editAuth,
        sort: true
     }, {
        dataField: 'exportId',
        text: t('ExportId'),
        editable: editAuth,
        sort: true
     }, {
        dataField: 'state',
        text: t('State'),
        editable: editAuth,
        sort: true,
        editor: {
          type: Type.SELECT,
          options: [{
            value: 'Active',
            label: t('Active')
          }, {
            value: 'Inactive',
            label: t('Inactive')
          }]
        }
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
          console.log(`clicked on row with index: ${row.description} ${row.accessoryId}`);
        },
        onMouseEnter: (e, row, rowIndex) => {
        }
      };

    return  (            
      <div>
        <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Setup_Accessories')}</h3>

        <BootstrapTable 
           bootstrap4
           classes="table-responsive"
           keyField='accessoryId'    
           striped={true} 
           hover={true} 
           condensed={true} 
           data={ this.props.accessories } 
           columns={ columns } 
           rowEvents={ rowEvents } 
           headerClasses="thead-dark"
           pagination={ paginationFactory({sizePerPage:15})}
           cellEdit={ cellEditFactory({mode: 'click', blurToSave:true, afterSaveCell: this.afterSaveCell })}
       />

     </div>
   )
 }

}
export default withTranslation()(SetupAccessoriesTable1);

