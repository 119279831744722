import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import InfoWindow from './MapInfoWindow';

class MapOverFillMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };

    this.handleClick = this.handleClick.bind(this);
  }


  handleClick(event) {
    console.log("MapOverFillMarker:handleClick:",this.props.holeInfo);
    //event.stopPropagation(); //Otherwise map rectangle select 
    //this.props.handleMarkerClick(this.props.holeInfo.holeId);
    console.log("MapOverFillMarker.Click ", this.props.holeInfo.holeId);//onCmdControlClick(e);
  }


  render() {
    let backgroundColor = 'lightgrey' ;
    let textColor = 'black' ;
    let border = '1px solid black' ;
    let radius=20;

    let overFillDiff = null;
    
    if(this.props.holeInfo.overFillDesignDiff!=null) overFillDiff = Math.round(parseFloat(this.props.holeInfo.overFillDesignDiff));
    if ((this.props.layerOptions === '2') && (this.props.holeInfo.overFillAdjDiff!=null)) overFillDiff=Math.round(parseFloat(this.props.holeInfo.overFillAdjDiff));
    if ((this.props.layerOptions === '3') && (this.props.holeInfo.overFillIdealDiff!=null)) overFillDiff=Math.round(parseFloat(this.props.holeInfo.overFillIdealDiff));

    if (overFillDiff!==null) {
      if(overFillDiff > 5.0 ){
        backgroundColor='red';
        radius = overFillDiff*2;
        textColor = 'white';
      } else if (overFillDiff < -5.0 ) {
        backgroundColor='blue';
        radius = overFillDiff * -2;
        textColor = 'white';
      } else {
        backgroundColor='green';
        textColor = 'white';
      }
    }

    //if (radius<15) textColor = 'black' ;

    if (radius > 100 ) radius=100;
   
    const markerStyle = {
      border: (this.props.$hover||this.props.selected)?'3px solid fuchsia':border,
      borderRadius: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: (this.props.$hover||this.props.selected)?radius+6:radius,
      width:  (this.props.$hover||this.props.selected)?radius+6:radius,
      backgroundColor: (this.props.$hover||this.props.selected)?'fuchsia':backgroundColor,
      cursor: 'pointer',
      zIndex: radius,
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const textStyle = {
      color: (this.props.$hover||this.props.selected)?'white':textColor,
      fontSize: (this.props.$hover||this.props.selected) ? 12:11,
      fontWeight:( this.props.$hover||this.props.selected) ? 'bold':'normal',
    };

    return (
      <div>
        <div 
          style={markerStyle}  
          onClick={this.handleClick}> 
          <span style={textStyle}>{this.props.holeInfo.holeNo ? this.props.holeInfo.holeNo:''}</span> 
        </div>
        {(this.props.$hover) && //hover but not clicked OR Clicked
          <InfoWindow
            holeInfo={this.props.holeInfo}
            userDetails={this.props.userDetails}
            handleUpdateHole={this.props.handleUpdateHole}
            handleMarkerClear={this.props.handleMarkerClear}
            handleClick={this.handleClick}
            markerClickId={this.props.markerClickId} />}
      </div>
    );
  }
}

export default MapOverFillMarker;

