import React, { useRef, useEffect } from 'react';
import DeckTable from './DeckTable';
 
function DeckCanvas(props) {
  const canvas = useRef();
  let ctx = null;
 
  // initialize the canvas context
  useEffect(() => {
    // dynamically assign the width and height to canvas
    const canvasEle = canvas.current;
    canvasEle.width = canvasEle.clientWidth;
    canvasEle.height = canvasEle.clientHeight;
 
    // get context of the canvas
    ctx = canvasEle.getContext("2d");
  }, []);

   useEffect(() => {

    let totalPx       = 400.0;
    let holeDepth     = 0;
    let maxStartDepth = 0;

    //Calc Y axis(Depth) max 
    for (let i=props.decks.length-1; i>=0; i--) {
      let startDepth = parseFloat(props.decks[i].designStartDepth);
      if (startDepth > maxStartDepth) maxStartDepth=startDepth;

      let deckingHeight=parseFloat(props.decks[i].surveyDeckingHeight);
      if (deckingHeight==0) deckingHeight=parseFloat(props.decks[i].designDeckingHeight);

      let columnHeight=parseFloat(props.decks[i].surveyDepth) - deckingHeight; 
      if (columnHeight<=0) columnHeight=parseFloat(props.decks[i].designColumnHeight);

      let columnWeight=parseFloat(props.decks[i].columnWeight);
      if (columnWeight==0) columnWeight=parseFloat(props.decks[i].designColumnWeight);

      holeDepth += deckingHeight + columnHeight;

      console.log ("DeckCanvas:holeDepth:",deckingHeight,columnHeight,maxStartDepth)
    }

    if (maxStartDepth > holeDepth ) holeDepth=maxStartDepth;

    console.log ("DeckCanvas:holeDepth:",holeDepth, props.decks.length);
    if (holeDepth==0) return; //Nothing to draw

    drawLine({ x:50, y:0, x1:350, y1:0}, {width:10} ); //Fat Ground line

    drawDepthLegend (100, totalPx, holeDepth);

    let errorDesign = drawDesignColumn(totalPx, holeDepth, 20);
    let errorSurvey = drawSurveyColumn(totalPx, holeDepth, 20);
    if (!errorDesign && errorSurvey) {
      let xOffset = 100 ;
      drawLoadedColumn(totalPx, holeDepth, xOffset, 20, 5);
    } else if (!errorSurvey) {
      let xOffset = 300 ;
      drawLoadedColumn(totalPx, holeDepth, xOffset, 20, 5);
    }
  }, []);

  const height2Px = (height, totalPx, holeDepth) => {
    let maxLegendDepth = getMaxLegendDepth(holeDepth);
    return ( height * (totalPx / maxLegendDepth));
  }

  const drawDesignColumn = (totalPx, holeDepth, widthPx) => {
    let xStartPx = 100;
    let x = xStartPx;
    let y = 0;
    let xTextOffset = xStartPx+widthPx/2-30;
    let error = false;
    let stackDepthPx = 0 ;

    for (let i=0; i<props.decks.length; i++) {
      let designStartDepth    = parseFloat(props.decks[i].designStartDepth);
      let designDeckingHeight = parseFloat(props.decks[i].designDeckingHeight);
      let designColumnHeight  = parseFloat(props.decks[i].designColumnHeight);

      let y        = height2Px(designStartDepth, totalPx, holeDepth );
      let heightPx = height2Px(designColumnHeight, totalPx, holeDepth );

      if (stackDepthPx==0) stackDepthPx=y; //First time should be total depth

      const r1Info = { x:x, y:y, w:widthPx, h:-heightPx };
      drawFillRect(r1Info, { backgroundColor: 'green' });
      y = y - heightPx;

      heightPx = height2Px(designDeckingHeight, totalPx, holeDepth );
      const r2Info = { x:x, y:y, w:widthPx, h:-heightPx };
      drawFillRect(r2Info, { backgroundColor: 'brown' });
    }

    if(!error) writeText({ text:'Design', x:xTextOffset, y:stackDepthPx+5 }, { fontSize:20, color:'LightGrey', textAlign:'left' });
  }

  const drawSurveyColumn = (totalPx, holeDepth, widthPx) => {
    let xStartPx = 300;
    let x = xStartPx;
    let y = 0;
    let xTextOffset = xStartPx+widthPx/2 - 30;
    let error = false;

    for (let i=props.decks.length-1; i>=0; i--) {
      let surveyDeckingHeight=parseFloat(props.decks[i].surveyDeckingHeight);
      if (surveyDeckingHeight==0) surveyDeckingHeight=parseFloat(props.decks[i].designDeckingHeight);

      let surveyColumnHeight=parseFloat(props.decks[i].surveyDepth) - surveyDeckingHeight; 
      //f (surveyColumnHeight<=0) surveyColumnHeight=parseFloat(props.decks[i].designColumnHeight);

      if ((surveyDeckingHeight > 0 ) && (surveyColumnHeight > 0)){
        //Design DeckingLayer
        let  heightPx = height2Px(surveyDeckingHeight, totalPx, holeDepth );
        const r1Info = { x:x, y:y, w:widthPx, h:heightPx };
        drawFillRect(r1Info, { backgroundColor: 'brown' });
        y=y+heightPx;
  
        //LoadLayer
        heightPx = height2Px(surveyColumnHeight, totalPx, holeDepth );
        const r2Info = { x:x, y:y, w:widthPx, h:heightPx };
        drawFillRect(r2Info, { backgroundColor: 'green' });
        y=y+heightPx;

      } else  {
        let  heightPx = height2Px(surveyDeckingHeight+surveyColumnHeight, totalPx, holeDepth );
        y=y+heightPx;
        error = true;
      }
    }

    if (!error) writeText({ text:'Loaded', x:xTextOffset, y:y+5 }, { fontSize:20, color:'LightGrey', textAlign:'left' });

    return (error);
  }

  const drawLoadedColumn = (totalPx, holeDepth, xOffset, columnPx, widthPx) => {
    let xStartPx = xOffset;
    let x = xStartPx + columnPx/2 - widthPx/2;
    let xTextOffset = xOffset+columnPx+10;
    let error = false;

    for (let i=props.decks.length-1; i>=0; i--) {
      let deckStartDepth = parseFloat(props.decks[i].surveyDepth); //Above me

      if (deckStartDepth==0) deckStartDepth = parseFloat(props.decks[i].designStartDepth);
      let y = height2Px(deckStartDepth, totalPx, holeDepth );

      let heightPx = height2Px(parseFloat(props.decks[i].loadedHeight), totalPx, holeDepth );
      const r3Info = { x:x, y:y, w:widthPx, h:-heightPx };
      drawFillRect(r3Info, { backgroundColor: 'yellow' });

      if(parseFloat(props.decks[i].designColumnWeight) > 0) {
        writeText({ text: props.decks[i].columnWeight+' / '+props.decks[i].designColumnWeight+' ' + props.userDetails.massMUnit+' ', //+ props.decks[i].designProductType,
                    x:xTextOffset, y:y-heightPx/2 }, { fontSize:12, color:'black', textAlign:'left' });
      } else {
        writeText({ text: props.decks[i].columnWeight+' ' + props.userDetails.massMUnit+' ', //+ props.decks[i].designProductType,
                    x:xTextOffset, y:y-heightPx/2 }, { fontSize:12, color:'black', textAlign:'left' });
      }
    }
  }

  const drawDepthLegend = (xT, totalPx, holeDepth) => {
    let length       = 10;
    let noIntervals  = getMaxLegendDepth(holeDepth) / 5;
    let intervalSize = totalPx/noIntervals;

    console.log ("DeckCanvas:drawDepthLegend", holeDepth, getMaxLegendDepth(holeDepth), noIntervals, intervalSize);

    writeText({ text: '0 ' + props.userDetails.lengthMUnit, x: xT-length, y: 10/2 }, { fontSize: 12, color: 'black', textAlign: 'right' });

    //Standard intervals
    for (var i=1;i<noIntervals+1;i++) {
      drawLine({ x: xT, y: i*intervalSize, x1: xT-length, y1: i*intervalSize });
      writeText({ text: i*5 +' ' + props.userDetails.lengthMUnit, x: xT-length, y: i*intervalSize-5 }, { fontSize: 12, color: 'black', textAlign: 'right' });
    }

    //Intervals based on hole Design
    for (let i=props.decks.length-1; i>=0; i--) {
      let depth = parseFloat(props.decks[i].designStartDepth);
      //if (depth==0) depth = parseFloat(props.decks[i].surveyDepth);

      if (depth > 0) {
        let y = height2Px(depth, totalPx, holeDepth );
        writeText({ text:depth+' '+props.userDetails.lengthMUnit, x:xT-50, y:y }, { fontSize:12, color:'black', textAlign:'right' });
        drawLine({ x:xT-50, y:y, x1:xT+200, y1:y}, { color:'LightGray'} );

        let designColumnHeight = parseFloat(props.decks[i].designColumnHeight);
        if (designColumnHeight>0) depth = depth - designColumnHeight ;
        if (depth<=0) depth=parseFloat(props.decks[i].surveyStemming);

        if (depth > 0) {
          let y = height2Px(depth, totalPx, holeDepth );
          writeText({ text:depth.toFixed(1)+' '+props.userDetails.lengthMUnit, x:xT-50, y:y }, { fontSize:12, color:'black', textAlign:'right' });
          drawLine({ x:xT-50, y:y, x1:xT+200, y1:y}, { color:'LightGray'} );
        }
      }
    }
  }

  const getMaxLegendDepth = (holeDepth) =>{
    return (parseInt(holeDepth/5)+1)*5; //max legend round up to nearest 10
  }

  const drawLine = (info, style = {}) => {
    const { x, y, x1, y1 } = info;
    const { color = 'black', width = 1 } = style;
 
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x1, y1);
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
    ctx.stroke();
  }

  // draw rectangle
  const drawRect = (info, style = {}) => {
    const { x, y, w, h } = info;
    const { borderColor = 'black', borderWidth = 1 } = style;

    ctx.beginPath();
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = borderWidth;
    ctx.rect(x, y, w, h);
    ctx.stroke();
  }

  // draw rectangle with background
const drawFillRect = (info, style = {}) => {
  const { x, y, w, h } = info;
  const { backgroundColor = 'black' } = style;

  ctx.beginPath();
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(x, y, w, h);
}

const writeText = (info, style = {}) => {
  const { text, x, y } = info;
  const { fontSize = 20, fontFamily = 'Arial', color = 'black', textAlign = 'left', textBaseline = 'top' } = style;

  ctx.beginPath();
  ctx.font = fontSize + 'px ' + fontFamily;
  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  ctx.fillStyle = color;
  ctx.fillText(text, x, y);
  ctx.stroke();
}
 
  return (
    <div className="DeckCanvas">
      <canvas ref={canvas} width="600" height="450"></canvas>
       <div>
            <h4 key={9} style={{textAlign: 'center', color: 'black'}}>Decks</h4>
            <DeckTable
              userDetails={props.userDetails}
              decks ={props.decks}
            />
          </div>
      

    </div>
  );
}
 
export default DeckCanvas;
