import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import InfoWindow from './MapSurveyInfoWindow';

class LoadAddMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  render() {
    let radius = 20;

    const markerStyle = {
      top: '50%',
      left: '50%',
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      border: '3px solid fuchsia',
      height: radius,
      width:  radius,
      fontWeight: 'normal',
      backgroundColor: 'lightgrey', 
      cursor: 'pointer',
      position: 'absolute',
      optimized: true,
      lineHeight: radius.toString() + 'px',
      textAlign: 'center',
      zIndex:5 
    };

    const textStyle = {
      color: 'black',
      fontSize: 11,
      fontWeight: 'normal',
    };

    return (
      <div>
        <div style={markerStyle} > <span style={textStyle}></span> </div>
      </div>
    );
  }
}

export default LoadAddMarker;

