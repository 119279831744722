import React, { Component } from 'react';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';

class DesignData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products:null,
      stemming:null,
    };

  }

  componentDidMount() {
  }

  render() {
    const { t } = this.props;

    const designData = this.props.designData;
     
    console.log ("DesignData:",this.props.designData);

    const style = {
      padding: 5,
      fontSize: 14,
    };

    return (
      <div style={style}>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Total_Weight')}({this.props.userDetails.massMUnit})</span><span style={{float:'right'}}>{designData.totalWeight}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Diameter')}({this.props.userDetails.lengthSUnit})</span><span style={{float:'right'}}>{designData.diameter}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Angle')}</span><span style={{float:'right'}}>{designData.angle}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Burden')}({this.props.userDetails.lengthMUnit})</span><span style={{float:'right'}}>{designData.burden}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Spacing')}({this.props.userDetails.lengthMUnit})</span><span style={{float:'right'}}>{designData.spacing}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Depth_Tol')}</span><span style={{float:'right'}}>{designData.depthTol}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Stemming_Tol')}</span><span style={{float:'right'}}>{designData.stemmingTol}</span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DesignData);
