import React, { Component } from "react";
import SetupNotificationsTable from './SetupNotificationsTable.1';
import AuthoriseObj from './Authorise.js'
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
 
class SetupNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {         
      newUser: null,
      users:null,
      notifications: null,
      selectedNotification: null
    };
  }


  componentDidMount() {
    console.log ("SetupNotifications:componentDidMount");
    this.getNotifications();
    this.getUsers();
  }


  getNotifications = () =>{
    fetch(process.env.REACT_APP_HOST_URL+'setupNotificationsGetDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId
      })
    })
    .then(response => response.json())
    .then(data => this.setState({ notifications:data, isLoadingPatterns: false }))
    .catch(error => this.setState({ error, isLoadingPatterns: true }));
  }


  getUsers = () =>{
    fetch(process.env.REACT_APP_HOST_URL+'registerUsersGetDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId
      })
    })
    .then(response => response.json())
    .then(data => this.setState({ users:data }))
    .catch(error => this.setState({ users:null }));
  }


  handleSelectNewUser = (selectedUser) => {
    console.log('Report6:handleSelectNewUser:', selectedUser);
    this.setState({newUser: selectedUser});
  }


  handleNotificationUpdate = () => {
    console.log('Report6:handleNotificationUpdate:');
    this.getNotifications();
  }

  handleNotificationSelect = (notification) => {
    console.log('Report6:handleNotificationSelect:' , notification);
    this.setState({selectedNotification: notification});
  }

  handleAdd = () => {
    console.log('Report6:handleAdd:' , this.state.newUser);

    fetch(process.env.REACT_APP_HOST_URL+'setupNotificationsAddDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId,
        newUserId : this.state.newUser.value
      })
    })
    .then(response => response.json())
    .then(data => {this.setState({ newUser:null }); this.getNotifications() })
    .catch(error => this.setState({ users:null }));
  }


  handleDelete = () => {
    console.log('Report6:handleDelete:' , this.state.selectedNotification);

    fetch(process.env.REACT_APP_HOST_URL+'setupNotificationsDeleteDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId,
        id        : this.state.selectedNotification.id
      })
    })
    .then(response => response.json())
    .then(data => {this.setState({ selectedNotification:null }); this.getNotifications() })
    .catch(error => this.setState({ users:null }));
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        {(this.state.notifications != null) && [
          <SetupNotificationsTable            
            userDetails              = {this.props.userDetails} 
            handleNotificationSelect = {this.handleNotificationSelect}
            handleNotificationUpdate = {this.handleNotificationUpdate}
            companyId                = {this.props.userDetails.companyId}
            notifications            = {this.state.notifications} />
        ]}

        {(((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_NOTIFICATIONS) > 0) && (this.state.selectedNotification!=null)) && [
          <div>
            <Button key={2} onClick={this.handleDelete} variant="primary">{t('Delete')}</Button>
          </div>
        ]}

        {(((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_NOTIFICATIONS) > 0) && (this.state.users!=null)) && [
          <div>
            <h4> {t('Add_Notification')} </h4>
            <div style={{ float:'left', width: '400px', margin: 'auto', paddingBottom: '10px'}}>
              <Select
                value={this.state.newUser}
                onChange={this.handleSelectNewUser}
                options={this.state.users}
                isMulti ={false}
                placeholder ={t('Select_User')}
              />
            </div>
          </div>
        ]}

        {(((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_NOTIFICATIONS) > 0) && (this.state.newUser!=null)) && [
          <div>
            <Button key={1} onClick={this.handleAdd} variant="primary">{t('Add')}</Button>
          </div>
        ]}
      </div>
    );
  }
}

export default withTranslation()(SetupNotifications);

