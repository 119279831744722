import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_AF } from "./af/translations";
import { TRANSLATIONS_FR } from "./fr/translations";
 
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
 debug: true,
 fallbackLng: 'en',
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     af: {
       translation: TRANSLATIONS_AF
     },
     fr: {
       translation: TRANSLATIONS_FR
     }
   },
  detection: { order: ["navigator"] },
  interpolation: {
      escapeValue: false // react already safes from xss
  }
 });
 
i18n.changeLanguage();
export default i18n;
