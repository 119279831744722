import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { withTranslation } from 'react-i18next';

class EventMapInfoWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const { t } = this.props;

    const eventInfo = this.props.eventInfo;

    const infoWindowStyle = {
      position: 'relative',
      bottom: 300,
      left: '-100px',
      width: 350,
      height: 220,
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 5,
      fontSize: 14,
      zIndex: 100,
      opacity: 0.90
    };

    return (

      <div style={infoWindowStyle}>
        <div style={{ fontSize: 20 }}>
          <span style={{textAlign:'left'}}>{t('Driver')}</span><span style={{float:'right'}}>{eventInfo.driverName}</span>
        </div>
        <hr style={{margin:2}}></hr>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Date')}</span><span style={{float:'right'}}>{eventInfo.eventDateTimeLocal}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Max_Speed')}({this.props.userDetails.speedUnit})</span><span style={{float:'right'}}>{eventInfo.maxSpeed}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Avg_Speed')}({this.props.userDetails.speedUnit})</span><span style={{float:'right'}}>{eventInfo.avgSpeed}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Speed_Limit')}({this.props.userDetails.speedUnit})</span><span style={{float:'right'}}>{eventInfo.speedLimit}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Duration')}</span><span style={{float:'right'}}>{eventInfo.duration}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Severity')}</span><span style={{float:'right'}}>{eventInfo.severity}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Vehicle')}</span><span style={{float:'right'}}>{eventInfo.vehicleDescription}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Zone')}</span><span style={{float:'right'}}>{eventInfo.zoneDescription}</span>
        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          <span style={{textAlign:'left'}}>{t('Address')}</span><span style={{float:'right'}}>{eventInfo.address}</span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EventMapInfoWindow);
