import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import InfoWindow from './MapSurveyInfoWindow';

class MapLoadProgressMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };

    this.handleClick = this.handleClick.bind(this);
  }


  handleClick(event) {
    console.log("MapOverFillMarker:handleClick:",this.props.holeInfo);
    //event.stopPropagation(); //Otherwise map rectangle select 
    //this.props.handleMarkerClick(this.props.holeInfo.holeId);
    console.log("MapOverFillMarker.Click ", this.props.holeInfo.holeId);//onCmdControlClick(e);
  }


  render() {
    let n         = -1;
    let rgb       = [211,211,211]; //lightgrey
    let textColor = 'black' ;
    let border    = '1px solid black' ;
    let radius    = 20;

    n=this.props.holeInfo.loadDate?this.props.patternDates.indexOf(this.props.holeInfo.loadDate) : -1;

    if (n===0) { rgb=[0x33, 0x66, 0xcc]; textColor = 'white'; }
    if (n===1) { rgb=[0xdc, 0x39, 0x12]; textColor = 'white'; }
    if (n===2) { rgb=[0xff, 0x99, 0x00]; textColor = 'white'; }
    if (n===3) { rgb=[0x10, 0x96, 0x18]; textColor = 'white'; }
    if (n===4) { rgb=[0x99, 0x00, 0x99]; textColor = 'white'; }
    if (n===5) { rgb=[0x00, 0x99, 0xc6]; textColor = 'white'; }
    if (n===6) { rgb=[0xdd, 0x44, 0x77]; textColor = 'white'; }
    if (n===7) { rgb=[0x66, 0xaa, 0x00]; textColor = 'white'; }
    if (n===8) { rgb=[0xb8, 0x2e, 0x2e]; textColor = 'white'; }
    if (n===9) { rgb=[0x31, 0x63, 0x95]; textColor = 'white'; }
    if (n===10){ rgb=[0x99, 0x44, 0x99]; textColor = 'white'; }
    if (n===11){ rgb=[0x22, 0xaa, 0x99]; textColor = 'white'; }
    if (n===12){ rgb=[0xaa, 0xaa, 0x11]; textColor = 'white'; }
    if (n===13){ rgb=[0x66, 0x33, 0xcc]; textColor = 'white'; }
    if (n===14){ rgb=[0xe6, 0x73, 0x00]; textColor = 'white'; }
    if (n>14)  { rgb=[0x8b, 0x07, 0x07]; textColor = 'white'; }
   
    const markerStyle = {
      border: (this.props.$hover||this.props.selected)?'3px solid fuchsia':border,
      borderRadius: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: (this.props.$hover||this.props.selected)?radius+6:radius,
      width:  (this.props.$hover||this.props.selected)?radius+6:radius,
      fontWeight: ((this.props.$hover&&this.props.markerClickId===null)||(this.props.showInfoWindow)||this.props.selected===true) ? 'bold':'normal',
      backgroundColor: (this.props.$hover||this.props.selected)?'fuchsia':`rgb(${rgb})`,
      cursor: 'pointer',
      zIndex: 3,
      textAlign: 'center',
      lineHeight: '20px',
    };

    const textStyle = {
      color: (this.props.$hover||this.props.selected)?'white':textColor,
      fontSize: (this.props.$hover||this.props.selected) ? 12:11,
      fontWeight:( this.props.$hover||this.props.selected) ? 'bold':'normal',
    };

    return (
      <div>
       <div style={markerStyle}  onClick={this.handleClick} > <span style={textStyle}>{this.props.holeInfo.holeNo}</span></div>
        {(this.props.$hover) && //hover but not clicked OR Clicked
         <InfoWindow
            hole              = {this.props.holeInfo}
            userDetails       = {this.props.userDetails}
            refreshData       = {this.props.refreshData}
            handleMarkerClear = {this.props.handleMarkerClear}
            handleClick       = {this.handleClick}
            loadAddEnabled    = {false}
            markerClickInfo   = {this.props.markerClickInfo} />}
      </div>
    );
  }
}

export default MapLoadProgressMarker;

