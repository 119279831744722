import Proj4 from 'proj4';

export function decodeType2(row, holeInfo) {
  let error = false;
  let offset = 0;

  if( (row[1]) && (row[2]) && (row[3]) && (row[4])) {
    holeInfo['units']     = row[1];
    holeInfo['patternNo'] = row[2];
    holeInfo['holeNo']    = row[3];
    let positionType      = row[4];

    console.log ('decodeType2');

    if (positionType === "UTM"){
    console.log ('decodeType2:UTM:',row[6], row[7]);
      if( (row[5]) && (!isNaN(row[6])) && (!isNaN(row[7])) ){
        let grid      = row[5];
        let eastings  = parseFloat(row[6]);
        let northings = parseFloat(row[7]);
        offset = 1;

        let gridNo     = parseInt (grid);
        let gridBand   = grid.replace(/[0-9]/g, '');;
        let hemisphere = "south";

        if (gridBand >= 'N') hemisphere = "north";

        console.log ("SetupImportDecodeType2:UTM:",grid, eastings, northings, gridNo, gridBand, hemisphere);

        let utm = "+proj=utm +zone=" + gridNo + " +" + hemisphere;
        console.log (utm);
        let wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";

        let point =[eastings, northings];
        let result = (Proj4(utm, wgs84, point));

        holeInfo['latitude']  = result[1]; //Strangely result=[longitude,latitude]
        holeInfo['longitude'] = result[0];
        console.log('decodeType2',holeInfo['latitude'],holeInfo['longitude']);
      } else error=true;
    } else if( positionType === "WGS84" ) {
      console.log ("SetupImportDecodeType2:WGS84:",row[3],row[4]);

      if( (!isNaN(row[5])) && (!isNaN(row[6])) ){
        offset = 0;
        holeInfo['latitude']  = parseFloat(row[5]);
        holeInfo['longitude'] = parseFloat(row[6]);

        let src      = new Proj4.Proj('WGS84');
        let destEpsg = proj4Get("EPSG:26791");
        let dest     = new Proj4.Proj(destEpsg);

        let point =[ holeInfo['longitude'], holeInfo['latitude']];
        let result = (Proj4(src, dest, point));

        console.log("XXXXX:",  result[0] ,  result[1]);
      } else error=true;
    } else {
    if( (!isNaN(row[5])) && (!isNaN(row[6])) ) {
        let x = parseFloat(row[5]);
        let y = parseFloat(row[6]);
        offset = 0;

        let srcEpsg = proj4Get(positionType);

        if (srcEpsg != null){
          //var dest   = new Proj4.Proj('WGS84');
          //var dest   = "+proj=longlat +datum=WGS84 +no_defs";
          //var dest   = "+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees";
          let dest   = "+proj=longlat +datum=WGS84 +no_defs";

          let point =[x, y];
          let result = (Proj4(srcEpsg, dest, point));

          holeInfo['latitude']  = result[1];
          holeInfo['longitude'] = result[0];

          console.log ("SetupImportDecodeType2:",  holeInfo['holeNo'], positionType, x, y, point,result[1], result[0]);
        } else error=true;
      } else error=true;
    }

    if (error == false){
      if ((row.length>=(7+offset))  && ( !isNaN(row[7+offset])))  holeInfo['altitude']    = row[7+offset];
      if ((row.length>=(8+offset))  && ( !isNaN(row[8+offset])))  holeInfo['depth']       = row[8+offset];
      if ((row.length>=(9+offset))  && ( !isNaN(row[9+offset])))  holeInfo['depthTol']    = row[9+offset];
      if ((row.length>=(10+offset)) && ( !isNaN(row[10+offset]))) holeInfo['stemming']    = row[10+offset];
      if ((row.length>=(11+offset)) && ( !isNaN(row[11+offset]))) holeInfo['stemmingTol'] = row[11+offset];
      if ((row.length>=(12+offset)) && ( !isNaN(row[12+offset]))) holeInfo['diameter']    = row[12+offset];
      if ((row.length>=(13+offset)) && ( !isNaN(row[13+offset]))) holeInfo['angle']       = row[13+offset];

      if ((row.length>=(14+offset)) && ( !isNaN(row[14+offset]))) holeInfo['burden']      = row[14+offset];
      if ((row.length>=(15+offset)) && ( !isNaN(row[15+offset]))) holeInfo['spacing']     = row[15+offset];

      //Load decks minimum one
      //deckNo,columnHeight,columnWeight,productType,density,deckingHeight,deckingType
      let deckNo = 0;
      holeInfo.decks =[];

      while (row.length>=(16+offset+7+deckNo*7)){
        console.log ("SetupImportDecodeType2: Deck", deckNo);
        holeInfo.decks[deckNo] ={};
        if (!isNaN(row[16+offset+deckNo*7])) holeInfo.decks[deckNo]['deckNo']        = row[16+offset+deckNo*7];
        if (!isNaN(row[17+offset+deckNo*7])) holeInfo.decks[deckNo]['columnHeight']  = row[17+offset+deckNo*7];
        if (!isNaN(row[18+offset+deckNo*7])) holeInfo.decks[deckNo]['columnWeight']  = row[18+offset+deckNo*7];
                                             holeInfo.decks[deckNo]['productType']   = row[19+offset+deckNo*7];
        if (!isNaN(row[20+offset+deckNo*7])) holeInfo.decks[deckNo]['density']       = row[20+offset+deckNo*7];
        if (!isNaN(row[21+offset+deckNo*7])) holeInfo.decks[deckNo]['deckingHeight'] = row[21+offset+deckNo*7];
                                             holeInfo.decks[deckNo]['deckingType']   = row[22+offset+deckNo*7];

        deckNo++;
      }
    }
  }

  return error;
}

function proj4Get(positionType) {
  var proj4 = null;

  if      (positionType=="EPSG:26791")  proj4='PROJCS["NAD27 / Minnesota North",GEOGCS["NAD27",DATUM["North_American_Datum_1927",SPHEROID["Clarke 1866",6378206.4,294.9786982138982,AUTHORITY["EPSG","7008"]],AUTHORITY["EPSG","6267"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.01745329251994328,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4267"]],UNIT["US survey foot",0.3048006096012192,AUTHORITY["EPSG","9003"]],PROJECTION["Lambert_Conformal_Conic_2SP"],PARAMETER["standard_parallel_1",47.03333333333333],PARAMETER["standard_parallel_2",48.63333333333333],PARAMETER["latitude_of_origin",46.5],PARAMETER["central_meridian",-93.1],PARAMETER["false_easting",2000000],PARAMETER["false_northing",0],AUTHORITY["EPSG","26791"],AXIS["X",EAST],AXIS["Y",NORTH]]';
  else if (positionType== "EPSG:26792") proj4="+proj=lcc +lat_1=45.61666666666667 +lat_2=47.05 +lat_0=45 +lon_0=-94.25 +x_0=609601.2192024384 +y_0=0 +ellps=clrk66 +datum=NAD27 +to_meter=0.3048006096012192 +no_defs";
  else if (positionType== "EPSG:26793") proj4="+proj=lcc +lat_1=43.78333333333333 +lat_2=45.21666666666667 +lat_0=43 +lon_0=-94 +x_0=609601.2192024384 +y_0=0 +ellps=clrk66 +datum=NAD27 +to_meter=0.3048006096012192 +no_defs";
  else if (positionType== "EPSG:32615") proj4="+proj=utm +zone=15 +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
  else if (positionType== "EPSG:3404")  proj4="+proj=lcc +lat_1=36.16666666666666 +lat_2=34.33333333333334 +lat_0=33.75 +lon_0=-79 +x_0=609601.2192024384 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs";

  return (proj4);
}

