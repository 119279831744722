import React from 'react';
import { withTranslation } from 'react-i18next';
import { DatePicker } from '@y0c/react-datepicker';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import dayjs from "dayjs"
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import FormControl from '@mui/material/FormControl/FormControl';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

class DesignAdd extends React.Component {
  constructor(props) {
    super(props);

    this.deckNos = [
      {label:'1', value:1},
//      {label:'2', value:2},
 //     {label:'3', value:3},
  //    {label:'4', value:4},
   //   {label:'5', value:5},
    ]

    this.state = {
      products             : [],
      stemming             : [],
      selectedProduct      : null,
      selectedStemming     : null,
      selectedDeckNo       : this.deckNos[0].value,
      holeNo               : '',
      startDepth           : '',
      columnHeight         : '',
      stemmingHeight       : '',
      errorSelectedDeckNo  : false,
      errorHoleNo          : false,
      errorStartDepth      : false,
      errorColumnHeight    : false,
      errorColumnWeight    : false,
      errorStemmingHeight  : false,
      errorSelectedProduct : false,
      errorSelectedStemming: false,
    };
  }

  componentDidMount() {
    console.log("DesignAdd:componentDidMount");
    this.getProducts ();
    this.getStemming ();
  }

  handleClick = (event) =>{
    event.stopPropagation();
  }

  getProducts = () =>{
    fetch(process.env.REACT_APP_HOST_URL+'commonProductTypesV2DS.php',
    {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            typeId    : 1,
        })
    })
    .then(response => response.json())
    .then(data => this.setState({ products:data }))
    .catch(error => this.setState({ products:[] }));
  }

  getStemming = () =>{
    fetch(process.env.REACT_APP_HOST_URL+'commonProductTypesV2DS.php',
    {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            typeId    : 0,
        })
    })
    .then(response => response.json())
    .then(data => this.setState({ stemming:data }))
    .catch(error => this.setState({ stemming:[] }));
  }

  handleSelectProduct = (e) => {
    console.log("DesignAdd:handleSelectProduct:", e.target.value);
    this.setState({ selectedProduct:e.target, errorSelectedProduct: false });
  }

  handleSelectStemming = (e) => {
    console.log("DesignAdd:handleSelectStemming:", e.target.value);
    this.setState({ selectedStemming:e.target, errorSelectedStemming: false });
  }

  handleSelectDeckNo = (e) => {
    console.log("DesignAdd:handleSelectDeckNo:", e.target.value);
    this.setState({ selectedDeckNo:e.target.value,errorSelectedDeckNo: false });
  }

  handleChangeColumnWeight = (event) => {
    var columnWeight = event.target.value;

    console.log("DesignAdd:handleChangeColumnWeight:", columnWeight);

    //if (event.target.validity.valid) {
    if (columnWeight.length<5) {
      this.setState({columnWeight:columnWeight, errorColumnWeight: false});
    }
  }

  handleChangeColumnHeight = (event) => {
    var columnHeight = event.target.value;

    console.log("DesignAdd:handleChangeColumnHeight:", columnHeight);

    if (columnHeight.length<5) {
      this.setState({columnHeight:columnHeight, errorColumnHeight: false});
    }
  }

  handleChangeStartDepth = (event) => {
    var startDepth = event.target.value;

    console.log("DesignAdd:handleChangeStartDepth:", startDepth);

    if (startDepth.length<5) {
      this.setState({startDepth:startDepth, errorStartDepth: false});
    }
  }

  handleChangeStemmingHeight = (event) => {
    var stemmingHeight = event.target.value;

    console.log("DesignAdd:handleChangeStemmingHeight:", stemmingHeight);

    if (stemmingHeight.length<5) {
      this.setState({stemmingHeight:stemmingHeight, errorStemmingHeight: false});
    }
  }


  handleChangeHoleNo = (event) => {
    var holeNo = event.target.value;
    console.log("DesignAdd:handleChangeHoleNo:", holeNo);
    //if (event.target.validity.valid) {
    this.setState({holeNo:holeNo, errorholeNo: false});
  }

  addDesign = () => {
    console.log("DesignAdd:addDesign:",this.props.siteId, this.props.patternNo, this.state.holeNo, this.state.selectedDeckNo, 
                                       this.state.selectedProduct, this.state.startDepth, this.state.columnWeight, this.state.columnHeight,
                                       this.state.selectedStemming, this.state.stemmingHeight);

    fetch(process.env.REACT_APP_HOST_URL+'commonAddDesignDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
          userId         : this.props.userDetails.userId,
          companyId      : this.props.userDetails.companyId,
          siteId         : this.props.siteId,
          patternNo      : this.props.patternNo,
          holeNo         : this.state.holeNo,
          deckNo         : this.state.selectedDeckNo,
          latitude       : this.props.latitude,
          longitude      : this.props.longitude,
          productTypeId  : this.state.selectedProduct?this.state.selectedProduct.value:null,
          productType    : this.state.selectedProduct?this.state.selectedProduct.label:null,
          startDepth     : this.state.startDepth,
          columnWeight   : this.state.columnWeight,
          columnHeight   : this.state.columnHeight,
          stemmingTypeId : this.state.selectedStemming?this.state.selectedStemming.value:null,
          stemmingType   : this.state.selectedStemming?this.state.selectedStemming.label:null,
          stemmingHeight : this.state.stemmingHeight,
      })
    })
    .then(response => response.json())
    .then(data=>{
      this.clearForm();
      this.props.handleAdd();
    })
    .catch(error => this.setState({ error })); 
  }


  handleAdd = (event) => {
    event.stopPropagation();
    console.log("DesignAdd:handleAdd:",this.props.siteId, this.props.patternNo, this.state.holeNo, this.state.selectedDeckNo, 
                                       this.state.selectedProduct, this.state.columnWeight, this.state.startDepth, this.state.columnHeight,
                                       this.state.selectedStemming, this.state.stemmingHeight);

    if((this.state.holeNo) && (this.state.selectedProduct) && (this.state.selectedDeckNo) && 
       (this.state.columnWeight) && (this.state.startDepth)){ //We have some edits

      this.addDesign();

      this.props.handleAdd();
    }
    else {
      if (!this.state.holeNo)           this.setState({errorHoleNo: true}); else this.setState({errorHoleNo: false});
      if (!this.state.selectedDeckNo)   this.setState({errorSelectedDeckNo: true}); else this.setState({errorSelectedDeckNo: false});
      if (!this.state.selectedProduct)  this.setState({errorSelectedProduct: true}); else this.setState({errorSelectedProduct: false});
      if (!this.state.columnWeight)     this.setState({errorColumnWeight: true}); else this.setState({errorColumnWeight: false});
      if (!this.state.startDepth)       this.setState({errorStartDepth: true}); else this.setState({errorStartDepth: false});
      //if (!this.state.columnHeight)     this.setState({errorColumnHeight: true}); else this.setState({errorColumnHeight: false});
      //if (!this.state.selectedStemming) this.setState({errorSelectedStemming: true}); else this.setState({errorSelectedStemming: false});
    }
   // event.stopPropagation(); //Otherwise HeatMap toggle
  }


  handleCancel = (event) => {
    event.stopPropagation();
    console.log("DesignAdd:handleCancel");
    this.clearForm();
    this.props.handleCancel();
  }

  clearForm= () => {
    this.setState({holeNo                : '', 
                   selectedDeckNo        : this.deckNos[0].value,
                   selectedProduct       : null,
                   columnWeight          : '',
                   columnHeight          : '',
                   startDepth            : '',
                   selectedStemming      : null,
                   stemmingHeight        : '',
                   errorHoleNo           : false,
                   errorStartDepth       : false,
                   errorSelectedProduct  : false, 
                   errorColumnWeight     : false,
                   errorColumnHeight     : false,
                   errorSelectedStemming : false, 
                   errorStemmingHeight   : false});
  }

  render() {
    const { t } = this.props;

    let initialDate = dayjs();

    var mapAddStyle={};
    if (this.props.holeNo===null) {
      mapAddStyle = {
        position: 'absolute',
        left: '-50%',
        top: '-10%',
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 5,
        fontSize: 14,
        zIndex: 100,
        opacity: 0.90
      };
    }

    return (
      <div >
        <div style={mapAddStyle}>
         <h6 key={11} style={{ captionSide: 'top', textAlign: 'left', color: 'black' }}>{t('Add_Design')}</h6>
          <form>
             <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
             >

               <FormControl variant="outlined" sx={{ m: 0.5, minWidth: 120, maxWidth:120 }}>
                 <TextField
                   ref={(input) => {this.holeNo = input; }}
                   required
                   size          = "small"
                   type          = "text"
                   id            = "HoleNo"
                   label         ={t('Hole_No')}
                   error         = {this.state.errorHoleNo}
                   onChange      = {this.handleChangeHoleNo}
                   onClick       = {this.handleClick}
                   onMouseDown   = {this.handleClick}
                   onMouseUp     = {this.handleClick}
                   value         = {this.state.holeNo}
                   maxLength     = {8}
                   inputProps    ={{ readOnly: this.props.holeNo?true:false }}
                 />
               </FormControl>

               <FormControl variant="outlined" sx={{ m: 0.5, minWidth: 80, maxWidth:80 }}>
                 <InputLabel id="deckNoSelectLabel">{t('DeckNo')}</InputLabel>
                 <Select
                   labelId           = "deckNoSelectLabel"
                   label             = {t('DeckNo')}
                   value             = {this.state.selectedDeckNo}
                   size              = "small"
                   required
                   onChange          = {this.handleSelectDeckNo}
                   error             = {this.state.errorSelectedDeckNo}
                 >
                   {this.deckNos?.map(option => {
                     return (
                       <MenuItem dense={true} key={option.value} value={option.value}>
                         {option.label ?? option.value}
                       </MenuItem>
                     );
                   })}
                 </Select>
               </FormControl>

               <FormControl variant="outlined" sx={{ m: 0.5, minWidth: 150 }}>
                 <InputLabel id="designSelectProduct">{t('Product' +' *')}</InputLabel>
                 <Select
                   labelId           = "designSelectProduct"
                   label             = {t('Product')+'*'}
                   value             = {this.state.selectedProduct?this.state.selectedProduct.value:null}
                   size              = "small"
                   required
                   onChange          = {this.handleSelectProduct}
                   error             = {this.state.errorSelectedProduct}
                   autoWidth
                 >
                   {this.state.products?.map(option => {
                     return (
                       <MenuItem dense={true} key={option.value} value={option.value}>
                         {option.label ?? option.value}
                       </MenuItem>
                     );
                   })}
                 </Select>
               </FormControl>

               <FormControl variant="outlined" sx={{ m: 0.5, minWidth: 120, maxWidth:150 }}>
                 <TextField
                   ref={(input) => {this.startDepth = input; }}
                   type          = "number"
                   id            = "StartDepth"
                   label         ={t('Start_Depth') + '('+this.props.userDetails.lengthMUnit+')'}
                   error         = {this.state.errorStartDepth}
                   onChange      = {this.handleChangeStartDepth}
                   onClick       = {this.handleClick}
                   onMouseDown   = {this.handleClick}
                   onMouseUp     = {this.handleClick}
                   value         = {this.state.startDepth}
                   size          = "small"
                   autoWidth
                 />
               </FormControl>

               <FormControl variant="outlined" sx={{ m: 0.5, minWidth: 120, maxWidth:150 }}>
                 <TextField
                   ref={(input) => {this.columnWeight = input; }}
                   required
                   type          = "number"
                   id            = "ColumnWeight"
                   label         ={t('Column_Weight') + '('+this.props.userDetails.massMUnit+')'}
                   error         =  {this.state.errorColumnWeight}
                   onChange      = {this.handleChangeColumnWeight}
                   onClick       = {this.handleClick}
                   onMouseDown   = {this.handleClick}
                   onMouseUp     = {this.handleClick}
                   value         = {this.state.columnWeight}
                   size          = "small"
                 />
               </FormControl>

               <FormControl variant="outlined" sx={{ m: 0.5, minWidth: 120, maxWidth:150 }}>
                 <TextField
                   ref={(input) => {this.columnHeight = input; }}
                   type          = "number"
                   id            = "ColumnHeight"
                   label         ={t('Column_Height') + '('+this.props.userDetails.lengthMUnit+')'}
                   error         =  {this.state.errorColumnHeight}
                   onChange      = {this.handleChangeColumnHeight}
                   onClick       = {this.handleClick}
                   onMouseDown   = {this.handleClick}
                   onMouseUp     = {this.handleClick}
                   value         = {this.state.columnHeight}
                   size          = "small"
                   autoWidth
                 />
               </FormControl>

               <FormControl variant="outlined" sx={{ m: 0.5, minWidth: 150 }}>
                 <InputLabel id="designSelectStemming">{t('Stemming')}</InputLabel>
                 <Select
                   labelId           = "designSelectStemming"
                   label             = {t('Stemming')}
                   value             = {this.state.selectedStemming?this.state.selectedStemming.value:null}
                   onChange          = {this.handleSelectStemming}
                   error             = {this.state.errorSelectedStemming}
                   size              = "small"
                   autoWidth
                 >
                   {this.state.stemming?.map(option => {
                     return (
                       <MenuItem dense={true} key={option.value} value={option.value}>
                         {option.label ?? option.value}
                       </MenuItem>
                     );
                   })}
                 </Select>
               </FormControl>

               <FormControl variant="outlined" sx={{ m: 0.5, minWidth: 120, maxWidth: 150 }}>
                 <TextField
                   ref={(input) => {this.stemmingHeight = input; }}
                   type          = "number"
                   id            = "StemmingHeight"
                   label         ={t('StemmingHeight') + '('+this.props.userDetails.lengthMUnit+')'}
                   error         =  {this.state.errorStemmingHeight}
                   onChange      = {this.handleChangeStemmingHeight}
                   onClick       = {this.handleClick}
                   onMouseDown   = {this.handleClick}
                   onMouseUp     = {this.handleClick}
                   value         = {this.state.stemmingHeight}
                   size          = "small"
                   autoWidth
                 />
               </FormControl>

               <Button sx={{ m: 1 }}variant="contained" size="small" onClick={this.handleAdd}>{t('Add')}</Button>
               <Button variant="contained" size="small" onClick={this.handleCancel}>{t('Cancel')}</Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withTranslation()(DesignAdd);
