/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3f862eb5-7606-45dc-af86-fcc849557be5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ldjQ295Td",
    "aws_user_pools_web_client_id": "1016lbn3dno5j8eik0c2ok4kmr",
    "oauth": {}
};


export default awsmobile;
