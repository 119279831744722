import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';

class TableDailySummary extends React.Component 
{   
    constructor(props) 
    {
        super(props);
       
        this.state = {
          error: null,
        };
    }

    componentDidMount() {
     //   this.getData(this.props);
    }


    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'date',
            text: t('Date'),
            sort: true             
        }, {
            dataField: 'vehicleDescription',
            text: t('Vehicle') ,
            sort: true
        }, {
            dataField: 'noHoles',
            text: t('No_Holes') ,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
        }, {
            dataField: 'noLoads',
            text: t('No_Loads') ,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
        }, {
            dataField: 'duration',
            text: t('Duration'),
            sort: true
        }, {
            dataField: 'volume',
            text: t('Weight')+'('+this.props.userDetails.massMUnit+')',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.date}`);
            },
            onMouseEnter: (e, row, rowIndex) => {
              //console.log(`enter on row with index: ${row.year} ${row.month}`);
            }
          };
         
        return  (
         <div>
            <BootstrapTable
                bootstrap4
                classes="table-responsive"
                keyField='uid'
                striped={true}
                condensed={true}
                data={ this.props.patternDaySummary }
                columns={ columns }
                rowEvents={ rowEvents }
		            pagination={ paginationFactory()}
                headerClasses="thead-dark"
            />
        </div>)
    }
}

export default withTranslation()(TableDailySummary);
