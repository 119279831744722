import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';

class Report6TableEventSummaryMonth extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.state = {
          error: null,
        };
    }

    componentDidMount() {       
      //console.log('Report6TableSpeedingEvents : componentDidMount');
    }
    
    componentWillReceiveProps(nextProps){
      //console.log('Report6TableSpeedingEvents : componentWillReceiveProps');
    }

    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'driverId',
            text: 'Driver Id',
            hidden:true
          }, {     
            dataField: 'monthName',
            text: t('Month'),
            editable: false,
            hidden:true,
            sort: true                
          }, {     
            dataField: 'driverName',
            text: t('Driver'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'lastDate',
            text: t('Date'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'count',
            text: t('No_Events'),
            editable: false,
            hidden:false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
          }, {     
            dataField: 'maxSpeed',
            text: t('Max_Speed')+'('+this.props.userDetails.speedUnit+')',
            editable: false,
            hidden:false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
          }, {     
            dataField: 'duration',
            text: t('Duration'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'severityTotal',
            text: t('Severity_Total'),
            editable: false,
            hidden:false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
          }, {
            dataField: 'activeDays',
            text: t('No_Days'),
            editable: false,
            hidden:false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
          }, {
            dataField: 'severityDay',
            text: t('Severity_Day'),
            editable: false,
            hidden:false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${this.props.userDetails.userId} ${this.props.userDetails.companyId} ${row.driverId} ${row.driverName} ${row.year} ${row.month}`);
              this.props.onDriverSelect(row.year, row.month, row.driverId, row.driverName);  
            },
        };
                      
        const selectRow = {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn:true,
          style: { backgroundColor: '#c8e6c9'}
        };


        var month = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";
        var monthName = month[this.props.month-1];

        return  (            
            <div>
                {(this.props.monthSummaryData!=null) && [
                <h3 key={1} style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{monthName} {this.props.year} {t('Summary')}</h3>,
                <BootstrapTable 
                    bootstrap4
                    classes="table-responsive-sm"
                    keyField='driverId'    
		                selectRow={ selectRow }
                    striped={true} 
                    hover={true} 
                    condensed={true} 
                    data={ this.props.monthSummaryData } 
                    columns={ columns } 
                    rowEvents={ rowEvents } 
   		              pagination={ paginationFactory()}
                    headerClasses="thead-dark"            
                />]}
             </div>
        )
    }
}

export default withTranslation()(Report6TableEventSummaryMonth);
