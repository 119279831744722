import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react';
import Marker from './EventMapMarker';

/* global google */

function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    },
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.ROAD 
  };
}


class EventMap extends Component {
  static defaultProps = {
    center: {
      lat: 0,
      lng: 0 
    },
    zoom: 11,
    userId    : 1,
    companyId : 2,
    siteId    : 1,
    date      : '2019-05-14',
    vehicleId : '12'
  };
  

  constructor(props)
  {
    super(props);

    this.state = {
       error: null,
       zoom : 11,
       center: {
         lat: 0,
         lng: 0 
       },
       markerClickId:null,
       zoneInfos:null,
       selectedPolygon:null,
       heatMapVisible: true,
       isLoading: true
    };

    this.map=null;
    this.maps=null;
    this.heatMap=null;
    this.polygons= [];
  }


  centerMap = (markers) => {
    if (markers.length === 0 ) return;
    if (this.map == null) return;
    if (this.maps == null) return;

    var bounds = new google.maps.LatLngBounds();

    for (var i=0;i<markers.length; i++){
      var myLatLng = new google.maps.LatLng(markers[i].latitude, markers[i].longitude);
      bounds.extend(myLatLng);
    }

    this.map.fitBounds(bounds)
  };


  getZoneInfos = (reCenter) =>{
      console.log("EventMap:getZoneInfos", this.props.userDetails.userId, " ", this.props.userDetails.companyId);
      this.setState({isLoading: true });
      fetch(process.env.REACT_APP_HOST_URL+'zoneMapZoneInfoDS.php',
      {
          method: 'POST',
          body: JSON.stringify(
          {
             userId    : this.props.userDetails.userId,
             companyId : this.props.userDetails.companyId
          })
      })
      .then(response => response.json())
      .then(data => {this.setState({ zoneInfos:data });
                     this.zonesDraw(data, reCenter);})
      .catch(error => this.setState({ error, isLoading: true }));
  }


  zonesDraw =(zoneInfos, reCenter) => {
    console.log("EventMap:zonesDraw", zoneInfos, reCenter);

    var bounds = new google.maps.LatLngBounds();

    //Remove all current Polygons on map
    for (var n=0; n<this.polygons.length; n++)
    {
      this.polygons[n].setMap(null);
    }
    this.polygons=[];

    var i = 0;
    while (i < zoneInfos.length) {
      var polygon = new google.maps.Polygon(
        { strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          userDetails:this.props.userDetails, zoneInfo:zoneInfos[i], handleZoneSelect:this.handleZoneSelect});

      for (var p=0;p<zoneInfos[i].points.length; p++){
        bounds.extend(zoneInfos[i].points[p]);
      }

      polygon.setPath(zoneInfos[i].points);

      var infoWindow = new google.maps.InfoWindow();
      google.maps.event.addListener(polygon, 'mouseover', function (e) {

        if (this.zoneInfo.speedLimit)
          infoWindow.setContent(this.zoneInfo.description + ":" + this.zoneInfo.speedLimit+this.userDetails.speedUnit);
        else 
          infoWindow.setContent(this.zoneInfo.description);

        var latLng = e.latLng;
        infoWindow.setPosition(latLng);
        infoWindow.open(this.map);
    });

      // and display it on the map
      polygon.setMap(this.map);

      this.polygons.push(polygon);

      i++;
    }

    if(reCenter==true) this.map.fitBounds(bounds);
  };


  updateHeatMapData = (eventInfos) => {
    if ((this.map) && (this.maps) && (this.heatMap))
    {
      var radius = 60;

      var i=0;
      var positions = [];
      var options = {
        radius: radius,
        opacity: 0.6
      }

      for (i=0; i< eventInfos.length; i++ )
      {
        var point = {location: new this.maps.LatLng(eventInfos[i].latitude, eventInfos[i].longitude), weight:parseInt(eventInfos[i].severity)};
        positions.push(point);
      }
      this.heatMap.setData(positions);
      this.heatMap.setOptions(options);
    }
  };


  componentWillReceiveProps(nextProps){
    console.log("EventMap:componentWillReceiveProps");
    if (nextProps.eventInfos.length!=this.props.eventInfos.length) this.centerMap(nextProps.eventInfos);

    this.updateHeatMapData(nextProps.eventInfos);
  }
  
  componentDidMount() {
    console.log("EventMap:componentDidMount");
    this.centerMap(this.props.eventInfos);

    //this.updateHeatMapData(this.props.eventInfos);
  }


  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = (key,holeInfo) => {
    //console.log("CommonMap:onChildClickCallback",key,":",holeInfo);
    //if (this.state.markerClickId===null) this.setState({markerClickId : key});
  };


  onClickCallback = (key) => {
    console.log("EventMap:onClickCallback",key);
    this.toggleHeatMap(); //Stop propagation on all children(InfoWindow and InfoWindowMulti)
  };


  handleMarkerClear = (eventId) => {
    console.log('EventMap:handleMarkerClear', eventId);
    this.setState({markerClickId : null});
  }


  handleMarkerClick = (eventId) => {
    console.log("EventMap:handleMarkerClick",eventId);
    if (this.state.markerClickId===null) this.setState({markerClickId : eventId});
  };


  toggleHeatMap() {
    console.log ("EventMap:toggleHeatMap");
    this.setState({
      heatMapVisible: !this.state.heatMapVisible
    }, () => {
      if (this.map !== undefined) {
      console.log ("EventMap:toggleHeatMap",this.state.heatMapVisible);
        this.heatMap.setMap(this.state.heatMapVisible ? this.map : null)
      }
    })
  }

  handleApiLoaded = (map, maps) => {
    console.log ("EventMap:handleApiLoaded");

    this.maps = maps;
    this.map  = map;

    this.heatMap = new google.maps.visualization.HeatmapLayer();
    this.heatMap.setMap(map);

    this.updateHeatMapData(this.props.eventInfos);
    this.getZoneInfos(false);
    this.centerMap(this.props.eventInfos);
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
           ref={(el) => this._googleMap = el}
           yesIWantToUseGoogleMapApiInternals
           onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
           bootstrapURLKeys={{ key: 'AIzaSyDlrKQh55n9-jY2KHHrUIx2t3dQOG30P98',  libraries: 'drawing,visualization' }}
           defaultCenter={this.state.center}
           center={this.state.center}
           zoom={this.state.zoom}
           defaultZoom={this.state.zoom}
           options={createMapOptions} 
	         onClick={this.onClickCallback}
           onChildClick={this.onChildClickCallback} >
           {this.props.eventInfos.map(data =>
              (<Marker
                userDetails={this.props.userDetails}
                key={data.id}
                lat={data.latitude}
                lng={data.longitude}
                showInfoWindow = {(this.state.markerClickId===data.id)?true:false} 
		            selected = {(this.props.selectedEventId===data.id)?true:false}      
	              markerClickId = {this.state.markerClickId}
		            handleMarkerClear = {this.handleMarkerClear}
                handleMarkerClick = {this.handleMarkerClick}
                eventInfo={data}
            />))}
        </GoogleMapReact>
      </div>
    );
  }
}

export default EventMap;
