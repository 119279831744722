import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import InfoWindow from './MapSurveyInfoWindow';

class LoadMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };

    this.handleClick = this.handleClick.bind(this);
  }


  handleClick(event) {
    console.log("LoadMarker:handleClick:",this.props.loadInfo, event);
    //event.stopPropagation(); //Otherwise map rectangle select 
    this.props.handleMarkerClick(this.props.loadInfo);
  }


  render() {
    let radius = 15+5*(this.props.loadInfo.noLoads);

    if(this.props.moving) {
      radius=20;
    }

    const markerStyle = {
      top: '50%',
      left: '50%',
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      border: (this.props.$hover&&this.props.markerClickInfo===null||this.props.showInfoWindow||this.props.selected) ? '3px solid fuchsia' : '1px solid black', //Googlemaps so kind to pass $hover
      height: (this.props.$hover&&this.props.markerClickInfo===null||this.props.showInfoWindow||this.props.selected) ? radius+5:radius,
      width:  (this.props.$hover&&this.props.markerClickInfo===null||this.props.showInfoWindow||this.props.selected) ? radius+5:radius,
      fontWeight: ((this.props.$hover&&this.props.markerClickInfo===null)||(this.props.showInfoWindow)||this.props.selected) ? 'bold':'normal',
      backgroundColor: (this.props.selected || this.props.$hover&&this.props.markerClickId===null || this.props.showInfoWindow || this.props.proximity)? 'fuchsia' :
                       (this.props.loadInfo.filtered===1) ? 'lightgrey'  : ( //else if
                       (this.props.holeNo  ? 'green'  : 'orange')) ,
      cursor: 'pointer',
      position: 'absolute',
      optimized: true,
      lineHeight: radius.toString() + 'px',
      textAlign: 'center',
      zIndex:6 
    };

    const textStyle = {
      color: this.props.holeNo ? 'white':'black',
      fontSize: this.props.$hover ? 12:11,
      fontWeight: this.props.$hover ? 'bold':'normal',
    };

    return (
      <div>
        <div style={markerStyle}  onClick={this.handleClick} > <span style={textStyle}>{this.props.holeNo ? this.props.holeNo:this.props.no}</span> </div>
        {(((this.props.$hover && this.props.markerClickInfo===null && this.props.designClickInfo===null && !this.props.loadAddWindow)||
            this.props.showInfoWindow)&&!this.props.removeInfoWindow)&&
          <InfoWindow
            hole              = {this.props.loadInfo}
            userDetails       = {this.props.userDetails}
            refreshData       = {this.props.refreshData}
            handleMarkerClear = {this.props.handleMarkerClear}
            loadAddEnabled    = {this.props.holeNo?true:false}
            markerClickInfo   = {this.props.markerClickInfo} />}
      </div>
    );
  }
}

export default LoadMarker;

