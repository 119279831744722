import Chart from "react-google-charts";
import React from "react";
import { withTranslation } from 'react-i18next';

class Chart2 extends React.Component 
{   
    constructor(props) 
    {
        super(props);
    
        this.state = {
          data: null,
          error: null,
          isLoading: true
        };
    }

      getData = (props) =>{
      this.setState({isLoading: true });
      fetch(process.env.REACT_APP_HOST_URL+'homeChart2DS.php',
          {
              method: 'POST',                
              body: JSON.stringify(
              {
                companyId : props.userDetails.companyId,
                userId    : props.userDetails.userId,
                siteId    : props.siteId,
                year      : props.year                  
              })
          })
        .then(response => response.json())
        .then(data => {
          this.setState({ data, isLoading: false }); } )
        .catch(error => this.setState({ error, isLoading: false, data:null}));
    }

    componentDidMount() {
      this.getData(this.props)
    }

    componentDidUpdate(prevProps, prevState) {
      if((prevProps.siteId!==this.props.siteId) || (prevProps.year!==this.props.year)) {
        this.getData(this.props);
      }
    };


    render() 
    { 
        const { t } = this.props;

        if (this.state.isLoading){
            return (<p>{t('Loading')}</p>);
        }


        if ((!this.state.data) || (this.state.data.length === 0)) {
            return (null);
        }
       
        return  (
            <Chart
                width={'1000'}
                chartType="ColumnChart"
                chartLanguage={this.props.userDetails.language}
                loader={<div>{t('Loading')}</div>}
                data={this.state.data}
                options={{
		            legend: { position: "top", maxLines: 3 },
                    chartArea: { width: 1000},
                    isStacked:true,
                }}
                rootProps={{ 'data-testid': '1' }}
          />
        )
    }
}

export default withTranslation()(Chart2);

