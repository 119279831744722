import React from 'react';
import { withTranslation } from 'react-i18next';
import { DatePicker } from '@y0c/react-datepicker';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import dayjs from "dayjs"
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import FormControl from '@mui/material/FormControl/FormControl';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

class LoadAdd extends React.Component {
  constructor(props) {
    super(props);

    this.deckNos = [
      {label:'1', value:1},
      {label:'2', value:2},
      {label:'3', value:3},
      {label:'4', value:4},
      {label:'5', value:5},
    ]

    this.state = {
      vehicles             : [],
      products             : [],
      selectedDate         : dayjs(),
      selectedVehicle      : null,
      selectedProduct      : null,
      selectedDeckNo       : this.deckNos[0].value,
      loadedWeight         : '',
      holeNo               : this.props.holeNo?this.props.holeNo:'',
      errorLoadedWeight    : false,
      errorHoleNo          : false,
      errorSelectedProduct : false,
      errorSelectedVehicle : false,
      errorSelectedDeckNo  : false,
    };
  }

  componentDidMount() {
    console.log("LoadAdd:componentDidMount");
    this.getVehicles ();
    this.getProducts ();
  }

  handleClick = (event) =>{
    event.stopPropagation();
    //if(event.target.id==='LoadedWeight') this.loadedWeightInput.focus();
    //console.log("LoadAdd:handleClick",event.target.id, this);
  }

  getVehicles = () =>{
    fetch(process.env.REACT_APP_HOST_URL+'reportVehiclesDS.php',
    {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            options   : 0,
        })
    })
    .then(response => response.json())
    .then(data => this.setState({ vehicles:data }))
    .catch(error => this.setState({ vehicles:[] }));
  }

  getProducts = () =>{
    fetch(process.env.REACT_APP_HOST_URL+'commonProductTypesV2DS.php',
    {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            typeId    : 1,
        })
    })
    .then(response => response.json())
    .then(data => this.setState({ products:data }))
    .catch(error => this.setState({ products:[] }));
  }

  onDateChange = (date) => {
    console.log("LoadAdd:onDateChange:");
    this.setState({selectedDate:date});
  }

  handleSelectVehicle = (e) => {
    console.log("LoadAdd:handleSelectVehicle:", e.target.value);
    this.setState({ selectedVehicle:e.target.value,errorSelectedVehicle: false });
  }

  handleSelectProduct = (e) => {
    console.log("LoadAdd:handleSelectProduct:", e.target.value);
    this.setState({ selectedProduct:e.target.value, errorSelectedProduct: false });
  }

  handleSelectDeckNo = (e) => {
    console.log("LoadAdd:handleSelectDeckNo:", e.target.value);
    this.setState({ selectedDeckNo:e.target.value,errorSelectedDeckNo: false });
  }

  handleChangeLoadedWeight = (event) => {
    var loadedWeight = event.target.value;
    console.log("LoadAdd:handleChangeLoadedWeight:", loadedWeight);
    //if (event.target.validity.valid) {
    this.setState({loadedWeight:loadedWeight, errorLoadedWeight: false});
  }


  handleChangeHoleNo = (event) => {
    var holeNo = event.target.value;
    console.log("LoadAdd:handleChangeHoleNo:", holeNo);
    //if (event.target.validity.valid) {
    this.setState({holeNo:holeNo, errorholeNo: false});
  }

  addLoad = (date, holeNo, deckNo, vehicle, product, weight) => {
    console.log('LoadAdd:addLoad', date, holeNo, deckNo, vehicle, product, weight);

    fetch(process.env.REACT_APP_HOST_URL+'commonAddLoadDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
          userId        : this.props.userDetails.userId,
          companyId     : this.props.userDetails.companyId,
          siteId        : this.props.siteId,
          patternNo     : this.props.patternNo,
          holeNo        : this.props.holeNo?this.props.holeNo:this.state.holeNo,
          latitude      : this.props.latitude,
          longitude     : this.props.longitude,
          duration      : 0,
          date          : date,
          deckNo        : deckNo,
          vehicleId     : vehicle,
          productType   : product,
          weight        : weight,
      })
    })
    .then(response => response.json())
    .then(data=>{
      this.clearForm();
      this.props.handleAdd();
    })
    .catch(error => this.setState({ error }));
  }


  handleAdd = (event) => {
    event.stopPropagation();
    console.log("LoadAdd:handleAdd:",this.state.holeNo, this.state.selectedDate, this.state.selectedDeckNo, 
                                     this.state.selectedVehicle, this.state.selectedProduct, this.state.loadedWeight);

    if((this.state.selectedDate) && (this.state.selectedVehicle) && (this.state.selectedProduct) &&
       (this.state.loadedWeight) && (this.state.selectedDeckNo)){ //We have some edits
      //let dateMySql = moment(this.state.selectedDate).format('YYYY-MM-DD');
      let dateMySql = this.state.selectedDate.toISOString().slice(0, 19).replace('T', ' '); 

      this.addLoad(dateMySql, this.state.holeNo, this.state.selectedDeckNo, this.state.selectedVehicle, this.state.selectedProduct, this.state.loadedWeight);

      this.props.handleAdd();
    }
    else {
      if (!this.state.loadedWeight)    this.setState({errorLoadedWeight: true}); else this.setState({errorLoadedWeight: false});
      if (!this.state.holeNo)          this.setState({errorHoleNo: true}); else this.setState({errorHoleNo: false});
      if (!this.state.selectedVehicle) this.setState({errorSelectedVehicle: true}); else this.setState({errorSelectedVehicle: false});
      if (!this.state.selectedDeckNo)  this.setState({errorSelectedDeckNo: true}); else this.setState({errorSelectedDeckNo: false});
      if (!this.state.selectedProduct) this.setState({errorSelectedProduct: true}); else this.setState({errorSelectedProduct: false});
    }
   // event.stopPropagation(); //Otherwise HeatMap toggle
  }


  handleCancel = (event) => {
    event.stopPropagation();
    console.log("LoadAdd:handleCancel");
    this.clearForm();
    this.props.handleCancel();
    //event.stopPropagation(); //Otherwise HeatMap toggle
  }

  clearForm= () => {
    this.setState({loadedWeight         : '', 
                   selectedVehicle      : null,
                   selectedProduct      : null,
                   selectedDeckNo       : this.deckNos[0].value,
                   errorSelectedDeckNo  : false, 
                   errorLoadedWeight    : false,
                   errorHoleNo          : false,
                   errorSelectedVehicle : false, 
                   errorSelectedProduct : false, 
                   selectedDate         : dayjs()});
  }

  render() {
    const { t } = this.props;

    let initialDate = dayjs();

    var mapAddStyle={};
    if (this.props.holeNo===null) {
      mapAddStyle = {
        position: 'absolute',
        left: '-50%',
        top: '-10%',
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 5,
        fontSize: 14,
        zIndex: 100,
        opacity: 0.90
      };
    }

    return (
      <div >
        <div style={mapAddStyle}>
         <h6 key={11} style={{ captionSide: 'top', textAlign: 'left', color: 'black' }}>{t('Add_Load')}</h6>
          <form>
             <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >

              <div style={{width: 100}}> 
              <DatePicker
                onChange={this.onDateChange}
                initialDate ={initialDate}
              />
              </div>

              <FormControl variant="outlined" sx={{ m: 0, minWidth: 80, maxWidth:80 }}>
                <TextField
                  ref={(input) => {this.holeNo = input; }}
                  required
                  type          = "text"
                  id            = "HoleNo"
                  label         ={t('Hole_No')}
                  size          = "small"
                  error         = {this.state.errorHoleNo}
                  onChange      = {this.handleChangeHoleNo}
                  onClick       = {this.handleClick}
                  onMouseDown   = {this.handleClick}
                  onMouseUp     = {this.handleClick}
                  value         = {this.state.holeNo}
                  maxLength     = {8}
                  inputProps    ={{ readOnly: this.props.holeNo?true:false }}
                />
              </FormControl>

              <FormControl variant="outlined" sx={{ m: 1, minWidth: 75, maxWidth:75 }}>
                <InputLabel id="deckNoSelectLabel">{t('DeckNo')}</InputLabel>
                <Select
                  labelId           = "deckNoSelectLabel"
                  label             = {t('DeckNo')}
                  size              = "small"
                  value             = {this.state.selectedDeckNo}
                  required
                  onChange          = {this.handleSelectDeckNo}
                  error             = {this.state.errorSelectedDeckNo}
                >
                  {this.deckNos?.map(option => {
                    return (
                      <MenuItem dense={true} key={option.value} value={option.value}>
                        {option.label ?? option.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl variant="outlined" sx={{ m: 0, minWidth: 150 }}>
                <InputLabel id="vehicleSelectLabel">{t('Vehicle')}</InputLabel>
                <Select
                  labelId           = "vehicleSelectLabel"
                  label             = {t('Vehicle')}
                  value             = {this.state.selectedVehicle}
                  required
                  size              = "small"
                  onChange          = {this.handleSelectVehicle}
                  error             = {this.state.errorSelectedVehicle}
                >
                  {this.state.vehicles?.map(option => {
                    return (
                      <MenuItem dense={true} key={option.value} value={option.value}>
                        {option.label ?? option.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl variant="outlined" sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="deckNoSelectProduct">{t('Product')}</InputLabel>
                <Select
                  labelId           = "deckNoSelectProduct"
                  label             = {t('Product')}
                  value             = {this.state.selectedProduct}
                  required
                  onChange          = {this.handleSelectProduct}
                  size              = "small"
                  error             = {this.state.errorSelectedProduct}
                >
                  {this.state.products?.map(option => {
                    return (
                      <MenuItem dense={true} key={option.value} value={option.value}>
                        {option.label ?? option.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl variant="outlined" sx={{ m: 0, minWidth: 150, maxWidth:150 }}>
                <TextField
                  ref={(input) => {this.loadedWeight = input; }}
                  required
                  type          = "number"
                  size          = "small"
                  id            = "LoadedWeight"
                  label         ={t('Weight') + '('+this.props.userDetails.massMUnit+')'}
                  error         =  {this.state.errorLoadedWeight}
                  onChange      = {this.handleChangeLoadedWeight}
                  onClick       = {this.handleClick}
                  onMouseDown   = {this.handleClick}
                  onMouseUp     = {this.handleClick}
                  value         = {this.state.loadedWeight}
                  maxLength     = {4}
                />
              </FormControl>

              <Button variant="contained" size="small" onClick={this.handleAdd}>{t('Add')}</Button>

              {(this.props.handleCancel) &&[
                <div>
                  &nbsp;
                  <Button variant="contained" size="small" onClick={this.props.handleCancel}>{t('Cancel')}</Button>
                </div>
              ]}

            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withTranslation()(LoadAdd);
