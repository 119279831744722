import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import AuthoriseObj from './Authorise.js'
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { withTranslation } from 'react-i18next';

class LoadTable extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.state = {
          patternNoList:[],
        };
    }

    componentDidMount() {       
      this.getPatternNoList();
    }

    getPatternNoList = () =>{
      console.log("LoadTable:ReportUnkownHoles:getPatternNoList");

      fetch(process.env.REACT_APP_HOST_URL+'commonPatternListGetDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId,
          siteId    : this.props.siteId,
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log('LoadTable:getPatternNoList', data);
        this.setState({ patternNoList:data });
      })
      .catch(error => this.setState({ patternNoList:[] }));
    }


    updateLoad=() => {
      console.log('LoadTable:updateLoad ' , this.editRow);

      fetch(process.env.REACT_APP_HOST_URL+'loadUpdateDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            siteId    : this.editRow.siteId,
            patternNo : this.editRow.patternNo,
            holeNo    : this.editRow.holeNo,
            vehicleId : this.editRow.vehicleId,
            holeId    : this.editLoadId,
            deckNo    : this.editRow.deckNo,
            weight    : this.editRow.weight,
            density   : this.editRow.density
        })
      })
      .then(response => response.json())
      .then(data => {this.props.refreshData();})
      .catch(error => this.setState({ error}));
    }


    deleteLoad=(loadId) => {
      console.log('LoadTable:deleteLoad ' , loadId);

      fetch(process.env.REACT_APP_HOST_URL+'loadDeleteDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            loadId    : loadId
        })
      })
      .then(response => response.json())
      .then(data => {this.props.refreshData();})
      .catch(error => this.setState({ error}));
    }



   afterSaveCell = (cellOld, cellNew, row, column) =>{
     console.log('LoadTable:afterSaveCell: ' + column.dataField + ":" + cellOld + "->" + cellNew);
     console.log('LoadTable:afterSaveCell: ', row);

     if (cellOld!=cellNew){
       this.editRow = {...row};

       if (column.dataField==='patternNo') {
        this.editRow.patternNo=cellNew;

        let temp=this.state.patternNoList.find(x => x.value == cellNew); //Just in cae its  is is still empty...
        if (temp) this.editRow.siteId=temp.siteId; else this.editRow.siteId=0; //Server side will not update
       } else {
         if (column.dataField==='deckNo') {
           this.editRow.deckNo=cellNew;
         } else if (column.dataField==='holeNo') {
           this.editRow.holeNo=cellNew;
         } else if (column.dataField==='weight') {
           this.editRow.weight=cellNew;
         } else if (column.dataField==='density') {
           this.editRow.density=cellNew;
         }
       }

       this.updateLoad() //Dont update when you change pattern Number only
     }
   }


    getNonEditableRows = () => {
      let nonEditableRows = [];

       //this.props.loads.forEach((load, i) => {
         //if (load.manual !=='*') nonEditableRows.push(load.loadId);
       //});

      console.log ('LoadTable:getNonEditableRows', nonEditableRows);

      return nonEditableRows;
    }


    handleDelete = (row) => {
      console.log ('LoadTable:handleDelete', row);

      if(row.noLoads==1) { //No more loads left, remove infoWindow
        this.props.handleMarkerClear();
      }

      this.deleteLoad(row.loadId);
    }


    DeleteButton = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Stack direction="row" spacing={0}>
          <IconButton  sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
            aria-label="Delete" 
            size="small" 
            onClick={() => {
              this.handleDelete(row)
            }} 
            disabled={row.manual=='*'?false:true} 
            color="primary">
            <DeleteIcon />
          </IconButton>
        </Stack>
      );
    };

    render() 
    { 
        const { t } = this.props;

        var editAuth = false;
        if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_PATTERN) > 0) editAuth=true;
   
        const columns = [{
            dataField: 'loadId',
            text: 'loadId',
            hidden: true
        }, {
            dataField: 'siteId',
            text: 'siteId',
            hidden: true
        }, {
            dataField: 'deckNo',
            text: t('DeckNo'),
            editable: editAuth,
            hidden: false,
            sort: true ,
        }, {
            dataField: 'eventDateTime',
            text: t('Date'),
            editable: false,
            sort: true                
        }, {
            dataField: 'patternNo',
            text: t('Pattern_No'),
            editable: editAuth,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; },
            editor: {
              type: Type.SELECT,
              options: this.state.patternNoList
            },
        }, {
            dataField: 'holeNo',
            text: t('Hole_No'),
            editable: editAuth,
            sort: true,
        }, {
            dataField: 'vehicleDescription',
            text: t('Vehicle'),
            editable: false,
            sort: true,
        }, {
            dataField: 'vehicleId',
            text: t('Vehicle'),
            hidden: true,
        }, {
            dataField: 'driverName',
            text: t('Driver'),
            editable: false,
            sort: true,
        }, {
            dataField: 'blasterName',
            text: t('Blaster'),
            editable: false,
            hidden:false,
            sort: true,
        }, {
            dataField: 'weight',
            text: t('Weight')+ '('+this.props.userDetails.massMUnit+')',
            editable: editAuth,
            sort: true,
        }, {
            dataField: 'productDescription',
            text: t('Product'),
            editable: false,
            sort: true,
        }, {
            dataField: 'columnHeight',
            text: t('Column_Height')+'('+this.props.userDetails.lengthMUnit+')',
            editable: false,
            sort: true,
            hidden: true
        }, {
            dataField: 'decking',
            text: t('Decking_Height')+'('+this.props.userDetails.lengthMUnit+')',
            editable: false,
            sort: true,
            hidden: true
        }, {
            dataField: 'duration',
            text: t('Duration'),
            editable: false,
            sort: true,
        }, {
            dataField: 'density',
            text: t('Density'),
            editable: editAuth,
            sort: true ,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; },
        }, {
            dataField: 'manual',
            text: t('Manual'),
            editable: false,
            sort: true ,
        }, {
            dataField: "follow",
            text: "Action",
            formatter: this.DeleteButton,
            sort: true,
            editable: false,
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              //e.stopPropagation();
              console.log("LoadTable:onClick:", e, row, rowIndex);
            },
            onMouseEnter: (e, row, rowIndex) => {
            }
          };

                      
        return  (            
          <div>
            <BootstrapTable 
               bootstrap4
               classes="table-responsive"
               keyField='loadId'    
               striped={true} 
               hover={true} 
               condensed={true} 
               data={ this.props.loads } 
               columns={ columns } 
               rowEvents={ rowEvents } 
               headerClasses="thead-dark"
               cellEdit={ cellEditFactory({mode: 'click',
                          blurToSave:true, 
                          nonEditableRows: this.getNonEditableRows, 
                          afterSaveCell: this.afterSaveCell, 
                          onStartEdit: (row) => {this.editLoadId=row.loadId;return(false); }})}
           />
        </div>)
    }
}

export default withTranslation()(LoadTable);
