import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react';
import Marker from './MapDesignMarker';

/* global google */

function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    },
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.ROAD 
  };
}


class ImportPatternMap extends Component {
  constructor(props)
  {
    super(props);

    this.state = {
       designMarkerClickInfo : null,
       markerClickId : null,
       zoom : 11,
       center: {
         lat: 0,
         lng: 0 
       },
    };

    this.map=null;
    this.maps=null;
  }


  centerMap = (markers) => {
    if (markers.length === 0 ) return;
    if (this.map == null) return;
    if (this.maps == null) return;

    var bounds = new google.maps.LatLngBounds();

    for (var i=0;i<markers.length; i++){
      var myLatLng = new google.maps.LatLng(markers[i].latitude, markers[i].longitude);
      bounds.extend(myLatLng);
    }

    this.map.fitBounds(bounds)
  };


  componentWillReceiveProps(nextProps){
    console.log("ImportPattern:componentWillReceiveProps");
    if (nextProps.holeInfos.length!=this.props.holeInfos.length) this.centerMap(nextProps.holeInfos);
  }
  
  componentDidMount() {
    console.log("ImportPattern:componentDidMount");
    this.centerMap(this.props.holeInfos);
  }


  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = (key,holeInfo) => {
    //console.log("CommonMap:onChildClickCallback",key,":",holeInfo);
    //if (this.state.markerClickId===null) this.setState({markerClickId : key});
  };


  onClickCallback = (key) => {
    console.log("ImportPattern:onClickCallback",key);
  };


  handleMarkerClear = (holeId) => {
    console.log('ImportPattern:handleMarkerClear', holeId);
    this.setState({designMarkerClickInfo : null});
  }


  handleMarkerClick = (designInfo) => {
    console.log("ImportPattern:handleMarkerClick",designInfo);

    if (this.state.designMarkerClickInfo===null) {
      this.setState({designMarkerClickInfo : designInfo});

      this.map.panTo({
        lat: parseFloat(designInfo.latitude),
        lng: parseFloat(designInfo.longitude)
      })

      this.map.panBy(500,-100);
    }
  };


  handleApiLoaded = (map, maps) => {
    console.log ("ImportPattern:handleApiLoaded");

    this.maps = maps;
    this.map  = map;

    this.centerMap(this.props.holeInfos);
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
           yesIWantToUseGoogleMapApiInternals
           onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
           bootstrapURLKeys={{ key: 'AIzaSyDlrKQh55n9-jY2KHHrUIx2t3dQOG30P98',  libraries: 'drawing,visualization' }}
           defaultCenter={this.state.center}
           center={this.state.center}
           zoom={this.state.zoom}
           defaultZoom={this.state.zoom}
           options={createMapOptions} 
	         onClick={this.onClickCallback}
           onChildClick={this.onChildClickCallback} >
           {this.props.holeInfos.map(data =>
              (<Marker
                userDetails={this.props.userDetails}
                key={data.holeId}
                lat={data.latitude}
                lng={data.longitude}
                //selected = {(this.props.selectedHoleId===data.holeId)?true:false}

                showInfoWindow    = {((this.state.designMarkerClickInfo!==null) && (this.state.designMarkerClickInfo.holeId===data.holeId))?true:false}
                //anchor= {new google.maps.Point(5, 58)}
                labelAnchor
                selected          = {((this.props.selectedHoleId===data.loadedHoleId) ||
                                      ((this.state.designMarkerClickInfo!==null) && (this.state.designMarkerClickInfo.holeId===data.holeId)))?true:false}

                markerClickInfo   = {this.state.designMarkerClickInfo}
                loadClickInfo     = {null}
		            handleMarkerClear = {this.handleMarkerClear}
                handleMarkerClick = {this.handleMarkerClick}
                designInfo={data}
                products={this.props.products}
                stemming={this.props.stemming}
            />))}
        </GoogleMapReact>
      </div>
    );
  }
}

export default ImportPatternMap;
