import React, { Component } from "react";
import {Element, scroller} from 'react-scroll';

import Chart1 from './HomeChart.1';
import Chart2 from './HomeChart.2';
import HomeTable1 from './HomeTable.1';
import Report4 from './Report.4';
import Welcome from './Welcome';
import YearSelect from './YearSelect';
import AuthoriseObj from './Authorise.js';
import { withTranslation } from 'react-i18next';
 
class Home extends Component {
  constructor(props) {
    super(props);

    const currentYear = new Date().getFullYear();

    this.state = {  
      selectedSiteId : null,
      selectedPatternId : null,
      year : currentYear 
    };
  }

  handleTable2RowClick = (siteId, patternId) => {
    this.setState({ selectedSiteId:siteId, selectedPatternId:patternId, drillLevel:1 });
    console.log('handleTable2Click:', siteId, ' ', patternId );

    scroller.scrollTo('scrollreport4', {
      duration: 800,
      offset: -150,
      delay: 500,
      smooth: 'easeInOutQuart'
    })
  }


  handleUpdateYear = (year) => {
    this.setState({ year:year });
    console.log('Report4:handleUpdateYear:', year, ' ' ,this.state.year );
  }


  render() {
    const { t } = this.props;

    return (
      <div>         
        {(this.props.userDetails.companyId===0) && [
          <Welcome/>
	      ]}
        <div style={{clear :'both'}}>
          <div key = 'YearSelect' style={{ width:'200px', margin: 'auto'}}><YearSelect
            handleUpdate={this.handleUpdateYear}
            defaultYear = {this.state.year}/></div>
          <Chart1 
            key='HomeChart1'
            userDetails={this.props.userDetails} 
            year={this.state.year}/>
          <Chart2 
            key='HomeChart2'
            userDetails={this.props.userDetails} 
            year={this.state.year}/>
          {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_VEHICLE_ISSUES)>0 && [  
            <HomeTable1            
              key='HomeTable1'
              userDetails={this.props.userDetails} /> ]} 
          <Report4
            key = 'HomeReport4'
   	        onRowClick={this.handleTable2RowClick}
            userDetails={this.props.userDetails} 
	          searchEnabled={false} />
        </div>

        <Element name='scrollreport4' className='element'/>
        {((this.state.selectedSiteId!=null)  && (this.state.selectedPatternId!=null)) && [
	<div>
	    <Report4
          key = 'HomeReport4'
	        userDetails={this.props.userDetails}
          siteId={this.state.selectedSiteId}
	        patternId={this.state.selectedPatternId}/>
        </div>
	]}
      </div>
    );
  }
}

export default withTranslation()(Home);
