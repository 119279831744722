import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';

class SetupNotificationsTable1 extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.editRow = {
          title         : null,
          destination   : null,
          frequency     : null,
          siteMonth     : null,
          siteDay       : null,
          tizMonth      : null,
          deviceIssues  : null,
          holeIssues    : null,
          holeFill      : null,
          speeding      : null,
          exportCsv     : null,
          exportBlastX  : null,
          maintenance   : null,
        };

        this.editNotificationId = null;

        this.state = {
          selected : null,
          isLoading     : true          
        };
    }

    componentDidMount() {       
      console.log ("SetupNotificationsTable1:componentDidMount()");
    }

    updateNotification = () => {       
        fetch(process.env.REACT_APP_HOST_URL+'setupNotificationsUpdateDS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                    userId           : this.props.userDetails.userId,
                    companyId        : this.props.userDetails.companyId,
                    id               : this.editRow.id,
                    destination      : this.editRow.destination,
                    title            : this.editRow.title,
                    frequency        : this.editRow.frequency,
                    siteMonth        : this.editRow.siteMonth,
                    siteDay          : this.editRow.siteDay,
                    tizMonth         : this.editRow.tizMonth,
                    deviceIssues     : this.editRow.deviceIssues,
                    holeIssues       : this.editRow.holeIssues,
                    holeFill         : this.editRow.holeFill,
                    speeding         : this.editRow.speeding,
                    exportCsv        : this.editRow.exportCsv,
                    exportBlastX     : this.editRow.exportBlastX,
                    maintenance      : this.editRow.maintenance  
                })
            })
          .then(response => response.json())
          .then(data => {this.props.handleNotificationUpdate();})
          .catch(error => null);   
    }


    afterSaveCell = (cellOld, cellNew, row, column) =>    
    {
        console.log('SetupNotificationsTable1:afterSaveCell: ' + column.dataField + ":" + cellOld +":" + cellNew, column);
        console.log (row);

        this.editRow = {...row}; //force copy else we have pointer to row
       
        if (column.dataField==='destination') {
          this.editRow.destination=cellNew;
        }
        else if (column.dataField==='title') {
          this.editRow.title=cellNew;
        }
        else if (column.dataField==='frequency') {
          this.editRow.frequency=cellNew;
        }
        else if (column.dataField==='siteMonth') {
          this.editRow.siteMonth=cellNew;
        }
        else if (column.dataField==='siteDay') {
          this.editRow.siteDay=cellNew;
        }
        else if (column.dataField==='tizMonth') {
          this.editRow.tizMonth=cellNew;
        }
        else if (column.dataField==='deviceIssues') {
          this.editRow.deviceIssues=cellNew;
        }
        else if (column.dataField==='holeIssues') {
          this.editRow.holeIssues=cellNew;
        }
        else if (column.dataField==='holeFill') {
          this.editRow.holeFill=cellNew;
        }
        else if (column.dataField==='speeding') {
          this.editRow.speeding=cellNew;
        }
        else if (column.dataField==='exportCsv') {
          this.editRow.exportCsv=cellNew;
        }
        else if (column.dataField==='exportBlastX') {
          this.editRow.exportBlastX=cellNew;
        }
        else if (column.dataField==='maintenance') {
          this.editRow.maintenance=cellNew;
        }

        this.updateNotification();
    }

    selectRow = (row, isSelect, rowIndex) => {
      console.log("SetupNotificationsTable1:selectRow",row,isSelect,rowIndex);
      this.props.handleNotificationSelect(row);
    };


    render() 
    { 
        const { t } = this.props;

        var editAuth = false;
        if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_NOTIFICATIONS) > 0) editAuth=true;
   
        const columns = [{
            dataField: 'userName',
            text: t('UserName'),
            editable: false,
            sort: true                
        }, {
            dataField: 'title',
            text: t('Title'),
            editable: editAuth,
            sort: true
        }, {
            dataField: 'destination',
            text: t('Destination'),
            editable: editAuth,
            sort: true,
            headerStyle: () => {
              return { width: "15%" };
            }
        }, {
            dataField: 'frequency',
            text: t('Frequency'),
            editable: editAuth,
            sort: true,
            editor: {
                type: Type.SELECT,
                options: [{
                  value: t('Daily'),
                  label: t('Daily')
                }, {
                  value: t('Weekdays'),
                  label: t('Weekdays')
                }, {
                  value: t('Monthly'),
                  label: t('Monthly')
                }, {
                  value: t('Live'),
                  label: t('Live')
                }]
            }
        }, {
            dataField: 'siteMonth',
            text: t('Site_Month'),
            editable: editAuth,
            editor: {
              type: Type.CHECKBOX,
              value: 'X: '
            }
        }, {
            dataField: 'siteDay',
            text: t('Site Day'),
            editable: editAuth,
            editor: {
              type: Type.CHECKBOX,
              value: 'X: '
            }
        }, {
            dataField: 'tizMonth',
            text: t('TIZ_Month'),
            editable: editAuth,
            hidden: ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_REPORT_TIZ)>0)?false:true,
            editor: {
              type: Type.CHECKBOX,
              value: 'X: '
            }
        }, {
            dataField:'deviceIssues',
            text: t('Vehicle_Issues'),
            editable: editAuth,
            editor: {
              type: Type.CHECKBOX,
              value: 'X: '
            }
        }, {
            dataField: 'holeIssues',
            text: t('Hole_Issues'),
            editable: editAuth,
            editor: {
              type: Type.CHECKBOX,
              value: 'X: '
            }
        }, {
            dataField: 'holeFill',
            text: t('Hole_Fill'),
            editable: editAuth,
            editor: {
              type: Type.CHECKBOX,
              value: 'X: '
            }
        }, {
            dataField: 'maintenance',
            text: t('Maintenance'),
            editable: editAuth,
            hidden:  ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_REPORT_MAINTENANCE)>0)?false:true,
            editor: {
              type: Type.CHECKBOX,
              value: 'X: '
            }
        }, {
            dataField: 'speeding',
            text: t('Speeding'),
            editable: editAuth,
            hidden:  ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_REPORT_SPEEDING)>0)?false:true,
            editor: {
              type: Type.CHECKBOX,
              value: 'X: '
            }
        }, {
            dataField: 'exportCsv',
            text: t('Export_CSV'),
            editable: editAuth,
            editor: {
              type: Type.CHECKBOX,
              value: 'X: '
            }
        }, {
            dataField: 'exportBlastX',
            text: t('Export_BlastX'),
            editable: editAuth,
            editor: {
              type: Type.CHECKBOX,
              value: 'X: '
            }
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.notificationId} ${row.userName}`);
            },
              onMouseEnter: (e, row, rowIndex) => {
            }
          };

        const selectRow = {
          mode: 'radio',
          clickToSelect: true,
          clickToEdit: true,
          onSelect: this.selectRow,
          style: { backgroundColor: '#c8e6c9'}
        };


        return  (            
            <div>
               <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Setup_Notifications')}</h3>
                 <BootstrapTable 
                     bootstrap4
                     classes="table-responsive"
                     keyField='id'    
                     striped={true} 
                     hover={true} 
                     condensed={true} 
                     data={ this.props.notifications } 
                     columns={ columns } 
                     rowEvents={ rowEvents } 
                     selectRow={ selectRow }
                     headerClasses="thead-dark"
	                   pagination={ paginationFactory({sizePerPage:15})}
                     cellEdit={ cellEditFactory({mode: 'click', blurToSave:true, afterSaveCell: this.afterSaveCell})}
                 />
            </div>
        )
    }
}

export default withTranslation()(SetupNotificationsTable1);
