import Chart from "react-google-charts";
import React from "react";
import { withTranslation } from 'react-i18next';

class ChartTizYear extends React.Component 
{   
    constructor(props) 
    {
        super(props);
    
        this.state = {
          data: null,
          error: null,
          isLoading: true
        };
    }

      getData = (props) =>{
      this.setState({isLoading: true });
      fetch(process.env.REACT_APP_HOST_URL+'report5ChartYearDS.php',
          {
              method: 'POST',                
              body: JSON.stringify(
              {
                companyId : props.userDetails.companyId,
                userId    : props.userDetails.userId
              })
          })
        .then(response => response.json())
        .then(data => this.setState({ data, isLoading: false }))
        .catch(error => this.setState({ error, isLoading: true }));
    }

    componentDidMount() {
      this.getData(this.props)
    }

    componentWillReceiveProps(nextProps){        
      if((nextProps.siteId!==this.props.siteId) || (nextProps.year!==this.props.year))
      {            
          this.getData(nextProps);
      }
  }

    render() 
    { 
        const { t } = this.props;

        if (this.state.isLoading)
        {
            return (<p>{t('Loading')}</p>);
        }
       
        return  (
            <Chart
                width={'1000'}
                chartType="ColumnChart"
                loader={<div>{t('Loading')}</div>}
                data={this.state.data}
                chartEvents={[
                    {
                      eventName: "select",
                        callback: ({ chartWrapper, google }) => {
                          var selection = chartWrapper.getChart().getSelection();
                          var row = selection[0].row;

                          var x=chartWrapper.getDataTable().getValue(row, 0);
                          var year = x.split("/")[0];
                          var month = x.split("/")[1];
                          console.log("ChartTizYear Click ", year, month);
                          this.props.onDateSelect(year, month);
                        }
                     }
                    ]}
                options={{
                    chartArea: { width: 1000},
                    legend: 'none'
                }}
          />
        )
    }
}

export default withTranslation()(ChartTizYear);
