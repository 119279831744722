import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { withTranslation } from 'react-i18next';

class MyTable1 extends React.PureComponent  
{   
    constructor(props) 
    {
        super(props);
    
        this.state = {
          data:      null,
          error:     null,
          isLoading: true          
        };
    }

    getData = (props) =>{
        this.setState({isLoading: true });
        fetch(process.env.REACT_APP_HOST_URL+'report1Table1DS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                    userId    : props.userDetails.userId,
                    companyId : props.userDetails.companyId,
                    siteId    : props.siteId,
                    year      : props.year
                })
            })
          .then(response => response.json())
          .then(data => this.setState({ data, isLoading: false }))
          .catch(error => this.setState({ error, isLoading: true }));
    }

    componentDidMount() {
        this.getData(this.props)
        console.log('Table1:componentDidMount');
    }

    componentWillReceiveProps(nextProps){               
        if(nextProps.siteId!==this.props.siteId) 
        {            
            this.getData(nextProps);
        }
    }

    render() 
    { 
        const { t } = this.props;

        if (this.state.isLoading)
        {
            return (<p>{t('Loading')}</p>);
        }
   
        const columns = [{
            dataField: 'month',
            text: t('Month'),
            hidden: true                
        }, {
            dataField: 'year',
            text: t('Year'),
            hidden: true
        }, {
            dataField: 'yearMonth',
            text: t('Date'),
            sort: true
        }, {
            dataField: 'noVehicles',
            text: t('No_Vehicles'),
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b;}
        }, {
            dataField: 'noHoles',
            text: t('No_Holes'),
            sort: true  ,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
        }, {
            dataField: 'duration',
            text: t('Duration'),
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/:/g, '');
              var y = b.replace(/:/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }, {
            dataField: 'volume',
            text: t('Weight')+'('+this.props.userDetails.massMUnit+')',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }];

        const rowEvents = {
          onClick: (e, row, rowIndex) => {
            console.log(`clicked on row with index: ${row.year} ${row.month} ${row.yearMonth}`);
            this.props.onSelectYearMonth(row.year, row.month, row.yearMonth);  
          },
          onMouseEnter: (e, row, rowIndex) => {
            //console.log(`enter on row with index: ${row.year} ${row.month}`);
          }
        };

        const selectRow = {
          mode: 'radio',
          clickToSelect: true,      
          hideSelectColumn:true,  
          style: { backgroundColor: '#c8e6c9'}
        };

         
        return  (
            <div>
               <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Monthly_Summary')}:{this.props.siteName}</h3>
               <BootstrapTable 
                   bootstrap4
                   classes="table-responsive-sm"
                   keyField='yearMonth'           
                   selectRow={ selectRow } 
                   striped={true} 
                   hover={true} 
                   condensed={true} 
                   data={ this.state.data } 
                   columns={ columns } 
                   rowEvents={ rowEvents } 
                   headerClasses="thead-dark"
               />
            </div>)
    }
}

export default withTranslation()(MyTable1);

