import React from 'react';

class MapVehicleLegend extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      vehicles: null,
    };
  }

  componentDidMount() {
    console.log ('MapVehicleLegend:componentDidMount');
    fetch(process.env.REACT_APP_HOST_URL+'commonPatternGetVehiclesDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
         userId    : this.props.userDetails.userId,
         companyId : this.props.userDetails.companyId,
         siteId    : this.props.siteId,
         patternNo : this.props.patternNo
      })
    })
    .then(response => response.json())
    .then(data => {this.setState({ vehicles:data});
                    })
    .catch(error => this.setState({ vehicles:null }));
  }


  onClick = (vehicleId) =>{
    console.log("MapVehicleLegend:onClick:",vehicleId);
    this.props.handleSelect(vehicleId);
  }


  getRgb(n) {
   let rgb = [];
   if (n===0)  rgb=[0x33, 0x66, 0xcc];
   if (n===1)  rgb=[0xdc, 0x39, 0x12];
   if (n===2)  rgb=[0xff, 0x99, 0x00];
   if (n===3)  rgb=[0x10, 0x96, 0x18];
   if (n===4)  rgb=[0x99, 0x00, 0x99];
   if (n===5)  rgb=[0x00, 0x99, 0xc6];
   if (n===6)  rgb=[0xdd, 0x44, 0x77];
   if (n===7)  rgb=[0x66, 0xaa, 0x00];
   if (n===8)  rgb=[0xb8, 0x2e, 0x2e];
   if (n===9)  rgb=[0x31, 0x63, 0x95];
   if (n===10) rgb=[0x99, 0x44, 0x99];
   if (n===11) rgb=[0x22, 0xaa, 0x99];
   if (n===12) rgb=[0xaa, 0xaa, 0x11];
   if (n===13) rgb=[0x66, 0x33, 0xcc];
   if (n===14) rgb=[0xe6, 0x73, 0x00];
   if (n>14)   rgb=[0x8b, 0x07, 0x07];

   return rgb;
  }

  render() {

   return (
   <div>
    {(this.state.vehicles!=null) && [

     <div key={153} style={{paddingTop: '3px', height:'30px',backgroundColor: 'white'}}>
     {this.state.vehicles.map((vehicle, index) => (
     <div key={index} onClick={()=>this.onClick(vehicle.id)}>
        <div style={{ float:'left',width: "20px", height: "20px", background: `rgb(${this.getRgb(index)})`, borderRadius: "50%", border: "3px solid lightgrey"}}></div>
        <div style={{ float:'left', marginRight: '1.0rem',color:'black'}}> {vehicle.description} </div>
     </div>
     ))}
     </div>
     ]}
     </div>)
  }
}

export default MapVehicleLegend;

