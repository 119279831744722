import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import InfoWindow from './MapSurveyInfoWindow';

class MapGpsPosMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let backgroundColor = 'lightgrey' ;
    let textColor = 'black' ;
    let border = '1px solid black' ;
    let radius=20;

    console.log("MapGpsPosMarker:",this.props.surveyInfo);

    if (this.props.gpsOptions==0)  {textColor = 'black'; backgroundColor = 'orange'; }
    else if (this.props.gpsOptions==1) {textColor = 'white'; backgroundColor = 'red'; }

    if (this.props.surveyInfo.filtered===1) {textColor = 'black'; backgroundColor = 'lightgrey'; border ='1px solid black'; radius=20;}

    const markerStyle = {
      border: (this.props.$hover||this.props.selected)?'3px solid fuchsia':border, 
      borderRadius: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: (this.props.$hover||this.props.selected)?radius+6:radius, 
      width:  (this.props.$hover||this.props.selected)?radius+6:radius,
      backgroundColor: (this.props.$hover||this.props.selected)?'fuchsia':backgroundColor,
      cursor: 'pointer',
      zIndex: 10,
      textAlign: 'center',
      lineHeight: '20px',
    };

    const textStyle = {
      color: (this.props.$hover||this.props.selected)?'white':textColor,
      fontSize: (this.props.$hover||this.props.selected) ? 12:11,
      fontWeight:( this.props.$hover||this.props.selected) ? 'bold':'normal',
    };

    return (
      (this.props.lat!=null && this.props.lng!=null)? //Dont display if we do not hav a valid position
      <div>
        <div style={markerStyle}  onClick={this.handleClick} > <span style={textStyle}>{this.props.surveyInfo.holeNo}</span></div>
      </div>:null
    );
  }
}

export default MapGpsPosMarker;

