export const TRANSLATIONS_AF = {
//NavBar
 Loading:"Besig",
 Reports:"Verslae",
 Setup:"Opstel",
 Downloads:"Aflaai",
 Logout:"Uit Teken",
 Year:"Jaar",
 Date_Range:"Datum Interval",
 Pattern:"Patroon",
 Pump_Service:"Pomp Onderhoud",
 TIZ:"TIZ",
 Speeding:"Spoed",
 Notifications:"Alarms",
 People:"Persone",
 Products:"Produkte",
 Vehicles:"Voertuie",
 Zones:"Zones",
 //Home
  Weight_Pumped_per_Day:"Gewig Gepomp per Dag",
  Vehicle_Issues:"Voertuig Probleme",
  Since:"Sedert",
  Days_Ago:"Dae Gelede",

  Last_Date:"Laaste Datum",
  Site:"Plek",
  Pattern_No:"Patroon No",
  No_Vehicles:"Voertuie",
  No_Holes:"Gate",
  No_Events:"No",
  No_Days:"No Dae",
  Loaded:"Gelaai",
  Drilled:"Geboor",
  Approved:"Goedgekeur",
  Approved_Date:"Datum",
  URL:"URL",
  Approved_State:"Status",
  Recent_Patterns:"Onlangse Patrone",

//Pattern Report
Pattern_Report:"Patroon Verslag",
Start_Date:"Begin Datum",
End_Date:"End  Datum",
Select_Site:"Kies Plek",
Select_Pattern:"Kies Patroon",
Export_Link:"Skakel",
Pattern_Approve:"Goedkeur",
Pattern_Unlock:"Ontsluit",
Design:"Ontwerp",
Heatmap:"Hittekaart",
Over_Fill:"Oorlaai",
Load_Progress:"Laai Vordering",
Summary:"Opsomming",

Daily_Summary:"Daaglikse Opsomming",
Date:"Datum",
Weight:"Gewig",

//Meida
Media:"Media",
File_Upload_Failed:"File Upload Failed!",
File_Upload_Already_in_Progress:"File Upload Already in Progress!",
Drag_and_drop_some_files_here_or_click_to_select_files:"Drag and drop some files here or, click to select files",
Filename:"Leernaam",
SizeKB:"Groote(KB)",
Description:"Beskrywing",


//Detail Hole Information
Id:"Id",
Hole_No:"Gat No",
Depth:"Diepte",
Stemming:"Stemming",
DateTime:"Datum",
Driver:"Operateur",
Blaster:"Blaster",
Loads:"Laaie",
Decks:"Decks",
Product:"Produk",
Manual:"Manual", 
State:"Status",
Notes:"Notas",
Detail_Hole_Information:"Per Gat Informasie",


//MapInfoWindows
noLoads:"no Laaie",
noDecks:"no Decks",
noDays:"no Dae",
DeckNo:"Deck No",
Diameter:"Diameter",
Duration:"Periode",
Vehicle:"Voertuig",
OK:"OK",
Cancel:"Kanseleer",
Angle:"Hoek",
Column_Weight:"Kolom Gewig",
Column_Height:"Kolom Hoogte",
ProductType:"Produk",
Density:"Digtheid",
Decking_Height:"Decking Hoogte",
Decking_Type:"Decking Tipe",
Max_Speed:"Max Spoed",
Avg_Speed:"Gem Spoed",
Speed_Limit:"Spoed Limiet",
Zone:"Zone",
Address:"Adres",
Multi_Hole:"Multi Gat",
Selected:"Gekies",

//Year Report
Year_Report:"Jaar Verslag",
Select_Year:"Kies Jaar",
Select_Vehicles:"Kies Voertuie",
Select_User:"Kies Gebruiker",
Search:"Soek",

Date_Range_Report:"Datum Interval Verslag",


Time_In_Zone_Report:"Tyd In Zone(TIZ) Verslag",
Speeding_Report:"Spoed Verslag",
Pump_Maintenance_Report:"Pomp Onderhoud Verslag",
History:"Geskiedenis",
Add_Service_Record:"Nuwe Onderhouds Rekord",
Service_Date:"Onderhouds Datum",
Technician:"Werker",
Hours_to_Next_Service:"Onderhoud Interval",
Service_Hours:"Onderhoud Ure",

Monthly_Summary:"Maand Opsomming",
TimeLine:"Tydlyn",


Enter:"In",
Exit:"Uit",
Details:"Besonderhede",


Severity:"Graad",
Severity_Total:"Graad Total",
Severity_Day:"Graad Day",

Speeding_Events:"Spoed Oortredings",

//Maintenance
Hours:"Ure",
Total_Hours:"Totale Ure",
Pump_Hours:"Pomp Ure",
Last_Service:"Laste Diens",
Hours_Since_Service:"Ure Sederd laaste Onderhoud",
Remaining_Hours:"Ure oor",
Days_to_Service:"Dae tot Volgende",
No_Services:"No Dienste",
Avg_Hours_Day:"Gem Ure Per Dag",
Over_Due:"Uitstaande",

Import_Pattern:"Patroon Oplaai",
Delete:"Verwyder",
Import_New_Pattern:"Nuwe Patroon",
Drop_CSV_file_here_or_click_to_Browse:"Laat Val CSV Leer hier of klik om te gaan soek",
Holes_Loaded:"Gate Gelaai",
Error:"Fout",
UserName:"Gebruiker",

Add_Notification:"Nuwe Alarm",
Setup_Notifications:"Alarm Opstelling",
Add:"Stoor",
Title:"Titel",
Destination:"e-Pos",
Frequency:"Interval",
Daily:"Daagliks",
Weekdays:"Weeksdae",
Monthly:"Maandeliks",
Site_Month:"Plek Maand",
Site_Day:"Plek Dag",
TIZ_Month:"TIZ Maand",
Hole_Issues:"Gat Probleme",
Hole_Fill:"Gat Vlak",
Maintenance:"Onderhoud",
Export_CSV:"CSV",
Export_BlastX:"BlastX",

Setup_People:"Persone Opstelling",
Add_New_Person:"Nuwe Persoon",
Name:"Naam",
Enter_Name:"Nuwe Naam",
Type:"Tipe",

Setup_Products:"Produk Opstelling",
Product_Description:"Produk Beskrywing",
Add_New_Product:"Nuwe Produk",

Vehicle_Setup:"Voertuig Opstelling",
Last_Download:"Laaste Aflaai",
Hardware_Id:"Hardeware Id",
Pulses_Rev:"Pulse/Rev",
Pump_Service_Hours:"Pomp Onder Ure",
OTA_Version:"OTA Weergave",
Hose_Reel_Sensor:"Slang Sensor",
Installed:"Ja",
Snap_Positions:"Snap Posisies",
Yes:"Ja",
No:"Nee",
Active:"Aktief",
Inactive:"Nie Aktief",

Save:"Stoor",
Close:"Sluit",
PNG:"PNG",
Zone_Edit:"Zone Verander",
Loading_Zone:"Laai Zone",

Android_Applications:"Android Applikasies",
Android_Install_Application_Version:"Android Instaleer Applikasie Weergawe",
Android_Driver_Application_Version:"Android Operateur Applikasie Weergawe",

Sunday:"Sondag",
Monday:"Maandag",
Tuesday:"Dinsdag",
Wednesday:"Woensdag",
Thursday:"Donderdag",
Friday:"Vrydag",
Saturday:"Saterdag",

Sun:"Son",
Mon:"Maan",
Tue:"Dins",
Wed:"Woen",
Thur:"Don",
Fri:"Vry",
Sat:"Sat",

Jan:"Jan",
Feb:"Feb",
Mar:"Mar",
Apr:"Apr",
May:"Mei",
Jun:"Jun",
Jul:"Jul",
Aug:"Aug",
Sep:"Sep",
Oct:"Okt",
Nov:"Nov",
Dec:"Des",

Wet:"Nat",
Decked:"Decked",
ReDrill:"OorBoor",
Cracked:"Gekraak",
TopLoaded:"BoLaai",
Collapsed:"Ingeval",
CleanOut:"SkoonMaak",
OverBurden:"VolCrap",
Tacked:"Tacked",
OverLoaded:"OorLaai",
UnderLoaded:"Under Loaded",

Bad_Hose_Reel_Sensor:"Slang Sensor Probleem",
No_Communication:"Geen Kommunikasie",

Survey:"Survey", //As Surveyed
Survey_None:"None",
Survey_Depth_OK:"Depth OK",
Survey_Depth_Err:"Depth Err",
Survey_Loaded:"Loaded",
Survey_Stemming_OK:"Stemming OK",
Survey_Stemming_Err:"Stemming Err",

Survey_Loading     : "Loading",
Survey_PostDrill   : "Post Drill",
Survey_PreLoading  : "Pre Loading",
Survey_PostLoading : "Post Loading",
Survey_PreStemming : "Pre Stemming",

Depth_Tol:"Depth Tolerance(%)",
Stemming_Tol:"Stemming Tolerance(%)",

No_Data_Found : "No Data Found",

};

