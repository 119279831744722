import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';

class SetupTabletTable1 extends React.Component 
{   
  constructor(props) 
  {
    super(props);

    this.editRow = null;

    this.state = {
    };
  }

  componentDidMount() {
  }


  handleClickDelete = (uuid) =>{
    console.log("SetupTablets:handleClickDelete", uuid);
    this.props.handleDelete(uuid);
  }


  updateRow() {       
    console.log("SetupTablets:updateRow",this.editRow);
    this.props.handleUpdate(this.editRow.uuid, this.editRow.description, this.editRow.companyId, this.editRow.vehicleId );
  }

  afterSaveCell = (cellOld, cellNew, row, column) =>    
  {
    console.log('SetupTabletTable1:afterSaveCell: ' + column.dataField + ":" + cellOld + "->" + cellNew);

    if (cellOld!==cellNew){
      this.editRow = {...row};
       
      if (column.dataField==='description') {
        this.editRow.description=cellNew;
      } else if (column.dataField==='companyId') {
        this.editRow.companyId=cellNew;
      } else if (column.dataField==='vehicleId') {
        this.editRow.vehicleId=cellNew;
      }

      this.updateRow();
    }
  }


  render() 
  { 
    const { t } = this.props;

    var editAuth = false;
    var editRoot = false;
    if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_VEHICLES) > 0) editAuth=true;
    if ((this.props.userDetails.flags & AuthoriseObj.AUTH_ROOT) > 0) editRoot=true;
 
    const columns = [{
        dataField: 'timeStamp',
        text: t('Connected')+'('+this.props.userDetails.timeZone+')',
        editable: false,
        headerStyle: (colum, colIndex) => {
          return { width: '180px', textAlign: 'center' };
        },
        sort: true                
    }, {
        dataField: 'description',
        text: t('Description'),
        editable: editAuth,
        sort: true
     }, {
        dataField: 'companyId',
        text: t('Company'),
        editable: editRoot,
        hidden: editRoot?false:true,
        sort: true,
        formatter: (cell, row) => {
          return this.props.companies.find(x => x.value == cell).label;
        },
        editor: {
          type: Type.SELECT,
          options: this.props.companies
        },
      }, {
        dataField: 'vehicleId',
        text: t('Vehicle'),
        editable: editAuth,
        hidden:false,
        sort: true,
        formatter: (cell, row) => {
          return this.props.vehicles.find(x => x.value == cell)?this.props.vehicles.find(x => x.value == cell).label:null; //find returns undefined if not  found
        },
        editor: {
          type: Type.SELECT,
          options: this.props.vehicles
        },
    }, {
        dataField: 'versionName',
        text: t('Version'),
        editable: false,
        sort: true,
    }, {
        dataField: 'registerDateTime',
        text: t('Registered')+'('+this.props.userDetails.timeZone+')',
        editable: false,
        sort: true            
    }, {
        dataField: 'mode',
        text: t('Mode'),
        editable: false,
        hidden: false,
        sort: true
    }, {
        dataField: "delete",
        text: t('Delete'),
        editable: false,
        hidden : editAuth?false:true,
        formatter: (cellContent, row) => {
          return (
            <button
              className="btn btn-danger btn-xs"
              onClick={() => this.handleClickDelete(row.uuid)}
            >
               {t('Delete')}
            </button>
          );
        },
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
          console.log(`clicked on row with index: ${row.id} ${row.uuid}`);
        },
        onMouseEnter: (e, row, rowIndex) => {
        }
      };

    return  (            
      <div>
        <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Setup_Tablets')}</h3>

        <BootstrapTable 
           bootstrap4
           classes="table-responsive"
           keyField='uuid'    
           striped={true} 
           hover={true} 
           condensed={true} 
           data={ this.props.tablets } 
           columns={ columns } 
           rowEvents={ rowEvents } 
           headerClasses="thead-dark"
           pagination={ paginationFactory({sizePerPage:15})}
           cellEdit={ cellEditFactory({mode: 'click', blurToSave:true, afterSaveCell: this.afterSaveCell })}
       />

     </div>
   )
 }

}
export default withTranslation()(SetupTabletTable1);

