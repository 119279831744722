import React, { Component } from "react";
import SetupPeopleTable1 from './SetupPeopleTable.1';
import AuthoriseObj from './Authorise.js';
import Form from 'react-bootstrap/Form';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
 
class SetupPeople extends Component {
  constructor(props) {
    super(props);
    this.state = {         
        name      : '',
        errorName : false,
        people    :null,
    };
    
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleSubmit     = this.handleSubmit.bind(this);
    this.table1Element    = React.createRef();
  }

  componentDidMount() {
    console.log ("SetupPeople:componentDidMount");
    this.getPeople();
  }


  handleChangeName(event) {
    this.setState({name: event.target.value, errorName: false});
  }

  handleSubmit(event) {
    console.log("handleSubmit");
    event.preventDefault();

    if(this.state.name){
      fetch(process.env.REACT_APP_HOST_URL+'setupPeopleAddDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            name      : this.state.name,
            typeDescription    : 'Driver',
            stateDescription   : 'Active'
        })
      })
      .then(response => response.json())
      .then(data => {this.setState ({name:''});
                     this.getPeople();})
      .catch(error => this.setState({ error }));
    } else {
      this.setState({errorName: true}); 
    }
  }

  getPeople = () =>{
    fetch(process.env.REACT_APP_HOST_URL+'setupPeopleTable1DS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId
      })
    })
    .then(response => response.json())
    .then(data => this.setState({ people:data }))
    .catch(error => this.setState({ error }));
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <SetupPeopleTable1 ref={this.table1Element}            
            userDetails = {this.props.userDetails} 
            companyId   = {this.props.userDetails.companyId}
            people      = {this.state.people}
            getPeople   = {this.getPeople} />

        {((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_PEOPLE) > 0) && [
          <div>
            <h4> {t('Add_New_Person')} </h4>
            <Form key={3} onSubmit={this.handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 200 }}>
                  <TextField
                    required
                    type          = "text"
                    id            = "name"
                    label         = {t('Name')}
                    error         = {this.state.errorName}
                    onChange      = {this.handleChangeName}
                    value         = {this.state.name}
                    maxLength     = {64}
                  />
                </FormControl>
                <Button key={4} variant="contained" size="large" type="submit"> {t('Add')}</Button>
              </div>
            </Form>
          </div>
        ]}
      </div>
    );
  }
}

export default withTranslation()(SetupPeople);
