import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import AuthoriseObj from './Authorise.js';
import { Lightbox } from "react-modal-image";
import { withTranslation } from 'react-i18next';

class AccessoryTable extends React.Component 
{   
    constructor(props) {
      super(props);

      this.state = {
        stateEdit:false,
        image:null,
      };

      this.editRow = null;
    }

    componentDidMount() {       
    }

    getImage = (row) => {
      console.log ('AccessoryTable:getImage:',row);
      fetch(process.env.REACT_APP_HOST_URL+'commonGetImageDS.php',
      {
          method: 'POST',
          body: JSON.stringify(
          {
              userId        : this.props.userDetails.userId,
              companyId     : this.props.userDetails.companyId,
              imageId       : row.imageId
          })
      })
      .then(response => response.json())
      .then(data => this.setState({image:data}))
      .catch(error => this.setState({ iumage: null }));
    };

    handleImageModalClose = () => {
      console.log ('AccessoryTable:handleImageModalClose');
      this.setState({image:null });
    };

    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'id',
            hidden: true,
          }, {           
            dataField: 'deckNo',
            text: t('Deck'),
            editable: false,
            hidden: false,
            sort: true                
         }, {
            dataField: 'dateTime',
            text: t('DateTime'),
            editable: false,
            hidden: false,
            sort: true,
          }, {
            dataField: 'description',
            text: t('Description'),
            editable: false,
            hidden: false,
            sort: true,
          }, {
            dataField: 'type',
            text: t('Type'),
            editable: false,
            hidden: false,
            sort: true,
          }, {           
            dataField: 'quantity',
            text: t('Quantity'),
            editable: false,
            hidden: false,
            sort: false,
          }, {
            dataField: 'depth',
            text: t('Depth'),
            editable: false,
            hidden: false,
            sort: false,
          }, {           
            dataField: 'blasterName',
            text: t('Blaster'),
            editable: false,
            hidden: false,
            sort: true,
          }, {
            dataField: 'hasImage',
            text: t('Image'),
            editable: false,
            hidden: false,
            sort: true
          }, {           
            dataField: 'notes',
            text: t('Notes'),
            editable: false,
            hidden: false,
            sort: true
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              let getCurrentCellIndex = e.target.cellIndex;
              console.log ('AccessoryTable:rowEvents:getCurrentCellIndex=',getCurrentCellIndex);

              if (getCurrentCellIndex===6) {//Image
                if(row.imageId) this.getImage(row);
              }

            },
            onMouseEnter: (e, row, rowIndex) => {
              //console.log(`enter on row with index: ${row.year} ${row.month}`);
            }
        };
                      
        const selectRow = {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn:true,
          style: { backgroundColor: '#c8e6c9'}
        };

        const handleStateModalClose = () => {
          this.setState({stateEdit:false });
        };

        return  (            
          <div>
            {(this.props.accessories!=null) && [
              <BootstrapTable 
                key={12}
                bootstrap4
                classes="table-responsive"
                keyField='id'    
                selectRow={ selectRow }
                striped={true} 
                hover={true} 
                condensed={true} 
                data={ this.props.accessories } 
                columns={ columns } 
                rowEvents={ rowEvents } 
                headerClasses="thead-dark"            
              />]}

             {(this.state.image) && [
               <Lightbox
                 large={this.state.image}
                 medium={this.state.image}
                 onClose={this.handleImageModalClose}
               />
             ]}

           </div>
        )
    }
}

export default withTranslation()(AccessoryTable);
