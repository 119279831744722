import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';

class Report7Table0 extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {       
    }
    
    componentWillReceiveProps(nextProps){
    }

    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'vehicleId',
            text: 'VehicleId',
            hidden:true
          }, {     
            dataField: 'vehicleDescription',
            text: t('Vehicle'),
            hidden:false
          }, {     
            dataField: 'totalHours',
            text: t('Total_Hours'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'lastServiceDateLocal',
            text: t('Last_Service'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'sinceHours',
            text: t('Hours_Since_Service'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'remainingHours',
            text: t('Remaining_Hours'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'daysToService',
            text: t('Days_to_Service'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'noServices',
            text: t('No_Services'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'avgHoursDay',
            text: t('Avg_Hours_Day'),
            editable: false,
            hidden:false,
            sort: true                
        }]

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.vehicleId} ${row.vehicleDescription} ${row.remainingHours} ${row.avgHoursDay} ${row.daysToService}`);
              this.props.handleVehicleSelect(row);  
            },
        };
                      
        const selectRow = {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn:true,
        };

        const rowStyle = (row, rowIndex) => {
          if (row.daysToService) {
            if ((row.daysToService<=0) || (row.daysToService==='Over Due')) 
            {
              row.daysToService = t('Over_Due');
              return { backgroundColor: 'tomato' };
            }
            else if (row.daysToService<15) return { backgroundColor: 'orange' };
            else return{};
          }
          return {};
        };

        return  (            
            <div>
                {(this.props.data!=null) && [
                <BootstrapTable 
                    key={1}
                    bootstrap4
                    classes="table-responsive-sm"
                    keyField='vehicleId'    
		                selectRow={ selectRow }
                    striped={true} 
                    hover={true} 
                    condensed={true} 
                    data={ this.props.data } 
                    columns={ columns } 
                    rowEvents={ rowEvents } 
                    rowStyle={ rowStyle }
   		              pagination={ paginationFactory()}
                    headerClasses="thead-dark"            
                />]}
             </div>
        )
    }
}

export default withTranslation()(Report7Table0);
