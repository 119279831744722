import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AuthoriseObj from './Authorise.js'
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { withTranslation } from 'react-i18next';

class DesignDeckTable extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.editRow = null;
        this.editDeckNo = null;

        this.state = {
        };
    }

    componentDidMount() {       
      console.log('DesignDeckTable : componentDidMount',this.props.designData, this.props.stemming);
    }
    

    afterSaveCell = (cellOld, cellNew, row, column) =>
    {
        console.log('DesignDeckTable:afterSaveCell: ' + column.dataField + ":" + cellOld +":" + cellNew);
        //console.log ('DesignDeckTable:afterSaveCell: ', row);

        this.editRow = {...row}; //force copy else we have pointer to row

        if (column.dataField==='columnWeight') {
          this.editRow.columnWeight=cellNew;
        } else if (column.dataField==='columnHeight') {
          this.editRow.columnHeight=cellNew;
        } else if (column.dataField==='productId') {
          this.editRow.productId=cellNew;
        } else if (column.dataField==='deckingHeight') {
          this.editRow.stemmingHeight=cellNew;
        } else if (column.dataField==='stemmingId') {
          this.editRow.stemmingId=cellNew;
        } else if (column.dataField==='density') {
          this.editRow.density=cellNew;
        } else if (column.dataField==='startDepth') {
          this.editRow.startDepth=cellNew;
        }

        this.updateDesign(row);
    }


    updateDesign = (row) => {
        console.log (this.props);
        fetch(process.env.REACT_APP_HOST_URL+'commonDesignUpdateDS.php',
            {
                method: 'POST',
                body: JSON.stringify(
                {
                    userId         : this.props.userDetails.userId,
                    companyId      : this.props.userDetails.companyId,
                    siteId         : this.props.designData.siteId,
                    patternNo      : this.props.designData.patternNo,
                    holeNo         : this.props.designData.holeNo,
                    deckNo         : row.deckNo,
                    columnWeight   : this.editRow.columnWeight,
                    columnHeight   : this.editRow.columnHeight,
                    stemmingHeight : this.editRow.stemmingHeight,
                    productId      : this.editRow.productId,
                    stemmingId     : this.editRow.stemmingId,
                    density        : this.editRow.density,
                    startDepth     : this.editRow.startDepth,
                })
            })
          .then(response => response.json())
          .then(data => this.props.getCompanyInfos())
          .catch(error => this.setState({ error }));
    }

    deleteDeck=(row) => {
      console.log('DesignDeckTable:deleteDeck ' , row);

      fetch(process.env.REACT_APP_HOST_URL+'designDeckDeleteDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            deckId    : row.id
        })
      })
      .then(response => response.json())
      .then(data => {this.props.refreshData();})
      .catch(error => this.setState({ error}));
    }

    handleDelete = (row) => {
      console.log ('DesignDeckTable:handleDelete', row);
      this.deleteDeck(row);
    }

    DeleteButton = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Stack direction="row" spacing={0}>
          <IconButton sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
            aria-label="Delete"
            size="small"
            onClick={() => {
              this.handleDelete(row)
            }}
            disabled={false}
            color="primary">
            <DeleteIcon />
          </IconButton>
        </Stack>
      );
    };



    render() 
    { 
        const { t } = this.props;

        var editAuth = false;
        if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_PATTERN) > 0) editAuth=true;
        editAuth = true;

        const columns = [{
            dataField: 'deckNo',
            text: t('DeckNo'),
          }, {           
            dataField: 'startDepth',
            text: t('Start_Depth'),
            editable: editAuth,
            sort: true
          }, {           
            dataField: 'columnWeight',
            text: t('Column_Weight'),
            editable: editAuth,
            sort: true
          }, {           
            dataField: 'columnHeight',
            text: t('Column_Height'),
            editable: editAuth,
            sort: true
          }, {     
            dataField: 'productId',
            text: t('ProductType'),
            editable: editAuth,
            sort: true ,
            formatter: (cell, row) => {
              var product = null;
              if(cell!==null) product =this.props.products.find(x => x.value === cell);
              if(product!=null) return product.label; //find returns unefined not null, so check properly
              else return row.productType;
            },
            editor: {
              type: Type.SELECT,
              options: this.props.products
            },

          }, {     
            dataField: 'density',
            text: t('Density'),
            editable: editAuth,
            sort: true                
          }, {     
            dataField: 'deckingHeight',
            text: t('Decking_Height'),
            editable: editAuth,
            sort: true                
          }, {     
            dataField: 'stemmingId',
            text: t('Decking_Type'),
            editable: false,
            sort: editAuth ,
            formatter: (cell, row) => {
              var product = null;
              if(cell!==null) product=this.props.stemming.find(x => x.value === cell);
              if(product!=null) return product.label;
              else return row.deckingType;
            },
            editor: {
              type: Type.SELECT,
              options: this.props.stemming
            },
         }, {
            dataField: "delete",
            text: "Action",
            formatter: this.DeleteButton,
            sort: true,
            editable: false,
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${this.props.userDetails.userId} ${this.props.userDetails.companyId} ${row.deckNo}`);
            },
            onMouseEnter: (e, row, rowIndex) => {
            }
        };
                      
        const selectRow = {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn:true,
          style: { backgroundColor: '#c8e6c9'}
        };

        return  (            
            <div>
                {(this.props.designData.decks!=null) && [
                <h6 key={1} style={{ textAlign: 'center', color: 'black' }}>{t('Decks')}</h6>,
                <BootstrapTable 
                    key={2}
                    bootstrap4
                    classes="table-responsive"
                    keyField='deckNo'    
                    striped={true} 
                    hover={true} 
                    condensed={true} 
                    data={ this.props.designData.decks } 
                    columns={ columns } 
                    rowEvents={ rowEvents } 
                    headerClasses="thead-dark"            
                    cellEdit={ cellEditFactory({mode: 'click',
                      blurToSave:true,
                      afterSaveCell: this.afterSaveCell,
                      onStartEdit: (row) => {this.editDeckNo=row.deckNo;return(false); }})}
                />]}
             </div>
        )
    }
}

export default withTranslation()(DesignDeckTable);
