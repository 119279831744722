import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import InfoWindow from './MapSurveyInfoWindow';

class MapHoleStateMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      selectedSurveyInfo: null,
    };
  }


  handleClick = (event) => {
    console.log("MapHoleStateMarker:handleClick:",this.props.surveyInfo);
    //this.props.handleMarkerClick(this.props.surveyInfo);
  }


  render() {
    let backgroundColor = 'lightgrey' ;
    let textColor = 'black' ;
    let border = '1px solid black' ;
    let radius=20;

    let surveyState = this.props.surveyInfo.surveyState;
    let holeState   = this.props.surveyInfo.holeState

    if (this.props.layerOptions==0) {
      if (holeState==0) {
        textColor = 'white'; backgroundColor = 'green'; }
      else {
        textColor = 'white'; backgroundColor = 'orange'; border='1px solid black';
      }
    } else {
        textColor = 'black'; backgroundColor = 'lightgrey'; border='1px solid black';
    }

    if(this.props.layerOptions){
      holeState = holeState & this.props.layerOptions;
      if (holeState > 0) { //Correct state bits set
        textColor = 'white'; backgroundColor = 'red'; 
      } 

      if ((this.props.layerOptions&0x01) > 0){ //Wet
        if (this.props.surveyInfo.waterDepthCm!=null) {
          if (this.props.surveyInfo.waterDepthCm > 0){ 
            textColor = 'white'; backgroundColor = 'red'; 
            let persentage=(this.props.surveyInfo.waterDepthCm/this.props.surveyInfo.designStartDepthCm)*100;
            radius = 20 + persentage; //Usually holes have way less than 50% water, so we need to adjust so it looks ok
          } else if (holeState==0){
            textColor = 'white'; backgroundColor = 'green';
          }
        }
      }
      else if ((this.props.layerOptions&0x2000) > 0) { //Hot
        if (this.props.surveyInfo.temperatureC!=null) {
          if (this.props.surveyInfo.temperatureC > 50){
            textColor = 'white'; backgroundColor = 'red';
            let persentage=((this.props.surveyInfo.temperatureC-50)/70.0)*100; //120 Degrees C MAx
            radius = 20 + persentage; //Usually holes have way less than 50% water, so we need to adjust so it looks ok
          } else if (holeState==0){
            textColor = 'white'; backgroundColor = 'green';
          }
        }
      }
    }

    if (radius>100) radius=100;

    const markerStyle = {
      border: (this.props.$hover||this.props.selected)?'3px solid fuchsia':border, 
      borderRadius: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: (this.props.$hover||this.props.selected)?radius+6:radius, 
      width:  (this.props.$hover||this.props.selected)?radius+6:radius,
      backgroundColor: (this.props.$hover||this.props.selected)?'fuchsia':backgroundColor,
      cursor: 'pointer',
      zIndex: radius,
      opacity: 1.0 ,
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const textStyle = {
      color: (this.props.$hover||this.props.selected)?'white':textColor,
      fontSize: (this.props.$hover||this.props.selected) ? 12:11,
      fontWeight:( this.props.$hover||this.props.selected) ? 'bold':'normal',
    };

    return (
      <div>
        <div style={markerStyle}  onClick={this.handleClick} > <span style={textStyle}>{this.props.surveyInfo.holeNo}</span></div>
        {((this.props.$hover && this.props.markerClickInfo === null) ||  (this.props.showInfoWindow)) && //hover but not clicked OR Clicked
         <InfoWindow
            hole              = {this.props.surveyInfo}
            userDetails       = {this.props.userDetails}
            refreshData       = {this.props.refreshData}
            handleMarkerClear = {this.props.handleMarkerClear}
            handleClick       = {this.handleClick}
            loadAddEnabled    = {false}
            markerClickInfo   = {this.props.markerClickInfo} />}
      </div>
    );
  }
}

export default MapHoleStateMarker;

