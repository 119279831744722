import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';

class Table1 extends React.Component 
{   
    constructor(props) 
    {
      super(props);
    
      this.state = {
      };
    }

    componentDidUpdate() {
      console.log ("Report5Table1:componentDidUpdate");
    }
    

    componentDidMount() {
      console.log ("Report5Table1:componentDidMount");
    }

    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'driverId',
            text: 'Driver Id',
            hidden: true          
        }, {
            dataField: 'driverName',
            text: t('Driver'),
            sort: true          
        }, {
            dataField: 'zoneDescription',
            text: t('Zone'),
            sort: true          
	      }, {
            dataField: 'noDays',
            text: t('No_Days'),
            sort: true
        }, {
            dataField: 'duration',
            text: t('Duration'),
            sort: true,
   	        sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/:/g, '');
              var y = b.replace(/:/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }];

        const rowEvents = {
           onClick: (e, row, rowIndex) => {
             console.log(`Report5Table1:Clicked on row with index: ${row.driverId} ${row.driverName}`);
             this.props.handleDriverSelect(row.driverId, row.driverName);
           },
           onMouseEnter: (e, row, rowIndex) => {
             console.log(`Report5Table1:Enter on row with index: ${row.no} ${rowIndex}`);
           },
           onMouseLeave: (e, row, rowIndex) => {
             console.log(`Report5Table1:Leave on row with index: ${row.no} ${rowIndex}`);
           }
        };
        
        const MyExportCSV = (props) => {
          const handleClick = () => {
            props.onExport();
          };
          return (
            <div>
              <button className="btn btn-success" onClick={ handleClick }>{t('Export_CSV')}</button>
            </div>
          );
        };

        return  (
        <div>
            <ToolkitProvider 
                keyField='driverId'
                data={ this.props.data } 
                columns={ columns }                 
                exportCSV
            >
            {
              props => (
                <div>        
                  <BootstrapTable { ...props.baseProps }
                  bootstrap4
                  classes="table-responsive-sm"
                  exportCSV
                  keyField='eventId'                
                  striped={true} 
                  hover={true} 
                  condensed={true}          
                  pagination={ paginationFactory({sizePerPage:10})} 
                  rowEvents={ rowEvents } 
                  headerClasses="thead-dark" />
                  <MyExportCSV { ...props.csvProps }>Export</MyExportCSV>
                </div>
              )
            }</ToolkitProvider>
        </div>)
    }
}

export default withTranslation()(Table1);
