import React, { Component } from "react";
import ReportUnkownHoleTable from './ReportUnkownHoleTable.js';
import AuthoriseObj from './Authorise.js'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
 
class ReportUnkownHoles extends Component {
  constructor(props) {
    super(props);
    this.state = {         
      unkownHoles : null,
      patternNoList :[],
    };
  }


  componentDidMount() {
    console.log ("ReportUnkownHoles:componentDidMount");
    this.getUnknownHoles();
    this.getPatternNoList();
  }


  updateHole = (hole) =>{
    console.log("ReportUnkownHoles:updateHole:",hole);

    fetch(process.env.REACT_APP_HOST_URL+'unkownHolesUpdateDS.php',
    {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            holeId    : hole.holeId,
            siteId    : hole.siteId,
            patternNo : hole.patternNo,
            holeNo    : hole.holeNo,
        })
    })
    .then(response => response.json())
    .then(data => {
      console.log ("ReportUnkownHoles:updateHole:OK");
      this.getUnknownHoles();
    })
    .catch(error => this.setState({ error, isLoading: true }));
  }


  getUnknownHoles = () =>{
    console.log("ReportUnkownHoles:getUnknownHoles");

    fetch(process.env.REACT_APP_HOST_URL+'unkownHolesGetDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log('ReportUnkownHoles:getUnknownHoles', data);
      this.setState({ unkownHoles:data });
    })
    .catch(error => this.setState({ unkownHoles:null }));
  }


  getPatternNoList = () =>{
    console.log("ReportUnkownHoles:getPatternNoList");

    fetch(process.env.REACT_APP_HOST_URL+'commonPatternListGetDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log('ReportUnkownHoles:getPatternNoList', data);
      this.setState({ patternNoList:data });
    })
    .catch(error => this.setState({ patternNoList:[] }));
  }



  render() {
    const { t } = this.props;

    return (
      <div>
        {(this.state.unkownHoles != null) && [
          <ReportUnkownHoleTable            
            userDetails   = {this.props.userDetails} 
            companyId     = {this.props.userDetails.companyId}
            unkownHoles   = {this.state.unkownHoles}
            updateHole    = {this.updateHole}
            patternNoList = {this.state.patternNoList} />
        ]}
      </div>
    );
  }
}

export default withTranslation()(ReportUnkownHoles);

