import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';

class ReportPatternLoadSummaryTable extends React.Component 
{   
    constructor(props) 
    {
        super(props);
       
        this.state = {
          error: null,
        };
    }

    componentDidMount() {
     //   this.getData(this.props);
    }


    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'patternNo',
            text: t('Pattern'),
            sort: true             
        }, {
            dataField: 'startDate',
            text: t('Start_Date'),
            sort: true
        }, {
            dataField: 'endDate',
            text: t('End_Date'),
            sort: true
        }, {
            dataField: 'noDays',
            text: t('No_Days') ,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
        }, {
            dataField: 'noVehicles',
            text: t('No_Vehicles') ,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
        }, {
            dataField: 'designWeight',
            text: t('Design')+'('+this.props.userDetails.massMUnit+')',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
         }, {
            dataField: 'loadedWeight',
            text: t('Loaded')+'('+this.props.userDetails.massMUnit+')',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
         }, {
            dataField: 'noDesignHoles',
            text: t('noDesign') ,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
        }, {
            dataField: 'noLoadedHoles',
            text: t('noLoaded') ,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
        }, {
            dataField: 'noHolesOverFilled',
            text: t('OverLoaded') ,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
         }, {
            dataField: 'noHolesUnderFilled',
            text: t('UnderLoaded') ,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
         }, {
            dataField: 'weightPerTruckDay',
            text: t('Weight_Truck_Day') ,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
         }, {
            dataField: 'noHolesPerTruckDay',
            text: t('Holes_Truck_Day') ,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.date} ${row.vehicleId} ${row.patternNo}`);
              this.props.onRowClick(row.date, row.vehicleId, row.patternNo);
            },
            onMouseEnter: (e, row, rowIndex) => {
              //console.log(`enter on row with index: ${row.year} ${row.month}`);
            }
          };
         
        return  (
         <div>
         <h3 key={1023} style={{borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black'}}>{t('Pattern_Load_Summary')}</h3>
            <BootstrapTable
                bootstrap4
                classes="table-responsive"
                keyField='patternNo'
                striped={true}
                condensed={true}
                data={ this.props.summaryData.summaries }
                columns={ columns }
                rowEvents={ rowEvents }
		            pagination={ paginationFactory()}
                headerClasses="thead-dark"
            />
        </div>)
    }
}

export default withTranslation()(ReportPatternLoadSummaryTable);
