import Chart from "react-google-charts";
import React from "react";
import { withTranslation } from 'react-i18next';

class Chart0 extends React.Component 
{   
    constructor(props) 
    {
        super(props);
    
        this.state = {
          data: null,
          error: null,
          isLoading: true
        };
    }

      getData = (props) =>{
      this.setState({isLoading: true });
      fetch(process.env.REACT_APP_HOST_URL+'report5Chart0DS.php',
          {
              method: 'POST',                
              body: JSON.stringify(
              {
                companyId : props.userDetails.companyId,
                userId    : props.userDetails.userId,
                siteId    : props.siteId,
                month     : props.monthYear.month,
                year      : props.monthYear.year
              })
          })
        .then(response => response.json())
        .then(data => this.setState({ data, isLoading: false }))
        .catch(error => this.setState({ error, isLoading: true }));
    }

    componentDidMount() {
      this.getData(this.props)
    }

    componentWillReceiveProps(nextProps){        
      if((nextProps.siteId!==this.props.siteId) || (nextProps.year!==this.props.year))
      {            
        this.getData(nextProps);
      }
    }

    render() 
    { 
        const { t } = this.props;

        if (this.state.isLoading)
        {
            return (<p>{t('Loading')}</p>);
        }
       
        return  (
            <Chart
                height={'300'}
                chartType="PieChart"
                loader={<div>{t('Loading')}</div>}
                data={this.state.data}
                chartEvents={[
                {
                  eventName: "select",
                    callback: ({ chartWrapper, google }) => {
                      var selection = chartWrapper.getChart().getSelection();
                      var row = selection[0].row;
                      var zoneId=this.state.data.rows[row].c[2];
                      var zoneDescription=this.state.data.rows[row].c[3];
                      console.log("Report5Chart0:onClick zoneId", zoneId, zoneDescription);
                      this.props.handleZoneSelect(zoneId, zoneDescription);
                    }
                }
                ]}
                options={{
	            	    //legend: { position: "top", maxLines: 3 },
                    chartArea: { width: '500'}
                }}
                rootProps={{ 'data-testid': '1' }}
          />
        )
    }
}

export default withTranslation()(Chart0);
