import React, { Component } from "react";
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import moment from "moment"
import {Element, scroller} from 'react-scroll';
import AuthoriseObj from './Authorise.js'
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

import TableRecentImports from './SetupImportPatternTable.1';
import ImportPatternMap from './ImportPatternMap';
import { CSVReader } from 'react-papaparse';

import {decodeType1} from "./SetupImportDecodeType.1";
import {decodeType2} from "./SetupImportDecodeType.2";
import {decodeType3} from "./SetupImportDecodeType.3";

import Proj4 from 'proj4';
import { withTranslation } from 'react-i18next';

class SetupImportPattern extends Component {

    constructor(props) {
        super(props);
 
        this.state = {
          importInfos    : null,
          holeInfos      : [],
          selectedImport : null,
          fileName       : null,
          fileHoleInfos  : [],
          importErrors   : 0,
        };
    }


    componentDidMount() {       
      this.getRecentImports();
    }


    getRecentImports = () =>{
      fetch(process.env.REACT_APP_HOST_URL+'setupImportPatternGetRecentDS.php',
      {
          method: 'POST',
          body: JSON.stringify(
          {
              userId    : this.props.userDetails.userId,
              companyId : this.props.userDetails.companyId
          })
      })
      .then(response => response.json())
      .then(data => this.setState({ importInfos:data }))
      .catch(error => this.setState({ importInfos:null }));
    }


    getHoleInfos = (importId) =>{
      this.setState({holeInfos:[]});

      fetch(process.env.REACT_APP_HOST_URL+'setupImportPatternGetHoleInfosDS.php',
      {
          method: 'POST',
          body: JSON.stringify(
          {
              userId    : this.props.userDetails.userId,
              companyId : this.props.userDetails.companyId,
              importId  : importId
          })
      })
      .then(response => response.json())
      .then(data => this.setState({ holeInfos:data }))
      .catch(error => this.setState({ holeInfos:[] }));
    }

    handleImportSelect = (row) => {
      console.log("SetupImportPattern:handleImportSelect", row.id);
      this.setState({selectedImport:row} );

      this.getHoleInfos(row.id);

      scroller.scrollTo('scrolltable1', {
        duration: 500,
        offset: 0,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }


    handleAdd = () => {
      console.log('SetupImportPattern:handleAdd:', this.state.fileName, this.state.fileHoleInfos, this.state.selectedImport);

      fetch(process.env.REACT_APP_HOST_URL+'setupImportPatternAddDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId,
          fileName  : this.state.fileName,
          holeInfos : this.state.fileHoleInfos
        })
      })
      .then(response => response.json())
      .then(data => {this.setState({ fileHoleInfos:[], holeInfos:[] }); this.getRecentImports() })
    }


    handleDelete = () => {
      console.log('SetupImportPattern:handleDelete:' , this.state.selectedImport);

      fetch(process.env.REACT_APP_HOST_URL+'setupImportPatternDeleteDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId,
          id        : this.state.selectedImport.id
        })
      })
      .then(response => response.json())
      .then(data => {this.setState({ selectedImport:null, holeInfos:[] }); this.getRecentImports() })
    }

    handleOnDrop = (data,file) => {
      console.log(data, file);

      let fileHoleInfos=[];
      let importErrors = 0;

      console.log('SetupImportPattern:handleOnDrop');

      for (let i=0; i<data.length; i++) {
        if (data[i].data.length >= 2) {
          let holeInfo =new Object();

          if( (data[i].data[0]) ){
            holeInfo['id']   = i;
            holeInfo['type'] = data[i].data[0];
            console.log('SetupImportPattern:handleOnDrop',holeInfo);

            let error = false;
            if (holeInfo['type']==1){
              error = decodeType1( data[i].data, holeInfo);
            } else if (holeInfo['type']==2) {
              error = decodeType2( data[i].data, holeInfo);
            } else if (holeInfo['type']==3) {
              error = decodeType3( data[i].data, holeInfo);
            } else {
              error=true;
            }  

            if (!error) {
              fileHoleInfos.push(holeInfo);
            } else importErrors++;
          } else importErrors++;
        } else importErrors++;
      }

      console.log ('SetupImportPattern:handleOnDrop',fileHoleInfos);
      this.setState({fileHoleInfos:fileHoleInfos, fileName:file.name, importErrors:importErrors, selectedImport:null});
      this.setState({holeInfos:fileHoleInfos}); //Display on map
    }
 
    handleOnError = (err, file, inputElem, reason) => {
      console.log(err)
    }
 
    handleOnRemoveFile = (data) => {
      this.setState({fileHoleInfos:[], fileName:null, importErrors:0});
      this.setState({holeInfos:[]});
    }

    
    render() {       
      const { t } = this.props;

      const progressContainerStyle = {
        margin : '0 auto',
        width: '250px',
      };

      return (
        <div>
          <h3 style={{textAlign: 'center', color: 'black'}}>{t('Import_Pattern')}</h3>

          {(this.state.importInfos!=null)&& [
            <div key={1}>
              <TableRecentImports
                 userDetails={this.props.userDetails}
                 handleImportSelect={this.handleImportSelect}
                 importInfos = {this.state.importInfos}/>
            </div>
          ]}

          {(((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_IMPORT_PATTERN) > 0) && (this.state.selectedImport!=null)) && [
            <div>
              <Button key={2} onClick={this.handleDelete} variant="primary">{t('Delete')}</Button>
            </div>
          ]}

          {((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_NOTIFICATIONS) > 0) && [
            <div>
              <h4> {t('Import_New_Pattern')} </h4>

              <CSVReader
                onDrop={this.handleOnDrop}
                onError={this.handleOnError}
                addRemoveButton
                onRemoveFile={this.handleOnRemoveFile}>
                  <span>{t('Drop_CSV_file_here_or_click_to_Browse')}</span>
              </CSVReader> 
              <div> 
                {((this.state.fileHoleInfos.length > 0) || (this.state.importErrors > 0)) ? t('Holes_Loaded')+':'+this.state.fileHoleInfos.length+' ' + t('Error')+':'+this.state.importErrors:null}
              </div>

              <Button key={4} variant="primary" type="submit" onClick={this.handleAdd} disabled={(((this.state.selectedImport!=null)||this.state.fileHoleInfos.length==0))?true:false}>{t('Add')} </Button>
            </div>
          ]}

          {(this.state.holeInfos.length > 0) && [
            <ImportPatternMap
               userDetails    = {this.props.userDetails}
               selectedHoleId = {0}
               holeInfos      = {this.state.holeInfos} />
          ]}
        
        </div>     
    );
  }
}

export default withTranslation()(SetupImportPattern);
