import Chart from "react-google-charts";
import React from "react";
import { withTranslation } from 'react-i18next';

class Chart2 extends React.Component 
{   
    constructor(props) 
    {
        super(props);
    
        this.state = {
          data: null,
          error: null,
          isLoading: true
        };
    }

      getData = (props) =>{
      this.setState({isLoading: true });
      fetch(process.env.REACT_APP_HOST_URL+'report1Chart2DS.php',
          {
              method: 'POST',                
              body: JSON.stringify(
              {
                companyId : props.userDetails.companyId,
                userId    : props.userDetails.userId,
                siteId    : props.siteId,
                year      : props.year                  
              })
          })
        .then(response => response.json())
        .then(data => this.setState({ data, isLoading: false }))
        .catch(error => this.setState({ error, isLoading: true }));
    }

    componentDidMount() {
      this.getData(this.props)
    }

    componentWillReceiveProps(nextProps){        
      if((nextProps.siteId!==this.props.siteId) || (nextProps.year!==this.props.year))
      {            
          this.getData(nextProps);
      }
    }


    render() 
    { 
        const { t } = this.props;

        if (this.state.isLoading)
        {
            return (<p>{t('Loading')}</p>);
        }
   
        return  (
          <div>
          {(this.state.data.length >1) ? 
            <Chart
                width={'1000'}            
                chartType="ColumnChart"
                loader={<div>{t('Loading')}</div>}
                data={this.state.data}
                chartEvents={[
                {
                    eventName: "select",
                    callback: ({ chartWrapper, google }) => {
                      const chart = chartWrapper.getChart();
                      const t = chart.getSelection();
                      console.log(t);
                    }
                  }
                ]}

                options={{
	      	    legend: { position: "top", maxLines: 3 },
                    chartArea: { width: 1000},
                    isStacked:true,
                }}
                rootProps={{ 'data-testid': '1' }}
            />
          : <div>No Data</div>}
          </div>
        )
        
    }
}
 
export default withTranslation()(Chart2);
