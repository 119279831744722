
const AuthoriseObject = {
 AUTH_APPROVE_PATTERN           : 2**0,
 AUTH_EDIT_PATTERN              : 2**1,
 AUTH_MOVE_HOLES                : 2**2,
 AUTH_EDIT_SETUP_VEHICLES       : 2**3,

 AUTH_EDIT_SETUP_PEOPLE         : 2**4,
 AUTH_EDIT_SETUP_ZONES          : 2**5,
 AUTH_EDIT_SETUP_PRODUCT        : 2**6,
 AUTH_EDIT_SETUP_NOTIFICATIONS  : 2**7,

 AUTH_EDIT_REPORT_MAINTENANCE   : 2**8,
 AUTH_ROOT                      : 2**9,

 AUTH_SEE_SETUP_VEHICLES        : 2**10,
 AUTH_SEE_SETUP_PEOPLE          : 2**11,
 AUTH_SEE_SETUP_ZONES           : 2**12,
 AUTH_SEE_SETUP_PRODUCT         : 2**13,
 AUTH_SEE_SETUP_NOTIFICATIONS   : 2**14,
 AUTH_SEE_SETUP_USERS           : 2**15,

 AUTH_SEE_REPORT_TIZ            : 2**16,
 AUTH_SEE_REPORT_SPEEDING       : 2**17,
 AUTH_SEE_REPORT_MAINTENANCE    : 2**18,

 AUTH_SEE_SETUP_IMPORT_PATTERN  : 2**19,
 AUTH_EDIT_SETUP_IMPORT_PATTERN : 2**20,

 AUTH_EDIT_PATTERN_EXPORT_CSV   : 2**21,
 AUTH_EDIT_PATTERN_EXPORT_BLASTX: 2**22,

 AUTH_SEE_ADMIN_COMPANIES       : 2**23,
 AUTH_EDIT_ADMIN_COMPANIES      : 2**23,

 AUTH_SEE_ADMIN_USERS           : 2**24,
 AUTH_EDIT_ADMIN_USERS          : 2**24,

 AUTH_SEE_MONETARY              : 2**25,
 AUTH_EDIT_MONETARY             : 2**26,

 AUTH_SEE_REPORT_UNKOWN_HOLES   : 2**27,

 AUTH_SEE_KPI                   : 2**28,
 AUTH_SEE_VEHICLE_ISSUES        : 2**29,
 AUTH_SEE_PATTERN_DAILY_SUMMARY : 2**30,

 AUTH_SEE_MAP_LAYERS            : 2**30, //Ran out of bits, seems we can only use 31 bits, not signed bit, hacked for now
 AUTH_SEE_DOWNLOADS             : 2**30, //Ran out of bits, seems we can only use 31 bits, not signed bit, hacked for now
};

export default AuthoriseObject

