import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import { withTranslation } from 'react-i18next';

class Report6TableEvents extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.editRow = null;

        this.state = {
          error: null,
        };
    }

    componentDidMount() {       
      //console.log('Report6TableSpeedingEvents : componentDidMount');
    }
    
    componentWillReceiveProps(nextProps){
      //console.log('Report6TableSpeedingEvents : componentWillReceiveProps');
    }

    afterSaveCell = (cellOld, cellNew, row, column) =>
    {
        console.log('Report6TableSpeedingEvents:Editing: ' + column.dataField + ":" + cellOld +":" + cellNew);
        console.log (row);

        this.editRow = row;

        if (column.dataField==='driverName')
        {
          this.editRow.driverName=cellNew;
        }

         this.updateEvent();
    }


    updateEvent() {
      fetch(process.env.REACT_APP_HOST_URL+'report6UpdateEventDS.php',
          {
              method: 'POST',
              body: JSON.stringify(
              {
                  userId     : this.props.userDetails.userId,
                  companyId  : this.props.userDetails.companyId,
                  eventId    : this.editRow.id,
                  driverName : this.editRow.driverName,
                  startDate  : this.props.startDate,
                  endDate    : this.props.endDate,
                  year       : this.props.year,
                  month      : this.props.month
              })
          })
          .then(response => response.json())
          .then(data => this.props.onUpdateEventInfos(data))
          .catch(error => this.setState({ error, isLoading: true }));

          this.props.onUpdateDriver();
    }

    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'id',
            text: 'id',
            hidden:true
          }, {     
            dataField: 'eventDateTimeLocal',
            text: t('Date')+'('+this.props.userDetails.timeZone+')',
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'driverName',
            text: t('Driver'),
            editable: true,
            sort: true,
            hidden:false,
            editor: {
                type: Type.SELECT,
                options: this.props.drivers
            }
          }, {     
            dataField: 'vehicleDescription',
            text: t('Vehicle'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'speedLimit',
            text: t('Speed_Limit')+'('+this.props.userDetails.speedUnit+')',
            editable: false,
            hidden:false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
          }, {     
            dataField: 'maxSpeed',
            text: t('Max_Speed')+'('+this.props.userDetails.speedUnit+')',
            editable: false,
            hidden:false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
          }, {     
            dataField: 'avgSpeed',
            text: t('Avg_Speed')+'('+this.props.userDetails.speedUnit+')',
            editable: false,
            hidden:false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
          }, {     
            dataField: 'duration',
            text: t('Duration'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'zoneDescription',
            text: t('Zone'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'address',
            text: t('Address'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'severity',
            text: t('Severity'),
            editable: false,
            hidden:false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`Report6Table1:Clicked on row with index: ${row.eventStartDateTimeLocal} `);
            },
            onMouseEnter: (e, row, rowIndex) => {
              this.props.onUpdateEventSelect(row.id);
              console.log(`Report6Table:Enter on row with index: ${row.id} ${rowIndex}`);
            },
            onMouseLeave: (e, row, rowIndex) => {
              this.props.onUpdateEventSelect(0);
              console.log(`Report6Table:Leave on row with index: ${row.id} ${rowIndex}`);
            }
        };
 
        return  (            
            <div>
                {(this.props.eventInfos!=null) && [
                <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Speeding_Events')} : {this.props.driverName}</h3>,
                <BootstrapTable 
                    bootstrap4
                    classes="table-responsive-sm"
                    keyField='id'    
                    striped={true} 
                    hover={true} 
                    condensed={true} 
                    data={ this.props.eventInfos } 
                    columns={ columns } 
                    rowEvents={ rowEvents } 
   		              pagination={ paginationFactory()}
                    headerClasses="thead-dark"            
                    cellEdit={ cellEditFactory({mode: 'click' , blurToSave:true, afterSaveCell: this.afterSaveCell })}
                />]}
             </div>
        )
    }
}

export default withTranslation()(Report6TableEvents);
