import React from "react";
import { Storage } from 'aws-amplify';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { withTranslation } from 'react-i18next';

class TableMedia extends React.Component 
{   
    constructor(props) 
    {
        super(props);
       
        this.editDescription=null;
        this.editMediaID=null;

        this.state = {
          error: null,
        };
    }

    componentDidUpdate() {
        console.log(`Report4TableMedia : componentDidUpdate`);
    }
    
    componentWillReceiveProps(nextProps){
        console.log(`Report4TableMedia : componentWillReceiveProps`);
    }  

    componentDidMount() {
    }


    afterSaveCell = (cellOld, cellNew, row, column) =>
    {
        console.log('Editing: ' + column.dataField + ":" + cellOld +":" + cellNew);
        console.log (row);

        if (column.dataField==='description')
        {
            this.editDescription=cellNew;
        }

        this.updateMedia();
    }

    updateMedia() {
      fetch(process.env.REACT_APP_HOST_URL+'mediaUpdateDS.php',
          {
              method: 'POST',
              body: JSON.stringify(
              {
                  userId      : this.props.userDetails.userId,
                  companyId   : this.props.userDetails.companyId,
                  siteId      : this.props.siteId,
                  patternId   : this.props.patternId,
                  mediaId     : this.editMediaId,
                  description : this.editDescription
              })
          })
          .then(response => response.json())
          .then(data => this.props.onUpdateMediaFiles(data))
          .catch(error => this.setState({ error, isLoading: true }));
  }


    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'dateTimeLocal',
            text: t('Date') ,
            editable: false,
            sort: true
        }, {
            dataField: 'filename',
            text: t('Filename') ,
            editable: false,
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
               Storage.get(row.fileKey)
               .then(result => {
                  console.log(result);
                  // fake server request, getting the file url as response
                  setTimeout(() => {
                    const response = {
                      file: result,
                    };
                    // server sent the url to the file!
                    // now, let's download:
                    //window.location.href = response.file;
                    // you could also do:
                    window.open(response.file);
                  }, 100);
               })
               .catch(err => console.log(err));
              }
            },
            sort: true
        }, {
            dataField: 'size',
            text: t('SizeKB') ,
            editable: false,
            sort: true
        }, {
            dataField: 'description',
            text: t('Description') ,
            editable: true,
            sort: true
        }, {
            dataField: 'fileKey',
            text: 'fileKey' ,
            hidden: true        
        }];
         
        return  (
         <div>
            <BootstrapTable
                bootstrap4
                classes="table-responsive-sm"
                keyField='mediaId'
                striped={true}
                condensed={true}
                data={this.props.mediaFiles}
                columns={ columns }
                cellEdit={ cellEditFactory({mode: 'click' , blurToSave:true, afterSaveCell: this.afterSaveCell, onStartEdit: (row) => { this.editMediaId=row.mediaId }})}
                headerClasses="thead-dark"
            />
        </div>)
    }
}

export default withTranslation()(TableMedia);
