import React, { useRef, useEffect } from 'react';
  
function PatternCanvas(props) {
  const canvas = useRef();
  let ctx = null;
 
  // initialize the canvas context
  useEffect(() => {
    // dynamically assign the width and height to canvas
    const canvasEle = canvas.current;
    canvasEle.width = canvasEle.clientWidth;
    canvasEle.height = canvasEle.clientHeight;
    console.log ("Canvas Size:",canvasEle.width,canvasEle.height);
 
    // get context of the canvas
    ctx = canvasEle.getContext("2d");
  }, []);

   useEffect(() => {


   //Heading
   writeText({ text: props.patternNo,
               x: canvas.current.width/2, y: 0}, { fontSize: 45, color: 'black', textAlign: 'left' });


   if (props.holeInfos.length > 0) {
     var maxLat = parseFloat(props.holeInfos[0].latitude);
     var maxLng = parseFloat(props.holeInfos[0].longitude);
     var minLat = parseFloat(props.holeInfos[0].latitude);
     var minLng = parseFloat(props.holeInfos[0].longitude);

     for (let i = 0; i < props.holeInfos.length; i++) {

       let lat = parseFloat(props.holeInfos[i].latitude);
       let lng = parseFloat(props.holeInfos[i].longitude);

       if (lat > maxLat)  maxLat = lat;
       if (lat < minLat)  minLat = lat;
       if (lng > maxLng) maxLng = lng;
       if (lng < minLng) minLng = lng;
     }
   }

   console.log ("LatMax Min:",maxLat,minLat);
   console.log ("LngMax Min",maxLng,minLng);
   const tl = {lat:maxLat, lng:minLng};
   const br = {lat:minLat, lng:maxLng};
   const avgLat = (maxLat+minLat)/2.0;
   //Get Global XY coordinates
   console.log ("tl",tl);
   console.log ("br",br);
   const mapTlGXY = latLngToGXY(tl,avgLat);
   const mapBrGXY = latLngToGXY(br,avgLat);
   
   console.log ("mapTlGXY",mapTlGXY);
   console.log ("mapBrGXY",mapBrGXY);

   //let canvasXY = map2CanvasXY(tl, avgLat, mapTlGXY, mapBrGXY )
   //const t1Info = { x: canvasXY.x, y: canvasXY.y, r: 10};
   //drawCircle (t1Info, {color:'green',backgroundColor:'red' });
   //canvasXY = map2CanvasXY(br, avgLat, mapTlGXY, mapBrGXY )
   //const brInfo = { x: canvasXY.x, y: canvasXY.y, r: 10};
   //drawCircle (brInfo);

   for (let i = 0; i < props.holeInfos.length; i++) {
     const canvasXY = map2CanvasXY({lat:parseFloat(props.holeInfos[i].latitude), lng:parseFloat(props.holeInfos[i].longitude)}, avgLat, mapTlGXY, mapBrGXY )

     console.log ("canvasXY",canvasXY);

     const r1Info = { x: canvasXY.x, y: canvasXY.y, r: 4};
     drawCircle (r1Info);

     writeText({ text: props.holeInfos[i].holeNo,
                 x: canvasXY.x+6, y: canvasXY.y-12}, { fontSize: 8, color: 'black', textAlign: 'left' });
     writeText({ text: props.holeInfos[i].volume+' lbs',
                 x: canvasXY.x+6, y: canvasXY.y-4}, { fontSize: 8, color: 'black', textAlign: 'left' });
     writeText({ text: props.holeInfos[i].depth+' ft',
                 x: canvasXY.x+6, y: canvasXY.y+4}, { fontSize: 8, color: 'black', textAlign: 'left' });
   }
   canvas.current.toDataURL();

  }, []);


 const map2CanvasXY=( latLng, avgLat, mapTlGXY, mapBrGXY ) =>{
    const gxy      = latLngToGXY (latLng, avgLat);
    const canvasXY = GXY2Canvas ( gxy, mapTlGXY, mapBrGXY);

    return (canvasXY);
  }

 const latLngToGXY=(latLng, avgLat)=> {
    let latRad    = (Math.PI * latLng.lat) / 180.0;
    let lngRad    = (Math.PI * latLng.lng) / 180.0;
    let avgLatRad = (Math.PI * avgLat) / 180.0;
    let radius = 6371*1000.0*1000*10;


        //pointXy.x = (long) (radius * Math.cos(lat) * Math.cos(lng));
        //pointXy.y = (long) (radius * Math.sin(lat));

    let x = radius * lngRad* Math.cos(avgLatRad) ;
    let y = radius * latRad;

    console.log ("latLngToGXY",latLng,avgLat,latRad,lngRad,avgLatRad,x,y);

    return ({x:x,y:y});
  }


const GXY2Canvas = (GXY,mapTlGXY, mapBrGXY) => {
   const canvasTlXY = {x:50,y:150};
   const canvasBrXY = {x:canvas.current.width-200,y:canvas.current.height-50};

   //Calculate scaling so it fits on canvas
   

   //let x =  ((GXY.x - mapTlGXY.x) * (canvasBrXY.x - canvasTlXY.x)) / (mapBrGXY.x - mapTlGXY.x) + canvasTlXY.x;
   //let y =  ((GXY.y - mapTlGXY.y) * (canvasBrXY.y - canvasTlXY.y)) / (mapBrGXY.y - mapTlGXY.y) + canvasTlXY.y;

   //Move to right coordinate quadrant with TL at 0,0
   console.log("GXY2Canvas:GXY",GXY);

   GXY.x = GXY.x - mapTlGXY.x;
   GXY.y = -1*(GXY.y - mapTlGXY.y);
   console.log("GXY2Canvas:GXYT",GXY);

   let scaleX = Math.abs((canvasBrXY.x - canvasTlXY.x) / (mapBrGXY.x - mapTlGXY.x));
   let scaleY = Math.abs((canvasBrXY.y - canvasTlXY.y) / (mapBrGXY.y - mapTlGXY.y));

   let scale = Math.min(scaleX,scaleY);;
   console.log("GXY2Canvas:Scale:",scaleX,scaleY,scale);

   let x =  (GXY.x) * scale + canvasTlXY.x;
   let y =  (GXY.y) * scale + canvasTlXY.y;

   console.log("GXY2Canvas:XY=",x,y);

   return ({x:x,y:y});
 }


  const drawLine = (info, style = {}) => {
    const { x, y, x1, y1 } = info;
    const { color = 'black', width = 1 } = style;
 
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x1, y1);
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
    ctx.stroke();
  }

  // draw rectangle
  const drawRect = (info, style = {}) => {
    const { x, y, w, h } = info;
    const { borderColor = 'black', borderWidth = 1 } = style;

    ctx.beginPath();
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = borderWidth;
    ctx.rect(x, y, w, h);
    ctx.stroke();
  }

   const drawCircle = (info, style = {}) => {
    const { x, y, r } = info;
    const { color = 'black', width = 1, backgroundColor='white' } = style;

    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI, false);
    ctx.strokeStyle = color;
    ctx.fillStyle = backgroundColor;
    ctx.lineWidth = width;
    ctx.stroke();
    ctx.closePath();
ctx.fill();
  }


  // draw rectangle with background
const drawFillRect = (info, style = {}) => {
  const { x, y, w, h } = info;
  const { backgroundColor = 'black' } = style;

  ctx.beginPath();
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(x, y, w, h);
}

const writeText = (info, style = {}) => {
  const { text, x, y } = info;
  const { fontSize = 20, fontFamily = 'Arial', color = 'black', textAlign = 'left', textBaseline = 'top' } = style;

  ctx.beginPath();
  ctx.font = fontSize + 'px ' + fontFamily;
  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  ctx.fillStyle = color;
  ctx.fillText(text, x, y);
  ctx.stroke();
}
 
  return (
    <div className="DeckCanvas">
      <canvas ref={canvas} width="2000" height="2000"></canvas>
    </div>
  );
}
 
export default PatternCanvas;
