import React, { Component } from "react";
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import moment from "moment"
import {Element, scroller} from 'react-scroll';
//import { RangeDatePicker } from '@y0c/react-datepicker';
//import Select from 'react-select';
//import Button from 'react-bootstrap/Button';

import Report6TableEvents from './Report6TableEvents';
import TableEventSummaryMonth from './Report6TableEventSummaryMonth';
import ChartSpeedingYear from './Report6Chart.1';
import ChartSpeedingDriversMonth from './Report6Chart.2';
import EventMap from './EventMap';
import { withTranslation } from 'react-i18next';
 
class Report6 extends Component {

    constructor(props) {
        super(props);
        var d = new Date();
        var month = d.getMonth() + 1;
        var year  = d.getFullYear();
 
        this.state = {
            drillLevel         : 0,                          
            startDate          : null,
            endDate            : null,      
            eventInfos         : null,
            monthSummaryData   : null,
            driverMonthData    : null,
            driverYearData     : null,
            drivers            : null,
            selectedDriverId   : null,
            selectedDriverName : null,
            selectedMonth      : month,
            selectedYear       : year,
            selectedEventId    : null,
            error              : null,
        };
    }


    componentDidMount() {       
      this.getDrivers();
      this.getMonthSummary (this.state.selectedYear, this.state.selectedMonth);
      this.getDriverMonthData (this.state.selectedYear, this.state.selectedMonth);
    }


    getDrivers = () =>{
      this.setState({drivers:null});

      fetch(process.env.REACT_APP_HOST_URL+'commonPeopleDriversDS.php',
      {
          method: 'POST',
          body: JSON.stringify(
          {
              userId    : this.props.userDetails.userId,
              companyId : this.props.userDetails.companyId
          })
      })
      .then(response => response.json())
      .then(data => this.setState({ drivers:data }))
      .catch(error => this.setState({ error }));
    }


    getMonthSummary = (year, month) =>{
        fetch(process.env.REACT_APP_HOST_URL+'report6TableMonthsDS.php',
            {
                method: 'POST',
                body: JSON.stringify(
                {
                    userId    : this.props.userDetails.userId,
                    companyId : this.props.userDetails.companyId,
                    year      : year,
                    month     : month
                })
            })
          .then(response => response.json())
          .then(data => this.setState({ monthSummaryData:data }))
          .catch(error => this.setState({ error }));
    }


    getDriverMonthData = (year, month) =>{
      fetch(process.env.REACT_APP_HOST_URL+'report6ChartDriversMonthDS.php',
          {
              method: 'POST',
              body: JSON.stringify(
              {
                companyId : this.props.userDetails.companyId,
                userId    : this.props.userDetails.userId,
                year      : year,
                month     : month
              })
          })
        .then(response => response.json())
        .then(data => this.setState({ driverMonthData:data}))
        .catch(error => this.setState({ error}));
    }


    getEventInfos = (driverId, startDate, endDate, year, month) =>{ 
      fetch(process.env.REACT_APP_HOST_URL+'getEventsDS.php',
      {
         method: 'POST',
         body: JSON.stringify(
         {
            userId        : this.props.userDetails.userId,
            companyId     : this.props.userDetails.companyId,
            driverId      : driverId,
            startDate     : startDate,
            endDate       : endDate,
            year          : year,
            month         : month
         })
       })
       .then(response => response.json())
       .then(data => {
         this.setState({ eventInfos:data});
       })
       .catch(error => this.setState({ eventInfos:null }));
    }


    onChange = (startDate,endDate) => {       
      var startDateMySql = moment(startDate).format('YYYY-MM-DD');
      var endDateMySql = moment(endDate).format('YYYY-MM-DD');
      this.setState({startDate:startDateMySql, endDate:endDateMySql, selectedYear:null, selectedMonth:null });
      console.log ("Report6:onChange", startDateMySql, endDateMySql);
    }


    handleChangeDriver = (selectedDriver) => {
      var selectedDriverId = selectedDriver.value ;

      this.setState({ selectedDriverId, drillLevel:0 });
      console.log('Report6:Driver selected:', selectedDriverId );
    }

    handleChartDateSelect = ( selectedYear, selectedMonth) => {
      console.log('Report6:handleChartDateSelect', selectedYear, selectedMonth );
      this.setState({ selectedYear, selectedMonth, startDate:null, endDate:null});
      this.getMonthSummary (selectedYear,selectedMonth);
      this.getDriverMonthData (selectedYear,selectedMonth);
    }

    handleChartDriverSelect = (row) => {
      var selectedDriverId=this.state.driverMonthData.rows[row].c[2];
      var selectedDriverName=this.state.driverMonthData.rows[row].c[3];
      console.log('Report6:handleChartDriverSelect', this.state.driverMonthData.rows[row].c) ;

      this.setState({ selectedDriverId, selectedDriverName, startDate:null, endDate:null});
      this.getEventInfos(selectedDriverId, null, null, this.state.selectedYear, this.state.selectedMonth);

      scroller.scrollTo('scrolltableinfo', {
        duration: 500,
        offset: 0,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }

    handleTableDriverSelect = ( selectedYear, selectedMonth ,selectedDriverId, selectedDriverName) => {
      console.log('Report6:handleTableDriverSelect', selectedYear, selectedMonth, selectedDriverId );
      this.setState({ selectedYear, selectedMonth, selectedDriverId, selectedDriverName});
      this.getEventInfos(selectedDriverId, null, null, selectedYear, selectedMonth);

      scroller.scrollTo('scrolltableinfo', {
        duration: 500,
        offset: 0,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }

    handleUpdateDriver = () => {
     console.log ('Report6:handleUpdateDriver');
     this.getMonthSummary(this.state.selectedYear, this.state.selectedMonth);
      this.getDriverMonthData (this.state.selectedYear, this.state.selectedMonth);
    }

    handleUpdateEventSelect = (eventId) => {
      console.log('Report6:handleUpdateEventSelect', eventId);
      this.setState({selectedEventId : eventId});
    }

    handleClick = () => {
      this.getEventInfos(this.state.selectedDriverId, this.state.startDate, this.state.endDate, null, null);
    }


    render() {       
      const { t } = this.props;

      return (
        <div>
          <h3 style={{textAlign: 'center', color: 'black'}}>{t('Speeding_Report')}</h3>
          <ChartSpeedingYear
            userDetails={this.props.userDetails} 
            onDateSelect={this.handleChartDateSelect} />

          {((this.state.monthSummaryData!=null) && (this.state.monthSummaryData!=null))&& [
            <div key={1}>
              <ChartSpeedingDriversMonth
                userDetails={this.props.userDetails}
                onDriverSelect={this.handleChartDriverSelect}
                driverMonthData={this.state.driverMonthData}
                year={this.state.selectedYear}
                month={this.state.selectedMonth} />

              <TableEventSummaryMonth
                userDetails={this.props.userDetails}
                onDriverSelect={this.handleTableDriverSelect}
                monthSummaryData = {this.state.monthSummaryData}
                year  = {this.state.selectedYear}
                month = {this.state.selectedMonth} />
            </div>
          ]}
{/*
          <div >            
            <div style={{ float:'left', maxWidth: '500px', margin: 'auto' }}> 
              <RangeDatePicker onChange={this.onChange}
                               startPlaceholder="Start Date"
                               endPlaceholder="End Date"/>  
            </div>
            <div style={{ clear:'both', width: '400px' }}> 
              <Select
                value={this.state.selectedDriver}
                onChange={this.handleChangeDriver}
                options={this.state.drivers}
                isMulti ={false}
                closeMenuOnSelect={true}
                placeholder ={'Select Drivers'}
              />
            </div >
          </div>
          ((this.state.selectedDriverId!=null) && (this.state.startDate!=null)  && (this.state.endDate!=null)) && [
              <div style={{ clear :'both' , paddingBottom: '20px'}}>
                  <Button onClick={this.handleClick} variant="primary">Search</Button>
              </div>
          ]       
*/}
          <Element name='scrolltableinfo' className='element'/>

          {(this.state.eventInfos!=null) && [
            <div style={{clear :'both'}}>
              <Report6TableEvents
                  onUpdateEventSelect={this.handleUpdateEventSelect}
                  onUpdateDriver={this.handleUpdateDriver}
                  userDetails={this.props.userDetails} 
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  year={this.state.selectedYear}
                  month={this.state.selectedMonth}
                  eventInfos={this.state.eventInfos}
                  drivers={this.state.drivers}
                  driverId={this.state.selectedDriverId}
                  driverName={this.state.selectedDriverName} />

                  <EventMap
                  userDetails={this.props.userDetails}
                  selectedEventId={this.state.selectedEventId}
                  eventInfos={this.state.eventInfos} />
            </div>

          ]}
        
        </div>     
    );
  }
}

export default withTranslation()(Report6);
