import React, { Component } from "react";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import NavItem from 'react-bootstrap/NavItem'
import { Auth } from 'aws-amplify'
import AuthoriseObj from './Authorise.js';
import logo from './iblastx.png';
import gb from './gb.png';
import fr from './fr.png';
import { withTranslation } from 'react-i18next';

class MyNavbar extends Component {

 constructor(props) {
   super(props);
   this.state = {
   }
 }

  signOut = () => {
    Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
  }
  

  changePassword = () => {
    Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
  }


render() {
  const { t } = this.props;

   const changeLanguage = () => {
   if (this.props.userDetails.language==='en') {
     this.props.updateUserDetails('fr');
   } else {
     this.props.updateUserDetails('en');
   }
  }

  return (
    <Navbar key='NavBarMain' collapseOnSelect expand="lg" style={{backgroundColor:"black"}} variant="dark" sticky="top">
    
      <Navbar.Brand key='NavBarBrand' href="#home">
        <img
          key = 'NavBarImg'
          src={logo}
          width="142"
          height="51"
          className="d-inline-block align-top"
          alt="iBlastX"
        />
      </Navbar.Brand>
      <Navbar.Toggle key='NavBarToggle' aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse key = 'NavBarCollapse' id="responsive-navbar-nav">
        <Nav key='Nav' className="mr-auto">            
          <NavDropdown key='NavDropdownReports' title={t('Reports')} id="basic-nav-dropdown">
            <NavDropdown.Item key='#Report1' href="#Report1">{t('Year')}</NavDropdown.Item>
            <NavDropdown.Item key='#Report2'href="#Report2">{t('Date_Range')}</NavDropdown.Item>
            <NavDropdown.Item key='#Report4' href="#Report4">{t('Pattern')}</NavDropdown.Item>        
            <NavDropdown.Item key='#ReportPatternLoadSummary"' href="#ReportPatternLoadSummary">{t('Pattern_Load_Summary')}</NavDropdown.Item>        
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_REPORT_MAINTENANCE)>0) && [<NavDropdown.Item key='#Report7' href="#Report7">{t('Pump_Service')}</NavDropdown.Item>]} 
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_REPORT_TIZ)>0) && [<NavDropdown.Item key='#Report5"' href="#Report5">{t('TIZ')}</NavDropdown.Item>]}
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_REPORT_SPEEDING)>0) && [<NavDropdown.Item key='#Report6"' href="#Report6">{t('Speeding')}</NavDropdown.Item>]}
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_REPORT_UNKOWN_HOLES)>0) && [<NavDropdown.Item key='#ReportUnkownHoles' href="#ReportUnkownHoles">{t('Unkown_Holes')}</NavDropdown.Item>]}
          </NavDropdown>

          {(((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_VEHICLES)>0) ||
            ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_PEOPLE)>0) ||
            ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_ZONES)>0) ||
            ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_PRODUCT)>0) ||
            ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_NOTIFICATIONS)>0) ||
            ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_USERS)>0) ||
            ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_IMPORT_PATTERN)>0)) && 
          [
          <NavDropdown key='NavDropDownSetup' title={t('Setup')} id="basic-nav-dropdown">
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_IMPORT_PATTERN)>0) && [<NavDropdown.Item key='#SetupImportPattern' href="#SetupImportPattern">{t('Import_Pattern')}</NavDropdown.Item>]}
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_NOTIFICATIONS)>0) && [<NavDropdown.Item key='#SetupNotifications' href="#SetupNotifications">{t('Notifications')}</NavDropdown.Item>]}
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_PEOPLE)>0) && [<NavDropdown.Item  key = '#SetupPeople' href="#SetupPeople">{t('People')}</NavDropdown.Item>]}
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_PRODUCT)>0) && [<NavDropdown.Item key='#SetupProduct' href="#SetupProduct">{t('Products')}</NavDropdown.Item>]}
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_PRODUCT)>0) && [<NavDropdown.Item key='#SetupAccessories'  href="#SetupAccessories">{t('Accessories')}</NavDropdown.Item>]}
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_VEHICLES)>0) && [<NavDropdown.Item key='#SetupVehicles' href="#SetupVehicles">{t('Vehicles')}</NavDropdown.Item>]}
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_ZONES)>0) && [<NavDropdown.Item key='#SetupZones' href="#SetupZones">{t('Zones')}</NavDropdown.Item>]}
            {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_VEHICLES)>0) && [<NavDropdown.Item key='#SetupTablets' href="#SetupTablets">{t('Tablets')}</NavDropdown.Item>]}
          </NavDropdown>
          ]}

          {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_ADMIN_COMPANIES)>0) && [
            <NavDropdown key ='NavDropDownAdmin' title={t('Admin')} id="basic-nav-dropdown">
              {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_ADMIN_COMPANIES)>0) && [<NavDropdown.Item key='#AdminCompanies' href="#AdminCompanies">{t('Company')}</NavDropdown.Item>]}
              {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_ADMIN_USERS)>0) && [<NavDropdown.Item key='#AdminUsers' href="#AdminUsers">{t('Users')}</NavDropdown.Item>]}
            </NavDropdown>
         ]}

          {((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_DOWNLOADS)>0) && [
            <Nav.Link key='NavLinkDownloads' href="#downloads">{t('Downloads')}</Nav.Link>
          ]}
        </Nav>

        <Navbar.Brand key='NavBarBrand2' >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{this.props.userDetails.companyDescription}</Navbar.Brand>
        
        <Navbar.Collapse key='NavBarCollapse2' className="justify-content-end">

        <NavDropdown key ='NavBarUserNAme' title={this.props.userName} id="basic-nav-dropdown">
            <NavDropdown.Item key='NavBarLogout' onClick={this.signOut}>{t('Logout')}</NavDropdown.Item>
        </NavDropdown>
{(this.props.userDetails.language!=='en')? 
        <NavItem key ='NavItemGB'>
          <img
            src={gb}
            onClick={changeLanguage}
            width="32"
            height="24"
            className="d-inline-block align-top"
            alt="GB"
          />
        </NavItem>: 
        <NavItem key='NavItemFR'>
          <img
            src={fr}
            onClick={changeLanguage}
            width="32"
            height="24"
            className="d-inline-block align-top"
            alt="FR"
          />
        </NavItem>}

        </Navbar.Collapse>
      </Navbar.Collapse>
    </Navbar>   
    );
  }
}

export default withTranslation()(MyNavbar);
