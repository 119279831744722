import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from "react-bootstrap/Modal";
import HoleStateEdit from './HoleStateEdit';
import AuthoriseObj from './Authorise.js';
import { Lightbox } from "react-modal-image";
import { withTranslation } from 'react-i18next';

class SurveyTable extends React.Component 
{   
    constructor(props) {
      super(props);

      this.state = {
        selectedRow:null,
        stateEdit:false,
        image:null,
      };

      this.editRow = null;
    }

    componentDidMount() {       
    }


    handleSurveyStateSave = (surveyId, holeState) => {
      console.log ('SurveyTable:handleSurveyStateSave:',surveyId,holeState);
      fetch(process.env.REACT_APP_HOST_URL+'surveyUpdateStateDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId,
          surveyId  : surveyId,
          holeState : holeState 
        })
      })
      .then(response => 
      {
        this.props.refreshData();
        this.setState({stateEdit:false});
      })
      .catch(error => this.setState({stateEdit:true}));
   };


   handleSurveyStateCancel = () => {
      this.setState({stateEdit:false });
   };


   getImage = (survey) => {
      console.log ('SurveyTable:getImage:',survey);
      fetch(process.env.REACT_APP_HOST_URL+'commonGetImageDS.php',
          {
              method: 'POST',
              body: JSON.stringify(
              {
                  userId        : this.props.userDetails.userId,
                  companyId     : this.props.userDetails.companyId,
                  imageId       : survey.imageId
              })
          })
          .then(response => response.json())
          .then(data => this.setState({image:data}))
          .catch(error => this.setState({ iumage: null }));
   };

         handleImageModalClose = () => {
          console.log ('SurveyTable:handleImageModalClose');
          this.setState({image:null });
        };
    
    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'id',
            hidden: true,
          }, {
            dataField: 'deckNo',
            text: t('DeckNo'),
            editable: false,
            hidden: false,
            sort: true,
          }, {           
            dataField: 'dateTime',
            text: t('DateTime'),
            editable: false,
            hidden: false,
            sort: true,
          }, {
            dataField: 'phase',
            text: t('Phase'),
            editable: false,
            hidden: false,
            sort: true,
          }, {           
            dataField: 'surveyStateDescription',
            text: t('HoleState'),
            editable: false,
            hidden: false,
            sort: false,
          }, {           
            dataField: 'surveyerName',
            text: t('Name'),
            editable: false,
            hidden: false,
            sort: true,
          }, {           
            dataField: 'depthString',
            text: t('Depth')+'('+this.props.userDetails.lengthMUnit+')',
            editable: false,
            hidden: false,
            sort: true,
          }, {           
            dataField: 'waterDepth',
            text: t('SurveyWaterDepth')+'('+this.props.userDetails.lengthMUnit+')',
            editable: false,
            hidden: false,
            sort: true,
          }, {           
            dataField: 'temperature',
            text: t('SurveyTemperature')+'('+this.props.userDetails.tempUnit+')',
            editable: false,
            hidden: false,
            sort: true,
          }, {           
            //dataField: 'stemmingString',
            //text: t('Stemming')+'('+this.props.userDetails.lengthMUnit+')',
            //editable: false,
            //hidden: false,
            //sort: true,
          //}, {     
            dataField: 'holeStateDescription',
            text: t('HoleState'),
            editable: false,
            hidden: false,
            sort: true 
          }, {
            dataField: 'hasImage',
            text: t('Image'),
            editable: false,
            hidden: false,
            sort: true
          }, {     
            dataField: 'notes',
            text: t('Notes'),
            editable: false,
            hidden: false,
            sort: true                
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              this.setState({selectedRow : row});

              let getCurrentCellIndex = e.target.cellIndex;
              console.log ('SurveyTable:rowEvents:getCurrentCellIndex=',getCurrentCellIndex);
              if ((getCurrentCellIndex===7) && //Hole State 
                  ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_PATTERN) > 0)){ //Not proud of this, but 10 is column nLoadso
                this.editRow = {...row};
                this.setState({stateEdit:true});
              }

              if (getCurrentCellIndex===8) {//Image
                if(row.imageId) this.getImage(row);
              }

              //this.props.onPatternClick(row.siteId, row.patternNo, row.approvedState);  
            },
            onMouseEnter: (e, row, rowIndex) => {
              //console.log(`enter on row with index: ${row.year} ${row.month}`);
            }
        };
                      
        const selectRow = {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn:true,
          style: { backgroundColor: '#c8e6c9'}
        };

        const handleStateModalClose = () => {
          console.log ('SurveyTable:handleStateModalClose');
          this.setState({stateEdit:false });
        };



        return  (            
          <div>
            {(this.props.surveys!=null) && [
              <BootstrapTable 
                key={12}
                bootstrap4
                classes="table-responsive"
                keyField='id'    
                selectRow={ selectRow }
                striped={true} 
                hover={true} 
                condensed={true} 
                data={ this.props.surveys } 
                columns={ columns } 
                rowEvents={ rowEvents } 
                headerClasses="thead-dark"            
              />]}

             <Modal size="sm" show={this.state.stateEdit?true:false} onHide={handleStateModalClose}>
               <Modal.Header closeButton>
                 <Modal.Title>{t('HoleState')} {this.state.selectedRow?this.state.selectedRow.holeNo:''}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                 <HoleStateEdit
                   userDetails  = {this.props.userDetails}
                   editRow      = {this.editRow}
                   handleSave   = {this.handleSurveyStateSave}
                   handleCancel = {this.handleSurveyStateCancel}
                 />
               </Modal.Body>
             </Modal>

             {(this.state.image) && [
               <Lightbox
                 large={this.state.image}
                 medium={this.state.image}
                 onClose={this.handleImageModalClose}
               />
             ]}

           </div>

        )
    }
}

export default withTranslation()(SurveyTable);
