import React, { Component } from "react";
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import Table0 from './Report5Table.0';
import Table1 from './Report5Table.1';
import Table2 from './Report5Table.2';
import Chart0 from './Report5Chart.0';
import Chart1 from './Report5Chart.1';
import ChartTizYear from './Report5ChartYear';
import {Element, scroller} from 'react-scroll';
import { withTranslation } from 'react-i18next';

 
class Report5 extends Component {

    constructor(props) {
        super(props);
        //Default to current month/year
        var d = new Date();
        var m = d.getMonth()+1;
        var y = d.getFullYear();

        this.state = {
          drillLevel : 0,                          
          table1Data : null,
          chart1Data : null,
          table2Data : null,
          selectedZoneId: null,
          selectedZoneDescription:null,
          selectedDriverId : null,
          selectedDriverName :null,
          error:null,
	        monthYear: {year: y, month: m}
        };
    }

    componentDidMount() {       
    }

    getTable1Data = (zoneId, year, month) =>{
      fetch(process.env.REACT_APP_HOST_URL+'report5Table1DS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId,
          zoneId    : zoneId,
          month     : month,
          year      : year
        })
      })
      .then(response => response.json())
      .then(data =>{this.setState({ table1Data:data });})
      .catch(error => this.setState({ table1Data:null }));
    }

    getChart1Data = (zoneId, year, month) =>{
      fetch(process.env.REACT_APP_HOST_URL+'report5Chart1DS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId,
          zoneId    : zoneId,
          month     : month,
          year      : year
        })
      })
      .then(response => response.json())
      .then(data =>{this.setState({ chart1Data:data });})
      .catch(error => this.setState({ chart1Data:null }));
    }

    getTable2Data = (driverId, zoneId, year, month) =>{
      fetch(process.env.REACT_APP_HOST_URL+'report5Table2DS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId,
          zoneId    : zoneId,
          driverId  : driverId,
          month     : month,
          year      : year
        })
      })
      .then(response => response.json())
      .then(data =>{this.setState({ table2Data:data })})
      .catch(error => this.setState({ table2Data:null }));
    }

    handleClick = () => {
      this.setState({ drillLevel:1 });
      this.setState({ table1Data:null, chart1Data:null, table2Data:null });
    }

    handleUpdateMonthYear = (selectedMonthYear) => {
        console.log('Report5:handleUpdateMonthYear', selectedMonthYear);
        this.setState({monthYear: selectedMonthYear});
        this.setState({ drillLevel:0 });
        this.handleClick();
    }

    handleZoneSelect = (zoneId, zoneDescription) => {
      console.log("Report5:handleZoneSelect", zoneId);
      this.setState({ selectedZoneId:zoneId, selectedZoneDescription:zoneDescription, table2Data:null });

      this.getTable1Data(zoneId,this.state.monthYear.year, this.state.monthYear.month);
      this.getChart1Data(zoneId,this.state.monthYear.year, this.state.monthYear.month);
    }

    handleDriverSelect = (driverId, driverName) => {
      console.log("Report5:handleDriverSelect", driverId, driverName);
      this.setState({ selectedDriverId:driverId, selectedDriverName:driverName });

      this.getTable2Data(driverId, this.state.selectedZoneId, this.state.monthYear.year, this.state.monthYear.month);

      scroller.scrollTo('scrolltable2', {
        duration: 500,
        offset: 0,
        delay: 0,
        smooth: 'easeInOutQuart'
      })

    }

    handleDriverUpdate = () => {
      console.log("Report5:handleDriverUpdate");

      this.getTable2Data(this.state.selectedDriverId, this.state.selectedZoneId, this.state.monthYear.year, this.state.monthYear.month);
      this.getTable1Data(this.state.selectedZoneId,this.state.monthYear.year, this.state.monthYear.month);
      this.getChart1Data(this.state.selectedZoneId,this.state.monthYear.year, this.state.monthYear.month);
    }

    handleChartDateSelect = ( selectedYear, selectedMonth) => {
      console.log('Report5:handleChartDateSelect', selectedYear, selectedMonth );
      var monthYear = {year:selectedYear, month:selectedMonth};
      this.handleUpdateMonthYear(monthYear);
    }

    render() { 
       const { t } = this.props;

       const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return (
        <div>
          <h3 style={{textAlign: 'center', color: 'black'}}>{t('Time_In_Zone_Report')}</h3>

           <ChartTizYear
            userDetails={this.props.userDetails}
            onDateSelect={this.handleChartDateSelect} />

          {(this.state.drillLevel >0) && [
            <div key={4} style={{clear :'both'}}>
              <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>
                {t('Summary')} {monthNames[this.state.monthYear.month-1]} {this.state.monthYear.year}</h3>
              <div style={{display:'flex', flexDirection:'row'} }>
                <Table0
                  handleZoneSelect={this.handleZoneSelect}
                  userDetails={this.props.userDetails} 
		              monthYear={this.state.monthYear}/>
                <Chart0
                  handleZoneSelect={this.handleZoneSelect}
                  userDetails={this.props.userDetails} 
		              monthYear={this.state.monthYear}/>
              </div>
              {((this.state.table1Data !=null) && (this.state.chart1Data !=null)) && [
                <div>
                  <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>
                   {this.state.selectedZoneDescription} {t('Driver')} {t('Summary')}</h3>
                  <div style={{display:'flex', flexDirection:'row'} }>
                    <Table1
                      userDetails={this.props.userDetails} 
                      handleDriverSelect={this.handleDriverSelect}
                      data={this.state.table1Data}
                      zoneId={this.state.selectedZoneId}
		                  monthYear={this.state.monthYear}/>
                    <Chart1
                      handleDriverSelect={this.handleDriverSelect}
                      data={this.state.chart1Data}
                      userDetails={this.props.userDetails} 
		                  monthYear={this.state.monthYear}/>
                  </div>
                </div>
              ]}
              <Element name='scrolltable2' className='element'/>
              {(this.state.table2Data !=null) && [
                <Table2
                  userDetails={this.props.userDetails} 
                  handleDriverUpdate={this.handleDriverUpdate}
                  data={this.state.table2Data}
                  zoneId={this.state.selectedZoneId}
                  driverId={this.state.selectedDrivereId}
                  driverName={this.state.selectedDriverName}
		              monthYear={this.state.monthYear}/>
              ]}
            </div>
          ]}
        
        </div>     
    );
  }
}

export default withTranslation()(Report5);
