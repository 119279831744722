import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';

function Welcome() {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        size="lg"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Welcome to
            <span style={{color:"#c00000"}}> i</span>Blast<span style={{color:"#c00000"}}>X</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>Nothing like a real live tour!</p> 
            <p>Select a Pattern/Shot from <b>Recent Patterns</b> table by clicking on the row. Scroll down to see tables containing summary and hole details and the shot on a map.  </p>
            <p>On the <b>Map</b> you can enable different overlays(Map Top left corner) or hover over a map marker for more details.</p>
            <p>In <b>Downloads</b> you will find some Videos and Documentation to help you on your way (Coming Soon)</p>
            <p>This is only a small taste of what is available, but we hope it will give you an understanding of what we do!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Welcome;
