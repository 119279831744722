import React, { Component } from "react";
import Table0 from './Report7Table.0';
import Table1 from './Report7Table.1';
import {Element, scroller} from 'react-scroll';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AuthoriseObj from './Authorise.js';
import { DatePicker } from '@y0c/react-datepicker';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import { withTranslation } from 'react-i18next';
 
class Report7 extends Component {

    constructor(props) {
        super(props);

        var today = new Date();
        var currentDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

        this.state = {
          drillLevel       : 0,                          
          table0Data       : null,
          table1Data       : null,
          notes            : null, 
          technician       : null, 
          serviceHoursPump : null, 
          selectedRow      : null,
          serviceDate      : currentDate,
          submitted        : false,
        };
    }

    componentDidMount() {       
     console.log("Report7:componentDidMount",this.props.userDetails.flags&AuthoriseObj.AUTH_EDIT_REPORT_MAINTENANCE);
     this.getTable0Data();
    }


    componentDidUpdate(prevProps, prevState) {
    }

    getTable0Data = () =>{
      fetch(process.env.REACT_APP_HOST_URL+'report7Table0DS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId
        })
      })
      .then(response => response.json())
      .then(data =>{this.setState({ table0Data:data });})
      .catch(error => this.setState({ table0Data:null }));
    }

    getTable1Data = (vehicleId) =>{
      fetch(process.env.REACT_APP_HOST_URL+'report7Table1DS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId,
          vehicleId : vehicleId
        })
      })
      .then(response => response.json())
      .then(data =>{this.setState({ table1Data:data })})
      .catch(error => this.setState({ table1Data:null }));
    }

    handleVehicleSelect = (row) => {
      console.log("Report7:handleVehicleSelect", row.vehicleId, row.vehicleDescription);
      this.setState({ selectedRow:row, submitted:false} );

      this.getTable1Data(row.vehicleId);

      scroller.scrollTo('scrolltable1', {
        duration: 500,
        offset: 0,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }

    handleChangeTechnician = (event) =>{
      this.setState({technician:event.target.value});
    }

    handleChangeNotes = (event) =>{
      this.setState({notes:event.target.value});
    }

    handleChangeServiceHoursPump = (event) =>{
      this.setState({serviceHoursPump:event.target.value});
    }

    handleSubmit = (event) => {
      console.log("Report7:handleSumbit", this.state.serviceDate, this.state.selectedRow.totalHours, this.state.technician, this.state.notes);

      //If user does not enter use default
      let serviceHoursPump = this.state.serviceHoursPump;
      if (!serviceHoursPump) serviceHoursPump = this.state.selectedRow.serviceHoursPump;

      fetch(process.env.REACT_APP_HOST_URL+'report7Table1AddDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId           : this.props.userDetails.userId,
          companyId        : this.props.userDetails.companyId,
          vehicleId        : this.state.selectedRow.vehicleId,
          totalHours       : this.state.selectedRow.totalHours,
          hours            : this.state.selectedRow.sinceHours,
          serviceHoursPump : serviceHoursPump,
          serviceDate      : this.state.serviceDate,
          technician       : this.state.technician,
          notes            : this.state.notes
        })
      })
      .then(response => response.json())
      .then(data =>{
        this.getTable0Data();
        this.getTable1Data(this.state.selectedRow.vehicleId);

        var today = new Date();
        var currentDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        this.setState({technician:'', notes:'', serviceHoursPump:null, submitted:true, serviceDate:currentDate});
      })
      .catch(error => this.setState({ table1Data:null }));
    }


    onChangeDate = (dateObj,dateText) => {
      let serviceDate = dateText 
      console.log('Report7:onChangeDate:',serviceDate);
      this.setState({serviceDate:serviceDate});
    }


    render() { 
      const { t } = this.props;

    return (
      <div>
        <h3 style={{textAlign: 'center', color: 'black'}}>{t('Pump_Maintenance_Report')}</h3>

        {(this.state.table0Data != null) && [
          <div key={4} style={{clear :'both'}}>
            <Table0
               handleVehicleSelect={this.handleVehicleSelect}
               userDetails={this.props.userDetails} 
               data={this.state.table0Data}/>
          </div>     
        ]}

        <Element name='scrolltable1' className='element'/>
                  
        {(this.state.table1Data !=null) && [
          <div>
             <h3 key={1} style={{ borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>
                {this.state.selectedRow.vehicleDescription} {t('History')}</h3>
             <Table1
                key={2}
                userDetails={this.props.userDetails} 
                handleServiceAdd={this.handleServiceAdd}
                data={this.state.table1Data}
                vehicleId={this.state.selectedRow.vehicleId}/>

             {(((this.props.userDetails.flags&AuthoriseObj.AUTH_EDIT_REPORT_MAINTENANCE)>0 && (!this.state.submitted)) ?   
               (<div>
                 <h4> {t('Add_Service_Record')} </h4>
                 <Form key={3} >
                   <Form.Group controlId="ServiceDate">
                     <Form.Label>{t('Service_Date')}</Form.Label>
                   <div>  <DatePicker onChange={this.onChangeDate} initialDate={this.state.serviceDate}/> </div>
                   </Form.Group>

                   <Form.Row>
                     <Form.Group controlId="formTechnician">
                       <Form.Label>{t('Technician')}</Form.Label>
                       <Form.Control type="plaintext" placeholder={t('Technician')} onChange={this.handleChangeTechnician}
                         value={this.state.technician}/>
                       <Form.Text className="text-muted">
                         {t('Technician')}
                       </Form.Text>
                     </Form.Group>
                     <Form.Group controlId="formServiceHoursPump">
                       <Form.Label>{t('Hours_to_Next_Service')}</Form.Label>
                       <Form.Control type="plaintext" placeholder={t('Service_Hours')} onChange={this.handleChangeServiceHoursPump}
                         defaultValue={this.state.selectedRow.serviceHoursPump} value={this.state.serviceHoursPump}/>
                       <Form.Text className="text-muted">
                         {t('Hours_to_Next_Service')}
                       </Form.Text>
                     </Form.Group>
                   </Form.Row>
    
                   <Form.Group controlId="formNotes">
                     <Form.Label>{t('Notes')}</Form.Label>
                     <Form.Control type="plaintext" placeholder={t('Notes')} onChange={this.handleChangeNotes}
                        value={this.state.notes}/>
                   </Form.Group>
    
                   <Button key={4} variant="primary" type="submit" onClick={this.handleSubmit}>
                     {t('Save')}
                   </Button>
                 </Form> 
               </div>): null)}

           </div>
        ]}
      </div>     
    );
  }
}

export default withTranslation()(Report7);
