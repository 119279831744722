import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { withTranslation } from 'react-i18next';
import i18n from '../translations/i18n';


class HomeTable1 extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.state = {
          data:      null,
          error:     null,
          isLoading: true          
        };
    }

    componentDidMount() {       
        this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
      console.log("HomeTable1:componentDidUpdate");
      if(prevProps.userDetails.language!==this.props.userDetails.language)
        this.getData();
    }

    getData() {
        fetch(process.env.REACT_APP_HOST_URL+'homeTable1DS.php',
        {
           method: 'POST',
           body: JSON.stringify(
           {
                userId    : this.props.userDetails.userId,
                companyId : this.props.userDetails.companyId
            })
        })
        .then(response => response.json())
        .then(data => {
           data.map(function(row){
             row.description = i18n.t(row.description);
          })

          this.setState({ data, isLoading: false });
        })
        .catch(error => this.setState({ error, isLoading: true }));
    }


    render() 
    { 
        const { t } = this.props; 
       
        if (this.state.isLoading)
        {
            return (<p>{t('Loading')}</p>);
        }
   
        const columns = [{
            dataField: 'devId',
            text: 'Device Id',
            hidden: true
            }, {     
            dataField: 'vehicleName',
            text: t('Vehicle'),
            editable: false,
            sort: true 
            }, {
            dataField: 'description',
            text: t('Description'),
            editable: false,
            sort: true
            }, {           
            dataField: 'since',
            text: t('Since')+ '('+this.props.userDetails.timeZone+')',
            editable: false,
            sort: true
            }, {           
            dataField: 'daysAgo',
            text: t('Days_Ago'),
            editable: false,
            sort: true
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.devId} ${row.description}`);
              //this.props.onSelectYearMonth(row.year, row.month, row.yearMonth);  
            },
            onMouseEnter: (e, row, rowIndex) => {
              //console.log(`enter on row with index: ${row.year} ${row.month}`);
            }
          };

                      
        return  (            
            <div>
                {(this.state.data.length >0) && [
                <h3 key={1} style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Vehicle_Issues')}</h3>,
                <BootstrapTable 
                    key='homeTable1'
                    bootstrap4
                    classes="table-responsive-sm"
                    keyField='uuid'    
                    striped={true} 
                    hover={true} 
                    condensed={true} 
                    data={ this.state.data } 
                    columns={ columns } 
                    rowEvents={ rowEvents } 
                    headerClasses="thead-dark"            
                />]}
             </div>
        )
    }
}

export default withTranslation()(HomeTable1);
