import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

class YearSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: this.props.defaultYear
    };

    this.handleClickLeft  = this.handleClickLeft.bind(this);
    this.handleClickRight = this.handleClickRight.bind(this);
  }


  handleClickLeft(event) {
    var year = this.state.year;

    if (year > 2019)
    {
      year--;
      this.setState({year:year});
      this.props.handleUpdate(year);
    }
    console.log("YearSelect:handleClickLeft:",year);
  }


  handleClickRight(event) {
    var year = this.state.year;

    if (year < 2035)
    {
      year++;
      this.setState({year:year});
      this.props.handleUpdate(year);
    }
    console.log("YearSelect:handleClickRigth:",year);
  }


  render() {

    const textStyle = {
      color: 'black',
      fontSize: 25,
    };

    return (
      <div style={{ clear :'both' , padding: '10px'}}>
        <Button onClick={this.handleClickLeft} variant="primary"> - </Button>
        <span style={textStyle}> {this.state.year} </span> 
        <Button onClick={this.handleClickRight} variant="primary"> + </Button>
      </div>
    );
  }
}

export default YearSelect;

