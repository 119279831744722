import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';

class DeckTable extends React.Component 
{   
    constructor(props) 
    {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {       
    }

    updateDeck=() => {
      console.log('DeckTable::updateDeck ' , this.editDeckId, this.editRow.deckingHeight);

      fetch(process.env.REACT_APP_HOST_URL+'deckUpdateDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId        : this.props.userDetails.userId,
            companyId     : this.props.userDetails.companyId,
            holeId        : this.editDeckId,
            deckNo        : this.editRow.deckNo,
            deckingHeight : this.editRow.deckingHeight
        })
      })
      .then(response => response.json())
      .then(data => {//this.props.updateDeck();
                    })
      .catch(error => this.setState({ error}));
    }


   afterSaveCell = (cellOld, cellNew, row, column) =>
    {
        console.log('DeckTable:afterSaveCell: ' + column.dataField + ":" + cellOld + "->" + cellNew);

        if (cellOld!=cellNew){
          this.editRow = {...row};
          if (column.dataField==='deckingHeight') {
            this.editRow.deckingHeight=cellNew;
          }
          this.updateDeck()
        }
    }

    render() 
    { 
        const { t } = this.props;

        var editAuth = false;
        if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_VEHICLES) > 0) editAuth=true;
   
        const columns = [{
            dataField: 'deckId',
            text: 'deckId',
            hidden: true
        }, {
            dataField: 'deckNo',
            text: t('DeckNo'),
            editable: false,
            headerStyle: (colum, colIndex) => {
              return { width: '50px', textAlign: 'center' };
            },
            sort: true            
        }, {
            dataField: 'eventDateTime',
            text: t('Date'),
            editable: false,
            headerStyle: (colum, colIndex) => {
              return { width: '150px', textAlign: 'center' };
            },
            sort: true                
        }, {
            dataField: 'duration',
            text: t('Duration'),
            editable: false,
            sort: true            
        }, {
            dataField: 'noLoads',
            text: t('noLoads'),
            editable: false,
            hidden:false,
            sort: true
        }, {
            dataField: 'designStartDepth',
            text: t('StartDepth')+'('+this.props.userDetails.lengthMUnit+')',
            editable: false,
            sort: true,
        }, {
            dataField: 'designProductType',
            text: t('Product'),
            editable: false,
            sort: true,
        }, {
            dataField: 'columnWeightString',
            text: t('Weight')+ '('+this.props.userDetails.massMUnit+')',
            editable: false,
            sort: true,
        }, {
            dataField: 'columnHeightString',
            text: t('ColumnHeight')+'('+this.props.userDetails.lengthMUnit+')',
            editable: false,
            sort: true,
        }, {
            dataField: 'deckingHeightString',
            text: t('StemmingHeight')+'('+this.props.userDetails.lengthMUnit+')',
            editable: false,
            sort: true,
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`DeckTable:clicked on row with index: ${row.loadId}`);
            },
            onMouseEnter: (e, row, rowIndex) => {
            }
          };

                      
        return  (            
          <div>
            <BootstrapTable 
               bootstrap4
               classes="table-responsive"
               keyField='deckId'    
               striped={true} 
               hover={true} 
               condensed={true} 
               data={ this.props.decks } 
               columns={ columns } 
               rowEvents={ rowEvents } 
               headerClasses="thead-dark"
               cellEdit={ cellEditFactory({mode: 'click', blurToSave:true, afterSaveCell: this.afterSaveCell, onStartEdit: (row) => {this.editDeckId=row.deckId }})}
           />
        </div>)
    }
}

export default withTranslation()(DeckTable);
