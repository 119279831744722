import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import InfoWindow from './EventMapInfoWindow';

class EventMapMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };

    this.handleClick = this.handleClick.bind(this);
  }


  handleClick(event) {
    console.log("EventMapMarker:handleClick:",this.props.eventInfo);
    //this.props.handleMarkerClick(this.props.eventInfo.id);
  }


  render() {
    const markerStyle = {
      border: ((this.props.$hover&&this.props.markerClickId===null)||(this.props.showInfoWindow)||this.props.selected===true) ? '3px solid black':'1px solid white',
      borderRadius: '50%',
      height: ((this.props.$hover&&this.props.markerClickId===null)||(this.props.showInfoWindow)||this.props.selected===true) ? 20:10,
      width:  ((this.props.$hover&&this.props.markerClickId===null)||(this.props.showInfoWindow)||this.props.selected===true) ? 20:10,
      fontWeight: ((this.props.$hover&&this.props.markerClickId===null)||(this.props.showInfoWindow)||this.props.selected===true) ? 'bold':'normal',
      backgroundColor: 'red',
      cursor: 'pointer',
      zIndex: 10,
    };

    const textStyle = {
      color: 'black',
      paddingLeft : 3,
      fontSize: this.props.$hover ? 14:10,
      fontWeight: this.props.$hover ? 'bold':'normal',
    };

    return (
      <div>
        <div style={markerStyle}  onClick={this.handleClick} /> <span style={textStyle}>{this.props.id}</span>
        {(this.props.$hover) && 
          <InfoWindow
            eventInfo={this.props.eventInfo}
            userDetails={this.props.userDetails}  />}
      </div>
    );
  }
}

export default EventMapMarker;

