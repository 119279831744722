import React, { Component } from "react";
import SetupAccessoriesTable1 from './SetupAccessoriesTable.1';
import AuthoriseObj from './Authorise.js'
import Form from 'react-bootstrap/Form';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
 
class SetupAccessories extends Component {
  constructor(props) {
    super(props);

    this.state = {         
      accessories               : null,
      newAccessoryDescription   : '',
      errorAccessoryDescription : false,
    };
  }

  componentDidMount() {
    this.getAccessories();
  }

  handleChangeAccessoryDescription=(event)=> {
    this.setState({newAccessoryDescription: event.target.value, errorAccessoryDescription: false});
  }


  handleSubmit=(event)=> {
    console.log("SetupAccessories:handleSubmit");
    event.preventDefault();

    if(this.state.newAccessoryDescription){
      fetch(process.env.REACT_APP_HOST_URL+'setupAccessoryAddDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId      : this.props.userDetails.userId,
          companyId   : this.props.userDetails.companyId,
          description : this.state.newAccessoryDescription
        })
      })
      .then(response => {this.getAccessories(); this.setState({newAccessoryDescription:''}); })
      .catch(error => this.setState({ error }));
    } else {
      this.setState({errorAccessoryDescription: true}); 
    }
  }


  getAccessories=()=> {
    console.log("SetupAccessories:getAccessories");

    fetch(process.env.REACT_APP_HOST_URL+'setupAccessoriesGetDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId,
      })
    })
    .then(response => response.json())
    .then(data => this.setState({ accessories:data }))
    .catch(error => this.setState({ accessories:null }));
  }


  handleUpdate = (accessory) =>{
    console.log("SetupAccessories:handleUpdate", accessory);
    fetch(process.env.REACT_APP_HOST_URL+'setupAccessoryUpdateDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId          : this.props.userDetails.userId,
        companyId       : this.props.userDetails.companyId,
        accessoryId     : accessory.accessoryId,
        description     : accessory.description,
        barcode         : accessory.barcode,
        type            : accessory.type,
        exportId        : accessory.exportId,
        state           : accessory.state
      })
    })
    .then(response => {this.getAccessories(); })
    .catch(error => this.setState({error}));
  }


  handleDelete = (accessoryId) =>{
    console.log("SetupAccessories:handleDelete",accessoryId);
    fetch(process.env.REACT_APP_HOST_URL+'setupAccessoryDeleteDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId      : this.props.userDetails.userId,
        companyId   : this.props.userDetails.companyId,
        accessoryId : accessoryId
      })
    })
    .then(response => {this.getAccessories();})
    .catch(error => this.setState({error}));
  }

  render() {
    const { t } = this.props;

    return (
        <div>
          {(this.state.accessories!==null) && [
            <SetupAccessoriesTable1 
              userDetails  = {this.props.userDetails} 
              accessories  = {this.state.accessories}
              handleDelete = {this.handleDelete}
              handleUpdate = {this.handleUpdate}/>
          ]}

          {((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_PRODUCT) > 0) && [
          <div>
            <h4>{t('Add_New_Accessory')} </h4>
            <Form key={3} onSubmit={this.handleSubmit}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 200 }}>
                  <TextField
                    required
                    type          = "text"
                    id            = "acccessoryDescription"
                    label         ={t('Accessory_Description')}
                    error         = {this.state.errorAccessoryDescription}
                    onChange      = {this.handleChangeAccessoryDescription}
                    value         = {this.state.newAccessoryDescription}
                    maxLength     = {64}
                  />
                </FormControl>
                <Button key={4} variant="contained" size="large" type="submit"> {t('Add')}</Button>
              </div>

            </Form>
          </div>
        ]}

        </div>

    );
  }
}

export default withTranslation()(SetupAccessories);
