import React, { Component } from "react";
import CompaniesSummaryTable from './AdminCompaniesTableSummary';
import AuthoriseObj from './Authorise.js';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
 
class AdminCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {         
      companyInfos: null,
      logos: null,
      description:'',
    };
    
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleSubmit            = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getCompanyInfos();
    this.getLogos();
  }


  handleChangeDescription(event) {
    this.setState({description: event.target.value});
  }

  getCompanyInfos = () => {
    console.log("AdminCompanies:getCompanyInfos", this.props.userDetails.userId);
    fetch(process.env.REACT_APP_HOST_URL+'adminGetCompanyInfosDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        companyId : this.props.userDetails.companyId,
        userId    : this.props.userDetails.userId
      })
    })
    .then(response => response.json())
    .then(data => {
       this.setState({companyInfos:data});
    })
    .catch(error => this.setState({ companyInfos:null }));
  }

  getLogos(){
    console.log ("AdminCompaniesTableSummary:getLogos");
    fetch(process.env.REACT_APP_HOST_URL+'adminGetLogosDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId
      })
    })
    .then(response => response.json())
    .then(data => this.setState({logos:data}))
    .catch(error => this.setState({logos:null}));
  };

  handleSubmit(event) {
    console.log("AdminCompanies:handleSubmit");
    fetch(process.env.REACT_APP_HOST_URL+'adminAddCompanyDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId      : this.props.userDetails.userId,
        companyId   : this.props.userDetails.companyId,
        description : this.state.description,
      })
    })
    .then(response => response.json())
    .then(data => {this.getCompanyInfos(); this.setState({description:''});});
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        {((this.state.companyInfos) && (this.state.logos)) && [
          <CompaniesSummaryTable key={0}           
            userDetails     = {this.props.userDetails} 
            companyId       = {this.props.userDetails.companyId}
            companyInfos    = {this.state.companyInfos}
            logos           = {this.state.logos}
            getCompanyInfos = {this.getCompanyInfos} />
        ]}
          <div>
            <h4> {t('Add_New_Company')} </h4>
            <Form key={1} onSubmit={this.handleSubmit}>
              <Form.Row key={2}>
                <Form.Group controlId="formCompany">
                  <Form.Label>{t('Description')}</Form.Label>
                  <Form.Control type="plaintext" placeholder={t('Enter_Description')} onChange={this.handleChangeDescription}
                    value={this.state.description}/>
                </Form.Group>
              </Form.Row>

              <Button key={4} variant="primary" type="submit">{t('Add')}</Button>
            </Form>
          </div>
      </div>
    );
  }
}

export default withTranslation()(AdminCompanies);
