import Chart from "react-google-charts";
import React from "react";
import { withTranslation } from 'react-i18next';

class Chart1 extends React.Component 
{   
    prevDate=0;
    constructor(props) 
    {
        super(props);
    
        this.state = {
          data: null,
          error: null,
          isLoading: true
        };
    }

    getData = (props) =>{
        this.setState({isLoading: true });
        fetch(process.env.REACT_APP_HOST_URL+'report1Chart1DS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                  companyId : props.userDetails.companyId,
                  userId    : props.userDetails.userId,
                  siteId    : props.siteId,
                  year      : props.year                  
                })
            })
          .then(response => response.json())
          .then(data => this.setState({ data, isLoading: false }))
          .catch(error => this.setState({ error, isLoading: true }));
      }

    componentDidMount() {
        this.getData(this.props)
    }

    componentWillReceiveProps(nextProps){        
        console.log('Report1Chart1:componentWillReceiveProps');
        if((nextProps.siteId!==this.props.siteId) || (nextProps.year!==this.props.year)) 
        {            
            this.getData(nextProps);
        }
    }

    render() 
    { 
        const { t } = this.props;

        if (this.state.isLoading)
        {
            return (<p>{t('Loading')}</p>);
        }

        var divStyle = {
           
            margin: '0 auto',
            width:'1000px'
          };
   
          return  (
            <div>
                {(this.state.data.rows.length >0) ?  
                    <div style={divStyle}>            
                        <Chart
                            chartType="Calendar"
                            loader={<div>{t('Loading')}</div>}
                            data={this.state.data}
                            chartEvents={[
                {
                    eventName: "select",
                    callback: ({ chartWrapper, google }) => {
                      const chart = chartWrapper.getChart();
                      const t = chart.getSelection();
                      var a = new Date(t[0].date);
                      var year  = a.getFullYear();
                      var month = ("0" +  (a.getMonth()+1)).slice(-2); //Zero pad for prettyness
                      var day   = ("0" +  a.getDate()).slice(-2); //Zero pad for prettyness
                      var selectedDate = year + '-' + month + '-' + day ;
                      
                      if (this.prevDate!==selectedDate) { 
                        this.prevDate=selectedDate;
                        console.warn("Click ", selectedDate);
                        this.props.onSelectDate(selectedDate);
                      }
                    }
                  }
                ]}

                            options={{
                                showRowNumber: true,
                                title: t('Weight_Pumped_per_Day'),
                                calendar: { cellSize: 15 }              
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        />
                    </div>
                : <div>No Data</div>}
            </div>
        )
    }
}

export default withTranslation()(Chart1);
