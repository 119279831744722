import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

class SetupImportPatternTable1 extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {       
      console.log('SetupImportPatternTable1:componentDidMount');
    }
    
    componentWillReceiveProps(nextProps){
    }


    selectRow = (row, isSelect, rowIndex) => {
      console.log("SetupImportPatternTable1:selectRow",row,isSelect,rowIndex);
      this.props.handleImportSelect(row);
    };


    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'id',
            text: 'Id',
            hidden:true
          }, {     
            dataField: 'timeStampLocal',
            text: t('Date'),
            hidden:false,
            sort:true
          }, {     
            dataField: 'fileName',
            text: t('Filename'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'patternNos',
            text: t('Pattern_No'),
            editable: false,
            hidden:false,
            sort: true,
            filter: textFilter()
          }, {     
            dataField: 'noHoles',
            text: t('No_Holes'),
            editable: false,
            hidden:false,
            sort: true                
          }, {     
            dataField: 'userName',
            text: t('UserName'),
            editable: false,
            hidden:false,
            sort: true                
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.id}`);
            },
        };
                      
        const selectRow = {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn:false,
          onSelect: this.selectRow,
          style: { backgroundColor: '#c8e6c9'}
        };

        return  (            
            <div>
                {(this.props.importInfos!=null) && [
                <BootstrapTable 
                    bootstrap4
                    classes="w-auto"
                    keyField='id'    
		                selectRow={ selectRow }
                    striped={true} 
                    hover={true} 
                    condensed={true} 
                    data={ this.props.importInfos } 
                    columns={ columns } 
                    rowEvents={ rowEvents } 
   		              pagination={ paginationFactory()}
                    filter={ filterFactory()}
                    headerClasses="thead-dark"            
                />]}
             </div>
        )
    }
}

export default withTranslation()(SetupImportPatternTable1);
