import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';

class MyTable3 extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.mustScroll=false;
    
        this.state = {
          data: null,
          error: null,
          isLoading: true
        };
    }

    componentWillReceiveProps(nextProps){
      console.log("Report1Table3:componentWillReceiveProps");
      if(nextProps.vehicleId!==this.props.vehicleId) 
      {            
          this.getData(nextProps);
      }
    }

//shouldComponentUpdate(nextProps,nextState)
//{
 //console.log(nextProps,this.props,nextState,this.state);
      //if((nextProps.vehicleId!=this.props.vehicleId)|| 
         //(nextState.isLoading!=this.state.isLoading))
      //{
        //return true;
      //}

//return (false);}

    componentDidUpdate() {
    }

    getData = (props) =>{
      //this.setState({isLoading: true });
      fetch(process.env.REACT_APP_HOST_URL+'report1Table3DS.php',
          {
              method: 'POST',                
              body: JSON.stringify(
              {
                  userId    : props.userDetails.userId,
                  companyId : props.userDetails.companyId,
                  siteId    : props.siteId,
                  year      : props.year,
                  month     : props.month,
                  vehicleId : props.vehicleId
              })
          })
        .then(response => response.json())
        .then(data => {this.mustScroll=true;this.setState({ data, isLoading: false })})
        .catch(error => this.setState({ error, isLoading: true }));
    }

    componentDidMount() {
        this.getData(this.props)
    }
      
    render() 
    { 
        const { t } = this.props;

        if (this.state.isLoading)
        {
            return (<p>{t('Loading')}</p>);
        }
   
        const columns = [{
            dataField: 'vehicleDescription',
            text: t('Vehicle'),
            hidden: true                
        }, {
            dataField: 'date',
            text: t('Date'),
            sort: true       
        }, {
            dataField: 'noHoles',
            text: t('No_Holes'),
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
        }, {
            dataField: 'duration',
            text: t('Duration'),
            sort: true,
             sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/:/g, '');
              var y = b.replace(/:/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }, {
            dataField: 'volume',
            text: t('Weight') + '('+this.props.userDetails.massMUnit+')',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`Report1Table3:clicked on row with index: ${row.date}`);
              this.props.onSelectDate(row.date); 
            },
            onMouseEnter: (e, row, rowIndex) => {
              //console.log(`enter on row with index: ${row.year} ${row.month}`);
            }
          };
        
        const selectRow = {
            mode: 'radio',
            clickToSelect: true,    
            hideSelectColumn:true,     
            style: { backgroundColor: '#c8e6c9'}
          };

        return  (
        <div>
            <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{this.props.vehicleDescription}</h3>     
            <BootstrapTable 
                bootstrap4
                classes="table-responsive-sm"
                keyField='date'
                selectRow={ selectRow }
                striped={true} 
                hover={true} 
                condensed={true} 
                data={ this.state.data } 
                columns={ columns } 
                pagination={ paginationFactory()} 
                rowEvents={ rowEvents }
                headerClasses="thead-dark" 
            />
        </div>)
    }
}

export default withTranslation()(MyTable3);
