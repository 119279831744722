import Chart from "react-google-charts";
import React from "react";
import { withTranslation } from 'react-i18next';

class Chart1 extends React.Component 
{   
    constructor(props) 
    {
        super(props);
    
        this.state = {
          data: null,
          error: null,
          isLoading: true
        };
    }

    getData = (props) =>{
        this.setState({isLoading: true });
        fetch(process.env.REACT_APP_HOST_URL+'homeChart1DS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                  companyId : props.userDetails.companyId,
                  userId    : props.userDetails.userId,
                  siteId    : props.siteId,
                  year      : props.year                  
                })
            })
          .then(response => response.json())
          .then(data => this.setState({ data, isLoading: false }))
          .catch(error => this.setState({ error, isLoading: true }));
      }

    componentDidMount() {
        this.getData(this.props)
    }

    componentDidUpdate(prevProps, prevState) {
      if((prevProps.siteId!==this.props.siteId) || (prevProps.year!==this.props.year)) {
        this.getData(this.props);
      }
    };


    render() 
    { 
        const { t } = this.props;

        if (this.state.isLoading)
        {
            return (<p>{t('Loading')}</p>);
        }

        var divStyle = {
            margin: '0 auto',
            width:'1000px'
          };
   
        return  (
            <div style={divStyle}>
            <Chart
                chartType="Calendar"
                chartLanguage={this.props.userDetails.language}
                loader={<div>{t('Loading')}</div>}
                data={this.state.data}
                options={{
                    showRowNumber: true,
                    title: t('Weight_Pumped_per_Day'),
                    calendar: { cellSize: 15 }              
                }}
                rootProps={{ 'data-testid': '1' }}
            />
            </div>
        )
    }
}

export default withTranslation()(Chart1);
