import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import AuthoriseObj from './Authorise.js';
import DeckCanvas from './DeckCanvas';
import LoadTable from './LoadTable';
import LoadAdd from './LoadAdd';
import SurveyTable from './SurveyTable';
import AccessoryTable from './AccessoryTable';
import DesignData from './DesignData.js';
import { withTranslation } from 'react-i18next';
import i18n from '../translations/i18n';


class TableHoleInfo extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.filteredHoleInfosLength = 0;

        this.editRow = {
		holeId:0,
		linkedId:0,
		selectedRow:null,
		patternNo:null,
		depth:null,
		stemming:null,
		diameter:null,
        };
    
        this.state = {
          error: null,
          isLoading: true,
          deckData:[],
          loadData:null,
          accessoryData:[],
          surveyData:[],
          designData:null,
          stateEdit:false,
        };
    }


    componentDidUpdate() {
    }
    
    componentWillReceiveProps(nextProps){
        console.log ("CommonTableHoleInfo:componentWillReceiveProps",this.props.userDetails);
    }  

    componentDidMount() {
    }

    getDecks(row) {
      fetch(process.env.REACT_APP_HOST_URL+'commonGetDecksDS.php',
          {
              method: 'POST',
              body: JSON.stringify(
              {
                  userId        : this.props.userDetails.userId,
                  companyId     : this.props.userDetails.companyId,
                  holeId        : row.holeId,
                  siteId        : row.siteId,
                  patternNo     : row.patternNo,
                  holeNo        : row.holeNo
              })
          })
          .then(response => response.json())
          .then(data => this.setState({deckData:data}))
          .catch(error => this.setState({ deckData: [] }));
    }


    getSurveys(row) {
      fetch(process.env.REACT_APP_HOST_URL+'commonGetSurveysDS.php',
          {
              method: 'POST',
              body: JSON.stringify(
              {
                  userId        : this.props.userDetails.userId,
                  companyId     : this.props.userDetails.companyId,
                  holeId        : row.holeId,
                  siteId        : row.siteId,
                  patternNo     : row.patternNo,
                  holeNo        : row.holeNo
              })
          })
          .then(response => response.json())
          .then(data => {
            data.map(function(survey){
              survey.surveyStateDescription = '';
              if(survey.surveyState==1) survey.surveyStateDescription=i18n.t('Survey_Depth_OK');
              if(survey.surveyState==2) survey.surveyStateDescription=i18n.t('Survey_Depth_Over');
              if(survey.surveyState==3) survey.surveyStateDescription=i18n.t('Survey_Depth_Under');
              if(survey.surveyState==4) survey.surveyStateDescription=i18n.t('Survey_Loaded');
              if(survey.surveyState==5) survey.surveyStateDescription=i18n.t('Survey_Stemming_OK');
              if(survey.surveyState==6) survey.surveyStateDescription=i18n.t('Survey_Stemming_Over');
              if(survey.surveyState==7) survey.surveyStateDescription=i18n.t('Survey_Stemming_Under');

              survey.phase = '';
              if(survey.options==0) survey.phase=i18n.t('Survey_Loading');
              if(survey.options==1) survey.phase=i18n.t('Survey_PostDrill');
              if(survey.options==2) survey.phase=i18n.t('Survey_PreLoading');
              if(survey.options==3) survey.phase=i18n.t('Survey_PostLoading');
              if(survey.options==4) survey.phase=i18n.t('Survey_PreStemming');

              survey.holeStateDescription = '';
              if (survey.holeState == 0) survey.holeStateDescription='OK';
              else {
                if((survey.holeState & 0x01) > 0)  survey.holeStateDescription+=i18n.t('Wet')+' ';
                if((survey.holeState & 0x02) > 0)  survey.holeStateDescription+=i18n.t('Collapsed')+' ';
                if((survey.holeState & 0x04) > 0)  survey.holeStateDescription+=i18n.t('Decked')+' ';
                if((survey.holeState & 0x08) > 0)  survey.holeStateDescription+=i18n.t('CleanOut')+' ';
                if((survey.holeState & 0x10) > 0)  survey.holeStateDescription+=i18n.t('ReDrill')+' ';
                if((survey.holeState & 0x20) > 0)  survey.holeStateDescription+=i18n.t('OverBurden')+' ';
                if((survey.holeState & 0x40) > 0)  survey.holeStateDescription+=i18n.t('Cracked')+' ';
                if((survey.holeState & 0x100) > 0) survey.holeStateDescription+=i18n.t('Tacked')+' ';
                if((survey.holeState & 0x200) > 0) survey.holeStateDescription+=i18n.t('TopLoaded')+' ';
                if((survey.holeState & 0x400) > 0) survey.holeStateDescription+=i18n.t('OverLoaded');
                if((survey.holeState & 0x800) > 0) survey.holeStateDescription+=i18n.t('UnderLoaded');
                if((survey.holeState & 0x1000) > 0) survey.holeStateDescription+=i18n.t('Start');
                if((survey.holeState & 0x2000) > 0) survey.holeStateDescription+=i18n.t('Hot');
              }
            })

            this.setState({surveyData:data});
        })
        .catch(error => this.setState({ surveyData: [] }));
    }


    getLoads(row) {
      console.log('CommonTableHoleInfo::getLoads ' + row);
      fetch(process.env.REACT_APP_HOST_URL+'commonGetLoadsDS.php',
          {
              method: 'POST',
              body: JSON.stringify(
              {
                  userId    : this.props.userDetails.userId,
                  companyId : this.props.userDetails.companyId,
                  siteId    : row.siteId,
                  patternNo : row.patternNo,
                  holeNo    : row.holeNo,
                  holeId    : row.holeId
              })
          })
          .then(response => response.json())
          .then(data => this.setState({loadData:data}))
          .catch(error => this.setState({ loadData: null }));
    }

    getAccessories(row) {
      console.log('CommonTableHoleInfo::getAccessories ' + row);
      fetch(process.env.REACT_APP_HOST_URL+'commonGetAccessoriesDS.php',
          {
              method: 'POST',
              body: JSON.stringify(
              {
                  userId    : this.props.userDetails.userId,
                  companyId : this.props.userDetails.companyId,
                  siteId    : row.siteId,
                  patternNo : row.patternNo,
                  holeNo    : row.holeNo,
                  holeId    : row.holeId
              })
          })
          .then(response => response.json())
          .then(data => this.setState({accessoryData:data}))
          .catch(error => this.setState({ accessoryData: [] }));
    }

    getDesign(row) {
      console.log('CommonTableHoleInfo::getDesign ' + row.patternNo + ':' + row.holeNo);
      fetch(process.env.REACT_APP_HOST_URL+'getDesignDataDS.php',
          {
              method: 'POST',
              body: JSON.stringify(
              {
                  userId        : this.props.userDetails.userId,
                  companyId     : this.props.userDetails.companyId,
                  holeId        : row.holeId,
                  siteId        : row.siteId,
                  patternId     : row.patternNo,
                  holeNo        : row.holeNo
              })
          })
          .then(response => response.json())
          .then(data => this.setState({designData:data}))
          .catch(error => this.setState({ designData: null }));
    }
  

    refreshLoads=() => {
      console.log('CommonTableHoleInfo::refreshLoads ');
      this.props.refreshData(14); 
      this.getLoads(this.state.selectedRow); 
    }

    refreshSurveys=() => {
      console.log('CommonTableHoleInfo::refreshSurveys ');
      this.getSurveys(this.state.selectedRow);
    }


    afterSaveCell = (cellOld, cellNew, row, column) =>    
    {
        console.log('CommonTableHoleInfo:Editing: ' + column.dataField + ":" + cellOld +":" + cellNew);

      	this.editRow = {...row}; //force copy else we have pointer to row
       
        if (column.dataField==='holeNo') {
	        this.editRow.holeNo=cellNew
        }
        else if (column.dataField==='patternNo') {
	        this.editRow.patternNo=cellNew
        }
	      else if (column.dataField==='diameter') {
	        this.editRow.diameter=cellNew
        }

        this.updateHole();
    }


    updateHole() {       
      fetch(process.env.REACT_APP_HOST_URL+'commonUpdateHoleDS.php',
          {
              method: 'POST',                
              body: JSON.stringify(
              {
                  userId        : this.props.userDetails.userId,
                  companyId     : this.props.userDetails.companyId,
                  siteId        : this.props.siteId,
                  date          : this.props.date,
                  startDate     : this.props.startDate,
                  endDate       : this.props.endDate,
                  patternId     : this.props.patternId,
                  vehicleId     : this.props.vehicleId,
                  vehicleIdList : this.props.vehicleIdList,
                  holeId        : this.editRow.holeId,
                  linkedId      : this.editRow.linkedId,
                  holeNo        : this.editRow.holeNo,
                  patternNo     : this.editRow.patternNo,
                  diameter      : this.editRow.diameter,
              })
          })
          .then(response => response.json())
          .then(data => this.props.onUpdateHoleInfos(data)) 
          .catch(error => this.setState({ error, isLoading: true }));
  }


    handleApproveClick = () => {
      console.log("CommonTableHoleInfo:handleApproveClick");
      fetch(process.env.REACT_APP_HOST_URL+'report4UpdatePatternStateDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId    : this.props.userDetails.userId,
           companyId : this.props.userDetails.companyId,
           siteId    : this.props.siteId,
           patternId : this.props.patternId,
           state     : 1
        })
      })
      .then(response => response.json())
      .then(data =>this.props.onUpdatePatternList(data) )
      .catch(error => this.setState({ error, isLoading:true }));
    }


    handleUnlockClick = () => {
      console.log("CommonTableHoleInfo:handleUnlockClick");
      fetch(process.env.REACT_APP_HOST_URL+'report4UpdatePatternStateDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId    : this.props.userDetails.userId,
           companyId : this.props.userDetails.companyId,
           siteId    : this.props.siteId,
           patternId : this.props.patternId,
           state     : 3
        })
      })
      .then(response => response.json())
      .then(data =>this.props.onUpdatePatternList(data) )
      .catch(error => this.setState({ error, isLoading:true }));
    }


    handleBlastXClick = () => {
      console.log("CommonTableHoleInfo:handleBlastXClick");
      fetch(process.env.REACT_APP_HOST_URL+'getBlastXPatternDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId    : this.props.userDetails.userId,
           companyId : this.props.userDetails.companyId,
           siteId    : this.props.siteId,
           patternId : this.props.patternId
        })
      })
      .then(response => response.text())
      .then( data =>{ 
          var fileDownload = require('js-file-download');
          fileDownload(data, 'filename.blx');
       })
      .catch(error => this.setState({ error, isLoading:true }));
    }


    handleCsvClick = () => {
      console.log("CommonTableHoleInfo:handleCsvClick");
      fetch(process.env.REACT_APP_HOST_URL+'getCsvPatternDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId    : this.props.userDetails.userId,
           companyId : this.props.userDetails.companyId,
           siteId    : this.props.siteId,
           patternId : this.props.patternId
        })
      })
      .then(response => response.text())
      .then( data =>{
          var fileDownload = require('js-file-download');
          fileDownload(data, 'filename.csv');
       })
      .catch(error => this.setState({ error, isLoading:true }));
    }


    handleCopyClick = () => {
      console.log("CommonTableHoleInfo:handleCopyClick");

this.copyToClipboard("I'm going to the clipboard !")
    .then(() => console.log('text copied !'))
    .catch(() => console.log('error'));
    }



   // return a promise
copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
}

   handleLoadAdd = () => {
     console.log ("CommonTableHoleInfo:handleLoadAdd");
     this.getLoads(this.state.selectedRow);
     this.props.refreshData(14);
   };

   handleLoadCancel = () => {
     console.log ("CommonTableHoleInfo:handleLoadCancel");
     this.setState({loadData:null });
   };

    render() 
    { 
        const { t } = this.props;

        var editAuth = false;
        if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_PATTERN) > 0) editAuth=true;

        const columns = [{
            dataField: 'holeId',
            text: 'Hole Id',
            hidden: true
        }, {
            dataField: 'linkedId',
            text: 'Linked Id',
            hidden: true
        }, {
            dataField: 'no',
            text: t('Id'),
            editable: false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; }
        }, {
            dataField: 'patternNo',
            text: t('Pattern_No'),
            editable: false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; },
            filter: textFilter()
        }, {
            dataField: 'holeNo',
            text: t('Hole_No'),
            editable: false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; } ,
            filter: textFilter()
        }, {
            dataField: 'dateTime',
            text: t('DateTime')+'('+this.props.userDetails.timeZone+')',
            editable: false,
            sort: true   ,
            filter: textFilter() 
        }, {
            dataField: 'noAccessories',
            text: t('Accessories'),
            editable: false,
            style: { textAlign: 'center' },
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; } ,
            sort: true
        }, {
            dataField: 'noSurveys',
            text: t('Surveys'),
            editable: false,
            style: { textAlign: 'center' },
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; } ,
            sort: true
        }, {
            dataField: 'noLoads',
            text: t('Loads'),
            editable: false,
            style: { textAlign: 'center' },
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; } ,
            sort: true    
        }, {
            dataField: 'noDecks',
            text: t('Decks'),
            editable: false,
            style: { textAlign: 'center' },
            sortFunc: (a, b, order, dataField) => {if (order === 'asc') { return b - a;} return a - b; } ,
            sort: true
        }, {
            dataField: 'design',
            text: t('Design'),
            editable: false,
            style: { textAlign: 'center' },
            sort: true
        }, {
            dataField: 'diameter',
            text: t('Diameter')+'('+this.props.userDetails.lengthSUnit+')',
            editable: editAuth,
            sort: true,
        }, {
            dataField: 'volume',
            text: t('Weight')+ '('+this.props.userDetails.massMUnit+')',
            editable: false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
        }, {
            dataField: 'duration',
            text: t('Duration'),
            editable: false,
            sort: true,
             sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/:/g, '');
              var y = b.replace(/:/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; },
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              let getCurrentCellIndex = e.target.cellIndex;
              console.log ('CommonTableHoleInfo:rowEvents:getCurrentCellIndex=',getCurrentCellIndex);

              this.setState({selectedRow : row});

              if (getCurrentCellIndex===4) this.getAccessories(row); //Not proud of this, but 4 is column with noAccessories
              if (getCurrentCellIndex===5) this.getSurveys(row); //Not proud of this, but 5 is column with noSurveys
              if (getCurrentCellIndex===6) this.getLoads(row); //Not proud of this, but 6 is columncwwith noLoads
              if (getCurrentCellIndex===7) this.getDecks(row); //Not proud of this, but 7 is column with noDecks
              if (getCurrentCellIndex===8) this.getDesign(row); //Not proud of this, but xxi811 is column with design X
            },
            onMouseEnter: (e, row, rowIndex) => {
              this.props.onUpdateHoleSelect(row.holeId);
            },
            onMouseLeave: (e, row, rowIndex) => {
              this.props.onUpdateHoleSelect(0);
            }
          };


        const handleDeckModalClose = () => {
              this.setState({deckData:[] });
        };

        const handleLoadModalClose = () => {
              this.setState({loadData:null });
        };

        const handleAccessoryModalClose = () => {
              this.setState({accessoryData:[] });
        };

        const handleSurveyModalClose = () => {
              this.setState({surveyData:[] });
        };

        const handleDesignModalClose = () => {
              this.setState({designData:null });
        };


        const afterFilter=(newResult, newFilters) =>{
          console.log(newFilters);
          if (newResult.length != this.filteredHoleInfosLength) {
            this.filteredHoleInfosLength = newResult.length;
            if(this.props.onUpdateFilteredHoleInfos!=null) this.props.onUpdateFilteredHoleInfos(newResult);
          }
        }
      
        return  (
        <div>
	    <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>
              {t('Detail_Hole_Information')}</h3>
            <ToolkitProvider                 
                keyField='holeId'
                data={ this.props.holeInfos } 
                columns={ columns }                 
            >
            {
    props => (
      <div>        
        <BootstrapTable { ...props.baseProps }
          bootstrap4
          classes="table-responsive"
          keyField='id'                
          striped={true} 
          hover={true} 
          condensed={true}          
          pagination={ paginationFactory()} 
          rowEvents={ rowEvents } 
          headerClasses="thead-dark"
          filter={ filterFactory({ afterFilter })}
          cellEdit={ cellEditFactory({mode: 'click' , blurToSave:true, afterSaveCell: this.afterSaveCell })}
        />

        <Modal size="xl" show={this.state.deckData.length?true:false} onHide={handleDeckModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Decks')} {this.state.selectedRow?this.state.selectedRow.holeNo:''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DeckCanvas 
              userDetails={this.props.userDetails}
              decks = {this.state.deckData}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleDeckModalClose}> {t('Close')} </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="xl" show={this.state.loadData?true:false} onHide={handleLoadModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Loads')} {this.state.selectedRow?this.state.selectedRow.holeNo:''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(this.state.loadData && this.state.loadData.length>0) && [
              <LoadTable
                userDetails   = {this.props.userDetails}
                loads         = {this.state.loadData}
                refreshData   = {this.refreshLoads}
                siteId        = {this.props.siteId}
                date          = {this.props.date}
                startDate     = {this.props.startDate}
                endDate       = {this.props.endDate}
                patternId     = {this.props.patternId}
                patternNo     = {this.props.patternNo}
                vehicleId     = {this.props.vehicleId}
                vehicleIdList = {this.props.vehicleIdList}
            />
            ]}
            {(this.props.userDetails.flags&AuthoriseObj.AUTH_EDIT_PATTERN)>0?  
              <LoadAdd 
                userDetails  = {this.props.userDetails}
                siteId       = {this.props.siteId}
                patternNo    = {this.props.patternId}
                holeNo       = {this.props.holeNo}
                /*latitude     = {this.state.selectedRow.latitude?this.state.selectedRow.latitude:this.state.selectedRow.bestLat}
                longitude    = {this.state.selectedRow.longitude?this.state.selectedRow.longitude:this.state.selectedRow.bestLng}*/
                handleAdd    = {this.handleLoadAdd}
                handleCancel = {this.handleLoadCancel}
              />
            :<Button variant="primary" onClick={handleLoadModalClose}> {t('Close')} </Button> }
          </Modal.Body>
        </Modal>


        <Modal size="xl" show={this.state.surveyData.length?true:false} onHide={handleSurveyModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Surveys')} {this.state.selectedRow?this.state.selectedRow.holeNo:''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SurveyTable
              userDetails   = {this.props.userDetails}
              surveys       = {this.state.surveyData}
              refreshData   = {this.refreshSurveys}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSurveyModalClose}> {t('Close')} </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="xl" show={this.state.accessoryData.length?true:false} onHide={handleAccessoryModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Accessories')} {this.state.selectedRow?this.state.selectedRow.holeNo:''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AccessoryTable
              userDetails   = {this.props.userDetails}
              accessories   = {this.state.accessoryData}
              refreshData   = {this.refreshAccessories}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleAccessoryModalClose}> {t('Close')} </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="xl" show={this.state.designData!=null?true:false} onHide={handleDesignModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Design')} {this.state.selectedRow?this.state.selectedRow.holeNo:''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.designData!=null?
            <DesignData
              userDetails  = {this.props.userDetails}
              designData   = {this.state.designData}
              isInfoWindow = {false}
            />:null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleDesignModalClose}> {t('Close')} </Button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }</ToolkitProvider>
        </div>)
    }
}
export default withTranslation()(TableHoleInfo);
