import React, { Component } from "react";
import ZoneMap from './ZoneMap';
import { withTranslation } from 'react-i18next';
 
class SetupZones extends Component {
    constructor(props) {
        super(props);
        this.state = {         
        };
      }

 componentDidMount() {
    console.log ("SetupZones:componentDidMount");
 }


  render() {
    const { t } = this.props;

    return (
      <div>
        <ZoneMap            
            userDetails={this.props.userDetails}  />
      </div>
    );
  }
}

export default withTranslation()(SetupZones);
