import Chart from "react-google-charts";
import React from "react";
import { withTranslation } from 'react-i18next';

class ChartSpeedingDriversMonth extends React.Component 
{   
    constructor(props) 
    {
        super(props);
    
        this.state = {
          data: null,
          error: null,
          isLoading: true
        };
    }


    componentDidMount() {
    }

    componentWillReceiveProps(nextProps){        
    }

    render() 
    { 
        const { t } = this.props;

        return  (
            <Chart
                width={'1000'}
                chartType="ColumnChart"
                loader={<div>{t('Loading')}</div>}
                data={this.props.driverMonthData}
                chartEvents={[
                    {
                      eventName: "select",
                        callback: ({ chartWrapper, google }) => {
                          var selection = chartWrapper.getChart().getSelection();
                          var row = selection[0].row;
                          console.log("Chart Click ", row,selection);
                          this.props.onDriverSelect(row);
                        }
                     }
                    ]}
                options={{
                    chartArea: { width: 1000},
                    legend: 'none'
                }}
          />
        )
    }
}

export default withTranslation()(ChartSpeedingDriversMonth);
