import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import AuthoriseObj from './Authorise.js';
import { withTranslation } from 'react-i18next';

class Report4TablePatternList extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.state = {
          error: null,
        };
    }

    componentDidMount() {       
    }
    
    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'siteId',
            text: 'Site Id',
	          hidden: true
          }, {           
            dataField: 'date',
            text: t('Last_Date'),
            editable: false,
            sort: true
          }, {           
            dataField: 'siteDescription',
            text: t('Site'),
            editable: false,
            sort: true
          }, {     
            dataField: 'patternNo',
            text: t('Pattern_No'),
            editable: false,
            filter: textFilter(),
            sort: true 
          }, {     
            dataField: 'noVehicles',
            text: t('No_Vehicles'),
            editable: false,
            sort: true                
          }, {
            dataField: 'noDays',
            text: t('No_Days'),
            editable: false,
            sort: true
          }, {     
            dataField: 'noHolesString',
            text: t('No_Holes'),
            editable: false,
            sort: true                
          }, {     
            dataField: 'weightString',
            text: t('Weight')+'('+this.props.userDetails.massMUnit+')',
            editable: false,
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              var x = a.replace(/,/g, '');
              var y = b.replace(/,/g, '');
              if (order === 'asc')
                { return y - x;} return x - y; }
          }, {
            dataField: 'persentageOverfill',
            text: t('Persentage_Overfill'),
            hidden : ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_KPI)>0)?false:true,
            editable: false,
            style: function callback(cell, row, rowIndex, colIndex)
                   {
                     if (row.overfillTrend < 0) {
                       return({color: '#FD0303'});
                     } else if (row.overfillTrend > 0){
                       return ({color: '#03D003'});
                     } else {
                       return ({color: '#000000'});
                     }

                   },
            sort: true
          }, {     
            dataField: 'budget',
            text: t('Budget') + '('+this.props.userDetails.currencyDescription + ')',
            editable: false,
            hidden : ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_MONETARY)>0)?false:true,
            style: function callback(cell, row, rowIndex, colIndex) 
                   {
                     if (row.budgetUsd < 0) {
                       return({color: '#FD0303'});
                     } else {
                       return ({color: '#03D003'}); 
                     }
                   },
            sort: true                
          }, {
            dataField: 'weightTruckDay',
            text: t('Weight_Truck_Day'),
            hidden : ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_KPI)>0)?false:true,
            editable: false,
            style: function callback(cell, row, rowIndex, colIndex)
                   {
                     if (row.weightTruckDayTrend < 0) {
                       return({color: '#FD0303'});
                     } else if (row.weightTruckDayTrend > 0){
                       return ({color: '#03D003'});
                     } else {
                       return ({color: '#000000'});
                     }
                   },
            sort: true
          }, {
            dataField: 'holesTruckDay',
            text: t('Holes_Truck_Day'),
            editable: false,
            hidden : ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_KPI)>0)?false:true,
            style: function callback(cell, row, rowIndex, colIndex)
                   {
                     if (row.holesTruckDayTrend < 0) {
                       return({color: '#FD0303'});
                     } else if (row.holesTruckDayTrend > 0){
                       return ({color: '#03D003'});
                     } else {
                       return ({color: '#000000'});
                     }
                   },
            sort: true
          }, {     
            dataField: 'approvedUserName',
            text: t('Approved'),
            editable: false,
            sort: true                
          }, {     
            dataField: 'approvedDate',
            text: t('Approved_Date')+'('+this.props.userDetails.timeZone+')',
             headerStyle: (colum, colIndex) => {
              return { width: '180px', textAlign: 'center' };
            },
            editable: false,
            sort: true                
          }, {     
            dataField: 'url',
            text: t('URL'),
            editable: false,
            hidden:true,
            sort: false ,
            headerStyle: (colum, colIndex) => {
              return { width: '300px', textAlign: 'center' };
            },
            formatter: (cell, row, rowIndex, extraData) => (
              <div>
                <a href={row.url}>{row.url}</a>
              </div>
            )
          }, {     
            dataField: 'approvedState',
            text: t('Approved_State'),
            hidden: true                
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${this.props.userDetails.userId} ${this.props.userDetails.companyId} ${row.siteId} ${row.patternNo}`);
              this.props.onPatternClick(row);  
            },
            onMouseEnter: (e, row, rowIndex) => {
              //console.log(`enter on row with index: ${row.year} ${row.month}`);
            }
        };
                      
        const selectRow = {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn:true,
          style: { backgroundColor: '#c8e6c9'}
        };

        return  (            
            <div>
                {(this.props.patternList!=null) && [
                <h3 key={1} style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Recent_Patterns')}</h3>,
                <BootstrapTable 
                    key={2}
                    bootstrap4
                    classes="table-responsive"
                    keyField='id'    
            		    selectRow={ selectRow }
                    striped={true} 
                    hover={true} 
                    condensed={true} 
                    data={ this.props.patternList } 
                    columns={ columns } 
                    rowEvents={ rowEvents } 
                    filter={ filterFactory()}
   		              pagination={ paginationFactory()}
                    headerClasses="thead-dark"            
                />]}
             </div>
        )
    }
}

export default withTranslation()(Report4TablePatternList);

