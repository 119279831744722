import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type} from 'react-bootstrap-table2-editor';
import AuthoriseObj from './Authorise.js'
import { withTranslation } from 'react-i18next';

class SetupVehicleTable1 extends React.Component 
{   
    constructor(props) 
    {
        super(props);

        this.editRow = null;

        this.state = {
          devIds:null
        };

        this.temp = false;
        this.devIds=null; //Load once then manage locally else the select options just out of synch
    }

    componentDidMount() {       
      this.getDevIds();
    }

    getDevIds() {
      console.log("SetupVehicleTable1:getDevIds");
      this.temp=false;

      fetch(process.env.REACT_APP_HOST_URL+'commonDevIdsDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId
        })
       })
       .then(response => response.json())
       .then(data => this.devIds=data)
       .catch(error => this.devIds=null);
    }

    updateRow() {       
        fetch(process.env.REACT_APP_HOST_URL+'setupVehicleUpdateDS.php',
            {
                method: 'POST',                
                body: JSON.stringify(
                {
                    userId             : this.props.userDetails.userId,
                    companyId          : this.props.userDetails.companyId,
                    vehicleId          : this.editVehicleId,
                    devId              : this.editRow.devId,
                    vehicleDescription : this.editRow.vehicleDescription,
                    stateDescription   : this.editRow.stateDescription,
                    counterCalibrate   : this.editRow.counterCalibrate,
                    hoseReel           : this.editRow.hoseReel,
                    importPos          : this.editRow.importPos,
                    speedLimit         : this.editRow.speedLimit,
                    pulsesRev          : this.editRow.pulsesRev,
                    serviceHoursPump   : this.editRow.serviceHoursPump,
                    exportId           : this.editRow.exportId,
                })
            })
          .then(response => {this.props.handleUpdate();})
          .catch(error => this.setState({ error}));   
    }

    afterSaveCell = (cellOld, cellNew, row, column) =>    
    {
        console.log('SetupVehicleTable1:afterSaveCell: ' + column.dataField + ":" + cellOld + "->" + cellNew);

        //Remove the DevId added onEditStart, but only if it is not 'Not Installed'
        if(!isNaN(cellOld)) { //probably better to serach for it, but it should be at the bottom
          this.devIds.pop();
        }

        if (cellOld!==cellNew){
          this.editRow = {...row};
          this.editRow.speedLimit = null;
          this.editRow.pulsesRev = null;
       
          if (column.dataField==='vehicleDescription') {
            this.editRow.vehicleDescription=cellNew;
          }
          else if (column.dataField==='devId') {
            this.editRow.devId=cellNew;

            //Remove New from our devId list, but not the not connected option
            if(!isNaN(cellNew)){
              let index = this.devIds.findIndex(x => x.value === cellNew);
              this.devIds.splice(index, 1);
            }

            //Got to add the old/non used anymore to our list
            if(!isNaN(cellOld)) {
              this.devIds.push({ value: cellOld, label: cellOld });
            }
          }
          else if (column.dataField==='stateDescription') {
            this.editRow.stateDescription=cellNew;
          } else if (column.dataField==='counterCalibrate') {
            this.editRow.counterCalibrate=cellNew;
          } else if (column.dataField==='hoseReel') {
            this.editRow.hoseReel=cellNew;
          } else if (column.dataField==='speedLimit') {
            this.editRow.speedLimit=cellNew;
          } else if (column.dataField==='pulsesRev') {
            this.editRow.pulsesRev=cellNew;
          } else if (column.dataField==='serviceHoursPump') {
            this.editRow.serviceHoursPump=cellNew;
          } else if (column.dataField==='importPos') {
            this.editRow.importPos=cellNew;
          } else if (column.dataField==='exportId') {
            this.editRow.exportId=cellNew;
          }

          this.updateRow();
        }
    }

    render() 
    { 
        const { t } = this.props;

        if ((this.props.vehicles==null) || (this.devIds==null))
        {
            return (<p>{t('Loading')}</p>);
        }

        var editAuth = false;
        if ((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_VEHICLES) > 0) editAuth=true;
   
        const columns = [{
            dataField: 'lastSeenDateTime',
            text: t('Last_Download')+'('+this.props.userDetails.timeZone+')',
            editable: false,
            headerStyle: (colum, colIndex) => {
              return { width: '180px', textAlign: 'center' };
            },
            sort: true                
        }, {
            dataField: 'vehicleDescription',
            text: t('Description'),
            editable: editAuth,
            sort: true
        }, {
            dataField: 'devId',
            text: t('Hardware_Id'),
            editable: editAuth,
            sort: true,
            editor: {
              type: Type.SELECT,
              getOptions: (setOptions, { row, column }) => { //Have to this dynamically because devIds list keeps on changing
                if(!isNaN(row.devId)){ //No need to add Not Installed, it should always be there
                  this.devIds.push({ value: row.devId, label: row.devId });
                }
                return this.devIds;
              }
              //options: this.state.devIds
            }
        }, {
            dataField: 'counterCalibrate',
            text: this.props.userDetails.massMUnit+'/'+t('Rev'),
            editable: editAuth,
            sort: true            
        }, {
            dataField: 'speedLimit',
            text: t('Speed_Limit')+ '('+this.props.userDetails.speedUnit+')',
            editable: editAuth,
            hidden: ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_REPORT_SPEEDING)>0)?false:true,
            sort: true            
        }, {
            dataField: 'pulsesRev',
            text: t('Pulses_Rev'),
            editable: editAuth,
            hidden: ((this.props.userDetails.flags & AuthoriseObj.AUTH_ROOT)>0)?false:true,
            sort: true            
        }, {
            dataField: 'serviceHoursPump',
            text: t('Pump_Service_Hours'),
            editable: editAuth,
            hidden: ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_REPORT_MAINTENANCE)>0)?false:true,
            sort: true            
        }, {
            dataField: 'otaDescription',
            text: 'OTA_Version',
            editable: false,
            sort: true
        }, {
            dataField: 'otaDateTime',
            text: t('Date')+'('+this.props.userDetails.timeZone+')',
            editable: false,
            headerStyle: (colum, colIndex) => {
              return { width: '180px', textAlign: 'center' };
            },
            sort: true
        }, {
            dataField: 'hoseReel',
            text: t('Hose_Reel_Sensor'),
            editable: editAuth,
            blurToSave: true,
            sort: true,
            editor: {
              type: Type.SELECT,
              options: [{
                value: t('Installed'),
                label: t('Installed')
              }, {
                value: '-',
                label: '-'
              }]
            }
         }, {
            dataField: 'importPos',
            text: t('Snap_Positions'),
            editable: editAuth,
            blurToSave: true,
            hidden: ((this.props.userDetails.flags & AuthoriseObj.AUTH_SEE_SETUP_IMPORT_PATTERN)>0)?false:true,
            sort: true,
            editor: {
              type: Type.SELECT,
              options: [{
                value: t('Yes'),
                label: t('Yes')
              }, {
                value: t('No'),
                label: t('No')
              }]
            }
         }, {
            dataField: 'stateDescription',
            text: t('State'),
            editable: editAuth,
            blurToSave: true,
            sort: true,            
            editor: {
              type: Type.SELECT,
              options: [{
                value: t('Active'),
                label: t('Active')
              }, {
                value: t('Inactive'),
                label: t('Inactive')
              }]
            }      
         }, {
           dataField: 'exportId',
           text: t('ExportId'),
           editable: editAuth,
           hidden: false,
           sort: true
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.id} ${row.vehicleDescription}`);
            },
            onMouseEnter: (e, row, rowIndex) => {
            }
          };

                      
        return  (            
          <div>
            <h3 style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Vehicle_Setup')}</h3>
            <BootstrapTable 
               bootstrap4
               classes="table-responsive"
               keyField='id'    
               striped={true} 
               hover={true} 
               condensed={true} 
               data={ this.props.vehicles } 
               columns={ columns } 
               rowEvents={ rowEvents } 
               headerClasses="thead-dark"
               cellEdit={ cellEditFactory({mode: 'click', blurToSave:true, afterSaveCell: this.afterSaveCell, onStartEdit: (row) => {this.editVehicleId=row.id }})}
           />
        </div>)
    }
}
export default withTranslation()(SetupVehicleTable1);

