import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import AuthoriseObj from './Authorise.js'
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';

class MapInfoWindowMulti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorPatternNo: false,
      errorDensity: false,
      patternNo: '',
      density:''
    };

    this.handleClick           = this.handleClick.bind(this);
    this.handleChangePatternNo = this.handleChangePatternNo.bind(this);
    this.handleChangeDensity   = this.handleChangeDensity.bind(this);
    this.handleSave            = this.handleSave.bind(this);
    this.handleCancel          = this.handleCancel.bind(this);
  }

  componentDidMount(){
    this.patternNoInput.focus();
  }

  handleClick(event) {
    event.stopPropagation();
    if(event.target.id==='patternNo') this.patternNoInput.focus();
    if(event.target.id==='density') this.densityInput.focus();
    console.log("MapInfoWindowMulti:handleClick",event.target.id);
  }

  handleChangePatternNo(event) {
    console.log("MapInfoWindowMulti:handleChangePatternNo");
    var patternNo = event.target.value;
    if (event.target.validity.valid) this.setState({patternNo:patternNo});
  }

  handleChangeDensity(event) {
    var density = event.target.value;
    if (event.target.validity.valid) this.setState({density:density});
  }

  handleSave(event) {
    console.log("MapInfoWindowMulti:handleSave:",this.state.patternNo, this.state.density);

    if((this.state.patternNo != null) || (this.state.density!=null)) //We have some edits
    {
      this.props.handleUpdateHoles(this.state.patternNo, this.state.density);
      this.props.handleMarkersClear();
    }
    else
    {
      this.setState({errorPatternNo: true});
      this.setState({errorDensity: true});
    }
   // event.stopPropagation(); //Otherwise HeatMap toggle
  }

  handleCancel(event) {
    console.log("MapInfoWindowMulti:handleCancel:");
    this.props.handleMarkersClear();
    //event.stopPropagation(); //Otherwise HeatMap toggle
  }

  render() {
    const { t } = this.props;
    const holeInfo = this.props.holeInfo;

    const infoWindowStyle = {
      position: 'relative',
      bottom: 250,
      left: 0,
      width: 350,
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 5,
      fontSize: 14,
      zIndex: 100,
      opacity: 1.0 
    };

    return (
      <div key='MapMultiInfoWindow'style={infoWindowStyle} onClick={this.handleClick} onMouseDown={this.handleClick} onMouseUp={this.handleClick}>
        <div key='MapInfoWindowDiv1' style={{ fontSize: 20 }}>
          {t('Multi_Hole')}
          <div key='MapInfoWindowDiv2' style={{ fontSize: 12 }}> {t('Selected_loads')} {this.props.noSelected}</div>
            <hr style={{margin:2}}></hr>
            <form key ='f1'>
              <div key='MapInfoWindowDiv3'>
                <label key ='l1' style={{fontSize:15, fontWeight:'normal', border:'none'}} htmlFor="patternNo">{t('Pattern_No')}</label>
                <input 
                  key ='i1'
                  id='patternNo' 
                  ref={(input) => {this.patternNoInput = input; }}
                  style={{
                    float:'right', 
                    textAlign:'right', 
                    fontSize:15, 
                    fontWeight:'bold', 
                    border:'1px solid black',
                    backgroundColor: (this.state.errorPatternNo==false) ? 'white':'lightpink'
                  }}
                  type="text" 
                  onChange={this.handleChangePatternNo} 
                  value={this.state.patternNo} 
                  pattern="^[a-zA-Z0-9-_]{1,32}$"
                />
              </div>
              <div key='MapInfoWindowDiv4'>
                <label key='l2'style={{fontSize:15, fontWeight:'normal', border:'none'}} htmlFor="density">{t('Density')}</label>
                <input 
                  key='i2' 
                  ref={(input) => {this.densityInput = input; }}
                  id='density' 
                  style={{
                    float:'right', 
                    textAlign:'right', 
                    fontSize:15, 
                    fontWeight:'bold', 
                    border:'1px solid black', 
                    backgroundColor: (this.state.errorDensity==false) ? 'white':'lightpink'
                  }}
                  type="text" 
                  onChange={this.handleChangeDensity} 
                  value={this.state.density} 
                  pattern="^-?[0-9]\d*\.?\d*$"/>
              </div>
          </form>
        </div>
        <div key='MapInfoWindowDiv6'style ={{ clear:'both'}}>
          <Button key='b1' size="sm" onClick={this.handleSave}>{t('OK')}</Button>
          {" "}
          <Button key='b2' size="sm" onClick={this.handleCancel}>{t('Cancel')}</Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MapInfoWindowMulti);

