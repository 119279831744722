import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import AuthoriseObj from './Authorise.js'
import AccessoryTable from './AccessoryTable';
import { withTranslation } from 'react-i18next';
import i18n from '../translations/i18n';


class MapAccessoryInfoWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessories : [],
      holeSummary : null
    };
  }

  componentDidMount() {
   this.getAccessories();
  }

  refreshAccessoryData = () => {
    console.log("MapAccessoryInfoWindow:refreshAccessoryData");
    this.getAccessories();
    this.props.refreshData(15);
  }


  handleCancel = (event) => {
    console.log("MapAccessoryInfoWindow:handleCancel:");
    this.props.handleMarkerClear();
  }

  handleClick = (event) => {
    event.stopPropagation(); //Otherwise HeatMap toggle
    console.log("MapAccessoryInfoWindow:handleClick:");
    this.props.handleClick(event);
  }

  getAccessories() {
    console.log("MapAccessoryInfoWindow:Accessories",this.props.siteId, this.props.patternNo,this.props.holeNo);
    fetch(process.env.REACT_APP_HOST_URL+'commonGetAccessoriesDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId        : this.props.userDetails.userId,
        companyId     : this.props.userDetails.companyId,
        holeId        : this.props.hole.holeId,
        siteId        : this.props.hole.siteId,
        patternNo     : this.props.hole.patternNo,
        holeNo        : this.props.hole.holeNo
      })
    })
    .then(response => response.json())
    .then(data => {
      this.setState({accessories:data});
    })
    .catch(error => this.setState({ accessories: [] }));
  }


  render() {
    const { t } = this.props;

    const holeInfo = this.props.holeInfo;

    let offset = 0;
    const infoWindowStyle = {
      position: 'relative',
      bottom: 300,
      left: '-100px',
      width: 750,
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 5,
      fontSize: 14,
      zIndex: 100,
      opacity: 0.90
    };

    return (
      <div onClick={this.handleClick} style={infoWindowStyle}>
        <div style={{ fontSize: 20 }}>
          <span style={{textAlign:'left', fontWeight:'bold' }}> {this.props.hole.patternNo} </span>
          <span style={{float:'right', textAlign:'right', fontWeight:'bold'}}>{this.props.hole.holeNo} </span>
        </div>
        <hr style={{margin:2}}></hr>
  
       {(this.state.accessories.length > 0) && [
         <div>
           <h6 style={{textAlign: 'center', color: 'black'}}>Accessories</h6>
           <AccessoryTable
             key={899}
             userDetails = {this.props.userDetails}
             refreshData = {this.refreshAccessoryData}
             accessories = {this.state.accessories}
           /> 
         </div>
       ]}
      </div>
    );
  }
}

export default withTranslation()(MapAccessoryInfoWindow);
