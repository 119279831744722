import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';

class TableAccessorySummary extends React.Component 
{   
    constructor(props) 
    {
        super(props);
       
        this.state = {
          error: null,
        };
    }

    componentDidMount() {
    }


    render() 
    { 
        const { t } = this.props;

        const columns = [{
            dataField: 'description',
            text: t('Description'),
            editable: false,
            hidden: false,
            sort: true,
          }, {
            dataField: 'type',
            text: t('Type'),
            editable: false,
            hidden: false,
            sort: true,
          }, {
            dataField: 'quantity',
            text: t('Quantity'),
            editable: false,
            hidden: false,
            sort: false,

        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(`clicked on row with index: ${row.description}`);
            },
            onMouseEnter: (e, row, rowIndex) => {
            }
          };
         
        return  (
         <div>
            <BootstrapTable
                bootstrap4
                classes="table-responsive"
                keyField='accessoryId'
                striped={true}
                condensed={true}
                data={ this.props.patternAccessorySummary }
                columns={ columns }
                rowEvents={ rowEvents }
		            pagination={ paginationFactory()}
                headerClasses="thead-dark"
            />
        </div>)
    }
}

export default withTranslation()(TableAccessorySummary);
