import React, { Component } from "react";
import SetupVehicleTable1 from './SetupVehicleTable.1';
import AuthoriseObj from './Authorise.js'
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
 
class SetupVehicles extends Component {
  constructor(props) {
    super(props);
    this.state = {         
      vehicles:null,
    };
  }

  componentDidMount() {
    this.getVehicles();
  }


  getVehicles=()=> {
    console.log("SetupVehicles:getVehicles");

    fetch(process.env.REACT_APP_HOST_URL+'setupVehiclesGetDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId
      })
    })
    .then(response => response.json())
    .then(data => this.setState({ vehicles:data }))
    .catch(error => this.setState({ vehicles:null }));
  }


  handleUpdate = () =>{
    this.getVehicles();
  }


  handleClickAdd = () => {
    console.log("SetupVehicles:handleClickAdd");

    fetch(process.env.REACT_APP_HOST_URL+'setupVehicleAddDS.php',
    {
        method: 'POST',
        body: JSON.stringify(
        {
            userId      : this.props.userDetails.userId,
            companyId   : this.props.userDetails.companyId,
        })
    })
    .then(response => this.handleUpdate())
    .catch(error => this.setState({ vehicles:null }));
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <SetupVehicleTable1 
            userDetails={this.props.userDetails} 
            handleUpdate={this.handleUpdate}
            vehicles={this.state.vehicles}/>
        {((this.props.userDetails.flags & AuthoriseObj.AUTH_EDIT_SETUP_VEHICLES) > 0) && [
          <div>
            <Button key={1} variant="primary" type="submit" onClick={this.handleClickAdd}> {t('Add')} </Button>
          </div>
        ]}

      </div>
    );
  }
}

export default withTranslation()(SetupVehicles);
